import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, Button, IconButton, TextField, Alert } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SVGBirthCompatibleBackImg from '../components/birthCompatibleBackImg';
import birthCompatibleBackImgSample from '../components/birthCompatibleBackImgSample.svg';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBannerMobile from '../components/AdSenseBannerMobile'; // 320x100、ラージモバイルバナー をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // 記事内広告 をインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');  // 日本語ロケールを設定

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

/* *********************************************************************************************** */
/* ***** ページ名の作成 *************************************************************************** */
/* *********************************************************************************************** */

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}
// let pageTitle = `九星で見る他の方との相性`
let pageTitleS = `相性判断`
let uppageTitle = `誕生日毎`

const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton
            aria-label="ホームページへページ移動"
            sx={{
                margin: '0px 0px 1px 0px',
                padding: '0px'
            }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Link
        component={RouterLink}
        underline="hover"
        key="2"
        color="inherit"
        to="/birth"
        onClick={handleClick}
    >
        {uppageTitle}
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];

function BirthCompatibleForm(partnerA: string, partnerB: string, partnerC: string, partnerD: string, partnerE: string, partnerF: string) {
    const [dateValueA, setDateValueA] = useState(partnerA);
    const [dateValueB, setDateValueB] = useState(partnerB);
    const [dateValueC, setDateValueC] = useState(partnerC);
    const [dateValueD, setDateValueD] = useState(partnerD);
    const [dateValueE, setDateValueE] = useState(partnerE);
    const [dateValueF, setDateValueF] = useState(partnerF);
    const navigate = useNavigate();

    const handleButtonClick = () => {
        // For this example, I'm just navigating to a dummy URL.
        // Adjust according to your needs.
        navigate(`/birth_compatible/${dateValueA}x${dateValueB}x${dateValueC}x${dateValueD}x${dateValueE}x${dateValueF}`);
    };

    return (
        <form>
            <span style={{ marginTop: '1rem', fontSize: '0.8rem', color: 'gray' }}>1人目の生年月日（必須）</span>
            <TextField
                required
                margin="dense"
                id="partnerA"
                label=""
                type="date"
                fullWidth
                variant="outlined"
                value={dateValueA}
                onChange={(e) => setDateValueA(e.target.value)}
                inputProps={{ min: "1901-01-01", max: "2099-12-31", style: { textAlign: 'left', backgroundColor: 'white', margin: '0.3rem', letterSpacing: '0.1rem' } }}
            />
            <span style={{ marginTop: '1rem', fontSize: '0.8rem', color: 'gray' }}>2人目の生年月日（必須）</span>
            <TextField
                required
                margin="dense"
                id="partnerB"
                label=""
                type="date"
                fullWidth
                variant="outlined"
                value={dateValueB}
                onChange={(e) => setDateValueB(e.target.value)}
                inputProps={{ min: "1901-01-01", max: "2099-12-31", style: { textAlign: 'left', backgroundColor: 'white', margin: '0.3rem', letterSpacing: '0.1rem' } }}
            />
            <span style={{ marginTop: '1rem', fontSize: '0.8rem', color: 'gray' }}>3人目の生年月日（任意）</span>
            <TextField
                margin="dense"
                id="partnerC"
                label=""
                type="date"
                fullWidth
                variant="outlined"
                value={dateValueC}
                onChange={(e) => setDateValueC(e.target.value)}
                inputProps={{ min: "1901-01-01", max: "2099-12-31", style: { textAlign: 'left', backgroundColor: 'white', margin: '0.3rem', letterSpacing: '0.1rem' } }}
            />
            <span style={{ marginTop: '1rem', fontSize: '0.8rem', color: 'gray' }}>4人目の生年月日（任意）</span>
            <TextField
                margin="dense"
                id="partnerD"
                label=""
                type="date"
                fullWidth
                variant="outlined"
                value={dateValueD}
                onChange={(e) => setDateValueD(e.target.value)}
                inputProps={{ min: "1901-01-01", max: "2099-12-31", style: { textAlign: 'left', backgroundColor: 'white', margin: '0.3rem', letterSpacing: '0.1rem' } }}
            />
            <span style={{ marginTop: '1rem', fontSize: '0.8rem', color: 'gray' }}>5人目の生年月日（任意）</span>
            <TextField
                margin="dense"
                id="partnerE"
                label=""
                type="date"
                fullWidth
                variant="outlined"
                value={dateValueE}
                onChange={(e) => setDateValueE(e.target.value)}
                inputProps={{ min: "1901-01-01", max: "2099-12-31", style: { textAlign: 'left', backgroundColor: 'white', margin: '0.3rem', letterSpacing: '0.1rem' } }}
            />
            <span style={{ marginTop: '1rem', fontSize: '0.8rem', color: 'gray' }}>6人目の生年月日（任意）</span>
            <TextField
                margin="dense"
                id="partnerF"
                label=""
                type="date"
                fullWidth
                variant="outlined"
                value={dateValueF}
                onChange={(e) => setDateValueF(e.target.value)}
                inputProps={{ min: "1901-01-01", max: "2099-12-31", style: { textAlign: 'left', backgroundColor: 'white', margin: '0.3rem', letterSpacing: '0.1rem' } }}
            />
            <Button variant="contained" sx={{ marginTop: '1rem' }} onClick={handleButtonClick}>結果を表示する</Button>
        </form>
    );
}

let partnerA = "";
let partnerB = "";
let partnerC = "";
let partnerD = "";
let partnerE = "";
let partnerF = "";

let partnerAYMD = "";
let partnerBYMD = "";
let partnerCYMD = "";
let partnerDYMD = "";
let partnerEYMD = "";
let partnerFYMD = "";

const Birthday: React.FC = () => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    let { pageName } = useParams();

    if (pageName !== undefined) {
        pageName = pageName.replace(/[^0-9\-Xx]/g, ''); // 数字と、-Xx以外を削除

        const pageNameTake = pageName.split("x");

        if (pageNameTake[0] === undefined) { pageNameTake[0] = ""; }
        if (pageNameTake[1] === undefined) { pageNameTake[1] = ""; }
        if (pageNameTake[2] === undefined) { pageNameTake[2] = ""; }
        if (pageNameTake[3] === undefined) { pageNameTake[3] = ""; }
        if (pageNameTake[4] === undefined) { pageNameTake[4] = ""; }
        if (pageNameTake[5] === undefined) { pageNameTake[5] = ""; }

        partnerA = pageNameTake[0]; // 9999-99-99
        partnerB = pageNameTake[1]; // 9999-99-99
        partnerC = pageNameTake[2]; // 9999-99-99
        partnerD = pageNameTake[3]; // 9999-99-99
        partnerE = pageNameTake[4]; // 9999-99-99
        partnerF = pageNameTake[5]; // 9999-99-99

        partnerAYMD = pageNameTake[0].replace(/-/g, "");
        partnerBYMD = pageNameTake[1].replace(/-/g, "");
        partnerCYMD = pageNameTake[2].replace(/-/g, "");
        partnerDYMD = pageNameTake[3].replace(/-/g, "");
        partnerEYMD = pageNameTake[4].replace(/-/g, "");
        partnerFYMD = pageNameTake[5].replace(/-/g, "");

        if (Number(partnerA.substring(0, 4)) < 1901 || Number(partnerA.substring(0, 4)) > 2100) { partnerA = ""; }
        if (Number(partnerB.substring(0, 4)) < 1901 || Number(partnerB.substring(0, 4)) > 2100) { partnerB = ""; }
        if (Number(partnerC.substring(0, 4)) < 1901 || Number(partnerC.substring(0, 4)) > 2100) { partnerC = ""; }
        if (Number(partnerD.substring(0, 4)) < 1901 || Number(partnerD.substring(0, 4)) > 2100) { partnerD = ""; }
        if (Number(partnerE.substring(0, 4)) < 1901 || Number(partnerE.substring(0, 4)) > 2100) { partnerE = ""; }
        if (Number(partnerF.substring(0, 4)) < 1901 || Number(partnerF.substring(0, 4)) > 2100) { partnerF = ""; }

        const partnerAdate = new Date(partnerA);
        const partnerBdate = new Date(partnerB);
        const partnerCdate = new Date(partnerC);
        const partnerDdate = new Date(partnerD);
        const partnerEdate = new Date(partnerE);
        const partnerFdate = new Date(partnerF);

        if (isNaN(partnerAdate.getDate()) === true) { partnerA = ""; } // 日付が存在しなければ、""
        if (isNaN(partnerBdate.getDate()) === true) { partnerB = ""; } // 日付が存在しなければ、""
        if (isNaN(partnerCdate.getDate()) === true) { partnerC = ""; } // 日付が存在しなければ、""
        if (isNaN(partnerDdate.getDate()) === true) { partnerD = ""; } // 日付が存在しなければ、""
        if (isNaN(partnerEdate.getDate()) === true) { partnerE = ""; } // 日付が存在しなければ、""
        if (isNaN(partnerFdate.getDate()) === true) { partnerF = ""; } // 日付が存在しなければ、""
    }

    let SvgBCIUserCount = 0;
    if (partnerA !== "") { SvgBCIUserCount++; }
    if (partnerB !== "") { SvgBCIUserCount++; }
    if (partnerC !== "") { SvgBCIUserCount++; }
    if (partnerD !== "") { SvgBCIUserCount++; }
    if (partnerE !== "") { SvgBCIUserCount++; }
    if (partnerF !== "") { SvgBCIUserCount++; }
    SvgBCIUserCount -= 1;

    const partner_LinkTxtPlus = "から見た、他の" + SvgBCIUserCount + "人";

    let partnerB_br = "";
    let partnerC_br = "";
    let partnerD_br = "";
    let partnerE_br = "";
    let partnerF_br = "";

    let partnerB_LinkTxt = "";
    let partnerC_LinkTxt = "";
    let partnerD_LinkTxt = "";
    let partnerE_LinkTxt = "";
    let partnerF_LinkTxt = "";

    let partnerB_LinkTxtPlus = "";
    let partnerC_LinkTxtPlus = "";
    let partnerD_LinkTxtPlus = "";
    let partnerE_LinkTxtPlus = "";
    let partnerF_LinkTxtPlus = "";


    if (partnerB !== "") {
        partnerB_br = "<div></div>";
        partnerB_LinkTxtPlus = partner_LinkTxtPlus;
        partnerB_LinkTxt = partnerB.substring(0, 4) + "年" + parseInt(partnerB.substring(5, 7), 10) + "月" + parseInt(partnerB.substring(8, 10), 10) + "日生まれ";
    }
    if (partnerC !== "") {
        partnerC_br = "<div></div>";
        partnerC_LinkTxtPlus = partner_LinkTxtPlus;
        partnerC_LinkTxt = partnerC.substring(0, 4) + "年" + parseInt(partnerC.substring(5, 7), 10) + "月" + parseInt(partnerC.substring(8, 10), 10) + "日生まれ";
    }
    if (partnerD !== "") {
        partnerD_br = "<div></div>";
        partnerD_LinkTxtPlus = partner_LinkTxtPlus;
        partnerD_LinkTxt = partnerD.substring(0, 4) + "年" + parseInt(partnerD.substring(5, 7), 10) + "月" + parseInt(partnerD.substring(8, 10), 10) + "日生まれ";

    }
    if (partnerE !== "") {
        partnerE_br = "<div></div>";
        partnerE_LinkTxtPlus = partner_LinkTxtPlus;
        partnerE_LinkTxt = partnerE.substring(0, 4) + "年" + parseInt(partnerE.substring(5, 7), 10) + "月" + parseInt(partnerE.substring(8, 10), 10) + "日生まれ";

    }
    if (partnerF !== "") {
        partnerF_br = "<div></div>";
        partnerF_LinkTxtPlus = partner_LinkTxtPlus;
        partnerF_LinkTxt = partnerF.substring(0, 4) + "年" + parseInt(partnerF.substring(5, 7), 10) + "月" + parseInt(partnerF.substring(8, 10), 10) + "日生まれ";

    }



    const mainContents = [
        <div key='maincontentskey'>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>{partnerA.substring(0, 4)}年{parseInt(partnerA.substring(5, 7), 10)}月{parseInt(partnerA.substring(8, 10), 10)}日生の相性判断</h2>
                <Alert severity="info" sx={{ marginTop: '1.5rem', marginBottom: '1.5rem', fontSize: '0.7rem' }}>
                    <span>日九星/月九星/年九星の</span><span>相生（ソウセイ）と</span><span>相剋（ソウコク）から、</span><span>崇暦.netが</span>独自に算出。<span>1から9まで</span><span>あり、</span><span>数字が大きいほど</span><span>良い影響を</span><span>もたらすと</span><span>判断します。</span>
                </Alert>
                <div style={{ marginBottom: '2rem' }}>
                    <img src={birthCompatibleBackImgSample} alt='' />

                </div>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box sx={{ textAlign: 'center', margin: '0 1rem' }}>
                        <div style={{ marginBottom: '1rem' }}>
                            {partnerA.substring(0, 4)}年{parseInt(partnerA.substring(5, 7), 10)}月
                            {parseInt(partnerA.substring(8, 10), 10)}日生の方への影響度
                        </div>
                        <SVGBirthCompatibleBackImg
                            sendA={partnerA}
                            sendB={partnerB}
                            sendC={partnerC}
                            sendD={partnerD}
                            sendE={partnerE}
                            sendF={partnerF}
                            sendArrow="in"
                        />
                    </Box>
                    <Box sx={{ textAlign: 'center', margin: '0 1rem' }}>
                        <div style={{ marginBottom: '1rem' }}>
                            {partnerA.substring(0, 4)}年{parseInt(partnerA.substring(5, 7), 10)}月
                            {parseInt(partnerA.substring(8, 10), 10)}日生の方からの影響度
                        </div>
                        <SVGBirthCompatibleBackImg
                            sendA={partnerA}
                            sendB={partnerB}
                            sendC={partnerC}
                            sendD={partnerD}
                            sendE={partnerE}
                            sendF={partnerF}
                            sendArrow="out"
                        />
                    </Box>
                </Box>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>視点の変更</h2>
                <Link component={RouterLink} underline="always" key="partnerB" color="primary" to={`/birth_compatible/${partnerB}x${partnerA}x${partnerC}x${partnerD}x${partnerE}x${partnerF}`} onClick={handleClick} sx={{ marginBottom: '0rem' }}>
                    <span>{partnerB_LinkTxt}{partnerB_LinkTxtPlus}</span>
                </Link>
                {partnerB_br && <div dangerouslySetInnerHTML={{ __html: partnerC_br }} style={{ marginBottom: '0.5rem' }} />}
                <Link component={RouterLink} underline="always" key="partnerC" color="primary" to={`/birth_compatible/${partnerC}x${partnerA}x${partnerB}x${partnerD}x${partnerE}x${partnerF}`} onClick={handleClick} sx={{ marginBottom: '0rem' }}>
                    <span>{partnerC_LinkTxt}{partnerC_LinkTxtPlus}</span>
                </Link>
                {partnerC_br && <div dangerouslySetInnerHTML={{ __html: partnerD_br }} style={{ marginBottom: '0.5rem' }} />}
                <Link component={RouterLink} underline="always" key="partnerD" color="primary" to={`/birth_compatible/${partnerD}x${partnerA}x${partnerB}x${partnerC}x${partnerE}x${partnerF}`} onClick={handleClick} sx={{ marginBottom: '0rem' }}>
                    <span>{partnerD_LinkTxt}{partnerD_LinkTxtPlus}</span>
                </Link>
                {partnerD_br && <div dangerouslySetInnerHTML={{ __html: partnerE_br }} style={{ marginBottom: '0.5rem' }} />}
                <Link component={RouterLink} underline="always" key="partnerE" color="primary" to={`/birth_compatible/${partnerE}x${partnerA}x${partnerB}x${partnerC}x${partnerD}x${partnerF}`} onClick={handleClick} sx={{ marginBottom: '0rem' }}>
                    <span>{partnerE_LinkTxt}{partnerE_LinkTxtPlus}</span>
                </Link>
                {partnerE_br && <div dangerouslySetInnerHTML={{ __html: partnerF_br }} style={{ marginBottom: '0.5rem' }} />}
                <Link component={RouterLink} underline="always" key="partnerF" color="primary" to={`/birth_compatible/${partnerF}x${partnerA}x${partnerB}x${partnerC}x${partnerD}x${partnerE}`} onClick={handleClick} sx={{ marginBottom: '0rem' }}>
                    <span>{partnerF_LinkTxt}{partnerF_LinkTxtPlus}</span>
                </Link>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>誕生日リンク</h2>
                <Link component={RouterLink} underline="always" key="partnerA" color="primary" to={`/birth/${partnerAYMD}`} onClick={handleClick} sx={{ marginBottom: '0rem' }}>
                    <span>{partnerA.substring(0, 4)}年{parseInt(partnerA.substring(5, 7), 10)}月{parseInt(partnerA.substring(8, 10), 10)}日生まれ</span>
                </Link>
                {partnerB_br && <div dangerouslySetInnerHTML={{ __html: partnerB_br }} style={{ marginBottom: '0.5rem' }} />}
                <Link component={RouterLink} underline="always" key="partnerB" color="primary" to={`/birth/${partnerBYMD}`} onClick={handleClick} sx={{ marginBottom: '0rem' }}>
                    <span>{partnerB_LinkTxt}</span>
                </Link>
                {partnerC_br && <div dangerouslySetInnerHTML={{ __html: partnerC_br }} style={{ marginBottom: '0.5rem' }} />}
                <Link component={RouterLink} underline="always" key="partnerC" color="primary" to={`/birth/${partnerCYMD}`} onClick={handleClick} sx={{ marginBottom: '0rem' }}>
                    <span>{partnerC_LinkTxt}</span>
                </Link>
                {partnerD_br && <div dangerouslySetInnerHTML={{ __html: partnerD_br }} style={{ marginBottom: '0.5rem' }} />}
                <Link component={RouterLink} underline="always" key="partnerD" color="primary" to={`/birth/${partnerDYMD}`} onClick={handleClick} sx={{ marginBottom: '0rem' }}>
                    <span>{partnerD_LinkTxt}</span>
                </Link>
                {partnerE_br && <div dangerouslySetInnerHTML={{ __html: partnerE_br }} style={{ marginBottom: '0.5rem' }} />}
                <Link component={RouterLink} underline="always" key="partnerE" color="primary" to={`/birth/${partnerEYMD}`} onClick={handleClick} sx={{ marginBottom: '0rem' }}>
                    <span>{partnerE_LinkTxt}</span>
                </Link>
                {partnerF_br && <div dangerouslySetInnerHTML={{ __html: partnerF_br }} style={{ marginBottom: '0.5rem' }} />}
                <Link component={RouterLink} underline="always" key="partnerF" color="primary" to={`/birth/${partnerFYMD}`} onClick={handleClick} sx={{ marginBottom: '0rem' }}>
                    <span>{partnerF_LinkTxt}</span>
                </Link>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>生年月日の変更</h2>
                {BirthCompatibleForm(partnerA, partnerB, partnerC, partnerD, partnerE, partnerF)}
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseInarticle />
            </div>
        </div>
    ];

    /*
    <meta name="description" content="This is an example page" />
    <meta property="og:site_name" content="suureki.net">
    <meta property="og:title" content="柿本実さんの情報【suureki.net | すうれきねっと】">
    <meta property="og:description" content="suureki.netとは、1901年から2100年迄を網羅した万年暦・カレンダー・こよみサイトです。様々な暦の他、著名人の情報や生年月日による吉凶運勢・吉方位・バイオリズム・カバラ数秘術の情報を提供しています。">
    <meta property="og:type" content="website">
    <meta property="og:locale" content="ja_JP">
    <meta property="og:url" content="https://suureki.net/75882/register.php?id=17630317">
    <meta property="og:image" content="https://suureki.net/img/suureki_ogp_1200x630.png">
    <meta property="og:image:width" content="1200">
    <meta property="og:image:height" content="630">
    <meta name="twitter:card" content="summary_large_image">
    <meta name="twitter:url" content="https://suureki.net/">
    <meta name="twitter:image" content="https://suureki.net/img/suureki_ogp_1200x630.png">
    <meta name="twitter:title" content="日々、役立つ暦注を！">
    <meta name="twitter:description" content="万年暦・こよみサイト。二十八宿や十二直、二十四節気/七十二候などの様々な暦注を掲載。">
    <meta name="twitter:site" content="@suureki_cal">
    */
    //                 <h2 className='content_subtitle'>{partnerA.substring(0, 4)}年{parseInt(partnerA.substring(5, 7), 10)}月{parseInt(partnerA.substring(8, 10), 10)}日生の相性判断</h2>


    const pageKey = "BirthCompatible";
    const pagePath = pagePathS[pageKey] + partnerA + "x" + partnerB + "x" + partnerC + "x" + partnerD + "x" + partnerE + "x" + partnerF;

    const pageTitle = partnerA.substring(0, 4) + '年' + Number(partnerA.substring(5, 7)) + '月' + Number(partnerA.substring(8, 10)) + '日生から見た相性';
    const metaDescription = partnerA.substring(0, 4) + '年' + Number(partnerA.substring(5, 7)) + '月' + Number(partnerA.substring(8, 10)) + '日' + pageDescription[pageKey];
    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        console.log(pagePath + ':', encryptedUid.substring(0, 3));

        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitle, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitle]);

    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };

    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>
                        {/* ***** メインコンテンツ ******************************************************************* */}

                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span><br />
                            </h1>
                            <div>{mainContents}</div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
};

const App = () => (
    <AuthProvider>
        <Birthday />
    </AuthProvider>
);

export default App;