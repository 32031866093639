// import * as React from 'react';
import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton, Alert, AlertTitle } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SwipeIcon from '@mui/icons-material/Swipe';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    ZodiacSigns,
    ZodiacSignsMark,
    ZodiacSigns12S,
    ZodiacSigns13A,
    ZodiacSigns13B,
    ZodiacSigns13C,
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBannerMobile from '../components/AdSenseBannerMobile'; // 320x100、ラージモバイルバナー をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // 記事内広告 をインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}
const pageTitle = `12星座と13星座の星座表`
const pageTitleS = `12星座と13星座の星座表` // ページトップのナビゲーションリンク
const uppageTitle = `情報/DB`

const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton
            aria-label="ホームページへページ移動"
            sx={{
                margin: '0px 0px 1px 0px',
                padding: '0px'
            }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Link
        component={RouterLink}
        underline="hover"
        key="2"
        color="inherit"
        to="/archives"
        onClick={handleClick}
    >
        {uppageTitle}
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];


// const DataBase: React.FC = () => {
const DataBase: React.FC = React.memo(() => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    const ZodiacTable = Array(13).fill(null).map((_, i) => (
        <tbody key={i}>
            <tr >
                {/* 星座名 */}
                <th className='swipe_table_cell calendar_bgcolor swipe_table_th_fixing' style={{ textAlign: "right" }}>
                    {ZodiacSigns[i]}{ZodiacSignsMark[i]}
                </th>
                {/* 12星座 */}
                <td className='swipe_table_cell calendar_bgcolor'>
                    <span style={{ fontSize: '0.9rem' }}>
                        {ZodiacSigns12S[i]}
                    </span>
                </td>
                {/* 13星座 */}
                <td className='swipe_table_cell calendar_bgcolor'>
                    <span style={{ fontSize: '0.9rem' }}>
                        {ZodiacSigns13C[i]}
                    </span>
                </td>
                {/* 13星座（ウォルター・バーグ氏） */}
                <td className='swipe_table_cell calendar_bgcolor'>
                    <span style={{ fontSize: '0.9rem' }}>
                        {ZodiacSigns13A[i]}
                    </span>
                </td>
                {/* 13星座（マーク矢崎氏） */}
                <td className='swipe_table_cell calendar_bgcolor'>
                    <span style={{ fontSize: '0.9rem' }}>
                        {ZodiacSigns13B[i]}
                    </span>
                </td>
            </tr>
        </tbody>
    ));


    const mainContents = [
        <div key='maincontentskey'>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>星座表</h2>
                <Alert severity="info" sx={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
                    <AlertTitle>（参考サイト）</AlertTitle>
                    <span style={{ marginTop: '0.5rem' }}>
                        <a href="https://ja.wikipedia.org/wiki/13%E6%98%9F%E5%BA%A7%E5%8D%A0%E3%81%84" target="_blank" rel="noopener noreferrer">https://ja.wikipedia.org/wiki/13星座占い</a>
                    </span>
                </Alert>
                <Box className='content_subbox'>
                    <div style={{ margin: '0rem 0rem 0rem 5rem', display: 'flex', alignItems: 'center' }}>
                        <ArrowLeftIcon color="disabled" />
                        <ArrowLeftIcon color="disabled" />
                        <SwipeIcon fontSize="small" color="disabled" />
                        <ArrowRightIcon color="disabled" />
                        <ArrowRightIcon color="disabled" />
                        <span style={{ color: 'gray', fontSize: '0.8rem', marginLeft: '0.5rem' }}>Swipe on the table.</span>
                    </div>
                    <div className='table_enclosure'>
                        <table className='swipe_table'>
                            <thead>
                                <tr>
                                    <th className='swipe_table_cell calendar_bgcolor swipe_table_th_fixing' style={{ textAlign: "center", fontSize: '0.9rem', width: '1.5rem' }}>星座名</th>
                                    <th className='swipe_table_cell calendar_bgcolor' style={{ textAlign: "left", fontSize: '0.9rem', width: '1.5rem' }}>12星座</th>
                                    <th className='swipe_table_cell calendar_bgcolor' style={{ textAlign: "left", fontSize: '0.9rem', width: '1.5rem' }}>13星座</th>
                                    <th className='swipe_table_cell calendar_bgcolor' style={{ textAlign: "left", fontSize: '0.9rem', width: '1.5rem' }}>13星座（ウォルター・バーグ氏）</th>
                                    <th className='swipe_table_cell calendar_bgcolor' style={{ textAlign: "left", fontSize: '0.9rem', width: '1.5rem' }}>13星座（マーク矢崎氏）</th>
                                </tr>
                            </thead>
                            {ZodiacTable}
                        </table>
                    </div>
                </Box>
                <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                    <AdSenseInarticle />
                </div>
            </Box>
        </div>
    ];

    const pageKey = "DBzodiacsigns";
    const pagePath = pagePathS[pageKey];

    const metaDescription = pageDescription[pageKey];
    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        console.log(pagePath + ':', encryptedUid.substring(0, 3));

        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitle, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitle]);


    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };

    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>

                        {/* ***** メインコンテンツ ******************************************************************* */}

                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                            </h1>
                            <div>{mainContents}</div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
});
// };

const App = () => (
    <AuthProvider>
        <DataBase />
    </AuthProvider>
);

export default App;