// src/pages/Navi.tsx
// import * as React from 'react';
import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
// import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBannerMobile from '../components/AdSenseBannerMobile'; // 320x100、ラージモバイルバナー をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // 記事内広告 をインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';
import bgImgMooon from '../img/bgImgMoon.svg'
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');  // 日本語ロケールを設定

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}
const pageTitle = `目的ナビ`
const pageTitleS = `目的ナビ` // ページトップのナビゲーションリンク
const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton
            aria-label="ホームページへページ移動"
            sx={{
                margin: '0px 0px 1px 0px',
                padding: '0px'
            }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];


const Navigation: React.FC = () => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    const dateY = new Date().getFullYear();
    const dateM = (new Date().getMonth() + 1).toString().padStart(2, '0');

    const today = new Date();
    const date = today.getFullYear() + (today.getMonth() + 1).toString().padStart(2, '0');


    const mainContents = [
        <div key='maincontentskey'>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>月の状態を知る</h2>
                <ul style={{ backgroundImage: `url(${bgImgMooon})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'right' }}>
                    <li className='inside-list-style' style={{ color: 'green', listStyleType: 'none' }}>昨今の満月と新月の日時を知る</li>
                    <ul style={{ width: '80%' }}>
                        <li className='inside-list-style'>
                            <Link
                                component={RouterLink}
                                underline="always"
                                key="db_etcday"
                                color="primary"
                                to="/archives/db_moon"
                                onClick={handleClick}
                            >
                                半年前～半年後迄の満月/新月の日
                            </Link>
                        </li>
                        <li className='inside-list-style'>
                            <Link
                                component={RouterLink}
                                underline="always"
                                key="db_etcday"
                                color="primary"
                                to={`/calendar/${dateY}${dateM}`}
                                onClick={handleClick}
                            >
                                月暦カレンダー
                            </Link>
                        </li>
                        <li className='inside-list-style'>
                            <Link
                                component={RouterLink}
                                underline="always"
                                key="db_etcday"
                                color="primary"
                                to={`/`}

                                onClick={handleClick}
                            >
                                トップページで7日後迄の状態を把握
                            </Link>
                        </li>
                    </ul>
                </ul>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>ことの吉凶日を知る</h2>
                <ul style={{ backgroundRepeat: 'no-repeat', backgroundPosition: 'right', backgroundSize: '45px 80px' }}>
                    <li className='inside-list-style' style={{ color: 'green', listStyleType: 'none' }}>十二直の吉凶事、二十八宿の吉凶事を知る</li>
                    <ul style={{ width: '80%' }}>
                        <li className='inside-list-style'>
                            <Link
                                component={RouterLink}
                                underline="always"
                                key="db_etcday"
                                color="primary"
                                to={`/goodday/${dateY}${dateM}`}
                                onClick={handleClick}
                            >
                                吉凶カレンダーで吉凶事を把握
                            </Link>
                        </li>
                    </ul>
                    <ul style={{ width: '80%' }}>
                        <li className='inside-list-style'>
                            <Link
                                component={RouterLink}
                                underline="always"
                                key="db_etcday"
                                color="primary"
                                to="/"
                                onClick={handleClick}
                            >
                                トップページで7日後迄の吉凶事を把握
                            </Link>
                        </li>
                    </ul>
                </ul>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>二十四節気や十二支、選日雑節を検索する</h2>
                <ul style={{ backgroundRepeat: 'no-repeat', backgroundPosition: 'right', backgroundSize: '45px 80px' }}>
                    <li className='inside-list-style' style={{ color: 'green', listStyleType: 'none' }}>選日や雑節、干支の日を知る</li>
                    <ul style={{ width: '80%' }}>
                        <li className='inside-list-style'>
                            <Link
                                component={RouterLink}
                                underline="always"
                                key="db_etcday"
                                color="primary"
                                to="/calendar_search"
                                onClick={handleClick}
                            >
                                暦の検索
                            </Link>
                        </li>
                        <li className='inside-list-style'>
                            <Link
                                component={RouterLink}
                                underline="always"
                                key="db_etcday"
                                color="primary"
                                to={`/calendar/${dateY}${dateM}`}
                                onClick={handleClick}
                            >
                                月暦カレンダー
                            </Link>
                        </li>
                    </ul>
                </ul>
                <ul style={{ backgroundRepeat: 'no-repeat', backgroundPosition: 'right', backgroundSize: '50px 50px' }}>
                    <li className='inside-list-style' style={{ color: 'green', listStyleType: 'none' }}>稲荷参拝の為に午の日を知る</li>
                    <ul style={{ width: '80%' }}>
                        <li className='inside-list-style'>
                            <Link
                                component={RouterLink}
                                underline="always"
                                key="db_etcday"
                                color="primary"
                                to={`/calendar_search/${dateY}000000000000000001000000000000100000`}
                                onClick={handleClick}
                            >
                                暦の検索＞{dateY}年の初午/午の日
                            </Link>
                        </li>
                        <li className='inside-list-style'>
                            <Link
                                component={RouterLink}
                                underline="always"
                                key="db_etcday"
                                color="primary"
                                to={`/calendar_search/${dateY + 1}000000000000000001000000000000100000`}
                                onClick={handleClick}
                            >
                                暦の検索＞{dateY + 1}年の初午/午の日
                            </Link>
                        </li>
                    </ul>
                </ul>
                <ul style={{ backgroundRepeat: 'no-repeat', backgroundPosition: 'right', backgroundSize: '50px 50px' }}>
                    <li className='inside-list-style' style={{ color: 'green', listStyleType: 'none' }}>縁起の良い日を知る</li>
                    <ul style={{ width: '80%' }}>
                        <li className='inside-list-style'>
                            <Link
                                component={RouterLink}
                                underline="always"
                                key="db_etcday"
                                color="primary"
                                to={`/calendar_search/${dateY}001000000010000000000000001000000000`}
                                onClick={handleClick}
                            >
                                暦の検索＞{dateY}年の一粒万倍日/天赦日/寅の日
                            </Link>
                        </li>
                        <li className='inside-list-style'>
                            <Link
                                component={RouterLink}
                                underline="always"
                                key="db_etcday"
                                color="primary"
                                to={`/calendar_search/${dateY + 1}001000000010000000000000001000000000`}
                                onClick={handleClick}
                            >
                                暦の検索＞{dateY + 1}年の一粒万倍日/天赦日/寅の日
                            </Link>
                        </li>
                    </ul>
                </ul>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>二十四節気について知る</h2>
                <ul>
                    <li className='inside-list-style' style={{ color: 'green', listStyleType: 'none' }}>種類と意味を知る</li>
                    <ul style={{ width: '80%' }}>
                        <li className='inside-list-style'>
                            <Link
                                component={RouterLink}
                                underline="always"
                                key="annotation_24sekki"
                                color="primary"
                                to="/annotation/24sekki"
                                onClick={handleClick}
                            >
                                二十四節気とはページで種類と意味を把握
                            </Link>
                        </li>
                    </ul>
                </ul>
                <ul>
                    <li className='inside-list-style' style={{ color: 'green', listStyleType: 'none' }}>去年～再来年の日時を知る</li>
                    <ul style={{ width: '80%' }}>
                        <li className='inside-list-style'>
                            <Link
                                component={RouterLink}
                                underline="always"
                                key="archives_db_24sekki"
                                color="primary"
                                to="/archives/db_24sekki"
                                onClick={handleClick}
                            >
                                二十四節気の日時ページで日時を把握
                            </Link>
                        </li>
                    </ul>
                </ul>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>仏教の仏様御縁日について知る</h2>
                <ul>
                    <li className='inside-list-style' style={{ color: 'green', listStyleType: 'none' }}>仏様参拝の為に御縁日を知る</li>
                    <ul style={{ width: '80%' }}>
                        <li className='inside-list-style'>
                            <Link
                                component={RouterLink}
                                underline="always"
                                key="buddhafair1"
                                color="primary"
                                to={`/buddhafair/${dateY}${dateM}`}
                                onClick={handleClick}
                            >
                                {dateY}年{dateM}月の仏様御縁日カレンダー
                            </Link>
                        </li>
                        <li className='inside-list-style'>
                            <Link
                                component={RouterLink}
                                underline="always"
                                key="buddhafair2"
                                color="primary"
                                to={`/buddhafair/${dayjs(date).add(1, 'month').format('YYYYMM')}`}
                                onClick={handleClick}
                            >
                                {dayjs(date).add(1, 'month').format('YYYY')}年{dayjs(date).add(1, 'month').format('MM')}月の仏様御縁日カレンダー
                            </Link>
                        </li>
                    </ul>
                </ul>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>自身について知る</h2>
                <ul>
                    <li className='inside-list-style' style={{ color: 'green', listStyleType: 'none' }}>満年齢と数え年を知る</li>
                    <li className='inside-list-style' style={{ color: 'green', listStyleType: 'none' }}>日々のバイオリズムを知る</li>
                    <li className='inside-list-style' style={{ color: 'green', listStyleType: 'none' }}>日々の吉凶運勢を知る</li>
                    <li className='inside-list-style' style={{ color: 'green', listStyleType: 'none' }}>日々の吉方位を知る</li>
                    <li className='inside-list-style' style={{ color: 'green', listStyleType: 'none' }}>他の方との相性を知る</li>
                    <li className='inside-list-style' style={{ color: 'green', listStyleType: 'none' }}>カバラ数秘術にて自身の特徴を知る</li>
                    <li className='inside-list-style' style={{ color: 'green', listStyleType: 'none' }}>カバラ数秘術にて年毎の運気と特徴を知る</li>
                    <ul style={{ width: '80%' }}>
                        <li className='inside-list-style'>
                            <Link
                                component={RouterLink}
                                underline="always"
                                key="db_etcday"
                                color="primary"
                                to="/birth"
                                onClick={handleClick}
                            >
                                誕生日の選択ページ
                            </Link>
                        </li>
                    </ul>
                </ul>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>他の情報/コンテンツ</h2>
                <ul>
                    <li className='inside-list-style' style={{ color: 'black', listStyleType: 'none' }}>崇暦.netでは、他にも様々な情報/コンテンツを提供しております。サイトマップにて、全てのページがご覧いただけます。</li>
                    <ul style={{ width: '80%' }}>
                        <li className='inside-list-style'>
                            <Link
                                component={RouterLink}
                                underline="always"
                                key="sitemap"
                                color="primary"
                                to="/sitemap"
                                onClick={handleClick}
                            >
                                サイトマップ
                            </Link>
                        </li>
                    </ul>
                </ul>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseInarticle />
            </div>
        </div>
    ];

    const pageKey = "Navi";
    const pagePath = pagePathS[pageKey];

    const metaDescription = pageDescription[pageKey];
    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        console.log(pagePath + ':', encryptedUid.substring(0, 3));

        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitle, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitle]);


    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };

    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>

                        {/* ***** メインコンテンツ ******************************************************************* */}

                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                            </h1>
                            <div>{mainContents}</div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
};

const App = () => (
    <AuthProvider>
        <Navigation />
    </AuthProvider>
);

export default App;