import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton, Alert, AlertTitle } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    WeekName,
    HoridayName,
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBannerMobile from '../components/AdSenseBannerMobile'; // 320x100、ラージモバイルバナー をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // 記事内広告 をインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

interface Horiday {
    date: string;           /* 年月日 */
    holiday_dat: string;    /* 祝日の該当数字 */
}

/*
{ "date": "19490101", "holiday_dat": "1" },
{ "date": "19490115", "holiday_dat": "2" },
{ "date": "19490321", "holiday_dat": "4" },
{ "date": "19490429", "holiday_dat": "17" },
{ "date": "19490503", "holiday_dat": "6" },
*/

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}
const pageTitle = `祝日/祭日の一覧`
const pageTitleS = `祝日/祭日一覧` // ページトップのナビゲーションリンク
const uppageTitle = `情報/DB`

const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton
            aria-label="ホームページへページ移動"
            sx={{
                margin: '0px 0px 1px 0px',
                padding: '0px'
            }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Link
        component={RouterLink}
        underline="hover"
        key="2"
        color="inherit"
        to="/archives"
        onClick={handleClick}
    >
        {uppageTitle}
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];

// const DataBase: React.FC = () => {
const DataBase: React.FC = React.memo(() => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    const { date: rawDate } = useParams<string>();

    let validDate = 2000; // デフォルトの値を先に設定
    let errChk = "OK";

    // dateの値が数値かどうかのチェックと、有効な範囲内にあるかのチェック
    if (rawDate && !isNaN(Number(rawDate))) {
        const dateNumber = Number(rawDate);
        if (dateNumber >= 1941 && dateNumber <= 2021) {
            validDate = dateNumber;
        } else if (dateNumber <= 1940) {
            validDate = 1940;
            errChk = "NG";
        } else if (dateNumber >= 2022) {
            validDate = 2021;
            errChk = "NG";
        }
    } else {
        validDate = 2000;
        errChk = "NG";
    }

    // console.log('errChk:' + errChk);
    let dateBeforeY = validDate;


    // 今年の祝日（祭日）jsonの呼び出し //////////////////////////////////////////////////////////////////////

    const horiday_json_url = "https://db.suureki.net/_db_horiday/horiday_all.json";
    // const horiday_json_url = "https://db.suureki.net/_db_horiday/horiday_" + `${dateY}` + ".json";

    const [horiday_data, setDataHoriday] = useState<Horiday[]>([]);

    useEffect(() => {
        const fetchHoridayData = () => {
            fetch(horiday_json_url)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(horiday_data => setDataHoriday(horiday_data))
                .catch(error => console.error('Error:', error));
        };

        fetchHoridayData();
        const interval = setInterval(fetchHoridayData, 2592000000); // 2592000000ミリ秒 = 30日ごとにデータを取得

        return () => clearInterval(interval); // クリーンアップ
    }, [horiday_json_url]);


    // JSONデータを日付をキーとするオブジェクトに変換します。
    let holidayMap: { [date: string]: string } = {};
    horiday_data.forEach(horiday => {
        holidayMap[horiday.date] = horiday.holiday_dat;
    });

    let holidayData: { [year: number]: JSX.Element[] } = {};

    for (let dateYMD in holidayMap) {
        let dateY = Number(dateYMD.slice(0, 4));
        let dateM = Number(dateYMD.slice(4, 6));
        let dateD = Number(dateYMD.slice(6, 8));
        let dateObj = new Date(dateY, dateM - 1, dateD); // dateM - 1 は、Dateオブジェクトが月を0から始まると考えるためです
        let dateW = dateObj.getDay().toString();


        if (!holidayData[dateY]) {
            holidayData[dateY] = [];
        }

        holidayData[dateY].push(
            <div key={dateYMD}>
                {dateY}年{("00" + dateM).slice(-2)}月{("00" + dateD).slice(-2)}日（{WeekName[dateW]}）： {HoridayName[holidayMap[dateYMD]]}
            </div>
        );
    }


    // //////////////////////////////////////////////////////////////////////



    let tmpY = dateBeforeY - 1;
    let iEnd = 10;

    if (dateBeforeY < 1949) {
        dateBeforeY = 1949;
    }
    const today = new Date();
    const todayY = today.getFullYear();

    const mainContents = [
        <div key='maincontentskey'>
            {Array.from({ length: iEnd }, (_, i) => {
                tmpY++;

                if (tmpY >= 1949 && tmpY <= todayY + 1) {
                    return (
                        <Box key={`inner-${tmpY}`} className='content_subbox_txt'>
                            <h2 className='content_subtitle'>{tmpY}年の祝日</h2>
                            <ul>
                                <li>{holidayData[tmpY]}</li>
                            </ul>
                        </Box>
                    );

                } else {
                    return (
                        <Box key={`inner-${tmpY}`} className='content_subbox_txt'>
                        </Box>
                    );

                }

            })}
        </div>
    ];

    const pageKey = "DBhoriday";
    const pagePath = pagePathS[pageKey] + rawDate;


    let pageTitleMove = '' + dateBeforeY + '年から' + tmpY + '年迄の' + pageTitle;

    const metaDescription = pageTitleMove = '' + dateBeforeY + '年から' + tmpY + '年迄の' + pageDescription[pageKey];
    const metaTitle = pageTitleMove + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        // console.log(pagePath + ':', encryptedUid.substring(0, 3));

        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitle, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitleMove]);


    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };

    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>

                        {/* ***** メインコンテンツ ******************************************************************* */}

                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                            </h1>
                            <Alert severity="info" sx={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
                                <AlertTitle sx={{ fontSize: '0.9rem' }}>参考ページ</AlertTitle>
                                <div>
                                    <span style={{ marginTop: '0.5rem' }}>
                                        <a href="https://www8.cao.go.jp/chosei/shukujitsu/gaiyou.html" target="_blank" rel="noopener noreferrer">内閣府＞「国民の祝日」について</a><br />
                                    </span>
                                </div>
                            </Alert>
                            <div>{mainContents}</div>
                            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                <AdSenseBannerMobile />
                            </div>
                            <h2 className='content_subtitle'>他の年代の祝日/祭日一覧</h2>
                            <Link
                                component={RouterLink}
                                underline="always"
                                key="db_horiday"
                                color="primary"
                                to="/archives/db_horiday"
                                onClick={handleClick}
                            >
                                去年~来年迄
                            </Link><br />
                            <Link component={RouterLink} underline="always" key="db_horiday_1941" color="primary" to="/archives/db_horiday/1941" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1949~1950年迄</Link>
                            <Link component={RouterLink} underline="always" key="db_horiday_1951" color="primary" to="/archives/db_horiday/1951" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1951~1960年迄</Link>
                            <Link component={RouterLink} underline="always" key="db_horiday_1961" color="primary" to="/archives/db_horiday/1961" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1961~1970年迄</Link>
                            <Link component={RouterLink} underline="always" key="db_horiday_1971" color="primary" to="/archives/db_horiday/1971" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1971~1980年迄</Link>
                            <Link component={RouterLink} underline="always" key="db_horiday_1981" color="primary" to="/archives/db_horiday/1981" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1981~1990年迄</Link>
                            <Link component={RouterLink} underline="always" key="db_horiday_1991" color="primary" to="/archives/db_horiday/1991" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1991~2000年迄</Link>
                            <Link component={RouterLink} underline="always" key="db_horiday_2001" color="primary" to="/archives/db_horiday/2001" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>2001~2010年迄</Link>
                            <Link component={RouterLink} underline="always" key="db_horiday_2011" color="primary" to="/archives/db_horiday/2011" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>2011~2020年迄</Link>
                            <Link component={RouterLink} underline="always" key="db_horiday_2021" color="primary" to="/archives/db_horiday/2021" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>2021~2030年迄</Link>
                            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                <AdSenseInarticle />
                            </div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
});
// };

const App = () => (
    <AuthProvider>
        <DataBase />
    </AuthProvider>
);

export default App;