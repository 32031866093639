// import * as React from 'react';
import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton, styled, Alert, AlertTitle } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SwipeIcon from '@mui/icons-material/Swipe';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    Zyunityoku,
    ZyunityokuEvent,
    ZyunityokuEventName,
    GoodBadTxt,
    ZyunityokuRuby,
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBannerMobile from '../components/AdSenseBannerMobile'; // 320x100、ラージモバイルバナー をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // 記事内広告 をインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        background: 'linear-gradient(to right, rgba(231, 244, 255, 0.99),rgba(231, 244, 255, 0.99), rgba(243, 250, 255, 0.99))',
        color: 'black',
        fontSize: theme.typography.pxToRem(16),
        border: '1px solid black',
    },
}));

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}


const pageTitle = `十二直の吉凶`
const pageTitleS = `十二直の吉凶` // ページトップのナビゲーションリンク
const uppageTitle = `暦注とは？`


const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton
            aria-label="ホームページへページ移動"
            sx={{
                margin: '0px 0px 1px 0px',
                padding: '0px'
            }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Link
        component={RouterLink}
        underline="hover"
        key="2"
        color="inherit"
        to="/annotation"
        onClick={handleClick}
    >
        {uppageTitle}
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];


const Annotation: React.FC = () => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    const ZyunityokuTableHeader = [
        <tr key='YadohachiTableHeader'>
            <th className='swipe_table_cell calendar_bgcolor swipe_table_th_fixing' style={{ textAlign: "center", fontSize: '0.9rem', width: '1.5rem', color: 'green' }}>直名</th>
            {ZyunityokuEventName.map((item, index) => {
                return <td key={index} className='swipe_table_cell calendar_bgcolor' style={{ textAlign: "left", fontSize: '1rem', width: '1.5rem', color: 'green', minWidth: '60px' }}>{ZyunityokuEventName[index]}</td>;
            })}
        </tr>
    ];
    const mainContents = [
        <div key='maincontentskey'>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>十二直とは</h2>
                <div>
                    中段とも言う。<br />
                    北斗七星の下三星の方位と十二支（方位）を結びつけたもの。日々の吉凶等を判断する。
                </div>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>十二直の吉凶表</h2>
                <Alert severity="info" sx={{ marginTop: '1.5rem', marginBottom: '1rem' }}>
                    <AlertTitle sx={{ fontSize: '0.9rem' }}>直名を選択すると、ふりがな・よみがなが表示されます。</AlertTitle>
                </Alert>
                <div style={{ margin: '0rem 0rem 0rem 4rem', display: 'flex', alignItems: 'center' }}>
                    <ArrowLeftIcon color="disabled" />
                    <ArrowLeftIcon color="disabled" />
                    <SwipeIcon fontSize="small" color="disabled" />
                    <ArrowRightIcon color="disabled" />
                    <ArrowRightIcon color="disabled" />
                    <span style={{ color: 'gray', fontSize: '0.8rem', marginLeft: '0.5rem' }}>Swipe on the table.</span>
                </div>
                <div className='table_enclosure'>
                    <table className='swipe_table' style={{ height: '500px' }}>
                        <thead>
                            {ZyunityokuTableHeader}
                        </thead>
                        <tbody>
                            {Zyunityoku.flatMap((item, index) => {
                                const row = (
                                    <tr key={index}>
                                        <th className='swipe_table_cell calendar_bgcolor swipe_table_th_fixing' style={{ textAlign: "right" }}>
                                            <HtmlTooltip
                                                leaveTouchDelay={5000}
                                                enterTouchDelay={0}
                                                title={
                                                    <React.Fragment>
                                                        <span style={{ lineHeight: '1.3rem' }}>
                                                            {Zyunityoku[index]}：{ZyunityokuRuby[index]}
                                                        </span>
                                                    </React.Fragment>
                                                }
                                                placement='top-start'
                                            >
                                                <span style={{ borderBottom: '1px dotted' }}>
                                                    {item}
                                                </span>
                                            </HtmlTooltip>
                                        </th>
                                        {ZyunityokuEvent[index].split('').map((char, charIndex) => {
                                            let color;
                                            if (char === '1' || char === '2') {
                                                color = 'red';
                                            } else if (char === '4' || char === '5') {
                                                color = 'blue';
                                            } else {
                                                color = 'gray';
                                            }
                                            return <td key={charIndex} className='swipe_table_cell calendar_bgcolor' style={{ color: color }}>{GoodBadTxt[Number(char)]}</td>;
                                        })}
                                    </tr>
                                );

                                if (index === 5 || index === 11) {
                                    return [row, ZyunityokuTableHeader];
                                }

                                return row;
                            })}
                        </tbody>
                    </table>
                </div>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseInarticle />
            </div>
        </div>
    ];

    /*
    <meta name="description" content="This is an example page" />
    <meta property="og:site_name" content="suureki.net">
    <meta property="og:title" content="柿本実さんの情報【suureki.net | すうれきねっと】">
    <meta property="og:description" content="suureki.netとは、1901年から2100年迄を網羅した万年暦・カレンダー・こよみサイトです。様々な暦の他、著名人の情報や生年月日による吉凶運勢・吉方位・バイオリズム・カバラ数秘術の情報を提供しています。">
    <meta property="og:type" content="website">
    <meta property="og:locale" content="ja_JP">
    <meta property="og:url" content="https://suureki.net/75882/register.php?id=17630317">
    <meta property="og:image" content="https://suureki.net/img/suureki_ogp_1200x630.png">
    <meta property="og:image:width" content="1200">
    <meta property="og:image:height" content="630">
    <meta name="twitter:card" content="summary_large_image">
    <meta name="twitter:url" content="https://suureki.net/">
    <meta name="twitter:image" content="https://suureki.net/img/suureki_ogp_1200x630.png">
    <meta name="twitter:title" content="日々、役立つ暦注を！">
    <meta name="twitter:description" content="万年暦・こよみサイト。二十八宿や十二直、二十四節気/七十二候などの様々な暦注を掲載。">
    <meta name="twitter:site" content="@suureki_cal">
    */

    const pageKey = "Annotation12";
    const pagePath = pagePathS[pageKey];

    const metaDescription = pageDescription[pageKey];
    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        console.log(pagePath + ':', encryptedUid.substring(0, 3));

        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitle, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitle]);

    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };

    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>

                        {/* ***** メインコンテンツ ******************************************************************* */}

                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                            </h1>
                            <div>{mainContents}</div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
};


const App = () => (
    <AuthProvider>
        <Annotation />
    </AuthProvider>
);

export default App;