// src/pages/CalendarIndex.tsx
// import * as React from 'react';
import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton, TextField, InputAdornment, styled } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SearchIcon from '@mui/icons-material/Search';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    pageSearhTitle,
    articleBody,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    searchLogUrl,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBannerMobile from '../components/AdSenseBannerMobile'; // 320x100、ラージモバイルバナー をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // 記事内広告 をインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');  // 日本語ロケールを設定

// const AppContent = () => {
//     const { user, loading } = useAuth();

//     if (loading) {
//         return <div>Loading...</div>;
//     }
//     return (
//         <div className="container mx-auto max-w-md mt-10">
//             {user ? <UserProfile /> : <GoogleSignIn />}
//         </div>
//     );
// };

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        background: 'linear-gradient(to right, rgba(231, 244, 255, 0.99),rgba(231, 244, 255, 0.99), rgba(243, 250, 255, 0.99))',
        color: 'black',
        fontSize: theme.typography.pxToRem(16),
        border: '1px solid black',
    },
}));


interface NamesearchState {
    search_no: number;
    add_id: string;
    name: string;
    birth: string;
}


/* *********************************************************************************************** */
/* ***** ページ名の作成 *************************************************************************** */
/* *********************************************************************************************** */

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}
let pageTitle = `サイト内検索`
let pageTitleS = `サイト内検索` // ページトップのナビゲーションリンク

const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton
            aria-label="ホームページへページ移動"
            sx={{
                margin: '0px 0px 1px 0px',
                padding: '0px'
            }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];

const Search: React.FC = React.memo(() => {
    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    const today = new Date();
    const todayY = today.getFullYear().toString(); // 年
    const todayM = (today.getMonth() + 1).toString().padStart(2, '0'); // 月（0-11なので+1が必要）
    const todayD = today.getDate().toString().padStart(2, '0'); // 日
    const todayMD = todayM + todayD;

    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState("");

    let { getdata } = useParams();
    if (getdata === undefined) {
        getdata = "xxx";
    } else {
        getdata = getdata.substring(0, 20);
    }

    // 全角英数字を半角に変換し、記号を削除する
    /*
    \p{Script=Hiragana}: ひらがな
    \p{Script=Katakana}: カタカナ
    \p{Script=Han}: 漢字
    a-zA-Z0-9: 半角英数字
    */
    getdata = getdata
        .replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
            return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
        })
        .replace(/[^\p{Script=Hiragana}\p{Script=Katakana}\p{Script=Han}a-zA-Z0-9]/gu, '');

    if (getdata === "12直") {
        getdata = "十二直";
    } else if (getdata === "12") {
        getdata = "十二";
    } else if (getdata === "24節気") {
        getdata = "二十四節気";
    } else if (getdata === "24節") {
        getdata = "二十四節";
    } else if (getdata === "24") {
        getdata = "二十四";
    } else if (getdata === "72候") {
        getdata = "七十二候";
    } else if (getdata === "72") {
        getdata = "七十二";
    } else if (getdata === "28宿") {
        getdata = "二十八宿";
    } else if (getdata === "28") {
        getdata = "二十八";
    } else if (getdata === "27宿") {
        getdata = "二十七宿";
    } else if (getdata === "27") {
        getdata = "二十七";
    }

    const highlightTextWithContext = (text: string, highlight: string) => {
        // `highlight` の位置を取得
        const startIndex = text.toLowerCase().indexOf(highlight.toLowerCase());

        // 見つからなければそのまま表示
        if (startIndex === -1) {
            return { __html: text };
        }

        // 前後5文字の範囲を計算
        const start = Math.max(0, startIndex - 15);
        const end = Math.min(text.length, startIndex + highlight.length + 15);

        // 前後に「...」をつけるかの判定
        const prefix = start > 0 ? "....." : "";
        const suffix = end < text.length ? "....." : "";

        // 前後の文脈を切り出す
        const surroundingText = text.substring(start, end);

        // 正規表現で一致部分をハイライト
        const regex = new RegExp(`(${highlight})`, 'gi');
        const highlightedText = surroundingText.replace(regex, '<mark>$1</mark>');

        return { __html: `${prefix}${highlightedText}${suffix}` };
    };

    // ページタイトルから検索（カレンダー：各ページ、吉凶判断：各ページ、仏様御縁日カレンダー：各ページ、誕生日毎：各ページ、座相表：各ページ）
    // /calendar/YYYYMMDD       YYYY年MM月DD日のカレンダー
    // /calendar/YYYYMM         YYYY年MM月のカレンダー
    // /calendar/YYYY           YYYY年の年間カレンダー
    // /goodday/YYYYMM          YYYY年MM月の吉凶カレンダー
    // /buddhafair/YYYYMM       YYYY年MM月の仏様御縁日
    // /birth/YYYYMMDD          YYYY年MM月DD日生まれの情報・吉凶判断
    // /aspect/YYYY             YYYY年の座相
    let getdataYYYY: String = '';
    let getdataYYYYMM: String = '';
    let getdataYYYYMMDD: String = '';
    let titleSearchCount = 0;

    // yyyy年mm月dd日、yyyy年mm月dd、の場合 = YYYYMMDD にする
    if (/^\d{4}年\d{1,2}月\d{1,2}(日)?$/.test(getdata)) {
        const matchYYYYMMDD = getdata.match(/^(\d{4})年(\d{1,2})月(\d{1,2})日?$/);
        if (matchYYYYMMDD) {
            const tmpY = matchYYYYMMDD[1];                    // YYYY
            const tmpM = matchYYYYMMDD[2].padStart(2, '0');   // MM (1桁なら0で埋める)
            const tmpD = matchYYYYMMDD[3].padStart(2, '0');   // DD (1桁なら0で埋める)
            getdata = `${tmpY}${tmpM}${tmpD}`; // YYYYMMDD
        }
    }

    // yyyy年mm月、yyyy年mm、の場合 = YYYYMM にする
    if (/^\d{4}年\d{1,2}(月)?$/.test(getdata)) {
        const matchYYYYMM = getdata.match(/^(\d{4})年(\d{1,2})月?$/);
        if (matchYYYYMM) {
            const tmpY = matchYYYYMM[1];                    // YYYY
            const tmpM = matchYYYYMM[2].padStart(2, '0');   // MM (1桁なら0で埋める)
            getdata = `${tmpY}${tmpM}`;                      // YYYYMM
        }
    }

    // yyyy年の場合、yyyyにする（年を消す）
    if (/^\d{4}年$/.test(getdata)) {
        getdata = getdata.substring(0, 4);
    }

    if (/^[0-9]+$/.test(getdata) && Number(getdata) >= 1901 && Number(getdata) <= 2100) {
        // getdataが数字のみで構成されている場合の処理
        getdataYYYY = getdata;
        titleSearchCount += 2;
    }
    if (/^[0-9]+$/.test(getdata) && Number(getdata) >= 190101 && Number(getdata) <= 210012 && Number(getdata.substring(4, 6)) >= 1 && Number(getdata.substring(4, 6)) <= 12) {
        // getdataが数字のみで構成されている場合の処理
        getdataYYYYMM = getdata;
        titleSearchCount += 3;
    }
    if (/^[0-9]+$/.test(getdata) && Number(getdata) >= 19010101 && Number(getdata) <= 21001231) {
        // getdataが数字のみで構成されている場合の処理
        const tmpY = parseInt(getdata.substring(0, 4), 10);
        const tmpM = parseInt(getdata.substring(4, 6), 10);
        const tmpD = parseInt(getdata.substring(6, 8), 10);
        const tmpYMD = new Date(tmpY, tmpM - 1, tmpD);
        if (tmpYMD.getFullYear() === tmpY && tmpYMD.getMonth() === tmpM - 1 && tmpYMD.getDate() === tmpD) {
            getdataYYYYMMDD = getdata;
            titleSearchCount += 2;
        }
    }

    // 検索キーワードを保存
    // useEffect(() => {
    //     if (getdata) {
    //         const search_log_url = `https://useruser.suureki.net/_search_log/_write_log.php?p=${encodeURIComponent(getdata)}`;
    //         fetch(search_log_url)
    //             .then(response => response.json())
    //             .then(data => {
    //                 console.log('Search log saved:', data);
    //             })
    //             .catch(error => {
    //                 console.error('Error saving search log:', error);
    //             });
    //     }

    // }, [getdata]);

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        if (getdata) {

            fetch(searchLogUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ p: getdata, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [getdata]);


    // ページタイトルから検索（実際の検索）
    const [resultsAnswerA, setResultsA] = useState<[string, string][]>([]);
    useEffect(() => {
        const searchResultsA = Object.entries(pageSearhTitle).filter(([_, value]) =>
            getdata !== undefined && value.includes(getdata)
        );

        setResultsA(searchResultsA);
    }, [getdata]); // getdataに依存するように変更


    // ページ説明から検索
    const [resultsAnswerB, setResultsB] = useState<[string, string][]>([]);
    useEffect(() => {
        const searchResultsB = Object.entries(articleBody).filter(([_, value]) =>
            getdata !== undefined && value.includes(getdata)
        );

        setResultsB(searchResultsB);
    }, [getdata]); // getdataに依存するように変更



    // JSON //////////////////////////////////////////////////////////////////////

    const celebrity_json_url = `https://db.suureki.net/_db_celebrity/_search_namesearch.php?name=${encodeURIComponent(getdata)}`;

    const [namesearchState_data, setDataNamesearchState] = useState<NamesearchState[]>([]);
    useEffect(() => {
        fetch(celebrity_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(namesearchState_data => setDataNamesearchState(namesearchState_data))
            .catch(error => console.error('Error:', error));
    }, [celebrity_json_url]);


    let add_id: { [search_no: number]: string } = {};
    let name: { [search_no: number]: string } = {};
    let birth: { [search_no: number]: string } = {};
    namesearchState_data.forEach(namesearch => {
        add_id[namesearch.search_no] = namesearch.add_id;
        name[namesearch.search_no] = namesearch.name;
        birth[namesearch.search_no] = namesearch.birth;
    });

    const keysAsNumbers = Object.keys(name).map(Number);
    // 最大値を求める
    let maxNumber = Math.max(...keysAsNumbers) + 1;
    let anserlinkZero = "";
    if (name[0] === "null") {
        maxNumber = 0;
        anserlinkZero = "検索結果はありません。";
    }

    let anserlinkOver = "";
    if (maxNumber > 20) {
        anserlinkOver = "20件の表示、21件以上は表示不可。<br />括弧内は生年月日と満年齢。死去情報未把握の為、故人の年齢も表示。<br /><br />";
    }


    const anserlink = Array.from({ length: 20 }, (_, index) => {
        let tmpName = "";
        let tmpId = "";
        let tmpAge = 0;
        let tmpBirthMD = 0;
        let tmpAgeTxt = "";
        let tmpBirthTxt = "";

        if (name[0] !== "null" && name[index] !== undefined) {

            tmpAge = Number(todayY) - Number(birth[index].substring(0, 4));
            tmpBirthMD = Number(birth[index].substring(5, 7) + birth[index].substring(8, 10));
            tmpBirthTxt = birth[index].substring(0, 4) + "-" + Number(birth[index].substring(5, 7)) + "-" + Number(birth[index].substring(8, 10)) + "";

            if (Number(todayMD) < tmpBirthMD) {
                tmpAge--;
            }
            tmpAgeTxt = "、" + tmpAge + "歳";
            if (birth[index].substring(0, 4) === "0000" || birth[index].substring(5, 7) === "00" || birth[index].substring(8, 10) === "00") {
                tmpAgeTxt = "";
            }
            if (birth[index].substring(0, 4) === "0000" && birth[index].substring(5, 7) !== "00" && birth[index].substring(8, 10) !== "00") {
                tmpBirthTxt = Number(birth[index].substring(5, 7)) + "-" + Number(birth[index].substring(8, 10)) + "";
            }
            if (birth[index].substring(0, 4) !== "0000" && birth[index].substring(5, 7) === "00" && birth[index].substring(8, 10) === "00") {
                tmpBirthTxt = birth[index].substring(0, 4) + "";
            }

            tmpName = name[index] + "（" + tmpBirthTxt + tmpAgeTxt + "）";
            tmpId = add_id[index];
        }

        // tmpNameが空でない場合のみ要素を返す
        if (tmpName) {
            return (
                <li key={index} style={{ listStyle: 'decimal' }}>
                    <Link
                        component={RouterLink}
                        underline="hover"
                        key="2"
                        color="primary"
                        to={`/celebrity/${tmpId}`}
                        onClick={handleClick}
                    >
                        {tmpName}
                    </Link>
                </li>
            );
        }

        // tmpNameが空の場合は何も返さない
        return null;
    });

    // ハイライト表示用の関数
    // getdataを全文表示の際、有効
    // const highlightText = (text: string, highlight: string) => {
    //     // 正規表現で一致部分をハイライトする
    //     const regex = new RegExp(`(${highlight})`, 'gi');
    //     const highlightedText = text.replace(regex, '<mark>$1</mark>');
    //     return { __html: highlightedText }; // HTMLとして返す
    // };


    const mainContents = [
        <div key='maincontentskey'>
            <Box className='content_subbox_txt' style={{ margin: '2rem 0rem 2rem 0rem' }}>
                <TextField
                    id="celebrity_search"
                    label="崇暦内からキーワードで検索"
                    variant="outlined"
                    defaultValue={getdata}
                    onChange={(e) => {
                        // 入力の値を状態に保持
                        setSearchValue(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        // Enter キーが押された場合に遷移
                        if (e.key === 'Enter' && searchValue) {
                            navigate(`/search/${searchValue}`);
                        }
                    }}

                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    edge="end"
                                    aria-label="search"
                                    onClick={() => {
                                        // SearchIconがクリックされたときに遷移
                                        if (searchValue) {
                                            navigate(`/search/${searchValue}`);
                                        }
                                    }}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}

                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#3277d5',  // 望む色に変更
                            },
                        },
                        backgroundColor: 'white',
                        width: '80vmin',
                        border: '0px solid #000',
                        borderRadius: 1
                    }}
                />
                <HtmlTooltip
                    leaveTouchDelay={5000}
                    enterTouchDelay={0}
                    title={
                        <React.Fragment>
                            <span style={{ lineHeight: '1.5rem' }}>
                                複数のキーワードでは検索出来ません。<br />
                                年月日の検索は、<br />
                                年月日は、YYYYMMDD（数字8桁）<br />
                                年月は、YYYYMM（数字6桁）<br />
                                年は、YYYY（数字6桁）で検索可。<br />
                            </span>
                        </React.Fragment>
                    }
                    placement='top-start'
                >
                    <ErrorOutlineIcon sx={{ fontSize: '2rem', color: '#3277d5', margin: '0px 0px 0px 10px' }} />
                </HtmlTooltip>
            </Box>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>ページタイトルから検索</h2>
                <div>
                    {/* 検索キーワードが、YYYYの場合 */}
                    <div>
                        {getdataYYYY && getdataYYYY.length === 4 ? (
                            // YYYYの形式を YYYY年 に変換
                            <>
                                <div>
                                    <Link
                                        component={RouterLink}
                                        underline="always"
                                        color="primary"
                                        to={`/calendar/${getdataYYYY}`}
                                        onClick={handleClick}
                                    >
                                        {`${getdataYYYY}年の年間カレンダー`}
                                    </Link>
                                </div>
                                <div>
                                    <Link
                                        component={RouterLink}
                                        underline="always"
                                        color="primary"
                                        to={`/aspect/${getdataYYYY}`}
                                        onClick={handleClick}
                                    >
                                        {`${getdataYYYY}年の座相`}
                                    </Link>
                                </div>
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                    {/* 検索キーワードが、YYYYMMの場合 */}
                    <div>
                        {getdataYYYYMM && getdataYYYYMM.length === 6 ? (
                            // YYYYMMDDの形式を YYYY年MM月DD日 に変換
                            <>
                                <div>
                                    <Link
                                        component={RouterLink}
                                        underline="always"
                                        color="primary"
                                        to={`/calendar/${getdataYYYYMM}`}
                                        onClick={handleClick}
                                    >
                                        {` ${getdataYYYYMM.substring(0, 4)}年${Number(getdataYYYYMM.substring(4, 6))}月のカレンダー`}
                                    </Link>
                                </div>
                                <div>
                                    <Link
                                        component={RouterLink}
                                        underline="always"
                                        color="primary"
                                        to={`/goodday/${getdataYYYYMM}`}
                                        onClick={handleClick}
                                    >
                                        {` ${getdataYYYYMM.substring(0, 4)}年${Number(getdataYYYYMM.substring(4, 6))}月の吉凶カレンダー`}
                                    </Link>
                                </div>
                                <div>
                                    <Link
                                        component={RouterLink}
                                        underline="always"
                                        color="primary"
                                        to={`/buddhafair/${getdataYYYYMM}`}
                                        onClick={handleClick}
                                    >
                                        {` ${getdataYYYYMM.substring(0, 4)}年${Number(getdataYYYYMM.substring(4, 6))}月の仏様御縁日`}
                                    </Link>
                                </div>
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                    {/* 検索キーワードが、YYYYMMDDの場合 */}
                    <div>
                        {getdataYYYYMMDD && getdataYYYYMMDD.length === 8 ? (
                            <>
                                <div>
                                    <Link
                                        component={RouterLink}
                                        underline="always"
                                        color="primary"
                                        to={`/calendar/${getdataYYYYMMDD}`}
                                        onClick={handleClick}
                                    >
                                        {` ${getdataYYYYMMDD.substring(0, 4)}年${Number(getdataYYYYMMDD.substring(4, 6))}月${Number(getdataYYYYMMDD.substring(6, 8))}日のカレンダー`}
                                    </Link>
                                </div>
                                <div>
                                    <Link
                                        component={RouterLink}
                                        underline="always"
                                        color="primary"
                                        to={`/birth/${getdataYYYYMMDD}`}
                                        onClick={handleClick}
                                    >
                                        {` ${getdataYYYYMMDD.substring(0, 4)}年${Number(getdataYYYYMMDD.substring(4, 6))}月${Number(getdataYYYYMMDD.substring(6, 8))}日生まれの情報・吉凶判断`}
                                    </Link>
                                </div>
                            </>
                        ) : (
                            ""
                        )}
                    </div>

                </div>
                {resultsAnswerA.length > 0 ? (
                    <ul>
                        {resultsAnswerA.map(([key, value]) => {
                            const path = pagePathS[key];
                            // パスが「/」で終わっていない場合のみ項目を表示
                            if (!path.endsWith('/')) {
                                titleSearchCount++
                                return (
                                    <li key={key}>
                                        <Link
                                            component={RouterLink}
                                            underline="always"
                                            color="primary"
                                            to={`/${path}`}
                                            onClick={handleClick}
                                        >
                                            {pageSearhTitle[key]}
                                        </Link>
                                        <br />
                                        <span>{pageDescription[key]}</span>
                                    </li>
                                );
                            }
                            // パスが「/」で終わる場合は何も表示しない
                            return null;
                        }).filter(Boolean)} {/* nullの項目を除外 */}
                    </ul>
                ) : (
                    ""
                )}
                <div>
                    {titleSearchCount === 0 ? (
                        <p>該当する結果が見つかりませんでした。</p>
                    ) : (
                        ""
                    )}
                </div>
                <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                    <AdSenseBannerMobile />
                </div>
                <h2 className='content_subtitle'>ページ説明から検索</h2>
                {resultsAnswerB.length > 0 ? (
                    <ol>
                        {resultsAnswerB.map(([key, value]) => (
                            <li key={key} style={{ listStyle: 'decimal' }}>
                                {/* <span>（{pageSearhTitle[key]} - {siteUrlHead}{pagePathS[key]}）</span><br /> */}
                                {/* dangerouslySetInnerHTMLでHTMLをレンダリング */}
                                {/* getdataを全文表示の際、有効 */}
                                {/* <span dangerouslySetInnerHTML={highlightText(value, getdata as string)} /> */}
                                <Link
                                    component={RouterLink}
                                    underline="always"
                                    key="update"
                                    color="primary"
                                    to={`/${pagePathS[key]}`}
                                    onClick={handleClick}
                                >
                                    {pageSearhTitle[key]}
                                </Link><br />
                                <span dangerouslySetInnerHTML={highlightTextWithContext(value, getdata as string)} />
                            </li>
                        ))}
                    </ol>
                ) : (
                    <p>該当する結果が見つかりませんでした。</p>
                )}

            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>著名人の検索結果</h2>
                {anserlinkOver && <div dangerouslySetInnerHTML={{ __html: anserlinkOver }} />}
                <Link
                    component={RouterLink}
                    underline="always"
                    key="update"
                    color="primary"
                    to={`/celebrity_search/${getdata}`}
                    onClick={handleClick}
                >
                    最大99件を表示するにはこちら
                </Link>
                <ol>
                    {anserlink}
                </ol>
                {anserlinkZero}
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseInarticle />
            </div>
        </div>
    ];

    const pageKey = "Search";
    const pagePath = pagePathS[pageKey] + getdata;

    // キーワード「愛」での、著名人の検索結果。
    // 「愛」での著名人の検索結果

    const metaDescription = 'キーワード「' + getdata + '」' + pageDescription[pageKey];
    const metaTitleS = '「' + getdata + '」での' + pageTitle;

    const metaTitle = metaTitleS + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        console.log(pagePath + ':', encryptedUid.substring(0, 3));

        console.log('pageTitle:', pageTitle);
        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: metaTitleS, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, metaTitleS]);


    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": metaTitleS,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };

    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}

                                </Breadcrumbs>
                            </Stack>
                        </h1>
                        {/* ***** メインコンテンツ ******************************************************************* */}

                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>「{getdata}」で{pageTitle}</span>
                            </h1>
                            <div>{mainContents}</div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
});

const App = () => (
    <AuthProvider>
        <Search />
    </AuthProvider>
);

export default App;