// src/pages/Calendar.tsx
// import * as React from 'react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
// import { Box, Breadcrumbs, Typography, Divider, Stack, IconButton, styled, Grid, Button } from '@mui/material';
import { Box, Breadcrumbs, Typography, Divider, Stack, IconButton, styled, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SwipeIcon from '@mui/icons-material/Swipe';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    NumberAlphabet,
    WeekName,
    Yadohachi,
    YadohachiRubyA,
    YadohachiRubyB,
    YadohachiVG,
    YadohachiG,
    YadohachiB,
    YadohachiVB,
    MonName,
    MonNameEng,
    MonNameRuby,
    Rokki,
    RokkiMeaningG,
    RokkiMeaningB,
    Zyunityoku,
    ZyunityokuRuby,
    ZyunityokuVG,
    ZyunityokuG,
    ZyunityokuB,
    ZyunityokuVB,
    Ninestar,
    EtoName10,
    EtoName12,
    EtoName10RubyB,
    EtoName10NinestarRuby,
    EtoName12RubyA,
    EtoName12RubyB,
    EtoName12RubyC,
    Sekki,
    SekkiMeaning,
    SekkiPlusRuby,
    Nanazyuni,
    NanazyuniRuby,
    NanazyuniMeaning,
    Bml,
    Senjitsu,
    SenjitsuMeaning,
    SenjitsuColor,
    Zassetsu,
    ZassetsuMeaning,
    ZassetsuColor,
    HoridayName,
    HoridayNameS,
    MoonName,
    MoonNameSimple,
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBannerMobile from '../components/AdSenseBannerMobile'; // 320x100、ラージモバイルバナー をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // 記事内広告 をインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { MonthLastDay } from '../SuurekiVarMonLastDay';
// import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');  // 日本語ロケールを設定

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

interface Koyomi {
    date: string;           /* 日付 */
    eto10_month: number;    /* 月干支（暦：10支）： */
    eto12_month: number;    /* 月干支（暦：12支） */
    eto10_year: number;     /* 年干支（暦：10支） */
    eto12_year: number;     /* 年干支（暦：12支） */
    week: string;           /* 曜日 */
    jd: number;             /* ユリウス通日 */
    xxolddate: string;      /* 旧暦 */
    xxrokki: number;        /* 六曜 */
    eto10_day: number;      /* 日干支（10支） */
    eto12_day: number;      /* 日干支（12支） */
    xxgogyo: string;        /* 五行 */
    xxyadohachi: number;    /* 二十八宿 */
    xxyadonana: string;     /* 二十七宿 */
    xxsekki: number;        /* 二十四節気 */
    xxsetuzuki: string;     /* 節月 */
    eto10_month_s: number;  /* 月干支（節：10支） */
    eto12_month_s: number;  /* 月干支（節：12支） */
    eto10_year_s: number;   /* 年干支（節：10支） */
    eto12_year_s: number;   /* 年干支（節：12支） */
    xxzyunityoku: number;   /* 十二直 */
    xxkyuusei: number;      /* 日九星 */
    xxinyo: string;         /* 陰陽 */
    xxsenjitsu: string;     /* 選日 */
    xxzassetsu: string;     /* 雑節 */
    xxnanazyuni: string;    /* 七十二候 */
    voidtime: string;       /* ボイドタイム */
    yylongitudesun: string; /*  */
    yylongitudemoon: string;    /*  */
    yyagemoon: string;      /*  */
    kyuusei_y: number;      /* 年九星 */
    kyuusei_m: number;      /* 月九星 */
    sekki_date_s: string;   /*  */
    sekki_date_e: string;   /*  */
}

interface MoonStateFull {
    date: string;         /* 年月日 */
    time: string;         /* 日時 */
    moonecliptic: string; /* 黄経差 */
    mooneclipticrounding: string; /* 黄経差の四捨五入 */
    moonage: string;      /* 月齢 */
}

interface MoonState {
    date: string;         /* 年月日 */
    time: string;         /* 日時 */
    mooninfo: string; /* 新月・満月・下弦・上弦 */
    moonage: string;      /* 月齢 */
}

interface Horiday {
    date: string;           /* 年月日 */
    holiday_dat: string;    /* 祝日の該当数字 */
}

interface Etcday {
    date: string;           /* 年月日 */
    etcday_dat: string;    /* 祝日の該当数字 */
}

interface SekkiDay {
    date: string;           /* 年月日 */
    time: string;         /* 日時 */
    angle: string;    /* 祝日の該当数字 */
}

// interface monthdayend {
//     dateym: string;           /* 年月日 */
//     monlastday: string;         /* dateymの最後の日 */
// }

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        background: 'linear-gradient(to right, rgba(231, 244, 255, 0.99),rgba(231, 244, 255, 0.99), rgba(243, 250, 255, 0.99))',
        color: 'black',
        fontSize: theme.typography.pxToRem(16),
        border: '1px solid black',
    },
}));

/* **************************************************************************************************** */
/* **************************************************************************************************** */
/* **************************************************************************************************** */
/* *****                               **************************************************************** */
/* ***** メインコンテンツの作成準備エリア **************************************************************** */
/* *****                               **************************************************************** */
/* **************************************************************************************************** */
/* **************************************************************************************************** */
/* **************************************************************************************************** */


function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}

const Calendar: React.FC = () => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    const { date } = useParams();

    const today = new Date();
    const todayY = today.getFullYear().toString(); // 年
    const todayM = (today.getMonth() + 1).toString().padStart(2, '0'); // 月（0-11なので+1が必要）
    const todayD = today.getDate().toString().padStart(2, '0'); // 日
    const todayYMD = todayY + todayM + todayD;

    let pageTitle: string | null;
    let pageTitleS: string;
    let dateF: string;
    let dateY: string;
    let dateYM: string;
    let dateYMD: string;
    let dateM: number;
    let calendarType: string;
    // let monthDayStart: number;
    let monthDayEnd: number;
    let monthDayStartWeek: number;
    let monthDayStartWeekFastsunday: number;
    // let monthDayStartWeekFastsunday = 0;
    let count: number = 0;
    let prevFastsunday: number = 0;

    let dateJpNumberA: number;
    let dateJpNumberB: number;
    let dateJpNumberC: number;
    let dateJpNumberD: number;
    let dateJpNumberE: number;

    let dateJpA: string = `明治`;
    let dateJpB: string = `大正`;
    let dateJpC: string = `昭和`;
    let dateJpD: string = `平成`;
    let dateJpE: string = `令和`;

    let dateJpExactA: number = 18680125;
    let dateJpExactB: number = 19120730;
    let dateJpExactC: number = 19261225;
    let dateJpExactD: number = 19890108;
    let dateJpExactE: number = 20190501;

    let mainContentsMonlink;
    let mainContentsA;
    let mainContentsB;
    let mainContentsC;
    let mainContentsD;



    /* *********************************************************************************************** */
    /* ***** 日歴・月暦・年歴         ***************************************************************** */
    /* ***** 各変数挿入               ****************************************************************** */
    /* *********************************************************************************************** */


    if (typeof date !== 'undefined') {

        let dateTmp = date;

        if (date.length === 4) {
            dateTmp = `${date}-01-01`;
        } else if (date.length === 6) {
            dateTmp = `${date.substring(0, 4)}-${date.substring(4, 6)}-01`;
        } else if (date.length === 8) {
            dateTmp = `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`;
        }

        let dateObj = new Date(dateTmp);
        let lowerLimit = new Date("1901-01-01");
        let upperLimit = new Date("2100-12-31");

        if (date.length === 4 && dateObj >= lowerLimit && dateObj <= upperLimit) {

            ////////////////////////////////////////////////////////////////////////
            //年暦の、各変数挿入
            ////////////////////////////////////////////////////////////////////////

            pageTitle = `${date}年の年間カレンダー`;
            pageTitleS = `${date}年`;
            dateF = `${date}`;
            dateYM = `${date}01`;
            dateYMD = `${date}0101`;
            dateM = 0;
            dateY = `${date}`;
            calendarType = 'year';

            dateJpNumberA = Number(dateY) - 1867; // 和暦の算出：明治
            dateJpNumberB = Number(dateY) - 1911; // 和暦の算出：大正
            dateJpNumberC = Number(dateY) - 1925; // 和暦の算出：昭和
            dateJpNumberD = Number(dateY) - 1988; // 和暦の算出：平成
            dateJpNumberE = Number(dateY) - 2018; // 和暦の算出：令和


            // 和暦の算出：明治
            if (dateJpNumberA === 1) {
                dateJpA = dateJpA + `元年`;
            } else if (dateJpNumberA >= 1 && Number(dateYMD) >= dateJpExactA) {
                dateJpA = dateJpA + `${dateJpNumberA}年/`;
            } else {
                dateJpA = "";
            }
            // 和暦の算出：大正
            if (dateJpNumberB === 1) {
                dateJpB = dateJpB + `元年`;
            } else if (dateJpNumberB >= 1) {
                dateJpB = dateJpB + `${dateJpNumberB}年/`;
            } else {
                dateJpB = "";
            }
            // 和暦の算出：昭和
            if (dateJpNumberC === 1) {
                dateJpC = dateJpC + `元年`;
            } else if (dateJpNumberC >= 1) {
                dateJpC = dateJpC + `${dateJpNumberC}年/`;
            } else {
                dateJpC = "";
            }
            // 和暦の算出：平成
            if (dateJpNumberD === 1) {
                dateJpD = dateJpD + `元年`;
            } else if (dateJpNumberD >= 1) {
                dateJpD = dateJpD + `${dateJpNumberD}年/`;
            } else {
                dateJpD = "";
            }
            // 和暦の算出：令和
            if (dateJpNumberE === 1) {
                dateJpE = dateJpE + `元年`;
            } else if (dateJpNumberE >= 1) {
                dateJpE = dateJpE + `${dateJpNumberE}年`;
            } else {
                dateJpE = "";
            }


        } else if (date.length === 6 && dateObj >= lowerLimit && dateObj <= upperLimit) {

            ////////////////////////////////////////////////////////////////////////
            //月暦の、各変数挿入
            ////////////////////////////////////////////////////////////////////////

            pageTitle = `${date.substring(0, 4)}年${Number(date.substring(4, 6))}月のカレンダー`;
            pageTitleS = `${date.substring(0, 4)}年${Number(date.substring(4, 6))}月`;
            dateF = `${date.substring(0, 4)}-${date.substring(4, 6)}`;
            dateYM = `${date.substring(0, 4)}${date.substring(4, 6)}`;
            dateYMD = `${date.substring(0, 4)}${date.substring(4, 6)}01`;
            dateM = Number(date.substring(4, 6));
            dateY = String(date.substring(0, 4));

            calendarType = 'month';

            dateJpNumberA = Number(dateY) - 1867; // 和暦の算出：明治
            dateJpNumberB = Number(dateY) - 1911; // 和暦の算出：大正
            dateJpNumberC = Number(dateY) - 1925; // 和暦の算出：昭和
            dateJpNumberD = Number(dateY) - 1988; // 和暦の算出：平成
            dateJpNumberE = Number(dateY) - 2018; // 和暦の算出：令和


            // 和暦の算出：明治
            if (dateJpNumberA === 1) {
                dateJpA = dateJpA + `元年`;
            } else if (dateJpNumberA >= 1 && Number(dateYMD) >= dateJpExactA) {
                dateJpA = dateJpA + `${dateJpNumberA}年/`;
            } else {
                dateJpA = "";
            }
            // 和暦の算出：大正
            if (dateJpNumberB === 1) {
                dateJpB = dateJpB + `元年`;
            } else if (dateJpNumberB >= 1) {
                dateJpB = dateJpB + `${dateJpNumberB}年/`;
            } else {
                dateJpB = "";
            }
            // 和暦の算出：昭和
            if (dateJpNumberC === 1) {
                dateJpC = dateJpC + `元年`;
            } else if (dateJpNumberC >= 1) {
                dateJpC = dateJpC + `${dateJpNumberC}年/`;
            } else {
                dateJpC = "";
            }
            // 和暦の算出：平成
            if (dateJpNumberD === 1) {
                dateJpD = dateJpD + `元年`;
            } else if (dateJpNumberD >= 1) {
                dateJpD = dateJpD + `${dateJpNumberD}年/`;
            } else {
                dateJpD = "";
            }
            // 和暦の算出：令和
            if (dateJpNumberE === 1) {
                dateJpE = dateJpE + `元年`;
            } else if (dateJpNumberE >= 1) {
                dateJpE = dateJpE + `${dateJpNumberE}年`;
            } else {
                dateJpE = "";
            }



        } else if (date.length === 8 && dateObj >= lowerLimit && dateObj <= upperLimit) {

            ////////////////////////////////////////////////////////////////////////
            // 日暦の、各変数挿入
            ////////////////////////////////////////////////////////////////////////

            // pageTitle = `日暦：${date.substring(0, 4)}年${Number(date.substring(4, 6))}月${Number(date.substring(6, 8))}日`;
            pageTitle = `${date.substring(0, 4)}年${Number(date.substring(4, 6))}月${Number(date.substring(6, 8))}日のカレンダー`;
            pageTitleS = `${date.substring(0, 4)}年${Number(date.substring(4, 6))}月${Number(date.substring(6, 8))}日`;
            dateF = `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`;
            dateYM = `${date.substring(0, 4)}${date.substring(4, 6)}`;
            dateYMD = `${date.substring(0, 4)}${date.substring(4, 6)}${date.substring(6, 8)}`;
            dateM = Number(date.substring(4, 6));
            dateY = String(date.substring(0, 4));

            calendarType = 'day';

            dateJpNumberA = Number(dateY) - 1867; // 和暦の算出：明治
            dateJpNumberB = Number(dateY) - 1911; // 和暦の算出：大正
            dateJpNumberC = Number(dateY) - 1925; // 和暦の算出：昭和
            dateJpNumberD = Number(dateY) - 1988; // 和暦の算出：平成
            dateJpNumberE = Number(dateY) - 2018; // 和暦の算出：令和

            // 和暦の算出：明治
            if (dateJpNumberA === 1 && Number(dateYMD) >= dateJpExactA) {
                dateJpA = dateJpA + `元年`;
            } else if (dateJpNumberA >= 1 && Number(dateYMD) >= dateJpExactA) {
                dateJpA = dateJpA + `${dateJpNumberA}年/`;
            } else {
                dateJpA = "";
            }
            // 和暦の算出：大正
            if (dateJpNumberB === 1 && Number(dateYMD) >= dateJpExactB) {
                dateJpB = dateJpB + `元年`;
            } else if (dateJpNumberB >= 1 && Number(dateYMD) >= dateJpExactB) {
                dateJpB = dateJpB + `${dateJpNumberB}年/`;
            } else {
                dateJpB = "";
            }
            // 和暦の算出：昭和
            if (dateJpNumberC === 1 && Number(dateYMD) >= dateJpExactC) {
                dateJpC = dateJpC + `元年`;
            } else if (dateJpNumberC >= 1 && Number(dateYMD) >= dateJpExactC) {
                dateJpC = dateJpC + `${dateJpNumberC}年/`;
            } else {
                dateJpC = "";
            }
            // 和暦の算出：平成
            if (dateJpNumberD === 1 && Number(dateYMD) >= dateJpExactD) {
                dateJpD = dateJpD + `元年`;
            } else if (dateJpNumberD >= 1 && Number(dateYMD) >= dateJpExactD) {
                dateJpD = dateJpD + `${dateJpNumberD}年/`;
            } else {
                dateJpD = "";
            }
            // 和暦の算出：令和
            if (dateJpNumberE === 1 && Number(dateYMD) >= dateJpExactE) {
                dateJpE = dateJpE + `元年`;
            } else if (dateJpNumberE >= 1 && Number(dateYMD) >= dateJpExactE) {
                dateJpE = dateJpE + `${dateJpNumberE}年`;
            } else {
                dateJpE = "";
            }

        } else {

            ////////////////////////////////////////////////////////////////////////
            // 日暦・月暦・年暦の判断不可のエラー
            ////////////////////////////////////////////////////////////////////////

            //エラーの場合の、各変数挿入
            pageTitle = "ERROR: このページは存在しません";
            pageTitleS = "";
            dateF = "";
            dateYM = "";
            dateYMD = "";
            dateM = 0;
            dateY = new Date().getFullYear().toString();

            calendarType = 'none';
        }
    } else {
        //エラーの場合の、各変数挿入
        pageTitle = "ERROR: このページは存在しません";
        pageTitleS = "";
        dateF = "";
        dateYM = "";
        dateYMD = "";
        dateM = 0;
        dateY = new Date().getFullYear().toString();
        calendarType = 'none';
    }

    const navigate = useNavigate();

    /* *********************************************************************************************** */
    /* ***** 日歴・月暦・年歴         ***************************************************************** */
    /* ***** 日付変更ウィンドウの作成 ******************************************************************* */
    /* ***** 日付変更リンクの作成     ******************************************************************* */
    /* *********************************************************************************************** */

    let searchWindow;
    let PrevNextCalendarLink;

    switch (calendarType) {
        case 'year':
            /* ***** 日付変更ウィンドウ：年歴 ***** */
            searchWindow = [
                <Box display="flex" alignItems="center" className='search_space' key='searchKey'>
                    年暦の変更：<MobileDatePicker
                        views={['year']}
                        defaultValue={dayjs(dateF)}
                        onChange={(newValue) => {
                            if (newValue) {
                                const formattedDate = newValue.format('YYYY');
                                navigate(`/calendar/${formattedDate}`);
                            }
                        }}
                        format="YYYY"
                        minDate={dayjs('1901-01-01')}
                        maxDate={dayjs('2100-12-31')}
                    />
                </Box>
            ];
            /* ***** 日付変更リンク：年歴 ***** */
            PrevNextCalendarLink = [
                <div key='prevnextcalendarlinkkeyhr' className='content_prevnextcalendarlink' style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>
                        <Link
                            component={RouterLink}
                            underline="hover"
                            key="1"
                            color="primary"
                            to={`/calendar/${dayjs(date).add(-1, 'year').format('YYYY')}`}
                            onClick={handleClick}
                            style={{ textDecoration: 'none' }}
                        >
                            前の年

                        </Link>
                    </span>
                    <span>{dateF}</span>
                    <span>
                        <Link
                            component={RouterLink}
                            underline="hover"
                            key="1"
                            color="primary"
                            to={`/calendar/${dayjs(date).add(1, 'year').format('YYYY')}`}
                            onClick={handleClick}
                            style={{ textDecoration: 'none' }}
                        >
                            次の年
                        </Link>
                    </span>
                </div>
            ];
            break;
        case 'month':
            /* ***** 日付変更ウィンドウ：月歴 ***** */
            searchWindow = [
                <Box display="flex" alignItems="center" className='search_space' key='searchKey'>
                    月暦の変更：<MobileDatePicker
                        views={['year', 'month']}
                        defaultValue={dayjs(dateF)}
                        onChange={(newValue) => {
                            if (newValue) {
                                const formattedDate = newValue.format('YYYYMM');
                                navigate(`/calendar/${formattedDate}`);
                            }
                        }}
                        format="YYYY/MM"
                        minDate={dayjs('1901-01-01')}
                        maxDate={dayjs('2100-12-31')}
                    />
                </Box>
            ];
            /* ***** 日付変更リンク：月歴 ***** */
            PrevNextCalendarLink = [
                <div key='prevnextcalendarlinkkeyhr' className='content_prevnextcalendarlink' style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>
                        <Link
                            component={RouterLink}
                            underline="hover"
                            key="1"
                            color="primary"
                            to={`/calendar/${dayjs(date).add(-1, 'month').format('YYYYMM')}`}
                            onClick={handleClick}
                            style={{ textDecoration: 'none' }}
                        >
                            前の月
                        </Link>
                    </span>
                    <span>{dateF}</span>
                    <span>
                        <Link
                            component={RouterLink}
                            underline="hover"
                            key="1"
                            color="primary"
                            to={`/calendar/${dayjs(date).add(1, 'month').format('YYYYMM')}`}
                            onClick={handleClick}
                            style={{ textDecoration: 'none' }}
                        >
                            次の月
                        </Link>
                    </span>
                </div>
            ];
            break;
        case 'day':
            /* ***** 日付変更ウィンドウ：日歴 ***** */
            searchWindow = [
                <Box display="flex" alignItems="center" className='search_space' key='searchKey'>
                    日暦の変更：<MobileDatePicker
                        views={['year', 'month', 'day']}
                        defaultValue={dayjs(dateF)}
                        onChange={(newValue) => {
                            if (newValue && newValue.isValid()) {
                                const formattedDate = newValue.format('YYYYMMDD');
                                navigate(`/calendar/${formattedDate}`);
                            }
                        }}
                        format="YYYY年MM月DD日"
                        minDate={dayjs('1901-01-01')}
                        maxDate={dayjs('2100-12-31')}
                        localeText={{ toolbarTitle: '日暦の年月日' }}
                        slotProps={{
                            toolbar: {
                                toolbarPlaceholder: '__',
                                toolbarFormat: 'YYYY年MM月DD日',
                                hidden: false,
                            }
                        }}
                    />
                </Box>
            ];
            /* ***** 日付変更リンク：日歴 ***** */
            PrevNextCalendarLink = [
                <div key='prevnextcalendarlinkkeyhr' className='content_prevnextcalendarlink' style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>
                        <Link
                            component={RouterLink}
                            underline="hover"
                            key="1"
                            color="primary"
                            to={`/calendar/${dayjs(date).add(-1, 'day').format('YYYYMMDD')}`}
                            onClick={handleClick}
                            style={{ textDecoration: 'none' }}
                        >
                            前の日
                        </Link>
                    </span>
                    <span>{dateF}</span>
                    <span>
                        <Link
                            component={RouterLink}
                            underline="hover"
                            key="1"
                            color="primary"
                            to={`/calendar/${dayjs(date).add(1, 'day').format('YYYYMMDD')}`}
                            onClick={handleClick}
                            style={{ textDecoration: 'none' }}
                        >
                            次の日
                        </Link>
                    </span>
                </div>
            ];
            break;
        default:
            /* ***** 日付変更ウィンドウ：日付該当無 ***** */
            searchWindow = [
                <Box key='searchKey'>
                </Box>
            ];
            /* ***** 日付変更リンク：日付該当無 ***** */
            PrevNextCalendarLink = [
                <span key='prevnextcalendarlinkkeyhr'>
                </span>
            ];
            break;
    }


    /* *********************************************************************************************** */
    /* ***** ページ名の作成 *************************************************************************** */
    /* *********************************************************************************************** */

    const breadcrumbs = [
        <Link
            component={RouterLink}
            underline="hover"
            key="1"
            color="inherit"
            to="/"
            onClick={handleClick}>
            <IconButton
                aria-label="ホームページへページ移動"
                sx={{
                    margin: '0px 0px 1px 0px',
                    padding: '0px'
                }}>
                <HomeIcon />
            </IconButton>
        </Link>,
        <Link
            component={RouterLink}
            underline="hover"
            key="2"
            color="inherit"
            to="/calendar"
            onClick={handleClick}
        >
            calendar
        </Link>,
        <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
            {pageTitle}
        </Typography>,
    ];

    /* ********************************************************************************************** */
    /* ***** メインコンテンツの作成 ******************************************************************* */
    /* ***** メインコンテンツの作成：前処理 ************************************************************* */
    /* *********************************************************************************************** */

    // 月暦カレンダー表作成のための、1日と月末日の把握
    if (calendarType === 'month' && dateYM) {
        // monthDayStart = 1;
        monthDayEnd = Number(MonthLastDay[dateYM]);
    } else {
        // monthDayStart = 1;
        monthDayEnd = 31;
    }

    // 今日の暦jsonの呼び出し //////////////////////////////////////////////////////////////////////

    const calendar_json_url = `https://db.suureki.net/_db_calendar/calendar_${dateY}.json`;
    const [koyomi_data, setDataKoyomi] = useState<Koyomi[]>([]);
    useEffect(() => {
        fetch(calendar_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(koyomi_data => setDataKoyomi(koyomi_data))
            .catch(error => console.error('Error:', error));
    }, [calendar_json_url]);

    // JSONデータを日付をキーとするオブジェクトに変換します。
    let weekMap: { [date: string]: number } = {};
    let xxrokkiMap: { [date: string]: number } = {};
    let xxsekkiMap: { [date: string]: number } = {};
    let eto10dayMap: { [date: string]: number } = {};
    let eto12dayMap: { [date: string]: number } = {};
    let eto10yearMap: { [date: string]: number } = {};
    let eto12yearMap: { [date: string]: number } = {};
    let xxyadohachiMap: { [date: string]: number } = {};
    let xxzyunityokuMap: { [date: string]: number } = {};
    let xxolddateMap: { [date: string]: string } = {};
    let xxolddateMapS: { [date: string]: string } = {};
    koyomi_data.forEach(koyomi => {
        weekMap[koyomi.date] = Number(koyomi.week);
        xxrokkiMap[koyomi.date] = koyomi.xxrokki;
        xxsekkiMap[koyomi.date] = koyomi.xxsekki;
        eto10dayMap[koyomi.date] = koyomi.eto10_day;
        eto12dayMap[koyomi.date] = koyomi.eto12_day;
        eto10yearMap[koyomi.date] = koyomi.eto10_year;
        eto12yearMap[koyomi.date] = koyomi.eto12_year;
        xxyadohachiMap[koyomi.date] = koyomi.xxyadohachi;
        xxzyunityokuMap[koyomi.date] = koyomi.xxzyunityoku;
        xxolddateMap[koyomi.date] = koyomi.xxolddate;
        const dateParts = koyomi.xxolddate.split('-').map(part => parseInt(part, 10));
        xxolddateMapS[koyomi.date] = dateParts[1] + "/" + dateParts[2];
    });

    // 旧暦の算出
    // "xxolddate": "1900-11-11",
    const formatOldDate = (xxolddate: string) => {
        const dateParts = xxolddate.split('-').map(part => parseInt(part, 10));
        return (<span key='xxolddatekey'>{dateParts[0]}年{dateParts[1]}月{dateParts[2]}日</span>);
    };

    // 七十二候の算出 Nanazyuni
    // "xxnanazyuni": "23-2-s",
    const formatNanazyuni = (xxnanazyuni: string) => {
        const nanazyuniParts = xxnanazyuni.split('-');
        nanazyuniParts[3] = (3 * Number(nanazyuniParts[0]) + Number(nanazyuniParts[1])).toString();
        return nanazyuniParts;
    };

    // 選日の算出 xxsenjitsu
    // "xxsenjitsu": "2f",
    // let senjitsuParts: string;
    const formatSenjitsu = (xxsenjitsu: string) => {
        let senjitsuecho = [];
        for (let i = 0; i <= xxsenjitsu.length - 1; i++) {
            let senjitsuParts = xxsenjitsu.substring(i, i + 1);
            // senjitsuParts は、0-G
            //NumberAlphabet[senjitsuParts] は、0-16
            senjitsuecho.push(<div key={i}>{Senjitsu[NumberAlphabet[senjitsuParts]]}<br /><span style={{ color: SenjitsuColor[NumberAlphabet[senjitsuParts]] }}>{SenjitsuMeaning[NumberAlphabet[senjitsuParts]]}</span></div>);
        }

        return (<span>{senjitsuecho}</span>);
    };

    // 雑節の算出 xxzassetsu
    // "xxzassetsu": "0p",
    // let zassetsuParts: string;
    const formatZassetsu = (xxzassetsu: string) => {
        let zassetsuecho = [];
        for (let i = 0; i <= xxzassetsu.length - 1; i++) {
            let zassetsuParts = xxzassetsu.substring(i, i + 1);
            // zassetsuParts は、0-S
            //NumberAlphabet[zassetsuParts] は、0-29
            zassetsuecho.push(<div key={i}>{Zassetsu[NumberAlphabet[zassetsuParts]]}<br /><span style={{ color: ZassetsuColor[NumberAlphabet[zassetsuParts]] }}>{ZassetsuMeaning[NumberAlphabet[zassetsuParts]]}</span></div>);
        }

        return (<span>{zassetsuecho}</span>);
    }



    // 今年の月状態jsonの呼び出し //////////////////////////////////////////////////////////////////////

    const moon_json_url = `https://db.suureki.net/_db_moon/moonstatefull_${dateY}.json`;
    const [moon_data, setDataMoonStateFull] = useState<MoonStateFull[]>([]);
    useEffect(() => {
        fetch(moon_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(moon_data => setDataMoonStateFull(moon_data))
            .catch(error => console.error('Error:', error));
    }, [moon_json_url]);

    // JSONデータを日付をキーとするオブジェクトに変換します。
    let moonEclipticRounding: { [date: string]: string } = {};
    let MoonAgeMap: { [date: string]: string } = {};
    moon_data.forEach(moon => {
        moonEclipticRounding[moon.date] = moon.mooneclipticrounding;
        MoonAgeMap[moon.date] = moon.moonage;
    });

    // 今年の月（新月・満月・下弦・上弦）状態jsonの呼び出し //////////////////////////////////////////////////////////////////////

    const moonstate_json_url = `https://db.suureki.net/_db_moon/moonstate_${dateY}.json`;
    const [moonstate_data, setDataMoonState] = useState<MoonState[]>([]);
    useEffect(() => {
        fetch(moonstate_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(moonstate_data => setDataMoonState(moonstate_data))
            .catch(error => console.error('Error:', error));
    }, [moonstate_json_url]);

    // JSONデータを日付をキーとするオブジェクトに変換します。
    let moonstatetime: { [date: string]: string } = {};
    let moonstateinfo: { [date: string]: string } = {};
    let moonstateage: { [date: string]: string } = {};
    moonstate_data.forEach(moon => {
        moonstatetime[moon.date] = moon.time;
        moonstateinfo[moon.date] = moon.mooninfo;
        moonstateage[moon.date] = moon.moonage;
    });


    // 月の状態の算出：0時の黄経差、0時の月齢
    // {"date": "20760110", "time": "0000", "moonecliptic": "44.9", "mooneclipticrounding": "045", "moonage": "3.19" },
    const formatMoon = (mooneclipticrounding: string) => {
        return mooneclipticrounding;
    };

    // 今年の祝日（祭日）jsonの呼び出し //////////////////////////////////////////////////////////////////////

    const horiday_json_url = "https://db.suureki.net/_db_horiday/horiday_all.json";

    const [horiday_data, setDataHoriday] = useState<Horiday[]>([]);
    useEffect(() => {
        fetch(horiday_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(horiday_data => setDataHoriday(horiday_data))
            .catch(error => console.error('Error:', error));
    }, [horiday_json_url]);

    // JSONデータを日付をキーとするオブジェクトに変換します。
    let holidayMap: { [date: string]: string } = {};
    horiday_data.forEach(horiday => {
        holidayMap[horiday.date] = horiday.holiday_dat;
    });


    // 祝日ではない日と風習 jsonの呼び出し //////////////////////////////////////////////////////////////////////

    const etcday_json_url = "https://db.suureki.net/_db_horiday/etcday_all.json";

    const [etcday_data, setDataEtcday] = useState<Etcday[]>([]);
    useEffect(() => {
        fetch(etcday_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(etcday_data => setDataEtcday(etcday_data))
            .catch(error => console.error('Error:', error));
    }, [etcday_json_url]);

    // JSONデータを日付をキーとするオブジェクトに変換します。
    let etcdayMap: { [date: string]: string } = {};
    etcday_data.forEach(etcday => {
        etcdayMap[etcday.date] = etcday.etcday_dat;
    });


    // 二十四節気の時間jsonの呼び出し //////////////////////////////////////////////////////////////////////

    const sekki_json_url = `https://db.suureki.net/_db_24sekki/24sekki_${dateY}.json`;


    const [sekki_data, setDataSekki] = useState<SekkiDay[]>([]);
    useEffect(() => {
        fetch(sekki_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(sekki_data => setDataSekki(sekki_data))
            .catch(error => console.error('Error:', error));
    }, [sekki_json_url]);

    // JSONデータを日付をキーとするオブジェクトに変換します。
    let sekkiTimeMap: { [date: string]: string } = {};
    let sekkiAngleMap: { [date: string]: string } = {};
    sekki_data.forEach(sekki => {
        sekkiTimeMap[sekki.date] = sekki.time;
        sekkiAngleMap[sekki.date] = sekki.angle;
    });


    /* **************************************************************************************************** */
    /* **************************************************************************************************** */
    /* **************************************************************************************************** */
    /* *****                            ******************************************************************* */
    /* ***** メインコンテンツの作成エリア ******************************************************************* */
    /* *****                            ******************************************************************* */
    /* **************************************************************************************************** */
    /* **************************************************************************************************** */
    /* **************************************************************************************************** */


    let monsEcho = 4; // スペースごとに、表示する月数

    switch (calendarType) {
        case 'year':
            /* **************************************************************************************************** */
            /* ***** メインコンテンツの作成：年暦：A ***************************************************************** */
            /* **************************************************************************************************** */

            count = 0;
            let tmpDateYM: number;
            tmpDateYM = Number(dateYM) - 1;
            let tmpDateD: number | null;
            tmpDateD = null;
            let tmpDateW: number;
            tmpDateW = weekMap[tmpDateYM + 1 + "01"];
            monthDayStartWeekFastsunday = 0;
            prevFastsunday = 0;



            mainContentsA = [
                <div key='maincontentskeya'>
                    <Box className='content_subbox'>
                        {koyomi_data.filter(koyomi => koyomi.date === dateYMD).map(koyomi => {
                            monthDayStartWeek = Number([koyomi.week]);
                            monthDayStartWeekFastsunday = 0 - monthDayStartWeek;
                            const prevYM = dayjs(dateYM).add(-1, 'month').format('YYYYMM')
                            const prevLastday = Number(dayjs(prevYM + '01').add(1, 'month').subtract(1, 'day').format('DD'));
                            prevFastsunday = prevLastday + monthDayStartWeekFastsunday;
                            return (
                                <Box sx={{ letterSpacing: '0.1em' }} key={koyomi.date}>
                                    {/* 年暦：新暦 */}
                                    <div className='calendar_info_box'>
                                        <span className='calendar_info_row_right'>
                                            <span style={{ color: 'green' }}>{pageTitleS}</span>、
                                            <span style={{ color: 'green' }}>{dateJpE}</span>{/* 令和 */}
                                            <br />
                                            <span style={{ color: 'gray' }}>{dateJpA}</span>{/* 明治 */}
                                            <span style={{ color: 'gray' }}>{dateJpB}</span>{/* 大正 */}
                                            <span style={{ color: 'gray' }}>{dateJpC}</span>{/* 昭和 */}
                                            <span style={{ color: 'gray' }}>{dateJpD}</span>{/* 平成 */}<br />
                                            <span style={{ color: 'gray' }}>{EtoName10[eto10yearMap[dateYMD]]}{EtoName12[eto12yearMap[dateYMD]]}（{EtoName12RubyA[eto12yearMap[dateYMD]]}年）</span>{/* 干支 */}<br />
                                        </span>
                                        <span style={{ color: 'green' }} className='calendar_info_row_left'>新暦：</span>
                                    </div>
                                </Box>
                            )
                        })}
                    </Box>
                </div>
            ];


            /* **************************************************************************************************** */
            /* ***** メインコンテンツの作成：年暦：B ***************************************************************** */
            /* **************************************************************************************************** */

            mainContentsB = [
                <div key='maincontentskeyb'>
                    <Box className='content_subbox'>
                        <Grid container>
                            {Array.from({ length: monsEcho }, (_, index) =>
                                <Grid key={index} sx={{ width: '335px', marginRight: '10px', marginBottom: '10px' }}>
                                    <Box sx={{ borderBottom: '5px solid white', padding: '0px 0px 0px 0px', backgroundColor: '#fff', width: '100%', display: 'grid', gridAutoFlow: 'row', gridTemplateColumns: 'repeat(2, 1fr)', gap: 0, minWidth: '330px', }}>
                                        <Box sx={{ textAlign: 'left', color: 'green', margin: '10px', fontWeight: 'normal', whiteSpace: 'nowrap' }}>
                                            {dateY}年{index + 1}月
                                            <span style={{ marginLeft: '1rem' }}>{MonName[index + 1]}</span>
                                            <span style={{ marginLeft: '0.3rem' }}>/</span>
                                            <span style={{ marginLeft: '0.3rem' }}>{MonNameEng[index + 1]}</span>
                                        </Box>
                                        <Box sx={{ textAlign: 'right', margin: '10px', fontWeight: 'normal' }}>
                                            <Link
                                                component={RouterLink}
                                                underline="hover"
                                                color="primary"
                                                to={`/calendar/${[tmpDateYM + 1]}`}
                                                onClick={handleClick}
                                                style={{ fontSize: '0.9rem', textDecoration: 'none', borderBottom: '1px dotted' }}
                                            >
                                                月暦
                                            </Link>
                                        </Box>
                                    </Box>
                                    <Box sx={{ borderBottom: '5px solid white', padding: '0px 0px 0px 0px', width: '100%', display: 'grid', gridAutoFlow: 'row', gridTemplateColumns: 'repeat(7, 1fr)', gap: 0, minWidth: '330px', }} className='calendar_bgcolor'>
                                        <Box sx={{ textAlign: 'right', color: '#f00', margin: '10px' }}>{WeekName[0]}</Box>
                                        <Box sx={{ textAlign: 'right', color: '#000', margin: '10px' }}>{WeekName[1]}</Box>
                                        <Box sx={{ textAlign: 'right', color: '#000', margin: '10px' }}>{WeekName[2]}</Box>
                                        <Box sx={{ textAlign: 'right', color: '#000', margin: '10px' }}>{WeekName[3]}</Box>
                                        <Box sx={{ textAlign: 'right', color: '#000', margin: '10px' }}>{WeekName[4]}</Box>
                                        <Box sx={{ textAlign: 'right', color: '#000', margin: '10px' }}>{WeekName[5]}</Box>
                                        <Box sx={{ textAlign: 'right', color: '#00F', margin: '10px' }}>{WeekName[6]}</Box>
                                    </Box>
                                    {Array.from({ length: 6 }, (_, i) => (
                                        <Box key={`outer-${i}`} sx={{ borderBottom: '5px solid white', padding: '0px 0px 0px 0px', width: '100%', display: 'grid', gridAutoFlow: 'row', gridTemplateColumns: 'repeat(7, 1fr)', gap: 0, minWidth: '330px', }} className='calendar_bgcolor'>
                                            {Array.from({ length: 7 }, (_, ii) => {
                                                if (i === 0 && ii === 0) {
                                                    tmpDateYM++;
                                                }
                                                if (ii === tmpDateW && tmpDateD === null && i === 0) {
                                                    tmpDateD = 0;
                                                }
                                                if (tmpDateD !== null) {
                                                    tmpDateD++;
                                                }
                                                if (Number(MonthLastDay[tmpDateYM]) + 1 === tmpDateD) {
                                                    tmpDateD = null;
                                                    tmpDateW = ii;
                                                }
                                                // count++;
                                                let tmpDateYMD = tmpDateYM.toString() + ("00" + tmpDateD).slice(-2);
                                                let tmpDateYMDJ = tmpDateYMD.slice(0, 4) + "年" + parseInt(tmpDateYMD.slice(4, 6), 10) + "月" + parseInt(tmpDateYMD.slice(6, 8), 10) + "日（" + WeekName[ii] + "）";
                                                // let sekkiTime = null;
                                                // if (sekkiTimeMap[tmpDateYMD]) {
                                                //     sekkiTime = parseInt(sekkiTimeMap[tmpDateYMD].slice(0, 2), 10) + "時" + parseInt(sekkiTimeMap[tmpDateYMD].slice(2, 4), 10) + "分";
                                                // }
                                                let thisDayHoriday = holidayMap[tmpDateYMD];
                                                let weekcolor;
                                                if (thisDayHoriday || ii === 0) { weekcolor = `red`; } else if (ii === 6) { weekcolor = `blue`; } else { weekcolor = `black`; }

                                                let calendarMOnBgColor = '#f8f8f8';
                                                if (todayYMD === tmpDateYMD) {
                                                    calendarMOnBgColor = '#ffc';
                                                } else {
                                                    calendarMOnBgColor = '#f8f8f8';
                                                }

                                                return (
                                                    <Box key={`inner-${ii}`} sx={{ textAlign: 'right', color: weekcolor, margin: '10px', height: '65px', backgroundColor: calendarMOnBgColor }}>
                                                        {/* 日にち */}
                                                        <span style={{ fontSize: '1.4rem' }}>
                                                            {tmpDateD}
                                                        </span>
                                                        <br />
                                                        {/* 六曜 */}
                                                        <span style={{ fontSize: '0.8rem', lineHeight: '1rem', color: '#666', whiteSpace: 'nowrap' }}>
                                                            {Rokki[xxrokkiMap[tmpDateYMD]]}
                                                        </span>
                                                        {/* 祝日（祭日） */}
                                                        {thisDayHoriday && <br />}
                                                        <HtmlTooltip
                                                            leaveTouchDelay={5000}
                                                            enterTouchDelay={0}
                                                            title={
                                                                <React.Fragment>
                                                                    {tmpDateYMDJ}
                                                                    <hr />
                                                                    {HoridayName[thisDayHoriday]}
                                                                </React.Fragment>
                                                            }
                                                            placement='top-start'
                                                        >
                                                            <span style={{ fontSize: '0.8rem', lineHeight: '1rem', color: 'red', whiteSpace: 'nowrap', borderBottom: '1px dotted' }}>
                                                                {HoridayNameS[thisDayHoriday]}
                                                            </span>
                                                        </HtmlTooltip>
                                                    </Box>
                                                );
                                            })}
                                        </Box>
                                    ))}
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </div>
            ];


            /* **************************************************************************************************** */
            /* ***** メインコンテンツの作成：年暦：C ***************************************************************** */
            /* **************************************************************************************************** */

            mainContentsC = [
                <div key='maincontentskeyc'>
                    <Box className='content_subbox'>
                        <Grid container>
                            {Array.from({ length: monsEcho }, (_, index) =>
                                <Grid key={index + monsEcho} sx={{ width: '335px', marginRight: '10px', marginBottom: '10px' }}>
                                    <Box sx={{ borderBottom: '5px solid white', padding: '0px 0px 0px 0px', backgroundColor: '#fff', width: '100%', display: 'grid', gridAutoFlow: 'row', gridTemplateColumns: 'repeat(2, 1fr)', gap: 0, minWidth: '330px', }}>
                                        <Box sx={{ textAlign: 'left', color: 'green', margin: '10px', fontWeight: 'normal', whiteSpace: 'nowrap' }}>
                                            {dateY}年{index + 1 + monsEcho}月
                                            <span style={{ marginLeft: '1rem' }}>{MonName[index + 1 + monsEcho]}</span>
                                            <span style={{ marginLeft: '0.3rem' }}>/</span>
                                            <span style={{ marginLeft: '0.3rem' }}>{MonNameEng[index + 1 + monsEcho]}</span>
                                        </Box>
                                        <Box sx={{ textAlign: 'right', margin: '10px', fontWeight: 'normal' }}>
                                            <Link
                                                component={RouterLink}
                                                underline="hover"
                                                color="primary"
                                                to={`/calendar/${[tmpDateYM + 1]}`}
                                                onClick={handleClick}
                                                style={{ fontSize: '0.9rem', textDecoration: 'none', borderBottom: '1px dotted' }}
                                            >
                                                月暦
                                            </Link>
                                        </Box>
                                    </Box>
                                    <Box sx={{ borderBottom: '5px solid white', padding: '0px 0px 0px 0px', width: '100%', display: 'grid', gridAutoFlow: 'row', gridTemplateColumns: 'repeat(7, 1fr)', gap: 0, minWidth: '330px', }} className='calendar_bgcolor'>
                                        <Box sx={{ textAlign: 'right', color: '#f00', margin: '10px' }}>{WeekName[0]}</Box>
                                        <Box sx={{ textAlign: 'right', color: '#000', margin: '10px' }}>{WeekName[1]}</Box>
                                        <Box sx={{ textAlign: 'right', color: '#000', margin: '10px' }}>{WeekName[2]}</Box>
                                        <Box sx={{ textAlign: 'right', color: '#000', margin: '10px' }}>{WeekName[3]}</Box>
                                        <Box sx={{ textAlign: 'right', color: '#000', margin: '10px' }}>{WeekName[4]}</Box>
                                        <Box sx={{ textAlign: 'right', color: '#000', margin: '10px' }}>{WeekName[5]}</Box>
                                        <Box sx={{ textAlign: 'right', color: '#00F', margin: '10px' }}>{WeekName[6]}</Box>
                                    </Box>
                                    {Array.from({ length: 6 }, (_, i) => (
                                        <Box key={`outer-${i}`} sx={{ borderBottom: '5px solid white', padding: '0px 0px 0px 0px', width: '100%', display: 'grid', gridAutoFlow: 'row', gridTemplateColumns: 'repeat(7, 1fr)', gap: 0, minWidth: '330px', }} className='calendar_bgcolor'>
                                            {Array.from({ length: 7 }, (_, ii) => {
                                                if (i === 0 && ii === 0) {
                                                    tmpDateYM++;
                                                }
                                                if (ii === tmpDateW && tmpDateD === null && i === 0) {
                                                    tmpDateD = 0;
                                                }
                                                if (tmpDateD !== null) {
                                                    tmpDateD++;
                                                }
                                                if (Number(MonthLastDay[tmpDateYM]) + 1 === tmpDateD) {
                                                    tmpDateD = null;
                                                    tmpDateW = ii;
                                                }
                                                // count++;
                                                let tmpDateYMD = tmpDateYM.toString() + ("00" + tmpDateD).slice(-2);
                                                let tmpDateYMDJ = tmpDateYMD.slice(0, 4) + "年" + parseInt(tmpDateYMD.slice(4, 6), 10) + "月" + parseInt(tmpDateYMD.slice(6, 8), 10) + "日（" + WeekName[ii] + "）";
                                                // let sekkiTime = null;
                                                // if (sekkiTimeMap[tmpDateYMD]) {
                                                //     sekkiTime = parseInt(sekkiTimeMap[tmpDateYMD].slice(0, 2), 10) + "時" + parseInt(sekkiTimeMap[tmpDateYMD].slice(2, 4), 10) + "分";
                                                // }
                                                let thisDayHoriday = holidayMap[tmpDateYMD];
                                                let weekcolor;
                                                if (thisDayHoriday || ii === 0) { weekcolor = `red`; } else if (ii === 6) { weekcolor = `blue`; } else { weekcolor = `black`; }

                                                let calendarMOnBgColor = '#f8f8f8';
                                                if (todayYMD === tmpDateYMD) {
                                                    calendarMOnBgColor = '#ffc';
                                                } else {
                                                    calendarMOnBgColor = '#f8f8f8';
                                                }

                                                return (
                                                    <Box key={`inner-${ii}`} sx={{ textAlign: 'right', color: weekcolor, margin: '10px', height: '65px', backgroundColor: calendarMOnBgColor }}>
                                                        {/* 日にち */}
                                                        <span style={{ fontSize: '1.4rem' }}>
                                                            {tmpDateD}
                                                        </span>
                                                        <br />
                                                        {/* 六曜 */}
                                                        <span style={{ fontSize: '0.8rem', lineHeight: '1rem', color: '#666', whiteSpace: 'nowrap' }}>
                                                            {Rokki[xxrokkiMap[tmpDateYMD]]}
                                                        </span>
                                                        {/* 祝日（祭日） */}
                                                        {thisDayHoriday && <br />}
                                                        <HtmlTooltip
                                                            leaveTouchDelay={5000}
                                                            enterTouchDelay={0}
                                                            title={
                                                                <React.Fragment>
                                                                    {tmpDateYMDJ}
                                                                    <hr />
                                                                    {HoridayName[thisDayHoriday]}
                                                                </React.Fragment>
                                                            }
                                                            placement='top-start'
                                                        >
                                                            <span style={{ fontSize: '0.8rem', lineHeight: '1rem', color: 'red', whiteSpace: 'nowrap', borderBottom: '1px dotted' }}>
                                                                {HoridayNameS[thisDayHoriday]}
                                                            </span>
                                                        </HtmlTooltip>
                                                    </Box>
                                                );
                                            })}
                                        </Box>
                                    ))}
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </div>
            ];

            /* **************************************************************************************************** */
            /* ***** メインコンテンツの作成：年暦：D ***************************************************************** */
            /* **************************************************************************************************** */

            mainContentsD = [
                <div key='maincontentskeyd'>
                    <Box className='content_subbox'>
                        <Grid container>
                            {Array.from({ length: monsEcho }, (_, index) =>

                                <Grid key={index + (monsEcho * 2)} sx={{ width: '335px', marginRight: '10px', marginBottom: '10px' }}>
                                    <Box sx={{ borderBottom: '5px solid white', padding: '0px 0px 0px 0px', backgroundColor: '#fff', width: '100%', display: 'grid', gridAutoFlow: 'row', gridTemplateColumns: 'repeat(2, 1fr)', gap: 0, minWidth: '330px', }}>
                                        <Box sx={{ textAlign: 'left', color: 'green', margin: '10px', fontWeight: 'normal', whiteSpace: 'nowrap' }}>
                                            {dateY}年{index + 1 + (monsEcho * 2)}月
                                            <span style={{ marginLeft: '1rem' }}>{MonName[index + 1 + (monsEcho * 2)]}</span>
                                            <span style={{ marginLeft: '0.3rem' }}>/</span>
                                            <span style={{ marginLeft: '0.3rem' }}>{MonNameEng[index + 1 + (monsEcho * 2)]}</span>
                                        </Box>
                                        <Box sx={{ textAlign: 'right', margin: '10px', fontWeight: 'normal' }}>
                                            <Link
                                                component={RouterLink}
                                                underline="hover"
                                                color="primary"
                                                to={`/calendar/${[tmpDateYM + 1]}`}
                                                onClick={handleClick}
                                                style={{ fontSize: '0.9rem', textDecoration: 'none', borderBottom: '1px dotted' }}
                                            >
                                                月暦
                                            </Link>
                                        </Box>
                                    </Box>
                                    <Box sx={{ borderBottom: '5px solid white', padding: '0px 0px 0px 0px', width: '100%', display: 'grid', gridAutoFlow: 'row', gridTemplateColumns: 'repeat(7, 1fr)', gap: 0, minWidth: '330px', }} className='calendar_bgcolor'>
                                        <Box sx={{ textAlign: 'right', color: '#f00', margin: '10px' }}>{WeekName[0]}</Box>
                                        <Box sx={{ textAlign: 'right', color: '#000', margin: '10px' }}>{WeekName[1]}</Box>
                                        <Box sx={{ textAlign: 'right', color: '#000', margin: '10px' }}>{WeekName[2]}</Box>
                                        <Box sx={{ textAlign: 'right', color: '#000', margin: '10px' }}>{WeekName[3]}</Box>
                                        <Box sx={{ textAlign: 'right', color: '#000', margin: '10px' }}>{WeekName[4]}</Box>
                                        <Box sx={{ textAlign: 'right', color: '#000', margin: '10px' }}>{WeekName[5]}</Box>
                                        <Box sx={{ textAlign: 'right', color: '#00F', margin: '10px' }}>{WeekName[6]}</Box>
                                    </Box>
                                    {Array.from({ length: 6 }, (_, i) => (
                                        <Box key={`outer-${i}`} sx={{ borderBottom: '5px solid white', padding: '0px 0px 0px 0px', width: '100%', display: 'grid', gridAutoFlow: 'row', gridTemplateColumns: 'repeat(7, 1fr)', gap: 0, minWidth: '330px', }} className='calendar_bgcolor'>
                                            {Array.from({ length: 7 }, (_, ii) => {
                                                if (i === 0 && ii === 0) {
                                                    tmpDateYM++;
                                                }
                                                if (ii === tmpDateW && tmpDateD === null && i === 0) {
                                                    tmpDateD = 0;
                                                }
                                                if (tmpDateD !== null) {
                                                    tmpDateD++;
                                                }
                                                if (Number(MonthLastDay[tmpDateYM]) + 1 === tmpDateD) {
                                                    tmpDateD = null;
                                                    tmpDateW = ii;
                                                }
                                                // count++;
                                                let tmpDateYMD = tmpDateYM.toString() + ("00" + tmpDateD).slice(-2);
                                                let tmpDateYMDJ = tmpDateYMD.slice(0, 4) + "年" + parseInt(tmpDateYMD.slice(4, 6), 10) + "月" + parseInt(tmpDateYMD.slice(6, 8), 10) + "日（" + WeekName[ii] + "）";
                                                // let sekkiTime = null;
                                                // if (sekkiTimeMap[tmpDateYMD]) {
                                                //     sekkiTime = parseInt(sekkiTimeMap[tmpDateYMD].slice(0, 2), 10) + "時" + parseInt(sekkiTimeMap[tmpDateYMD].slice(2, 4), 10) + "分";
                                                // }
                                                let thisDayHoriday = holidayMap[tmpDateYMD];
                                                let weekcolor;
                                                if (thisDayHoriday || ii === 0) { weekcolor = `red`; } else if (ii === 6) { weekcolor = `blue`; } else { weekcolor = `black`; }

                                                let calendarMOnBgColor = '#f8f8f8';
                                                if (todayYMD === tmpDateYMD) {
                                                    calendarMOnBgColor = '#ffc';
                                                } else {
                                                    calendarMOnBgColor = '#f8f8f8';
                                                }

                                                return (
                                                    <Box key={`inner-${ii}`} sx={{ textAlign: 'right', color: weekcolor, margin: '10px', height: '65px', backgroundColor: calendarMOnBgColor }}>
                                                        {/* 日にち */}
                                                        <span style={{ fontSize: '1.4rem' }}>
                                                            {tmpDateD}
                                                        </span>
                                                        <br />
                                                        {/* 六曜 */}
                                                        <span style={{ fontSize: '0.8rem', lineHeight: '1rem', color: '#666', whiteSpace: 'nowrap' }}>
                                                            {Rokki[xxrokkiMap[tmpDateYMD]]}
                                                        </span>
                                                        {/* 祝日（祭日） */}
                                                        {thisDayHoriday && <br />}
                                                        <HtmlTooltip
                                                            leaveTouchDelay={5000}
                                                            enterTouchDelay={0}
                                                            title={
                                                                <React.Fragment>
                                                                    {tmpDateYMDJ}
                                                                    <hr />
                                                                    {HoridayName[thisDayHoriday]}
                                                                </React.Fragment>
                                                            }
                                                            placement='top-start'
                                                        >
                                                            <span style={{ fontSize: '0.8rem', lineHeight: '1rem', color: 'red', whiteSpace: 'nowrap', borderBottom: '1px dotted' }}>
                                                                {HoridayNameS[thisDayHoriday]}
                                                            </span>
                                                        </HtmlTooltip>
                                                    </Box>
                                                );
                                            })}
                                        </Box>
                                    ))}
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </div>
            ];
            break;
        case 'month':
            /* **************************************************************************************************** */
            /* ***** メインコンテンツの作成：月暦 ******************************************************************* */
            /* **************************************************************************************************** */

            monthDayStartWeekFastsunday = 0;
            prevFastsunday = 0;
            let countprevday = prevFastsunday;

            /*
            let monthCalendarData: JSX.Element[] = [];
            {
                koyomi_data.map(koyomi => {
                    monthCalendarData.push(<Box sx={{ textAlign: 'center' }}>{koyomi.date}</Box>);
                    return (monthCalendarData)
                })
            }
            */


            mainContentsMonlink = [
                <div key='calendarLink'>
                    <h2 className='content_subtitle'>カレンダー切替</h2>
                    <div style={{ marginBottom: '2rem' }}>
                        <Stack spacing={2} direction="row" sx={{ marginTop: '2rem' }}>
                            <Link component={RouterLink} underline="always" key="calendarLinkA" color="primary" to={`/calendar/${dateYM}`} onClick={handleClick}>
                                <Button variant="contained"><CheckBoxIcon style={{ marginRight: '0.5rem' }} />暦注</Button>
                            </Link>
                            <Link component={RouterLink} underline="always" key="calendarLinkB" color="primary" to={`/goodday/${dateYM}`} onClick={handleClick}>
                                <Button variant="outlined"><CheckBoxOutlineBlankIcon style={{ marginRight: '0.5rem' }} />吉凶判断</Button>
                            </Link>
                            <Link component={RouterLink} underline="always" key="calendarLinkC" color="primary" to={`/buddhafair/${dateYM}`} onClick={handleClick}>
                                <Button variant="outlined"><CheckBoxOutlineBlankIcon style={{ marginRight: '0.5rem' }} />仏様御縁日</Button>
                            </Link>
                        </Stack>
                    </div>
                </div>
            ]

            /* **************************************************************************************************** */
            /* ***** メインコンテンツの作成：月暦：A ***************************************************************** */
            /* **************************************************************************************************** */

            mainContentsA = [
                <div key='maincontentskeya'>
                    <Box className='content_subbox'>
                        {koyomi_data.filter(koyomi => koyomi.date === dateYMD).map(koyomi => {
                            monthDayStartWeek = Number([koyomi.week]);
                            monthDayStartWeekFastsunday = 0 - monthDayStartWeek;
                            const prevYM = dayjs(dateYM).add(-1, 'month').format('YYYYMM')
                            const prevLastday = Number(dayjs(prevYM + '01').add(1, 'month').subtract(1, 'day').format('DD'));
                            prevFastsunday = prevLastday + monthDayStartWeekFastsunday;
                            return (
                                <Box sx={{ letterSpacing: '0.1em' }} key={koyomi.date}>
                                    {/* 月暦：新暦 */}
                                    <div className='calendar_info_box'>
                                        <span className='calendar_info_row_right'>
                                            <span style={{ color: 'green' }}>{pageTitleS}</span><br />
                                            <span style={{ color: 'green' }}>{dateJpE}、</span>{/* 令和 */}
                                            <HtmlTooltip
                                                leaveTouchDelay={5000}
                                                enterTouchDelay={0}
                                                title={
                                                    <React.Fragment>
                                                        <span style={{ lineHeight: '1.3rem' }}>
                                                            {MonName[dateM]}（{MonNameRuby[dateM]}）
                                                            <hr />
                                                            {dateM}月の別名
                                                        </span>
                                                    </React.Fragment>
                                                }
                                                placement='top-start'
                                            >
                                                <span style={{ color: 'green', borderBottom: '1px dotted' }}>
                                                    {MonName[dateM]}
                                                </span>
                                            </HtmlTooltip>
                                            <br />
                                            <span style={{ color: 'gray' }}>{dateJpA}</span>{/* 明治 */}
                                            <span style={{ color: 'gray' }}>{dateJpB}</span>{/* 大正 */}
                                            <span style={{ color: 'gray' }}>{dateJpC}</span>{/* 昭和 */}
                                            <span style={{ color: 'gray' }}>{dateJpD}</span>{/* 平成 */}<br />
                                        </span>
                                        <span style={{ color: 'green' }} className='calendar_info_row_left'>新暦：</span>
                                    </div>
                                </Box>
                            )
                        })}
                    </Box>
                </div>
            ];

            /* **************************************************************************************************** */
            /* ***** メインコンテンツの作成：月暦：B ***************************************************************** */
            /* **************************************************************************************************** */

            count = monthDayStartWeekFastsunday;
            countprevday = prevFastsunday;

            mainContentsB = [
                <div key='maincontentskeyb'>
                    <h2 className='content_subtitle'>月暦カレンダー</h2>
                    <Box className='content_subbox'>
                        <Box sx={{
                            borderBottom: '5px solid white',
                            padding: '0px 0px 0px 0px',
                            backgroundColor: '#f8f8f8',
                            width: '100%',
                            display: 'grid',
                            gridAutoFlow: 'row',
                            gridTemplateColumns: 'repeat(7, 1fr)',
                            gap: 0,
                            minWidth: '330px',
                        }}>
                            <Box sx={{ textAlign: 'right', color: '#f00', margin: '10px' }}>{WeekName[0]}</Box>
                            <Box sx={{ textAlign: 'right', color: '#000', margin: '10px' }}>{WeekName[1]}</Box>
                            <Box sx={{ textAlign: 'right', color: '#000', margin: '10px' }}>{WeekName[2]}</Box>
                            <Box sx={{ textAlign: 'right', color: '#000', margin: '10px' }}>{WeekName[3]}</Box>
                            <Box sx={{ textAlign: 'right', color: '#000', margin: '10px' }}>{WeekName[4]}</Box>
                            <Box sx={{ textAlign: 'right', color: '#000', margin: '10px' }}>{WeekName[5]}</Box>
                            <Box sx={{ textAlign: 'right', color: '#00F', margin: '10px' }}>{WeekName[6]}</Box>
                        </Box>
                        {
                            Array.from({ length: 6 }, (_, i) => (
                                <Box key={`outer-${i}`} sx={{
                                    borderBottom: '5px solid white',
                                    padding: '0px 0px 0px 0px',
                                    width: '100%',
                                    display: 'grid',
                                    gridAutoFlow: 'row',
                                    gridTemplateColumns: 'repeat(7, 1fr)',
                                    gap: 0,
                                    minWidth: '330px',
                                }} className='calendar_bgcolor'>
                                    {Array.from({ length: 7 }, (_, ii) => {
                                        count++;
                                        if (count >= 1 && count <= monthDayEnd) {
                                            let tmpDateYMD = dateYM + String(count).padStart(2, '0');
                                            let thisDayHoriday = holidayMap[tmpDateYMD];
                                            let weekcolor;

                                            let moonTooltipA = "0時の月齢：" + MoonAgeMap[tmpDateYMD];
                                            let moonTooltipB = "";
                                            let betweenmoonTooltipAB = "";
                                            let rawTime = "";
                                            let formattedTime = "";

                                            if (thisDayHoriday || ii === 0) { weekcolor = `red`; } else if (ii === 6) { weekcolor = `blue`; } else { weekcolor = `black`; }

                                            let tmpDateYMDJ = tmpDateYMD.slice(0, 4) + "年" + parseInt(tmpDateYMD.slice(4, 6), 10) + "月" + parseInt(tmpDateYMD.slice(6, 8), 10) + "日（" + WeekName[ii] + "）";


                                            if (moonstatetime[tmpDateYMD]) {
                                                rawTime = moonstatetime[tmpDateYMD];
                                                formattedTime = parseInt(rawTime.slice(0, 2), 10) + "時" + parseInt(rawTime.slice(2), 10) + "分";
                                                moonTooltipB = MoonName[moonstateinfo[tmpDateYMD]] + "の時間：" + formattedTime;
                                                betweenmoonTooltipAB = "<hr />";
                                            }

                                            let moonBgcolor;
                                            let moonTooltipImg = "";

                                            if (moonstateinfo[tmpDateYMD] === "newxxx-000") {
                                                moonBgcolor = "yellow";
                                                moonTooltipImg = "<hr /><img width='35px' src='https://suureki.net/moon_img/moon_000_half.svg' />";
                                            } else if (moonstateinfo[tmpDateYMD] === "halfxx-090") {
                                                moonTooltipImg = "<hr /><img width='35px' src='https://suureki.net/moon_img/moon_090_half.svg' />";
                                            } else if (moonstateinfo[tmpDateYMD] === "fullxx-180") {
                                                moonBgcolor = "yellow";
                                                moonTooltipImg = "<hr /><img width='35px' src='https://suureki.net/moon_img/moon_180_half.svg' />";
                                            } else if (moonstateinfo[tmpDateYMD] === "waning-270") {
                                                moonTooltipImg = "<hr /><img width='35px' src='https://suureki.net/moon_img/moon_270_half.svg' />";
                                            }

                                            let sekkiTime = null;
                                            if (sekkiTimeMap[tmpDateYMD]) {
                                                sekkiTime = parseInt(sekkiTimeMap[tmpDateYMD].slice(0, 2), 10) + "時" + parseInt(sekkiTimeMap[tmpDateYMD].slice(2, 4), 10) + "分";
                                            }

                                            let calendarMOnBgColor = '#f8f8f8';
                                            if (todayYMD === tmpDateYMD) {
                                                calendarMOnBgColor = '#ffc';
                                            } else {
                                                calendarMOnBgColor = '#f8f8f8';
                                            }

                                            return (<Box key={`inner-${ii}`} sx={{ textAlign: 'right', height: '180px', margin: '10px 10px 10px 0px', backgroundColor: calendarMOnBgColor }}>
                                                {/* 日にち */}
                                                <span style={{ fontSize: '1.5rem', textAlign: 'right', color: weekcolor, whiteSpace: 'nowrap', marginBottom: '6px' }}>{count}</span>
                                                {/* 旧暦 */}

                                                {/* 月画像 */}
                                                <br />
                                                <HtmlTooltip
                                                    leaveTouchDelay={5000}
                                                    enterTouchDelay={0}
                                                    title={
                                                        <React.Fragment>
                                                            {tmpDateYMDJ}
                                                            <hr />
                                                            {moonTooltipA}
                                                            {betweenmoonTooltipAB && <div dangerouslySetInnerHTML={{ __html: betweenmoonTooltipAB }} />}
                                                            {moonTooltipB}

                                                            {moonTooltipImg && <div dangerouslySetInnerHTML={{ __html: moonTooltipImg }} />}

                                                        </React.Fragment>
                                                    }
                                                    placement='top-start'
                                                >
                                                    <span style={{ fontSize: '0.9rem', lineHeight: '1rem', color: 'red', whiteSpace: 'nowrap' }}>
                                                        <span style={{ fontSize: '0.9rem', lineHeight: '1rem', color: 'black', whiteSpace: 'nowrap', backgroundColor: moonBgcolor, margin: '0px', padding: '0px 2px 0px 2px' }}>{MoonNameSimple[moonstateinfo[tmpDateYMD]]}</span>
                                                        <br />
                                                        <span><img width='35px' src={`https://suureki.net/moon_img/moon_${moonEclipticRounding[tmpDateYMD]}_half.svg`} alt='' /></span>
                                                    </span>
                                                </HtmlTooltip>

                                                <br /><span style={{ fontSize: '0.8rem', lineHeight: '1rem', color: 'green', whiteSpace: 'nowrap' }}>{xxolddateMapS[tmpDateYMD]}</span>
                                                {/* 六曜 */}
                                                <br />
                                                <span style={{ fontSize: '0.9rem', lineHeight: '1rem', color: '#666', whiteSpace: 'nowrap' }}>{Rokki[xxrokkiMap[tmpDateYMD]]}</span>
                                                {/* 干支 */}
                                                <br />
                                                <span style={{ fontSize: '0.9rem', lineHeight: '1rem', color: '#666', whiteSpace: 'nowrap' }}>{EtoName10[eto10dayMap[tmpDateYMD]]}{EtoName12[eto12dayMap[tmpDateYMD]]}</span>
                                                {/* 祝日（祭日） */}
                                                {thisDayHoriday && <br />}
                                                <HtmlTooltip
                                                    leaveTouchDelay={5000}
                                                    enterTouchDelay={0}
                                                    title={
                                                        <React.Fragment>
                                                            {tmpDateYMDJ}
                                                            <hr />
                                                            {HoridayName[thisDayHoriday]}
                                                        </React.Fragment>
                                                    }
                                                    placement='top-start'
                                                >
                                                    <span style={{ fontSize: '0.9rem', lineHeight: '1rem', color: 'red', whiteSpace: 'nowrap', borderBottom: '1px dotted' }}>{HoridayNameS[thisDayHoriday]}</span>
                                                </HtmlTooltip>
                                                {/* 二十四節気 */}
                                                {xxsekkiMap[tmpDateYMD] && <br />}
                                                <HtmlTooltip
                                                    leaveTouchDelay={5000}
                                                    enterTouchDelay={0}
                                                    title={
                                                        <React.Fragment>
                                                            <span style={{ lineHeight: '1.3rem' }}>
                                                                {tmpDateYMDJ}
                                                                <hr />
                                                                {SekkiPlusRuby[xxsekkiMap[tmpDateYMD]]}入の時間：
                                                                {sekkiTime}
                                                                <br />
                                                                {SekkiMeaning[xxsekkiMap[tmpDateYMD]]}
                                                            </span>
                                                        </React.Fragment>
                                                    }
                                                    placement='top-start'
                                                >
                                                    <span style={{ fontSize: '0.9rem', lineHeight: '1rem', color: 'green', whiteSpace: 'nowrap', borderBottom: '1px dotted' }}>{Sekki[xxsekkiMap[tmpDateYMD]]}</span>
                                                </HtmlTooltip>

                                            </Box>);
                                        } else if (count > monthDayEnd) {
                                            let countnextmonth = count - monthDayEnd;
                                            return (<Box key={`inner-${ii}`} sx={{ textAlign: 'right', color: 'gray', height: '90px', fontSize: '1.0rem', margin: '10px 10px 10px 0px' }}>{countnextmonth}</Box>);
                                        } else {
                                            countprevday = countprevday + 1;
                                            return (<Box key={`inner-${ii}`} sx={{ textAlign: 'right', color: 'gray', height: '90px', fontSize: '1.0rem', margin: '10px 10px 10px 0px' }}>{countprevday}</Box>);
                                        }
                                    })}
                                </Box>
                            ))
                        }
                    </Box>
                </div>
            ];

            /* **************************************************************************************************** */
            /* ***** メインコンテンツの作成：月暦：C ***************************************************************** */
            /* **************************************************************************************************** */

            count = monthDayStartWeekFastsunday;
            countprevday = prevFastsunday;

            mainContentsC = [
                <div key='maincontentskeyc'>
                </div>
            ];

            /* **************************************************************************************************** */
            /* ***** メインコンテンツの作成：月暦：D ***************************************************************** */
            /* **************************************************************************************************** */

            count = monthDayStartWeekFastsunday;
            countprevday = prevFastsunday;
            const datesMonCalendar: string[] = [];
            for (let iii = 1; iii <= 31; iii++) {
                const formattedDate = `<tr><th>-${String(iii).padStart(2, '0')}</th><td>comment</td></tr>`;
                datesMonCalendar.push(formattedDate);
            }

            mainContentsD = [
                <div key='maincontentskeyd'>
                    <h2 className='content_subtitle'>干支・二十八宿・十二直・雑節・選日カレンダー</h2>
                    <div style={{ margin: '0rem 0rem 0rem 5rem', display: 'flex', alignItems: 'center' }}>
                        <ArrowLeftIcon color="disabled" />
                        <ArrowLeftIcon color="disabled" />
                        <SwipeIcon fontSize="small" color="disabled" />
                        <ArrowRightIcon color="disabled" />
                        <ArrowRightIcon color="disabled" />
                        <span style={{ color: 'gray', fontSize: '0.8rem', marginLeft: '0.5rem' }}>Swipe on the table.</span>
                    </div>

                    <Box className='content_subbox'>
                        <div className='table_enclosure'>
                            <table className='swipe_table'>
                                <thead>
                                    <tr>
                                        <th className='swipe_table_cell swipe_table_th_fixing' style={{ textAlign: "center", fontSize: '0.9rem', width: '1.5rem', backgroundColor: '#e7e7e7' }}>{dateY}</th>
                                        <th className='swipe_table_cell calendar_bgcolor' style={{ textAlign: "left", fontSize: '0.9rem', width: '1.5rem' }}>六曜<br />干支</th>
                                        <th className='swipe_table_cell calendar_bgcolor' style={{ textAlign: "left", fontSize: '0.9rem', width: '1.5rem' }}>28宿<br />12直</th>
                                        <th className='swipe_table_cell calendar_bgcolor' style={{ textAlign: "left", fontSize: '0.9rem', width: '1.5rem' }}>二十四節気・祝日<br />選日・雑節</th>
                                        <th className='swipe_table_cell calendar_bgcolor' style={{ textAlign: "left", fontSize: '0.9rem', width: '1.5rem' }}>日暦<br />ﾘﾝｸ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* koyomi_data.filter(koyomi => koyomi.date === dateYMD).map(koyomi => ( */}
                                    {/* koyomi_data.filter(koyomi => koyomi.date.substring(0, 6) === dateYMD.substring(0, 6)).map(koyomi => { */}
                                    {/* koyomi_data.filter(koyomi => koyomi.date.substring(0, 6) === dateYMD?.substring(0, 6)).map(koyomi => */}
                                    {/* koyomi_data.map(koyomi => { */}
                                    {koyomi_data.filter(koyomi => koyomi.date.substring(0, 6) === dateYMD.substring(0, 6)).map(koyomi => {

                                        const dateStr = String(koyomi.date);
                                        const date = new Date(
                                            parseInt(dateStr.slice(0, 4), 10),
                                            parseInt(dateStr.slice(4, 6), 10) - 1,
                                            parseInt(dateStr.slice(6, 8), 10)
                                        );
                                        const formattedDate = `${date.getMonth() + 1}/${date.getDate()}`;
                                        // const oldDateParts = koyomi.xxolddate.split("-");
                                        // const formattedOldDate = `${parseInt(oldDateParts[1], 10)}/${parseInt(oldDateParts[2], 10)}`;
                                        let weekcolor;
                                        let thisDayHoriday = holidayMap[koyomi.date];
                                        if (holidayMap[koyomi.date] || parseInt(koyomi.week, 10) === 0) {
                                            weekcolor = `red`;
                                        } else if (parseInt(koyomi.week, 10) === 6) {
                                            weekcolor = `blue`;
                                        } else {
                                            weekcolor = `black`;
                                        }
                                        let thisDayEtcday = etcdayMap[koyomi.date];

                                        // 選日の算出 xxsenjitsu
                                        // "xxsenjitsu": "2f",
                                        // let senjitsuParts: string;
                                        const formatSenjitsu = (xxsenjitsu: string) => {
                                            let senjitsuecho = [];
                                            for (let i = 0; i <= xxsenjitsu.length - 1; i++) {
                                                let senjitsuParts = xxsenjitsu.substring(i, i + 1);
                                                senjitsuecho.push(
                                                    <span key={i}>
                                                        {Senjitsu[NumberAlphabet[senjitsuParts]]}
                                                        {i !== xxsenjitsu.length - 1 ? "、" : ""}
                                                    </span>
                                                );
                                            }
                                            return (<span>{senjitsuecho}</span>);
                                        }

                                        // 雑節の算出 xxzassetsu
                                        // "xxzassetsu": "0p",
                                        // let zassetsuParts: string;
                                        const formatZassetsu = (xxzassetsu: string) => {
                                            let zassetsuecho = [];
                                            for (let i = 0; i <= xxzassetsu.length - 1; i++) {
                                                let zassetsuParts = xxzassetsu.substring(i, i + 1);
                                                zassetsuecho.push(
                                                    <span key={i}>
                                                        {Zassetsu[NumberAlphabet[zassetsuParts]]}
                                                        {i !== xxzassetsu.length - 1 ? "、" : ""}
                                                    </span>
                                                );
                                            }

                                            return (<span>{zassetsuecho}</span>);
                                        }

                                        const senjitsuComponent = formatSenjitsu(koyomi.xxsenjitsu);
                                        const zassetsuComponent = formatZassetsu(koyomi.xxzassetsu);
                                        let betweensenjitsuzassetsu = "";

                                        if (koyomi.xxsenjitsu !== "" && koyomi.xxzassetsu !== "") {
                                            betweensenjitsuzassetsu = "、";
                                        }

                                        let betweenxxsekkithisDayHoriday = "";
                                        if (String(koyomi.xxsekki) !== "" && thisDayHoriday !== null) {
                                            betweenxxsekkithisDayHoriday = "、";
                                        }

                                        let betweenxxsekkithisDayEtcday = "";
                                        if (thisDayHoriday !== undefined || String(koyomi.xxsekki) !== "") {
                                            if (thisDayEtcday != null) {
                                                betweenxxsekkithisDayEtcday = "、";
                                            }
                                        }

                                        let sekkiTime = null;
                                        if (sekkiTimeMap[koyomi.date]) {
                                            sekkiTime = "（" + parseInt(sekkiTimeMap[koyomi.date].slice(0, 2), 10) + "時" + parseInt(sekkiTimeMap[koyomi.date].slice(2, 4), 10) + "分）";
                                        }


                                        let calendarMOnBgColor = '#f8f8f8';
                                        if (todayYMD === koyomi.date) {
                                            calendarMOnBgColor = '#ffc';
                                        } else {
                                            calendarMOnBgColor = '#f8f8f8';
                                        }
                                        let calendarMOnBgColorTh = '#e7e7e7';
                                        if (todayYMD === koyomi.date) {
                                            calendarMOnBgColorTh = '#ffc';
                                        } else {
                                            calendarMOnBgColorTh = '#e7e7e7';
                                        }



                                        return (
                                            <tr key={koyomi.date} style={{ color: weekcolor }}>
                                                {/* 日付・曜日 */}
                                                <th className='swipe_table_cell calendar_bgcolor swipe_table_th_fixing' style={{ textAlign: "right", color: weekcolor, backgroundColor: calendarMOnBgColorTh }}>
                                                    {formattedDate}<br />
                                                    {WeekName[koyomi.week]}
                                                </th>
                                                {/* 干支 */}
                                                <td className='swipe_table_cell calendar_bgcolor' style={{ backgroundColor: calendarMOnBgColor }}>
                                                    <span style={{ fontSize: '0.9rem' }}>
                                                        <span>{Rokki[xxrokkiMap[koyomi.date]]}</span><br />
                                                        <span>{EtoName10[eto10dayMap[koyomi.date]]}{EtoName12[eto12dayMap[koyomi.date]]}</span>
                                                    </span>
                                                </td>
                                                {/* 28宿、12直 */}
                                                <td className='swipe_table_cell calendar_bgcolor' style={{ backgroundColor: calendarMOnBgColor }}>
                                                    <span style={{ fontSize: '0.9rem' }}>
                                                        <span>{Yadohachi[xxyadohachiMap[koyomi.date]]}</span><br />
                                                        <span>{Zyunityoku[xxzyunityokuMap[koyomi.date]]}</span>
                                                    </span>
                                                </td>
                                                {/* 干支・二十八宿・十二直・雑節・選日 */}
                                                <td className='swipe_table_cell calendar_bgcolor' style={{ backgroundColor: calendarMOnBgColor }}>
                                                    <span style={{ fontSize: '0.9rem' }}>
                                                        <span style={{ color: 'green' }}>{Sekki[koyomi.xxsekki]}{sekkiTime}</span>
                                                        {betweenxxsekkithisDayHoriday}
                                                        <span style={{ color: 'red' }}>{HoridayName[thisDayHoriday]}</span>
                                                        {betweenxxsekkithisDayEtcday}
                                                        <span style={{ color: 'green' }}>{thisDayEtcday}</span>
                                                        <br />
                                                        <span>
                                                            {senjitsuComponent}
                                                            {betweensenjitsuzassetsu}
                                                            {zassetsuComponent}
                                                        </span>
                                                    </span>
                                                </td>

                                                {/* 日暦リンク */}
                                                <td className='swipe_table_cell calendar_bgcolor' style={{ backgroundColor: calendarMOnBgColor }}>
                                                    <span style={{ fontSize: '0.9rem' }}>
                                                        <Link
                                                            component={RouterLink}
                                                            underline="hover"
                                                            key="1"
                                                            color="primary"
                                                            to={`/calendar/${[koyomi.date]}`}
                                                            onClick={handleClick}
                                                            style={{ textDecoration: 'none', borderBottom: '1px dotted' }}
                                                        >
                                                            日暦
                                                        </Link>
                                                    </span>
                                                </td>

                                            </tr>
                                        );
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </Box>

                </div>
            ];

            break;
        case 'day':
            /* **************************************************************************************************** */
            /* ***** メインコンテンツの作成：日暦：A ***************************************************************** */
            /* **************************************************************************************************** */

            mainContentsA = [
                <div key='maincontentskeya'>
                    <Box className='content_subbox'>
                        {/* mapは、フィルターを掛けずに全て表示する */}
                        {/* {koyomi_data.map(koyomi => ( */}
                        {koyomi_data.filter(koyomi => koyomi.date === dateYMD).map(koyomi => (
                            <Box sx={{ letterSpacing: '0.1em' }} key={koyomi.date}>
                                {/* 日暦：新暦 */}
                                <div className='calendar_info_box'>
                                    <span className='calendar_info_row_right'>
                                        <span style={{ color: 'green' }}>{pageTitleS}（{WeekName[koyomi.week]}）</span><br />
                                        <span style={{ color: 'green' }}>{dateJpE}/</span>{/* 令和 */}
                                        <span style={{ color: 'green' }}>{MonName[dateM]}（{MonNameRuby[dateM]}、{dateM}月の別名）</span><br />
                                        <span style={{ color: 'gray' }}>{dateJpA}{/* 明治 */}</span>
                                        <span style={{ color: 'gray' }}>{dateJpB}{/* 大正 */}</span>
                                        <span style={{ color: 'gray' }}>{dateJpC}{/* 昭和 */}</span>
                                        <span style={{ color: 'gray' }}>{dateJpD}{/* 平成 */}</span>
                                    </span>
                                    <span style={{ color: 'green' }} className='calendar_info_row_left'>新暦：</span>
                                </div>
                                {/* 日暦：旧暦 */}
                                <div className='calendar_info_box' style={{ color: 'green' }}>
                                    <span className='calendar_info_row_right'>{formatOldDate(koyomi.xxolddate)}</span>
                                    <span className='calendar_info_row_left'>旧暦：</span>
                                </div>
                                {/* 日暦：ユリウス暦 */}
                                <div className='calendar_info_box' style={{ color: 'green' }}>
                                    <span className='calendar_info_row_right'>{koyomi.jd}</span>
                                    <span className='calendar_info_row_left'>ﾕﾘｳｽ暦：</span>
                                </div>
                                <Divider className='content_hr' style={{ margin: "1rem 0rem 1rem 0rem" }} />
                                {/* 日暦：二十四節気 */}
                                <div className='calendar_info_box'>
                                    <span className='calendar_info_row_right'>{SekkiPlusRuby[koyomi.xxsekki]}</span><br />
                                    <span className='calendar_info_row_left'>二十四節気：</span>
                                </div>
                                {/* 日暦：七十二候 */}
                                <div className='calendar_info_box'>
                                    <span className='calendar_info_row_right'>
                                        {Sekki[Number(formatNanazyuni(koyomi.xxnanazyuni)[0])]}の{Bml[Number(formatNanazyuni(koyomi.xxnanazyuni)[1])]}、<br />
                                        {Nanazyuni[Number(formatNanazyuni(koyomi.xxnanazyuni)[3])]}
                                        （{NanazyuniRuby[Number(formatNanazyuni(koyomi.xxnanazyuni)[3])]}）<br />
                                        {NanazyuniMeaning[Number(formatNanazyuni(koyomi.xxnanazyuni)[3])]}ころ<br />
                                    </span><br />
                                    <span className='calendar_info_row_left'>七十二候：</span>
                                </div>
                            </Box >
                        ))}
                    </Box >
                </div>
            ];

            /* **************************************************************************************************** */
            /* ***** メインコンテンツの作成：日暦：B ***************************************************************** */
            /* **************************************************************************************************** */

            // let moonTooltipB = "";
            let rawTime = "";
            let formattedTime = "";

            // let tmpDateYMDJ = dateYMD.slice(0, 4) + "年" + parseInt(dateYMD.slice(4, 6), 10) + "月" + parseInt(dateYMD.slice(6, 8), 10) + "日";



            if (moonstatetime[dateYMD]) {
                rawTime = moonstatetime[dateYMD];
                formattedTime = parseInt(rawTime.slice(0, 2), 10) + "時" + parseInt(rawTime.slice(2), 10) + "分は、" + MoonName[moonstateinfo[dateYMD]] + "です。";
            }

            let moonTooltipImg = "";

            if (moonstateinfo[dateYMD] === "newxxx-000") {
                moonTooltipImg = "<img width='80px' src='https://suureki.net/moon_img/moon_000.svg' alt='' />";
            } else if (moonstateinfo[dateYMD] === "halfxx-090") {
                moonTooltipImg = "<img width='80px' src='https://suureki.net/moon_img/moon_090_half.svg' alt='' />";
            } else if (moonstateinfo[dateYMD] === "fullxx-180") {
                moonTooltipImg = "<img width='80px' src='https://suureki.net/moon_img/moon_180_half.svg' alt='' />";
            } else if (moonstateinfo[dateYMD] === "waning-270") {
                moonTooltipImg = "<img width='80px' src='https://suureki.net/moon_img/moon_270_half.svg' alt='' />";
            }


            mainContentsB = [
                <div key='maincontentskeyb'>
                    <Box className='content_subbox'>
                        {moon_data.filter(moon => moon.date === dateYMD).map(moon => (
                            <Box sx={{ letterSpacing: '0.1em' }} key={moon.date}>
                                {/* 日暦：旧暦 */}
                                <div className='calendar_info_box' style={{ color: 'green' }}>
                                    <span className='calendar_info_row_right' style={{ textAlign: 'right', paddingRight: '40px' }}>
                                        <img src={`https://suureki.net/moon_img/moon_${formatMoon(moon.mooneclipticrounding)}.svg`} alt='' />
                                    </span>
                                    <span className='calendar_info_row_right'>
                                        黄経差：{formatMoon(moon.moonecliptic)}
                                        <br />
                                        月齢：{formatMoon(moon.moonage)}
                                    </span>
                                    <span className='calendar_info_row_left'>
                                        月（0時）：<br />
                                    </span>
                                </div>
                                <div className='calendar_info_box' style={{ color: 'green' }}>
                                    <span className='calendar_info_row_right' style={{ textAlign: 'right', paddingRight: '40px' }}>
                                        {moonTooltipImg && <div dangerouslySetInnerHTML={{ __html: moonTooltipImg }} />}
                                    </span>
                                    <span className='calendar_info_row_right'>
                                        {formattedTime}
                                    </span>
                                    <span className='calendar_info_row_left'>

                                    </span>
                                </div>
                            </Box>
                        ))}
                    </Box>
                </div>
            ];

            /* **************************************************************************************************** */
            /* ***** メインコンテンツの作成：日暦：C ***************************************************************** */
            /* **************************************************************************************************** */

            mainContentsC = [
                <div key='maincontentskeyc'>
                    <Box className='content_subbox'>
                        {/* mapは、フィルターを掛けずに全て表示する */}
                        {/* {koyomi_data.map(koyomi => ( */}
                        {koyomi_data.filter(koyomi => koyomi.date === dateYMD).map(koyomi => (
                            <Box sx={{ letterSpacing: '0.1em' }} key={koyomi.date}>
                                {/* 日暦：日干支 */}
                                <div className='calendar_info_box'>
                                    <span className='calendar_info_row_right'>
                                        {EtoName12RubyA[koyomi.eto12_day]}日<br />
                                        {EtoName10[koyomi.eto10_day]}
                                        {EtoName12[koyomi.eto12_day]}
                                        （
                                        {EtoName10NinestarRuby[koyomi.eto10_day]}
                                        {EtoName12RubyB[koyomi.eto12_day]}
                                        /
                                        {EtoName10RubyB[koyomi.eto10_day]}
                                        {EtoName12RubyC[koyomi.eto12_day]}
                                        ）
                                    </span><br />
                                    <span className='calendar_info_row_left'>日干支：</span>
                                </div>
                                {/* 日暦：月干支 */}
                                <div className='calendar_info_box'>
                                    <span className='calendar_info_row_right'>
                                        {EtoName12RubyA[koyomi.eto12_month]}月<br />
                                        {EtoName10[koyomi.eto10_month]}
                                        {EtoName12[koyomi.eto12_month]}
                                        （
                                        {EtoName10NinestarRuby[koyomi.eto10_month]}
                                        {EtoName12RubyB[koyomi.eto12_month]}
                                        /
                                        {EtoName10RubyB[koyomi.eto10_month]}
                                        {EtoName12RubyC[koyomi.eto12_month]}
                                        ）
                                    </span><br />
                                    <span className='calendar_info_row_left'>月干支：</span>
                                </div>
                                {/* 日暦：年干支 */}
                                <div className='calendar_info_box'>
                                    <span className='calendar_info_row_right'>
                                        {EtoName12RubyA[koyomi.eto12_year]}年<br />
                                        {EtoName10[koyomi.eto10_year]}
                                        {EtoName12[koyomi.eto12_year]}
                                        （
                                        {EtoName10NinestarRuby[koyomi.eto10_year]}
                                        {EtoName12RubyB[koyomi.eto12_year]}
                                        /
                                        {EtoName10RubyB[koyomi.eto10_year]}
                                        {EtoName12RubyC[koyomi.eto12_year]}
                                        ）
                                    </span><br />
                                    <span className='calendar_info_row_left'>年干支：</span>
                                </div>
                                <Divider className='content_hr' style={{ margin: "1rem 0rem 1rem 0rem" }} />
                                {/* 日暦：日九星 */}
                                <div className='calendar_info_box'>
                                    <span className='calendar_info_row_right'>{Ninestar[koyomi.xxkyuusei]}</span><br />
                                    <span className='calendar_info_row_left'>日九星：</span>
                                </div>
                                {/* 日暦：月九星 */}
                                <div className='calendar_info_box'>
                                    <span className='calendar_info_row_right'>{Ninestar[koyomi.kyuusei_m]}</span><br />
                                    <span className='calendar_info_row_left'>月九星：</span>
                                </div>
                                {/* 日暦：年九星 */}
                                <div className='calendar_info_box'>
                                    <span className='calendar_info_row_right'>{Ninestar[koyomi.kyuusei_y]}</span><br />
                                    <span className='calendar_info_row_left'>年九星：</span>
                                </div>
                            </Box>
                        ))}
                    </Box>
                </div>
            ];

            /* **************************************************************************************************** */
            /* ***** メインコンテンツの作成：日暦：D ***************************************************************** */
            /* **************************************************************************************************** */

            mainContentsD = [
                <div key='maincontentskeyd'>
                    <Box className='content_subbox'>
                        {/* mapは、フィルターを掛けずに全て表示する */}
                        {/* {koyomi_data.map(koyomi => ( */}
                        {koyomi_data.filter(koyomi => koyomi.date === dateYMD).map(koyomi => (
                            <Box sx={{ letterSpacing: '0.1em' }} key={koyomi.date}>
                                {/* 日暦：六曜 */}
                                <div className='calendar_info_box'>
                                    <span className='calendar_info_row_right'>
                                        {Rokki[koyomi.xxrokki]}<br />
                                        <span style={{ color: 'blue' }}>{RokkiMeaningG[koyomi.xxrokki]}</span>
                                        <span style={{ color: 'red' }}>{RokkiMeaningB[koyomi.xxrokki]}</span><br />
                                    </span>
                                    <span className='calendar_info_row_left'>六曜：</span>
                                </div>
                                <Divider className='content_hr' style={{ margin: "1rem 0rem 1rem 0rem" }} />
                                {/* 日暦：二十八宿 */}
                                <div className='calendar_info_box'>
                                    <span className='calendar_info_row_right'>
                                        {Yadohachi[koyomi.xxyadohachi]}（{YadohachiRubyA[koyomi.xxyadohachi]}/{YadohachiRubyB[koyomi.xxyadohachi]}）<br />
                                        <span style={{ color: 'blue' }}>大吉事は、{YadohachiVG[koyomi.xxyadohachi]}</span><br />
                                        <span style={{ color: 'blue' }}>吉事は、{YadohachiG[koyomi.xxyadohachi]}</span><br />
                                        <span style={{ color: 'red' }}>凶事は、{YadohachiB[koyomi.xxyadohachi]}</span><br />
                                        <span style={{ color: 'red' }}>大凶事は、{YadohachiVB[koyomi.xxyadohachi]}</span><br />
                                    </span>
                                    <span className='calendar_info_row_left'>二十八宿：</span>
                                </div>
                                <Divider className='content_hr' style={{ margin: "1rem 0rem 1rem 0rem" }} />
                                {/* 日暦：十二直 */}
                                <div className='calendar_info_box'>
                                    <span className='calendar_info_row_right'>
                                        {Zyunityoku[koyomi.xxzyunityoku]}（{ZyunityokuRuby[koyomi.xxzyunityoku]}）<br />
                                        <span style={{ color: 'blue' }}>大吉事は、{ZyunityokuVG[koyomi.xxzyunityoku]}</span><br />
                                        <span style={{ color: 'blue' }}>吉事は、{ZyunityokuG[koyomi.xxzyunityoku]}</span><br />
                                        <span style={{ color: 'red' }}>凶事は、{ZyunityokuB[koyomi.xxzyunityoku]}</span><br />
                                        <span style={{ color: 'red' }}>大凶事は、{ZyunityokuVB[koyomi.xxzyunityoku]}</span><br />
                                    </span>
                                    <span className='calendar_info_row_left'>十二直：</span>
                                </div>
                                <Divider className='content_hr' style={{ margin: "1rem 0rem 1rem 0rem" }} />
                                {/* 日暦：選日 */}
                                <div className='calendar_info_box'>
                                    <span className='calendar_info_row_right'>
                                        {formatSenjitsu(koyomi.xxsenjitsu)}<br />
                                    </span>
                                    <span className='calendar_info_row_left'>
                                        選日：
                                    </span>
                                </div>
                                <Divider className='content_hr' style={{ margin: "1rem 0rem 1rem 0rem" }} />
                                {/* 日暦：雑節 */}
                                <div className='calendar_info_box'>
                                    <span className='calendar_info_row_right'>
                                        {formatZassetsu(koyomi.xxzassetsu)}<br />
                                    </span>
                                    <span className='calendar_info_row_left'>
                                        雑節：
                                    </span>
                                </div>

                            </Box>
                        ))}
                    </Box>
                </div>
            ];

            break;
        default:
            /* **************************************************************************************************** */
            /* ***** メインコンテンツの作成：該当無し：A ************************************************************* */
            /* **************************************************************************************************** */
            mainContentsA = [
                <div key='maincontentskeya'>
                    <h2 className='content_subtitle'>小さな町ソレイユビル：穏やかな生活と絆の物語</h2>
                    <Box className='content_subbox'>
                        太陽が地平線から昇ると、ソレイユビルの小さな町は静かに目覚め始めました。<br />町の中心にある大時計が7時を告げると、家々からは朝食の香りが立ち上り、子どもたちの元気な声が響き始めました。<br />メインストリートには、さまざまな店が立ち並び、果物や野菜、新鮮なパン、手作りの品々が並べられています。<br /><br />
                        この町には、一つだけ特別な場所があります。<br />それは町の西端、古い樹木に囲まれた小さな公園です。<br />公園の中心には大きな噴水があり、その周りには色とりどりの花々が植えられています。<br />公園の一角には、ベンチがいくつか並び、その上には、読書を楽しむ人、話し合う人々、あるいはただ静かに景色を眺める人々が座っています。<br /><br />
                        午後になると、公園はさらに活気づきます。<br />子どもたちは放課後、公園の遊具で遊び、親たちはベンチで会話を楽しみます。<br />時折、公園の小道を散歩する老夫婦の姿も見かけます。<br />彼らは手をつなぎながら、ゆっくりと時間を共有しています。<br /><br />
                        ソレイユビルの町の日は、いつもこのように穏やかに過ぎていきます。<br />夜になると、家々からは暖かい光が灯り、星々が空に輝きます。<br />大時計が10時を告げると、町は静かに眠りにつきます。<br /><br />
                        ここは、大都市の喧騒から離れた、小さな町ソレイユビル。<br />しかし、その小さな規模とは裏腹に、この町には温かさと人々の強い絆が溢れています。<br />それは、町の風景の美しさだけでなく、人々の日々の生活の中にも現れています。<br />これこそが、ソレイユビルの魅力の一部です。<br />そして、この町の人々は、何があってもこの町を愛し、自分たちの生活を大切にし続けることでしょう。
                    </Box>
                    <h2 className='content_subtitle'>暦の歴史とその文化的影響</h2>
                    <Box className='content_subbox'>
                        かつて、人々は季節の変化を通じて時間を感じ取り、それを基に生活を送っていました。<br />春には種を蒔き、夏には収穫をし、秋には収穫を備え、冬には火を囲んで語らったのです。<br />しかし、人類が農耕を始め、社会が発展していくにつれ、より詳細な時間の管理が必要となりました。<br />そこで生まれたのが、私たちが現在使っている暦です。<br /><br />
                        暦とは、時間を年、月、週、日といった単位に分割し、それを記録・管理するシステムです。<br />多くの文化では太陽や月の運行を基に暦が作られてきました。<br />太陽暦は地球の公転周期を基に、月暦は月の満ち欠けの周期を基に時間を刻みます。<br />また、これらを組み合わせた太陰暦も存在します。<br /><br />
                        最も広く使われているグレゴリオ暦は太陽暦で、一年を365日とし、4年に一度は閏年として一日を加え366日とすることで、地球の公転周期と合わせています。<br />これにより、季節の移り変わりを一定に保つことができます。<br /><br />
                        一方、旧暦やイスラム暦などの月暦は月の満ち欠けを基に時間を刻みます。<br />旧暦では一年を12ヶ月とし、それぞれの月が一回りする29.5日を基準にした月日で年を表します。<br /><br />
                        しかし、どの暦にも誤差はあり、長い年月を経ると季節とのずれが生じます。<br />そのため、人類は暦の改正を繰り返し、より正確な時間の管理を試みてきました。<br /><br />
                        また、暦はただ時間を刻むだけでなく、文化や宗教の一部ともなっています。<br />お祭りや記念日、宗教行事など、その年の特定の日を祝う習慣は暦と密接に関連しています。<br /><br />
                        現代では、デジタル化が進み、スマートフォンやパソコンで瞬時に日付や時刻を確認できます。<br />しかし、その背後には太陽や月、地球の運行といった自然現象に基づいた時間の流れが存在します。<br />暦を通じて、私たちは自然とのつながりを感じ、それが私たちの生活と文化に影響を与えていることを理解することができます。
                    </Box>
                </div>
            ];

            /* **************************************************************************************************** */
            /* ***** メインコンテンツの作成：該当無し：B ************************************************************* */
            /* **************************************************************************************************** */

            mainContentsB = [
                <div key='maincontentskeyb'>
                </div>
            ];

            /* **************************************************************************************************** */
            /* ***** メインコンテンツの作成：該当無し：C ************************************************************* */
            /* **************************************************************************************************** */

            mainContentsC = [
                <div key='maincontentskeyc'>
                </div>
            ];

            /* **************************************************************************************************** */
            /* ***** メインコンテンツの作成：該当無し：D ************************************************************* */
            /* **************************************************************************************************** */

            mainContentsD = [
                <div key='maincontentskeyd'>
                </div>
            ];

            break;
    }


    /* **************************************************************************************************** */
    /* **************************************************************************************************** */
    /* **************************************************************************************************** */
    /* *****                            ******************************************************************* */
    /* ***** returnエリア               ******************************************************************* */
    /* *****                            ******************************************************************* */
    /* **************************************************************************************************** */
    /* **************************************************************************************************** */
    /* **************************************************************************************************** */


    let PrevNextCalendarLinkA: JSX.Element[] = [];
    let PrevNextCalendarLinkB: JSX.Element[] = [];
    let PrevNextCalendarLinkC: JSX.Element[] = [];
    let PrevNextCalendarLinkD: JSX.Element[] = [];

    if (calendarType === "year" || calendarType === "month" || calendarType === "day") {
        // 月暦・日暦
        PrevNextCalendarLinkA = PrevNextCalendarLink;
        PrevNextCalendarLinkB = PrevNextCalendarLink;
        PrevNextCalendarLinkC = PrevNextCalendarLink;
        PrevNextCalendarLinkD = PrevNextCalendarLink;
    } else {
        // 年暦・エラー画面
        PrevNextCalendarLinkA = PrevNextCalendarLink;
        PrevNextCalendarLinkB = [];
        PrevNextCalendarLinkC = [];
        PrevNextCalendarLinkD = [];
    }

    let etcPageLink;

    if (calendarType === "year") {
        etcPageLink = [
            <div key="etcPageLink" style={{ marginLeft: '1rem' }}>
                <Link
                    component={RouterLink}
                    underline="hover"
                    key="2"
                    color="primary"
                    to={`/aspect/${dateY}`}
                    onClick={handleClick}
                >
                    {dateY}年盤の座相
                </Link>
            </div>
        ];

    } else if (calendarType === "month") {
        etcPageLink = [
            <div key="etcPageLink">
                <div style={{ marginLeft: '1rem' }}>
                    <Link
                        component={RouterLink}
                        underline="hover"
                        key="2"
                        color="primary"
                        to={`/calendar/${dateY}`}
                        onClick={handleClick}
                    >
                        {dateY}年の年暦
                    </Link>
                </div>
                <div style={{ marginLeft: '1rem' }}>
                    <Link
                        component={RouterLink}
                        underline="hover"
                        key="2"
                        color="primary"
                        to={`/aspect/${dateY}`}
                        onClick={handleClick}
                    >
                        {dateY}年盤の座相
                    </Link>
                </div>
            </div>
        ];

    } else if (calendarType === "day") {
        etcPageLink = [
            <div key="etcPageLink">
                <div style={{ marginLeft: '1rem' }}>
                    <Link
                        component={RouterLink}
                        underline="hover"
                        key="2"
                        color="primary"
                        to={`/calendar/${dateYM}`}
                        onClick={handleClick}
                    >
                        {dateY}年{dateM}月の月暦
                    </Link>
                </div>
                <div style={{ marginLeft: '1rem' }}>
                    <Link
                        component={RouterLink}
                        underline="hover"
                        key="2"
                        color="primary"
                        to={`/calendar/${dateY}`}
                        onClick={handleClick}
                    >
                        {dateY}年の年暦
                    </Link>
                </div>
                <div style={{ marginLeft: '1rem' }}>
                    <Link
                        component={RouterLink}
                        underline="hover"
                        key="2"
                        color="primary"
                        to={`/aspect/${dateY}`}
                        onClick={handleClick}
                    >
                        {dateY}年盤の座相
                    </Link>
                </div>
                <div style={{ marginLeft: '1rem' }}>
                    <Link
                        component={RouterLink}
                        underline="hover"
                        key="2"
                        color="primary"
                        to={`/birth/${dateYMD}`}
                        onClick={handleClick}
                    >
                        {dateY}年{parseInt(dateYMD.substring(4, 6), 10)}月{parseInt(dateYMD.substring(6, 8), 10)}日の誕生日
                    </Link>
                </div>
            </div>
        ];
    } else {
        etcPageLink = [
            <div key="etcPageLink" style={{ marginLeft: '1rem' }}>
                none
            </div>
        ];
    }

    /*
    <meta name="description" content="This is an example page" />
    <meta property="og:site_name" content="suureki.net">
    <meta property="og:title" content="柿本実さんの情報【suureki.net | すうれきねっと】">
    <meta property="og:description" content="suureki.netとは、1901年から2100年迄を網羅した万年暦・カレンダー・こよみサイトです。様々な暦の他、著名人の情報や生年月日による吉凶運勢・吉方位・バイオリズム・カバラ数秘術の情報を提供しています。">
    <meta property="og:type" content="website">
    <meta property="og:locale" content="ja_JP">
    <meta property="og:url" content="https://suureki.net/75882/register.php?id=17630317">
    <meta property="og:image" content="https://suureki.net/img/suureki_ogp_1200x630.png">
    <meta property="og:image:width" content="1200">
    <meta property="og:image:height" content="630">
    <meta name="twitter:card" content="summary_large_image">
    <meta name="twitter:url" content="https://suureki.net/">
    <meta name="twitter:image" content="https://suureki.net/img/suureki_ogp_1200x630.png">
    <meta name="twitter:title" content="日々、役立つ暦注を！">
    <meta name="twitter:description" content="万年暦・こよみサイト。二十八宿や十二直、二十四節気/七十二候などの様々な暦注を掲載。">
    <meta name="twitter:site" content="@suureki_cal">

    */

    let metaDescription = '';

    const pageKey = "Calendar";
    let pagePath = "";

    if (calendarType === "year") {
        metaDescription = dateY + '年の年間カレンダー。暦注は六曜のみを掲載。' + pageDescription[pageKey];
        pagePath = pagePathS[pageKey] + dateY;
    } else if (calendarType === "month") {
        metaDescription = dateY + '年' + dateM + '月の様々な暦注カレンダー。月の状態、旧暦、六曜、月干支、二十四節気、十二直、二十八宿、選日、雑節等を掲載。' + pageDescription[pageKey];
        pagePath = pagePathS[pageKey] + dateYM;
    } else if (calendarType === "day") {
        metaDescription = dateY + '年' + Number(dateM) + '月' + Number(dateYMD.substring(6, 8)) + '日の暦注カレンダー。月の状態、旧暦、六曜、日干支、月干支、年干支、二十四節気、十二直、二十八宿、選日、雑節等を掲載。' + pageDescription[pageKey];
        pagePath = pagePathS[pageKey] + dateYMD;
    }
    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    const pageTitleTmp = pageTitle; // title: pageTitle にすると、何故かエラーになるので、一旦変数に格納

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        console.log(pagePath + ':', encryptedUid.substring(0, 3));

        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitleTmp, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitleTmp]);


    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };

    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                            <DemoContainer components={['MobileDatePicker']}>
                                {searchWindow}
                            </DemoContainer>
                        </LocalizationProvider>

                        {/* ***** メインコンテンツ ******************************************************************* */}
                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                                <span></span>
                            </h1>
                            {mainContentsMonlink}
                            <div>{PrevNextCalendarLink}</div>
                            <div>{mainContentsA}</div>
                            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                <AdSenseBannerMobile />
                            </div>
                            <div>{PrevNextCalendarLinkA}</div>
                            <div>{mainContentsB}</div>
                            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                <AdSenseBannerMobile />
                            </div>
                            <div>{PrevNextCalendarLinkB}</div>
                            <div>{mainContentsC}</div>
                            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                <AdSenseBannerMobile />
                            </div>
                            <div>{PrevNextCalendarLinkC}</div>
                            <div>{mainContentsD}</div>
                            <div>{PrevNextCalendarLinkD}</div>
                            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                <AdSenseBannerMobile />
                            </div>
                            <Box className='content_subbox_txt'>
                                <h2 className='content_subtitle'>他ページへのリンク</h2>
                                {etcPageLink}
                            </Box>
                            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                <AdSenseInarticle />
                            </div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
};

const App = () => (
    <AuthProvider>
        <Calendar />
    </AuthProvider>
);

export default App;