// import * as React from 'react';
import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');  // 日本語ロケールを設定

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

/* *********************************************************************************************** */
/* ***** ページ名の作成 *************************************************************************** */
/* *********************************************************************************************** */

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}
const pageTitle = `プライバシーポリシー・クッキーポリシー・免責事項`
const pageTitleS = `プライバシーポリシー・クッキーポリシー・免責事項` // ページトップのナビゲーションリンク

const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton
            aria-label="ホームページへページ移動"
            sx={{
                margin: '0px 0px 1px 0px',
                padding: '0px'
            }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];

const Privacy: React.FC = React.memo(() => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    const mainContents = [
        <div key='maincontentskey'>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>個人情報の収集方法</h2>
                <span>当サイトのログイン機能は、googleの<a href="https://firebase.google.com/docs/auth?hl=ja" target="_blank" rel="noopener noreferrer">firebase Authentication</a>を使用しています。</span><br />
                <span>ログインにより、googleアカウントの以下の情報を所得します。</span><br />
                <ul>
                    <li className='inside-list-style'>プロフィール画像</li>
                    <li className='inside-list-style'>名前</li>
                    <li className='inside-list-style'>メールアドレス</li>
                    <li className='inside-list-style'>id（識別ID）</li>
                </ul>
                <span>プロフィール画像・名前・メールアドレスはページ表示の際のみに使用し、id（識別ID）は個別サービスの必要事項保持の為、暗号化してサーバーに保存します。</span><br />
                <span>又、ログイン状態を保持する目的として、生成されたキーコードをユーザー端末にcookieとして保存します。</span><br />
            </Box>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>suureki.netによる第三者サービスの利用</h2>
                <span>アクセス解析として
                    <a href="https://www.google.com/search?q=google+analytics" target="_blank" rel="noopener noreferrer">google analytics</a>
                    、広告掲載として
                    <a href="https://www.google.com/search?q=google+adsense" target="_blank" rel="noopener noreferrer">google adsense</a>
                    を利用しています。</span><br />
                <br />
                <span>上記理由により、以下の事案が発生します。</span><br />
                <span>・Google などの第三者配信事業者が Cookie を使用して、ユーザーがそのウェブサイトや他のウェブサイトに過去にアクセスした際の情報に基づいて広告を配信すること。</span><br />
                <span>・Google が広告 Cookie を使用することにより、ユーザーがそのサイトや他のサイトにアクセスした際の情報に基づいて、Google やそのパートナーが適切な広告をユーザーに表示できること。</span><br />
                <span>・ユーザーは、
                    <a href="https://myadcenter.google.com/home?sasb=true&ref=ad-settings" target="_blank" rel="noopener noreferrer">広告設定</a>
                    でパーソナライズ広告を無効にできること（または、
                    <a href="https://www.aboutads.info" target="_blank" rel="noopener noreferrer">www.aboutads.info</a>
                    にアクセスすれば、パーソナライズ広告に使われる第三者配信事業者の Cookie を無効にできること）。</span><br />
                <br />
                <hr />
                <br />
                <span>・第三者配信事業者や広告ネットワークの配信する広告がサイトに掲載されます。</span><br />
                <span>・対象となる第三者配信事業者や広告ネットワークの適切なウェブサイトへのリンクを掲載します。</span><br />
                <span>・これらのウェブサイトにアクセスすれば、パーソナライズド広告の掲載で使用される Cookie を無効にできることをユーザーに明示します（配信事業者や広告ネットワークがこの機能を提供している場合）。または、
                    <a href="https://www.aboutads.info" target="_blank" rel="noopener noreferrer">www.aboutads.info</a>
                    にアクセスすれば、第三者配信事業者がパーソナライズド広告の掲載で使用する Cookie を無効にできます。</span><br />
                <br />
            </Box>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>個人情報を収集・利用する目的</h2>
                <span>googleアカウントでのログインによる所得・収集・利用する目的</span><br />
                <ul>
                    <li className='inside-list-style'>プロフィール画像：</li>
                    <li className='inside-list-style-none'>ページ表示に利用</li>
                    <li className='inside-list-style'>プロフィール画像：</li>
                    <li className='inside-list-style-none'>ページ表示に利用</li>
                    <li className='inside-list-style'>名前：</li>
                    <li className='inside-list-style-none'>ページ表示に利用</li>
                    <li className='inside-list-style'>メールアドレス：</li>
                    <li className='inside-list-style-none'>ページ表示に利用</li>
                    <li className='inside-list-style'>id（識別ID）：</li>
                    <li className='inside-list-style-none'>個別サービスの必要事項保持の為に利用。暗号化してサーバーに保存します。</li>
                </ul>
            </Box>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>クッキーポリシー</h2>
                <span>当サイトは、ユーザビリティおよびユーザーエクスペリエンス向上の為、、クッキーを使用しています。</span><br />
                <span>当サイト単独ではクッキーは使用せず、googleサービス（google analytics / google adsense / Firebase Authentication）の使用に伴う使用となります。</span><br />
            </Box>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>プライバシーポリシー・クッキーポリシー・免責事項の変更</h2>
                <span>
                    <Link
                        component={RouterLink}
                        underline="always"
                        key="pppage_aboutadsB"
                        color="primary"
                        to="/"
                        onClick={handleClick}
                    >
                        トップページ
                    </Link>
                    ・プライバシーポリシー・クッキーポリシー・免責事項ページ（当ページ）にて、14日前にお知らせします。</span><br />
            </Box>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>著作権</h2>
                <span>当サイトで掲載している文章等のコンテンツにつきましては、無断転載することを禁止します。</span><br />
            </Box>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>免責事項</h2>
                <span>当サイトコンテンツ・情報について、正確な情報を提供するように努めておりますが、正確性や安全性を保証するものではありません。</span><br />
                <span>当サイトからのリンクやバナーなどで移動したサイトで提供される情報、サービス等について一切の責任を負いません。</span><br />
                <span>当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。</span><br />
            </Box>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>お問い合わせ窓口</h2>
                <span>
                    <Link
                        component={RouterLink}
                        underline="always"
                        key="pppage_aboutadsB"
                        color="primary"
                        to="/about"
                        onClick={handleClick}
                        sx={{ marginRight: '0.3rem' }}
                    >
                        suureki.netについてページ
                    </Link>
                    からお願いします。</span><br />
            </Box>


        </div>
    ];

    const pageKey = "Privacy";
    const pagePath = pagePathS[pageKey];

    const metaDescription = pageDescription[pageKey];
    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        console.log(pagePath + ':', encryptedUid.substring(0, 3));

        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitle, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitle]);


    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };

    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>
                        {/* ***** メインコンテンツ ******************************************************************* */}

                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                            </h1>
                            <div>{mainContents}</div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
});

const App = () => (
    <AuthProvider>
        <Privacy />
    </AuthProvider>
);

export default App;