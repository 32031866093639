import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton, TextField, Alert, AlertTitle } from '@mui/material';
// import { FixedSizeList } from 'react-window';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';    // [>]のアイコン
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBannerMobile from '../components/AdSenseBannerMobile'; // 320x100、ラージモバイルバナー をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // 記事内広告 をインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

/*
interface CelebritynNmelist {
    search_no: number;
    add_id: string;
    name: string;
    birth: string;
}
*/

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}
let pageTitle = `著名人の情報`
let pageTitleS = `著名人の情報` // ページトップのナビゲーションリンク

const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton
            aria-label="ホームページへページ移動"
            sx={{
                margin: '0px 0px 1px 0px',
                padding: '0px'
            }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];

interface NamesearchState {
    search_no: number;
    add_id: string;
    name: string;
    birth: string;
}

// const Application: React.FC = () => {
const Celebrity: React.FC = React.memo(() => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState("");


    // JSON //////////////////////////////////////////////////////////////////////

    const celebrity_json_url = "https://db.suureki.net/_db_celebrity/_search_randomsearch.php";
    const [namesearchState_data, setDataNamesearchState] = useState<NamesearchState[]>([]);
    useEffect(() => {
        fetch(celebrity_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(namesearchState_data => setDataNamesearchState(namesearchState_data))
            .catch(error => console.error('Error:', error));
    }, [celebrity_json_url]);

    const add_id: { [search_no: number]: string } = {};
    const name: { [search_no: number]: string } = {};
    const birth: { [search_no: number]: string } = {};
    namesearchState_data.forEach(namesearch => {
        add_id[namesearch.search_no] = namesearch.add_id;
        name[namesearch.search_no] = namesearch.name;
        birth[namesearch.search_no] = namesearch.birth;
    });

    const anserlink = Array.from({ length: 10 }, (_, index) => {
        const tmpName = name[index] + "（" + birth[index] + "生）";
        const tmpId = add_id[index];

        return (
            <div key={index}>
                {index + 1}：
                <Link
                    component={RouterLink}
                    underline="hover"
                    key="2"
                    color="primary"
                    to={`/celebrity/${tmpId}`}
                    onClick={handleClick}
                >
                    {tmpName}
                </Link>
            </div>
        );
    });

    const mainContents = [
        <div key='maincontentskey'>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>著名人の検索</h2>
                <Alert severity="info" sx={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
                    <AlertTitle sx={{ fontSize: '0.9rem' }}>人物名の部分一致での検索です。複数の言葉、グループ名等での検索不可。</AlertTitle>
                </Alert>
                <TextField
                    id="celebrity_search"
                    label="人物名で検索"
                    variant="outlined"
                    onChange={(e) => {
                        // 入力の値を状態に保持
                        setSearchValue(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        // Enter キーが押された場合に遷移
                        if (e.key === 'Enter' && searchValue) {
                            navigate(`/celebrity_search/${searchValue}`);
                        }
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    edge="end"
                                    aria-label="search"
                                    onClick={() => {
                                        // SearchIconがクリックされたときに遷移
                                        if (searchValue) {
                                            navigate(`/celebrity_search/${searchValue}`);
                                        }
                                    }}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#3277d5',  // 望む色に変更
                            },
                        },
                        backgroundColor: 'white',
                        width: '80vmin',
                        border: '0px solid #000',
                        borderRadius: 1
                    }}
                />
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>ランダム表示</h2>
                {anserlink}
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseInarticle />
            </div>
        </div>
    ];

    const pageKey = "CelebrityIndex";
    const pagePath = pagePathS[pageKey];

    const metaDescription = pageDescription[pageKey];
    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        console.log(pagePath + ':', encryptedUid.substring(0, 3));

        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitle, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitle]);


    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };

    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>

                        {/* ***** メインコンテンツ ******************************************************************* */}

                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                            </h1>
                            <div>{mainContents}</div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
});
// };

const App = () => (
    <AuthProvider>
        <Celebrity />
    </AuthProvider>
);

export default App;