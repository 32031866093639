// import * as React from 'react';
import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBannerMobile from '../components/AdSenseBannerMobile'; // 320x100、ラージモバイルバナー をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // 記事内広告 をインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');  // 日本語ロケールを設定

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

/* *********************************************************************************************** */
/* ***** ページ名の作成 *************************************************************************** */
/* *********************************************************************************************** */

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}
let pageTitle = `誕生日の選択`
let pageTitleS = `誕生日毎` // ページトップのナビゲーションリンク


const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton
            aria-label="ホームページへページ移動"
            sx={{
                margin: '0px 0px 1px 0px',
                padding: '0px'
            }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];

const Birthday: React.FC = () => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    const today = new Date();

    const navigate = useNavigate();

    // dateF(Year): YYYY 
    // dateF(month): YYYY-MM
    // dateF(day):  YYYY-MM-DD

    let dateY = today.getFullYear();
    let dateM = (today.getMonth() + 1).toString().padStart(2, '0');
    let dateD = (today.getDate()).toString().padStart(2, '0');

    let dateF = dateY + "-" + dateM + "-" + dateD;
    dateF = "1980-01-01";

    const randomYearA = Math.floor(Math.random() * (dateY - 1901 + 1)) + 1901;
    const randomMonA = Math.floor(Math.random() * 12) + 1;
    let randomDayA;
    if (randomMonA === 2) {
        randomDayA = Math.floor(Math.random() * 28) + 1;
    } else if (randomMonA === 4 || randomMonA === 6 || randomMonA === 9 || randomMonA === 11) {
        randomDayA = Math.floor(Math.random() * 30) + 1;
    } else {
        randomDayA = Math.floor(Math.random() * 31) + 1;
    }

    const randomYearB = Math.floor(Math.random() * (dateY - 1901 + 1)) + 1901;
    const randomMonB = Math.floor(Math.random() * 12) + 1;
    let randomDayB;
    if (randomMonB === 2) {
        randomDayB = Math.floor(Math.random() * 28) + 1;
    } else if (randomMonB === 4 || randomMonB === 6 || randomMonB === 9 || randomMonB === 11) {
        randomDayB = Math.floor(Math.random() * 30) + 1;
    } else {
        randomDayB = Math.floor(Math.random() * 31) + 1;
    }

    const randomYearC = Math.floor(Math.random() * (dateY - 1901 + 1)) + 1901;
    const randomMonC = Math.floor(Math.random() * 12) + 1;
    let randomDayC;
    if (randomMonC === 2) {
        randomDayC = Math.floor(Math.random() * 28) + 1;
    } else if (randomMonC === 4 || randomMonC === 6 || randomMonC === 9 || randomMonC === 11) {
        randomDayC = Math.floor(Math.random() * 30) + 1;
    } else {
        randomDayC = Math.floor(Math.random() * 31) + 1;
    }

    const randomYearD = Math.floor(Math.random() * (dateY - 1901 + 1)) + 1901;
    const randomMonD = Math.floor(Math.random() * 12) + 1;
    let randomDayD;
    if (randomMonD === 2) {
        randomDayD = Math.floor(Math.random() * 28) + 1;
    } else if (randomMonD === 4 || randomMonD === 6 || randomMonD === 9 || randomMonD === 11) {
        randomDayD = Math.floor(Math.random() * 30) + 1;
    } else {
        randomDayD = Math.floor(Math.random() * 31) + 1;
    }

    const randomYearE = Math.floor(Math.random() * (dateY - 1901 + 1)) + 1901;
    const randomMonE = Math.floor(Math.random() * 12) + 1;
    let randomDayE;
    if (randomMonE === 2) {
        randomDayE = Math.floor(Math.random() * 28) + 1;
    } else if (randomMonE === 4 || randomMonE === 6 || randomMonE === 9 || randomMonE === 11) {
        randomDayE = Math.floor(Math.random() * 30) + 1;
    } else {
        randomDayE = Math.floor(Math.random() * 31) + 1;
    }


    const mainContents = [
        <div key='maincontentskey'>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>誕生日の選択</h2>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                    <DemoContainer components={['MobileDatePicker']}>
                        <Box display="flex" alignItems="center" className='search_space' key='birthKeyYMD'>
                            誕生日の選択：
                            <MobileDatePicker
                                views={['year', 'month', 'day']}
                                defaultValue={dayjs(dateF)}
                                onChange={(newValue) => {
                                    if (newValue && newValue.isValid()) {
                                        const formattedDate = newValue.format('YYYYMMDD');
                                        navigate(`/birth/${formattedDate}`);
                                    }
                                }}
                                format="YYYY/MM/DD"
                                minDate={dayjs('1901-01-01')}
                                maxDate={dayjs('2090-12-31')}
                                localeText={{ toolbarTitle: '日暦の年月日' }}
                                slotProps={{
                                    toolbar: {
                                        toolbarPlaceholder: '__',
                                        toolbarFormat: 'YYYY年MM月DD日',
                                        hidden: false,
                                    }
                                }}
                            />
                        </Box>
                    </DemoContainer>
                </LocalizationProvider>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>ランダムな誕生日を見る</h2>
                <div style={{ lineHeight: '1.8rem' }}>
                    <Link
                        component={RouterLink}
                        underline="hover"
                        key="1"
                        color="primary"
                        to={`/birth/${randomYearA}${('00' + randomMonA).slice(-2)}${('00' + randomDayA).slice(-2)}`}
                        onClick={handleClick}
                    >
                        {randomYearA}年{randomMonA}月{randomDayA}日生
                    </Link><br />
                    <Link
                        component={RouterLink}
                        underline="hover"
                        key="2"
                        color="primary"
                        to={`/birth/${randomYearB}${('00' + randomMonB).slice(-2)}${('00' + randomDayB).slice(-2)}`}
                        onClick={handleClick}
                    >
                        {randomYearB}年{randomMonB}月{randomDayB}日生
                    </Link><br />
                    <Link
                        component={RouterLink}
                        underline="hover"
                        key="3"
                        color="primary"
                        to={`/birth/${randomYearC}${('00' + randomMonC).slice(-2)}${('00' + randomDayC).slice(-2)}`}
                        onClick={handleClick}
                    >
                        {randomYearC}年{randomMonC}月{randomDayC}日生
                    </Link><br />
                    <Link
                        component={RouterLink}
                        underline="hover"
                        key="4"
                        color="primary"
                        to={`/birth/${randomYearD}${('00' + randomMonD).slice(-2)}${('00' + randomDayD).slice(-2)}`}
                        onClick={handleClick}
                    >
                        {randomYearD}年{randomMonD}月{randomDayD}日生
                    </Link><br />
                    <Link
                        component={RouterLink}
                        underline="hover"
                        key="5"
                        color="primary"
                        to={`/birth/${randomYearE}${('00' + randomMonE).slice(-2)}${('00' + randomDayE).slice(-2)}`}
                        onClick={handleClick}
                    >
                        {randomYearE}年{randomMonE}月{randomDayE}日生
                    </Link><br /><br />
                    <Link
                        component={RouterLink}
                        underline="hover"
                        key="reload"
                        color="primary"
                        to="/birth"
                        onClick={handleClick}
                    >
                        他のランダムな誕生日を見る（ページの更新）
                    </Link>
                </div>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseInarticle />
            </div>
        </div>
    ];

    /*
    <meta name="description" content="This is an example page" />
    <meta property="og:site_name" content="suureki.net">
    <meta property="og:title" content="柿本実さんの情報【suureki.net | すうれきねっと】">
    <meta property="og:description" content="suureki.netとは、1901年から2100年迄を網羅した万年暦・カレンダー・こよみサイトです。様々な暦の他、著名人の情報や生年月日による吉凶運勢・吉方位・バイオリズム・カバラ数秘術の情報を提供しています。">
    <meta property="og:type" content="website">
    <meta property="og:locale" content="ja_JP">
    <meta property="og:url" content="https://suureki.net/75882/register.php?id=17630317">
    <meta property="og:image" content="https://suureki.net/img/suureki_ogp_1200x630.png">
    <meta property="og:image:width" content="1200">
    <meta property="og:image:height" content="630">
    <meta name="twitter:card" content="summary_large_image">
    <meta name="twitter:url" content="https://suureki.net/">
    <meta name="twitter:image" content="https://suureki.net/img/suureki_ogp_1200x630.png">
    <meta name="twitter:title" content="日々、役立つ暦注を！">
    <meta name="twitter:description" content="万年暦・こよみサイト。二十八宿や十二直、二十四節気/七十二候などの様々な暦注を掲載。">
    <meta name="twitter:site" content="@suureki_cal">
    */

    const pageKey = "BirthIndex";
    const pagePath = pagePathS[pageKey];

    const metaDescription = pageDescription[pageKey];
    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        // console.log(pagePath + ':', encryptedUid.substring(0, 3));

        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitle, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitle]);

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitle, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl]);

    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };

    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>
                        {/* ***** メインコンテンツ ******************************************************************* */}

                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                            </h1>
                            <div>{mainContents}</div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
};

const App = () => (
    <AuthProvider>
        <Birthday />
    </AuthProvider>
);

export default App;