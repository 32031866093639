import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton, Tab, Tabs } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import { TabContext, TabPanel } from '@mui/lab';
import {
    EtoName12,
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBannerMobile from '../components/AdSenseBannerMobile'; // 320x100、ラージモバイルバナー をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // 記事内広告 をインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}
const pageTitle = `学校入学/卒業年表`
const pageTitleS = `学校入学/卒業年表` // ページトップのナビゲーションリンク
const uppageTitle = `情報/DB`

interface Koyomi {
    dateY: string;           /* 日付 */
    eto10_year: number;     /* 年干支（暦：10支） */
    eto12_year: number;     /* 年干支（暦：12支） */
}

const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton
            aria-label="ホームページへページ移動"
            sx={{
                margin: '0px 0px 1px 0px',
                padding: '0px'
            }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Link
        component={RouterLink}
        underline="hover"
        key="2"
        color="inherit"
        to="/archives"
        onClick={handleClick}
    >
        {uppageTitle}
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];

const todayY = new Date().getFullYear();
// const todayM = ("0" + (new Date().getMonth() + 1)).slice(-2); // JavaScriptの月は0から始まるので、1を足して調整
// const todayD = ("0" + new Date().getDate()).slice(-2);
// const todayYMD = todayY + todayM + todayD;

/*
明治元年（1868年）1月25日以降   明治45年（1912年）7月29日迄
大正元年（1912年）7月30日以降   大正15年（1926年）12月24日迄
昭和元年（1926年）12月25日以降  昭和64年（1989年）1月7日迄
平成元年（1989年）1月8日以降    平成31年（2019年）4月30日迄
令和元年（2019年）5月1日以降
*/

const yearChange = (sendYMD: number) => {

    let receiveYMD = "****";
    if (sendYMD >= 21001231) {
        receiveYMD = "****";
    } else if (sendYMD >= 20190501 && sendYMD.toString().slice(0, 4) === '2019') {
        // 令和元年
        receiveYMD = "令和元年";
    } else if (sendYMD >= 20190501) {
        // 令和2年以降
        receiveYMD = "令和" + String(Number(sendYMD.toString().slice(0, 4)) - 2018) + "年";
    } else if (sendYMD >= 19890108 && sendYMD.toString().slice(0, 4) === '1989') {
        // 平成元年
        receiveYMD = "平成元年";
    } else if (sendYMD >= 19890108) {
        // 平成2年以降
        receiveYMD = "平成" + String(Number(sendYMD.toString().slice(0, 4)) - 1988) + "年";
    } else if (sendYMD >= 19261225 && sendYMD.toString().slice(0, 4) === '1926') {
        // 昭和元年
        receiveYMD = "昭和元年";
    } else if (sendYMD >= 19261225) {
        // 昭和2年以降
        receiveYMD = "昭和" + String(Number(sendYMD.toString().slice(0, 4)) - 1925) + "年";
    } else if (sendYMD >= 19120730 && sendYMD.toString().slice(0, 4) === '1912') {
        // 大正元年
        receiveYMD = "大正元年";
    } else if (sendYMD >= 18680125) {
        // 大正2年以降
        receiveYMD = "大正" + String(Number(sendYMD.toString().slice(0, 4)) - 1867) + "年";
    }

    return receiveYMD;
}


// const DataBase: React.FC = () => {
const DataBase: React.FC = React.memo(() => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    // 暦月の干支一覧のjsonの呼び出し //////////////////////////////////////////////////////////////////////

    const eto_rekigetsu_json_url = "https://db.suureki.net/_db_etc/eto_all_rekigetsu.json";
    const [koyomi_data, setDataKoyomi] = useState<Koyomi[]>([]);
    useEffect(() => {
        fetch(eto_rekigetsu_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(koyomi_data => setDataKoyomi(koyomi_data))
            .catch(error => console.error('Error:', error));
    });
    let eto10yearMap: { [dateY: string]: number } = {};
    let eto12yearMap: { [dateY: string]: number } = {};

    koyomi_data.forEach(koyomi => {
        eto10yearMap[koyomi.dateY] = Number(koyomi.eto10_year);
        eto12yearMap[koyomi.dateY] = Number(koyomi.eto12_year);
    }, [todayY]);


    // 全期間の、年干支と年九星の中身作成 //////////////////////////////////////////////////////////////////////

    const tabCount = 7;    // タブの数
    const yearsPerTab = 10; // タブがカバーする年数
    const rekigetsuTab = [];
    const tabPanels = [];
    const schoolGraphContents: { [key: number]: JSX.Element[] } = {};

    let tmpY = todayY;
    const [value, setValue] = React.useState('1');
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };




    for (let i = 0; i < tabCount; i++) {
        if (!schoolGraphContents[i]) {
            schoolGraphContents[i] = [];
        }
        for (let ii = 0; ii < yearsPerTab; ii++) {

            // 小学校
            let schoolAstart = tmpY + 7;
            let schoolAend = tmpY + 7 + 6;
            let schoolAstartYMD = parseInt(schoolAstart + "0401");
            let schoolAendYMD = parseInt(schoolAend + "0331");
            // 中学校
            let schoolBstart = schoolAend;
            let schoolBend = schoolAend + 3;
            let schoolBstartYMD = parseInt(schoolBstart + "0401");
            let schoolBendYMD = parseInt(schoolBend + "0331");
            // 高校
            let schoolCstart = schoolBend;
            let schoolCend = schoolBend + 3;
            let schoolCstartYMD = parseInt(schoolCstart + "0401");
            let schoolCendYMD = parseInt(schoolCend + "0331");
            // 大学
            let schoolDstart = schoolCend;
            let schoolDend = schoolCend + 4;
            let schoolDstartYMD = parseInt(schoolDstart + "0401");
            let schoolDendYMD = parseInt(schoolDend + "0331");

            let tmpYplus = tmpY + 1;
            let tmpYMD = Number(tmpY + "0101");
            let tmpYMDplus = Number(tmpYplus + "0101");
            schoolGraphContents[i].push(
                <div key={`menu-${i}-${tmpY}`} style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                    <div style={{ fontSize: '0.95rem', color: 'green', backgroundColor: '#eee', padding: '0.2rem 0.5rem 0.2rem 0.5rem ' }}>
                        {EtoName12[eto12yearMap[tmpY]]}：{tmpY}年/{yearChange(tmpYMD)}4月1日~12月31日生<br />
                        {EtoName12[eto12yearMap[tmpYplus]]}：{tmpYplus}年/{yearChange(tmpYMDplus)}1月1日~3月31日生<br />

                    </div>
                    <div style={{ fontSize: '0.95rem', marginTop: '0.5rem' }}>
                        <div style={{ marginBottom: '0.5rem', marginTop: '1rem', borderBottom: '3px solid #ddd' }}><span>小学校：{schoolAstart}年（{yearChange(schoolAstartYMD)}）4月入学~</span><span>{schoolAend}年（{yearChange(schoolAendYMD)}）3月卒業</span></div>
                        <div style={{ marginBottom: '0.5rem', borderBottom: '3px solid #ddd' }}><span>中学校：{schoolBstart}年（{yearChange(schoolBstartYMD)}）4月入学~</span><span>{schoolBend}年（{yearChange(schoolBendYMD)}）3月卒業</span></div>
                        <div style={{ marginBottom: '0.5rem', borderBottom: '3px solid #ddd' }}><span>高等学校：{schoolCstart}年（{yearChange(schoolCstartYMD)}）4月入学~</span><span>{schoolCend}年（{yearChange(schoolCendYMD)}）3月卒業</span></div>
                        <div style={{ marginBottom: '1rem' }}><span>四年制大学：{schoolDstart}年（{yearChange(schoolDstartYMD)}）4月入学~</span><span>{schoolDend}年（{yearChange(schoolDendYMD)}）3月卒業</span></div>
                    </div>
                </div>
            );

            tmpY--;
        }
    }


    // 全期間の、年干支と年九星の中身+外枠の作成 //////////////////////////////////////////////////////////////////////

    for (let i = 0; i < tabCount; i++) {
        const startYear = todayY - i * yearsPerTab;
        const startYearE = startYear - yearsPerTab + 1;
        // const endYear = startYear + yearsPerTab - 1;

        rekigetsuTab.push(
            <Tab label={`${startYear}-${startYearE}年生`} value={`${i + 1}`
            } key={i} />
        );

        tabPanels.push(
            <TabPanel value={`${i + 1}`} key={i}>
                {schoolGraphContents[i]}
            </TabPanel>
        );
    }


    const mainContents = [
        <div key='maincontentskey'>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>学校入学/卒業年表</h2>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile
                                aria-label="scrollable"
                            >
                                {rekigetsuTab}
                            </Tabs>
                        </Box>
                        {tabPanels}
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile
                                aria-label="scrollable"
                            >
                                {rekigetsuTab}
                            </Tabs>
                        </Box>
                    </TabContext>
                </Box>
                <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                    <AdSenseInarticle />
                </div>
            </Box>
        </div>
    ];

    const pageKey = "DBschooleg";
    const pagePath = pagePathS[pageKey];

    const metaDescription = pageDescription[pageKey];
    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        console.log(pagePath + ':', encryptedUid.substring(0, 3));

        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitle, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitle]);


    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };

    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>

                        {/* ***** メインコンテンツ ******************************************************************* */}

                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                            </h1>
                            <div>{mainContents}</div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
});
// };

const App = () => (
    <AuthProvider>
        <DataBase />
    </AuthProvider>
);

export default App;