// src/pages/CalendarSearchIndex.tsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton, Checkbox, InputLabel, MenuItem, FormControl, FormControlLabel, Button, Alert } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import SearchIcon from '@mui/icons-material/Search';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    WeekName,
    Sekki,
    EtoName10,
    EtoName12,
    SenjitsuChk,
    SenjitsuChkS,
    ZassetsuChk,
    ZassetsuChkS,
    SearchSenjitsu,
    SeacrhZassetsu,
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBannerMobile from '../components/AdSenseBannerMobile'; // 320x100、ラージモバイルバナー をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // 記事内広告 をインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

interface Koyomi {
    date: string;           /* 日付 */
    week: string;           /* 曜日 */
    eto10_day: number;      /* 日干支（10支） */
    eto12_day: number;      /* 日干支（12支） */
    xxsekki: number;        /* 二十四節気 */
    xxsenjitsu: string;     /* 選日 */
    xxzassetsu: string;     /* 雑節 */
}

const initialData: string[] = ["二十四節気"];
// const chkboxOption = SearchSenjitsu.concat(SeacrhZassetsu).concat(EtoName12);
let chkboxOption: string[] = initialData.concat(SearchSenjitsu, SeacrhZassetsu, EtoName12);

let chkboxOptionCount = chkboxOption.length;
// console.log(chkboxOptionCount);

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}
let pageTitle = `二十四節気/選日/雑節/十二支の検索`
let pageTitleS = `暦の検索結果`
let uppageTitle = `暦の検索`

const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton
            aria-label="ホームページへページ移動"
            sx={{
                margin: '0px 0px 1px 0px',
                padding: '0px'
            }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Link
        component={RouterLink}
        underline="hover"
        key="2"
        color="inherit"
        to="/calendar_search"
        onClick={handleClick}
    >
        {uppageTitle}
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];

// const selectchk: number[] = [0, 0, 0, 0, 0];
let selectchk: number[] = Array(chkboxOptionCount).fill(0);
let sendDataZero = "0".repeat(chkboxOptionCount);

// const Calendar: React.FC = () => {
const Calendar: React.FC = React.memo(() => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    const { pageName } = useParams();

    const today = new Date();
    const todayY = today.getFullYear().toString(); // 年
    const todayM = (today.getMonth() + 1).toString().padStart(2, '0'); // 月（0-11なので+1が必要）
    const todayD = today.getDate().toString().padStart(2, '0'); // 日
    const todayMD = todayM + todayD;

    const dateY = new Date().getFullYear();
    const dateYBefore = dateY - 10;
    const dateYAfter = dateY + 11;
    let dateJpNumberD; // 和暦の算出：平成
    let dateJpNumberE; // 和暦の算出：令和
    let dateJpNumberDtxt = ""; // 和暦の算出：令和
    let dateJpNumberEtxt = ""; // 和暦の算出：令和
    let count = -10;
    let countTxt = "";

    const dateYJp: { [key: number]: string } = {};
    const todayYChk: { [key: number]: string } = {};

    for (let i = dateYBefore; i <= dateYAfter; i++) {

        dateJpNumberD = Number(i) - 1988; // 和暦の算出：平成
        dateJpNumberE = Number(i) - 2018; // 和暦の算出：令和
        dateJpNumberDtxt = '/平成' + dateJpNumberD + '年';

        if (dateJpNumberE === 1) {
            dateJpNumberEtxt = '/令和元年';
        } else if (dateJpNumberE > 1) {
            dateJpNumberEtxt = '/令和' + dateJpNumberE + '年';
            dateJpNumberDtxt = '';
        }

        if (count < 0) {
            // countTxt = Math.abs(count) + '年前'
            countTxt = '';
        } else if (count === 0) {
            countTxt = '（今年）'
        } else {
            // countTxt = count + '年後'
            countTxt = '';
        }

        dateYJp[i] = dateJpNumberDtxt + dateJpNumberEtxt;
        todayYChk[i] = countTxt + '';
        count++;

    }


    let pageNameYear: number;
    let pageNameYeaNo: string;
    if (pageName !== undefined) {
        pageNameYear = parseInt(pageName.substring(0, 4), 10);
        pageNameYeaNo = pageName.substring(4, pageName.length);
    } else {
        pageNameYear = new Date().getFullYear();
        pageNameYeaNo = "0".repeat(chkboxOptionCount);
    }
    if (pageNameYear < 1901 || pageNameYear > 2100 || pageNameYeaNo.length !== chkboxOptionCount) {
        pageNameYear = new Date().getFullYear();
        pageNameYeaNo = "0".repeat(chkboxOptionCount);
    }

    // const [checked, setChecked] = React.useState([false, false, false, false, false]);
    const [checked, setChecked] = React.useState(Array(chkboxOptionCount).fill(false));
    const [selectyear, setYear] = React.useState(pageNameYear.toString());

    React.useEffect(() => {
        if (pageNameYeaNo) {
            const newChecked = Array.from(pageNameYeaNo).map(char => char === '1');
            setChecked(newChecked);
        }
    }, [pageNameYeaNo]);

    // console.log(newChecked)

    const handleChange = (event: SelectChangeEvent) => {
        setYear(event.target.value as string);
    };
    const handleChangeParent = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newChecked = Array(chkboxOptionCount).fill(event.target.checked);
        setChecked(newChecked);
    };
    const handleChangeChild = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newChecked = [...checked];
        newChecked[index] = event.target.checked;
        setChecked(newChecked);
    };

    const chkBox = (
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', ml: 3 }}>
            {Array.from({ length: chkboxOptionCount }).map((_, index) => (
                <FormControlLabel
                    key={index}
                    label={<span style={{ color: '#666' }}>{`${chkboxOption[index]}`}</span>}
                    control={<Checkbox checked={checked[index]} onChange={handleChangeChild(index)} />}
                />
            ))}
        </Box>
    );


    for (let i = 0; i < checked.length; i++) {
        selectchk[i] = checked[i] ? 1 : 0;
    }
    // let sendData = selectyear;
    let sendData = "";
    for (let i = 0; i < chkboxOptionCount; i++) {
        sendData = sendData + selectchk[i].toString();
    }
    let sendDataChk;
    if (sendData === sendDataZero) {
        sendDataChk = null;
    } else {
        sendDataChk = "ok";
    }
    sendData = selectyear + sendData;


    // 今日の暦jsonの呼び出し //////////////////////////////////////////////////////////////////////

    const calendar_json_url = `https://db.suureki.net/_db_calendar/calendar_${pageNameYear}.json`;

    const [koyomi_data, setDataKoyomi] = useState<Koyomi[]>([]);
    useEffect(() => {
        fetch(calendar_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(koyomi_data => setDataKoyomi(koyomi_data))
            .catch(error => console.error('Error:', error));
    }, [calendar_json_url]);

    // JSONデータを日付をキーとするオブジェクトに変換します。
    let weekMap: { [date: string]: number } = {};
    let xxsekkiMap: { [date: string]: number } = {};
    let eto10dayMap: { [date: string]: number } = {};
    let eto12dayMap: { [date: string]: number } = {};
    let xxsenjitsuMap: { [date: string]: string } = {};
    let xxzassetsuMap: { [date: string]: string } = {};
    koyomi_data.forEach(koyomi => {
        weekMap[koyomi.date] = Number(koyomi.week);
        xxsekkiMap[koyomi.date] = koyomi.xxsekki;
        eto10dayMap[koyomi.date] = koyomi.eto10_day;
        eto12dayMap[koyomi.date] = koyomi.eto12_day;
        xxsenjitsuMap[koyomi.date] = koyomi.xxsenjitsu;
        xxzassetsuMap[koyomi.date] = koyomi.xxzassetsu;
    });

    let searchResults: JSX.Element[] = [];
    let totalling_sz_txt: { [date: string]: string } = {};
    let totalling_eto_txt: { [date: string]: string } = {};
    let totalling_sekki_txt: { [date: string]: string } = {};
    // const chkTest: { [dateYMD: string]: string } = {};

    const searchkey: string[] = [];
    const searchkeyREV: string[] = [];

    count = 0;
    let countREV = 0;
    let makeTitle: string = "";

    if (pageNameYeaNo !== undefined) {
        for (let i = 0; i < pageNameYeaNo.length; i++) {
            if (pageNameYeaNo.substring(i, i + 1) === "1") {
                searchkey[count] = chkboxOption[i];
                if (i >= 24 && i <= 35) {
                    // 干支の場合、最後に「の日、」を付ける。
                    makeTitle += chkboxOption[i] + 'の日、';
                } else {
                    // それ以外の場合、最後に「、」を付ける。
                    makeTitle += chkboxOption[i] + '、';
                }
                count++;
            } else {
                searchkeyREV[countREV] = chkboxOption[i];
                countREV++;
            }
        }
    }

    // makeTitleの最後の一文字が、'、'の場合、削除する。
    if (makeTitle.substring(makeTitle.length - 1) === '、') {
        makeTitle = makeTitle.substring(0, makeTitle.length - 1);
    }


    let echoMonthCountBefore = 0;
    let eto10plus = "";

    for (let i = 1; i <= 12; i++) {
        let echoMonthCount = 0;
        for (let ii = 1; ii <= 31; ii++) {
            const month = i.toString().padStart(2, '0');
            const day = ii.toString().padStart(2, '0');
            const dateYMD = `${pageNameYear}${month}${day}`;

            // 十二支の表示の有無の確認
            totalling_eto_txt[dateYMD] = "";
            let etoChk = pageNameYeaNo.length - 12;
            let countEtochk = etoChk;
            // 巳の日が検索対象＆干支が己の場合＝「己巳の日」と表示
            // eto12dayMap[dateYMD]が「5」 & 干支が「5」
            for (let i = 0; i < 12; i++) {
                if (pageNameYeaNo.substring(countEtochk, countEtochk + 1) === "1" && Number(eto12dayMap[dateYMD]) === i) {
                    if (Number(eto10dayMap[dateYMD]) === 5 && Number(eto12dayMap[dateYMD]) === 5) {
                        eto10plus = EtoName10[eto10dayMap[dateYMD]];
                    } else {
                        eto10plus = "";
                    }
                    totalling_eto_txt[dateYMD] = "、" + eto10plus + EtoName12[eto12dayMap[dateYMD]] + "の日";
                    echoMonthCount = 1;
                }
                countEtochk++;
            }

            // 二十四節気の表示の有無の確認
            // if (Sekki[xxsekkiMap[dateYMD]] !== undefined && parseInt(pageNameYeaNo.substring(0, 1), 10) === 1) {
            if (Sekki[xxsekkiMap[dateYMD]] !== undefined && pageNameYeaNo.substring(0, 1) === "1") {
                totalling_sekki_txt[dateYMD] = "、" + Sekki[xxsekkiMap[dateYMD]];
                echoMonthCount = 1;
            }

            // 選日の表示の有無の確認
            /*
                "三隣亡",       // 1 番目
                "一粒万倍日",   // 2 番目
                "不成就日",     // 3 番目
                "臘日",         // 4 番目
                "八専",         // 5 番目
                "犯土",         // 6 番目
                "十方暮",       // 7 番目
                "天一天上",     // 8 番目
                "三伏",         // 9 番目
                "天赦日"        // 10 番目
            */
            totalling_sz_txt[dateYMD] = "";
            if (xxsenjitsuMap[dateYMD]) {
                // totalling_txt[dateYMD] += "、選日＝（" + xxsenjitsuMap[dateYMD] + "）";
                for (let iii = 0; iii < xxsenjitsuMap[dateYMD].length; iii++) {
                    // 受け取った文字列「111111111111111111111111111111111111」等から、0番目が二十四節気の為、1番目以降からの把握の為、1を足す。
                    let senjitsuNo = parseInt(SenjitsuChk[xxsenjitsuMap[dateYMD].substring(iii, iii + 1)]);
                    if (senjitsuNo !== undefined && pageNameYeaNo.substring(senjitsuNo + 1, senjitsuNo + 2) === "1") {
                        totalling_sz_txt[dateYMD] += "、" + SenjitsuChkS[xxsenjitsuMap[dateYMD].substring(iii, iii + 1)] + "";
                        echoMonthCount = 1;
                    }
                }
            }

            // 雑節の表示の有無の確認
            /*
                "節分",         // 11
                "彼岸",         // 12
                "社日",         // 13
                "八十八夜",     // 14
                "二百十日",     // 15
                "二百二十日",   // 16
                "初午",        // 17
                "大祓",        // 18
                "入梅",        // 19
                "半夏生",      // 20
                "土用",        // 21
                "三元",        // 22
                "盂蘭盆"       // 23
            */
            if (xxzassetsuMap[dateYMD]) {
                // totalling_sz_txt[dateYMD] += "、雑節（" + xxzassetsuMap[dateYMD] + "）＝";
                for (let iii = 0; iii < xxzassetsuMap[dateYMD].length; iii++) {
                    let ZassetsuuNo = parseInt(ZassetsuChk[xxzassetsuMap[dateYMD].substring(iii, iii + 1)]);
                    if (ZassetsuuNo !== undefined && pageNameYeaNo.substring(ZassetsuuNo + 1 + SearchSenjitsu.length, ZassetsuuNo + 2 + SearchSenjitsu.length) === "1") {
                        totalling_sz_txt[dateYMD] += "、" + ZassetsuChkS[xxzassetsuMap[dateYMD].substring(iii, iii + 1)] + "";
                        echoMonthCount = 1;
                    }
                }
            }

            // 1日前の、改行表示
            if (ii === 1 && echoMonthCountBefore === 1) {
                searchResults.push(<hr className="hr_tooltop" key={`${dateYMD}-hr`} />);
            }

            // 表示エリア
            if ((totalling_eto_txt[dateYMD] || totalling_sekki_txt[dateYMD] || totalling_sz_txt[dateYMD]) && totalling_eto_txt[dateYMD] !== undefined) {
                searchResults.push(
                    <div key={dateYMD}>
                        {dateYMD.substring(0, 4)}年{dateYMD.substring(4, 6)}月{dateYMD.substring(6, 8)}日
                        （{WeekName[weekMap[dateYMD]]}）
                        <span style={{ color: 'green' }}>{totalling_eto_txt[dateYMD]}</span>
                        <span style={{ color: 'red' }}>{totalling_sekki_txt[dateYMD]}</span>
                        <span style={{ color: 'blue' }}>{totalling_sz_txt[dateYMD]}</span>
                    </div>
                );

            }
            echoMonthCountBefore = echoMonthCount;
        }
    }

    if (searchResults.length === 0) {
        searchResults.push(
            <div key="none">
                該当はありません。
            </div>
        );
    }


    const mainContents = [
        <div key='maincontentskey'>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>検索フォーム</h2>
                <Alert severity="info" sx={{ marginBottom: '2rem' }}>
                    <Link component={RouterLink} underline="always" key="annotation-sz" color="primary" to="/annotation/sz" onClick={handleClick}>
                        選日と雑節の意味
                    </Link>
                    <div style={{ marginTop: '0.5rem' }}>
                        「巳」での検索時のみ、十干と十二支「己巳」が表示されます。
                    </div>
                </Alert>
                <Box sx={{ minWidth: 200 }}>
                    <form>
                        <FormControl fullWidth >
                            <InputLabel id="calendarsearch-label">表示する年</InputLabel>
                            <Select
                                labelId="calendarsearch-select-label"
                                id="calendarsearch-select"
                                value={selectyear}
                                defaultValue={dateY.toString()}
                                label="select year"
                                onChange={handleChange}
                            >
                                {Array.from({ length: 21 }, (_, index) => (
                                    <MenuItem key={dateYBefore + index} value={dateYBefore + index}>
                                        {dateYBefore + index}年{dateYJp[dateYBefore + index]}{todayYChk[dateYBefore + index]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div style={{ marginTop: '1.0rem' }}>
                            <FormControlLabel
                                sx={{ color: 'green' }}
                                label="一括選択・解除"
                                control={
                                    <Checkbox
                                        checked={checked.every(v => v)}
                                        indeterminate={checked.some(v => v) && !checked.every(v => v)}
                                        onChange={handleChangeParent}
                                    />
                                }
                            />
                            {chkBox}
                        </div>
                        <div style={{ marginTop: '1.0rem' }}>
                            <Button
                                component={Link}
                                href={`/calendar_search/${sendData}`}
                                variant="contained"
                                endIcon={<SearchIcon />}
                                sx={{ marginLeft: '1.5rem' }}
                                disabled={!sendDataChk}
                            >
                                表示する
                            </Button>
                        </div>
                    </form>
                </Box>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>検索結果</h2>
                <div>
                    {searchResults}
                </div>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseInarticle />
            </div>
        </div>
    ];

    const pageKey = "CalendarSearch";
    const pagePath = pagePathS[pageKey] + sendData;

    pageTitle = pageNameYear + '年の' + makeTitle + '';

    const metaDescription = pageNameYear + '年の' + makeTitle + pageDescription[pageKey];
    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        console.log(pagePath + ':', encryptedUid.substring(0, 3));

        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitle, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitle]);


    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };

    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>

                        {/* ***** メインコンテンツ ******************************************************************* */}

                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                            </h1>
                            <div>{mainContents}</div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
});
// };

const App = () => (
    <AuthProvider>
        <Calendar />
    </AuthProvider>
);

export default App;