// src/pages/CalendarIndex.tsx
// import * as React from 'react';
import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
// import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import mailAddressImg from '../168mail.png'
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBannerMobile from '../components/AdSenseBannerMobile'; // 320x100、ラージモバイルバナー をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // 記事内広告 をインポート
import koyomiBookImg from '../img/koyomi_book.jpg';
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');  // 日本語ロケールを設定

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

/* *********************************************************************************************** */
/* ***** ページ名の作成 *************************************************************************** */
/* *********************************************************************************************** */

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}
const pageTitle = `崇暦について`
const pageTitleS = `崇暦について` // ページトップのナビゲーションリンク


const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton
            aria-label="ホームページへページ移動"
            sx={{
                margin: '0px 0px 1px 0px',
                padding: '0px'
            }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];

const About: React.FC = React.memo(() => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    // const navigate = useNavigate();
    let mainContents;

    mainContents = [
        <div key='maincontentskey'>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>崇暦について</h2>
                <span>SUUREKI.net（崇暦）とは、1901年から2100年迄の200年間の暦注カレンダー＋αのサイトです。</span><br />
                <span>暦・月（月齢）の日時は、独自に算出しております。</span><br />
            </Box>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>更新履歴</h2>
                <Link
                    component={RouterLink}
                    underline="always"
                    key="pppage_aboutadsB"
                    color="primary"
                    to="/update"
                    onClick={handleClick}
                >
                    更新履歴はこちら
                </Link><br />
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>暦注の配信</h2>
                <span>毎朝6時に＜その日の暦注＞を、メルマガで配信しております。</span><br />
                <Link
                    component={RouterLink}
                    underline="always"
                    key="pppage_aboutadsB"
                    color="primary"
                    to="/mailmagazine"
                    onClick={handleClick}
                >
                    詳細はこちら
                </Link><br />
            </Box >
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>崇暦への連絡</h2>
                <span>下記のメールアドレス迄、お願いします。</span><br />
                <span>お手数ですが、ご使用のメールソフトにて手入力をし、送信して下さい。</span><br />
                <br />
                <img width='172px' height='38px' src={mailAddressImg} alt='メールアドレス' />
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>参考文献（書籍）</h2>
                <div>
                    <a href="https://www.amazon.co.jp/dp/4404023855/" target="_blank">日本の暦</a>
                    （著者：岡田芳朗、編集：歴史研究会/暦の会、出版社：新人物往来社）
                </div>
                <div>
                    <a href="https://www.amazon.co.jp/dp/440402083X/" target="_blank">暦日大鑑</a>
                    （著者：西沢宥綜、出版社：新人物往来社）
                </div>
                <div>
                    <a href="https://www.amazon.co.jp/dp/476010951X/" target="_blank">現代こよみ読み解き事典</a>
                    （著者：岡田芳朗・阿久根末忠、出版社：柏書房）
                </div>
                <div>
                    <a href="https://www.amazon.co.jp/dp/489361276X/" target="_blank">暦のからくり</a>
                    （著者：岡田芳朗、出版社：はまの出版）
                </div>
                <div>
                    <a href="https://www.amazon.co.jp/dp/4840718415/" target="_blank">陰陽五行説、その発生と展開</a>
                    （著者：根本幸夫、根井養智、出版社：薬業時報社）
                </div>
                <div>
                    <a href="https://www.amazon.co.jp/dp/4915261522/" target="_blank">こよみの基本学</a>
                    （著者：江口鳳祥、出版社：神宮館）
                </div>
                <div style={{ marginBottom: '1rem' }}>
                    <a href="https://www.amazon.co.jp/dp/4422230379/" target="_blank">改訂新版 旧暦読本</a>
                    （著者：岡田芳朗、出版社：創元社）
                </div>
                <div>
                    <a href="https://www.isejingu.or.jp/about/jingutaima/index.html" target="_blank">神宮暦</a>
                    （頒布：伊勢神宮）
                </div>
                <div>
                    <a href="https://oomiwa.or.jp/" target="_blank">大三輪暦</a>
                    （頒布：三輪明神 大神神社）
                </div>
                <div>
                    <a href="https://inari.jp/" target="_blank">稲荷暦</a>
                    （頒布：伏見稲荷大社）
                </div>
                <div>
                    <a href="https://www.tenkawa-jinja.or.jp/" target="_blank">辨財天暦</a>
                    （頒布：大峰本宮 天河大辨財天社）
                </div>
                <div>
                    <a href="https://www.hozanji.com/" target="_blank">歓喜宝暦</a>
                    （頒布：真言律宗大本山 生駒山宝山寺）
                </div>
                <img src={koyomiBookImg} alt="koyomiBookImg" width="400" height="auto" style={{ marginTop: '20px', marginBottom: '20px' }} />
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>参考文献（サイト）</h2>
                <div>
                    <a href="https://ja.wikipedia.org/wiki/" target="_blank">wikipedia日本語</a>
                </div>
                <div>
                    <a href="https://www.calc-site.com/" target="_blank">計算サイト</a>
                </div>
                <div>
                    <a href="http://koyomi8.com/" target="_blank">こよみのページ</a>
                </div>
                <div>
                    <a href="https://eco.mtk.nao.ac.jp/koyomi/" target="_blank">国立天文台 天文情報センター 暦計算室</a>
                </div>
                <div>
                    <a href="https://www8.cao.go.jp/chosei/shukujitsu/gaiyou.html" target="_blank">内閣府 「国民の祝日」について</a>
                </div>
                <br />
                ※2018年5月29日リニューアル時より2019年1月13日迄、参考文献が不記載でした。この場を借りてお詫びいたします。
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseInarticle />
            </div>

        </div >
    ];

    const pageKey = "About";
    const pagePath = pagePathS[pageKey];

    const metaDescription = pageDescription[pageKey];
    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        console.log(pagePath + ':', encryptedUid.substring(0, 3));

        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitle, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitle]);

    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };

    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>
                        {/* ***** メインコンテンツ ******************************************************************* */}

                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                            </h1>
                            <div>{mainContents}</div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
});

const App = () => (
    <AuthProvider>
        <About />
    </AuthProvider>
);

export default App;