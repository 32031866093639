// import * as React from 'react';
import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton, styled, Alert, AlertTitle, AccordionSummary, AccordionDetails } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SwipeIcon from '@mui/icons-material/Swipe';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    Sekki,
    SekkiRuby,
    SekkiMonth,
    SekkiFourSeasons,
    SekkiFourSeasonsColor,
    Bml,
    BmlRuby,
    Nanazyuni,
    NanazyuniBonusData,
    NanazyuniRuby,
    NanazyuniMeaning,
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBannerMobile from '../components/AdSenseBannerMobile'; // 320x100、ラージモバイルバナー をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // 記事内広告 をインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));


const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        background: 'linear-gradient(to right, rgba(231, 244, 255, 0.99),rgba(231, 244, 255, 0.99), rgba(243, 250, 255, 0.99))',
        color: 'black',
        fontSize: theme.typography.pxToRem(16),
        border: '1px solid black',
    },
}));

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}


const pageTitle = `二十四節気/七十二候の意味`
const pageTitleS = `二十四節気/七十二候の意味` // ページトップのナビゲーションリンク
const uppageTitle = `暦注とは？`

const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton
            aria-label="ホームページへページ移動"
            sx={{
                margin: '0px 0px 1px 0px',
                padding: '0px'
            }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Link
        component={RouterLink}
        underline="hover"
        key="2"
        color="inherit"
        to="/annotation"
        onClick={handleClick}
    >
        {uppageTitle}
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];


const Annotation: React.FC = () => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    const microseasonsTable = Array(72).fill(null).map((_, i) => (
        <tbody key={i}>
            <tr style={{ backgroundColor: 'red' }}>
                {/* 節気名 */}
                <th className='swipe_table_cell calendar_bgcolor swipe_table_th_fixing' style={{ textAlign: "right", backgroundColor: SekkiFourSeasonsColor[Math.floor(i / 3)] }}>

                    <HtmlTooltip
                        leaveTouchDelay={5000}
                        enterTouchDelay={0}
                        title={
                            <React.Fragment>
                                <span style={{ lineHeight: '1.3rem' }}>
                                    {Sekki[Math.floor(i / 3)]}（{SekkiRuby[Math.floor(i / 3)]}）
                                </span>
                            </React.Fragment>
                        }
                        placement='top-start'
                    >
                        <span style={{ borderBottom: '1px dotted', fontSize: '0.9rem' }}>
                            {Sekki[Math.floor(i / 3)]}
                        </span>
                    </HtmlTooltip>

                </th>
                {/* 節月 */}
                <td className='swipe_table_cell calendar_bgcolor' style={{ backgroundColor: SekkiFourSeasonsColor[Math.floor(i / 3)] }}>
                    <span style={{ fontSize: '0.9rem' }}>
                        {SekkiMonth[Math.floor(i / 3)]}月
                    </span>
                </td>
                {/* 節月 */}
                <td className='swipe_table_cell calendar_bgcolor' style={{ backgroundColor: SekkiFourSeasonsColor[Math.floor(i / 3)] }}>
                    <span style={{ fontSize: '0.9rem' }}>
                        {SekkiFourSeasons[Math.floor(i / 3)]}の季節
                    </span>
                </td>
                {/* 七十二候 */}
                <td className='swipe_table_cell calendar_bgcolor' style={{ backgroundColor: SekkiFourSeasonsColor[Math.floor(i / 3)] }}>
                    <span style={{ lineHeight: '1.3rem', fontSize: '0.9rem' }}>
                        {Sekki[Math.floor(i / 3)]}の{Bml[i % 3]}：
                    </span>
                    <HtmlTooltip
                        leaveTouchDelay={5000}
                        enterTouchDelay={0}
                        title={
                            <React.Fragment>
                                <span style={{ lineHeight: '1.3rem' }}>
                                    {Nanazyuni[i]}<br />{NanazyuniRuby[i]}
                                </span>
                            </React.Fragment>
                        }
                        placement='top-start'
                    >
                        <span style={{ borderBottom: '1px dotted', fontSize: '0.9rem' }}>
                            {Nanazyuni[i]}
                        </span>
                    </HtmlTooltip>
                </td>
                {/* 入の日 */}
                <td className='swipe_table_cell calendar_bgcolor' style={{ backgroundColor: SekkiFourSeasonsColor[Math.floor(i / 3)] }}>
                    <span style={{ fontSize: '0.9rem' }}>
                        {parseInt(NanazyuniBonusData[i].substring(0, 2), 10)}月{parseInt(NanazyuniBonusData[i].substring(2, 4), 10)}日~
                        {parseInt(NanazyuniBonusData[i].substring(5, 7), 10)}月{parseInt(NanazyuniBonusData[i].substring(7, 9), 10)}日<br />

                    </span>
                </td>
                {/* 意味 */}
                <td className='swipe_table_cell calendar_bgcolor' style={{ backgroundColor: SekkiFourSeasonsColor[Math.floor(i / 3)] }}>
                    <span style={{ fontSize: '0.9rem' }}>
                        {NanazyuniMeaning[i]}頃
                    </span>
                </td>
            </tr>
        </tbody>
    ));



    const mainContents = [
        <div key='maincontentskey'>
            <Alert severity="info" sx={{ marginTop: '1.5rem', marginBottom: '1rem' }}>
                <AlertTitle sx={{ fontSize: '0.9rem' }}>
                    「
                    <Link
                        component={RouterLink}
                        underline="always"
                        key="sekki_climates"
                        color="primary"
                        to="/annotation/24sekki"
                        onClick={handleClick}
                    >
                        二十四節気とはページ
                    </Link>
                    」も、伏せてご覧下さい。
                </AlertTitle>
            </Alert>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>二十四節気・七十二候・節月とは</h2>
                <div>
                    二十四節気とは、一年を24分割し、それぞれに節気・季節を表す名称を割り当てたもの。
                </div>
                <div>
                    七十二候とは、其々の二十四節気をさらに3分割（
                    <HtmlTooltip
                        leaveTouchDelay={5000}
                        enterTouchDelay={0}
                        title={
                            <React.Fragment>
                                <span style={{ lineHeight: '1.3rem' }}>{Bml[0]}（{BmlRuby[0]}）</span>
                            </React.Fragment>
                        }
                        placement='top-start'
                    >
                        <span style={{ borderBottom: '1px dotted' }}>{Bml[0]}</span>
                    </HtmlTooltip>、
                    <HtmlTooltip
                        leaveTouchDelay={5000}
                        enterTouchDelay={0}
                        title={
                            <React.Fragment>
                                <span style={{ lineHeight: '1.3rem' }}>{Bml[1]}（{BmlRuby[1]}）</span>
                            </React.Fragment>
                        }
                        placement='top-start'
                    >
                        <span style={{ borderBottom: '1px dotted' }}>{Bml[1]}</span>
                    </HtmlTooltip>、
                    <HtmlTooltip
                        leaveTouchDelay={5000}
                        enterTouchDelay={0}
                        title={
                            <React.Fragment>
                                <span style={{ lineHeight: '1.3rem' }}>{Bml[2]}（{BmlRuby[2]}）</span>
                            </React.Fragment>
                        }
                        placement='top-start'
                    >
                        <span style={{ borderBottom: '1px dotted' }}>{Bml[2]}</span>
                    </HtmlTooltip>
                    ）したもので、季節を表す方式のひとつ。<br />
                    読み仮名は、貞享暦（江戸時代）ではなく、略本暦（明治時代）に記載のものです。
                </div>
                <div>
                    節月とは、二十四節気を月に割り当てたもの。
                </div>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>二十四節気・七十二候表</h2>
                <div style={{ margin: '0rem 0rem 0rem 5rem', display: 'flex', alignItems: 'center' }}>
                    <ArrowLeftIcon color="disabled" />
                    <ArrowLeftIcon color="disabled" />
                    <SwipeIcon fontSize="small" color="disabled" />
                    <ArrowRightIcon color="disabled" />
                    <ArrowRightIcon color="disabled" />
                    <span style={{ color: 'gray', fontSize: '0.8rem', marginLeft: '0.5rem' }}>Swipe on the table.</span>
                </div>
                <div className='table_enclosure'>
                    <table className='swipe_table'>
                        <thead>
                            <tr>
                                <th className='swipe_table_cell calendar_bgcolor swipe_table_th_fixing' style={{ textAlign: "center", fontSize: '0.9rem', width: '1.5rem', color: 'green' }}>節気</th>
                                <th className='swipe_table_cell calendar_bgcolor' style={{ textAlign: "left", fontSize: '0.9rem', width: '1.5rem', color: 'green' }}>節月</th>
                                <th className='swipe_table_cell calendar_bgcolor' style={{ textAlign: "left", fontSize: '0.9rem', width: '1.5rem', color: 'green' }}>季節</th>
                                <th className='swipe_table_cell calendar_bgcolor' style={{ textAlign: "left", fontSize: '0.9rem', width: '1.5rem', color: 'green' }}>七十二候</th>
                                <th className='swipe_table_cell calendar_bgcolor' style={{ textAlign: "left", fontSize: '0.9rem', width: '1.5rem', color: 'green' }}>入の日</th>
                                <th className='swipe_table_cell calendar_bgcolor' style={{ textAlign: "left", fontSize: '0.9rem', width: '1.5rem', color: 'green' }}>七十二候の意味</th>
                            </tr>
                        </thead>
                        {microseasonsTable}
                    </table>
                </div>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>七十二候の日は？</h2>
                <div style={{ marginBottom: '1rem' }}>
                    二十四節気の日もですが、七十二候の日も、年毎に違います。各七十二候毎に、何月何日が何日あるのか、崇暦にある情報の1901年から2100年迄の日を算出してみました。<br />
                    各二十四節気の日入と、各初候の七十二候の日入は、同一の日となります。
                </div>
                <div>
                    <Accordion>
                        <AccordionSummary
                            style={{ backgroundColor: '#eee' }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel72-content"
                            id="panel72-header"
                        >
                            <Typography>200年間の七十二候の日の集計表</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {Nanazyuni.map((item, index) => (
                                    <span key={index}>
                                        <span style={{ paddingLeft: '7px', borderLeft: `7px solid ${SekkiFourSeasonsColor[Math.floor(index / 3)]}` }}>
                                            {Sekki[Math.floor(index / 3)]}の{Bml[index % 3]}：<br />{item}（{NanazyuniRuby[index]}）：
                                        </span><br />
                                        <span style={{ paddingLeft: '7px', borderLeft: `7px solid ${SekkiFourSeasonsColor[Math.floor(index / 3)]}`, marginBottom: '0.8rem' }}>
                                            {NanazyuniBonusData[index].split(',').map((str, strIndex, arr) => (
                                                strIndex !== 0 && (
                                                    <span key={strIndex} style={{ color: 'green' }}>
                                                        {parseInt(str.substring(0, 2), 10)}月{parseInt(str.substring(2, 4), 10)}日（{parseInt(str.substring(5, 8), 10)}日）
                                                        {strIndex !== arr.length - 1 && '、'}
                                                    </span>
                                                )
                                            ))}
                                        </span><br />
                                        {(index % 3 === 2 && index < 71) && <br />}
                                    </span>
                                ))}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseInarticle />
            </div>
        </div>
    ];

    /*
    <meta name="description" content="This is an example page" />
    <meta property="og:site_name" content="suureki.net">
    <meta property="og:title" content="柿本実さんの情報【suureki.net | すうれきねっと】">
    <meta property="og:description" content="suureki.netとは、1901年から2100年迄を網羅した万年暦・カレンダー・こよみサイトです。様々な暦の他、著名人の情報や生年月日による吉凶運勢・吉方位・バイオリズム・カバラ数秘術の情報を提供しています。">
    <meta property="og:type" content="website">
    <meta property="og:locale" content="ja_JP">
    <meta property="og:url" content="https://suureki.net/75882/register.php?id=17630317">
    <meta property="og:image" content="https://suureki.net/img/suureki_ogp_1200x630.png">
    <meta property="og:image:width" content="1200">
    <meta property="og:image:height" content="630">
    <meta name="twitter:card" content="summary_large_image">
    <meta name="twitter:url" content="https://suureki.net/">
    <meta name="twitter:image" content="https://suureki.net/img/suureki_ogp_1200x630.png">
    <meta name="twitter:title" content="日々、役立つ暦注を！">
    <meta name="twitter:description" content="万年暦・こよみサイト。二十八宿や十二直、二十四節気/七十二候などの様々な暦注を掲載。">
    <meta name="twitter:site" content="@suureki_cal">
    */

    const pageKey = "Annotation72";
    const pagePath = pagePathS[pageKey];

    const metaDescription = pageDescription[pageKey];
    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        console.log(pagePath + ':', encryptedUid.substring(0, 3));

        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitle, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitle]);

    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };
    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>

                        {/* ***** メインコンテンツ ******************************************************************* */}

                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                            </h1>
                            <div>{mainContents}</div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
};


const App = () => (
    <AuthProvider>
        <Annotation />
    </AuthProvider>
);

export default App;