import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
//import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton, Button, Alert, AlertTitle } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    EtoName10,
    EtoName12,
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBannerMobile from '../components/AdSenseBannerMobile'; // 320x100、ラージモバイルバナー をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // 記事内広告 をインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

const dateJpA: string = `明治`;
const dateJpB: string = `大正`;
const dateJpC: string = `昭和`;
const dateJpD: string = `平成`;
const dateJpE: string = `令和`;

const dateJpExactA: number = 1868;
const dateJpExactB: number = 1912;
const dateJpExactC: number = 1926;
const dateJpExactD: number = 1989;
const dateJpExactE: number = 2019;

const today = new Date();
const todayY = today.getFullYear();

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}
const pageTitle = `数え年の年齢早見表`
const pageTitleS = `数え年の年齢早見表` // ページトップのナビゲーションリンク
const uppageTitle = `情報/DB`

interface Koyomi {
    dateY: string;           /* 日付 */
    eto10_year: number;     /* 年干支（暦：10支） */
    eto12_year: number;     /* 年干支（暦：12支） */
    kyuusei_y: number;     /* 九星 */
}

const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton
            aria-label="ホームページへページ移動"
            sx={{
                margin: '0px 0px 1px 0px',
                padding: '0px'
            }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Link
        component={RouterLink}
        underline="hover"
        key="2"
        color="inherit"
        to="/archives"
        onClick={handleClick}
    >
        {uppageTitle}
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];


const DataBase: React.FC = React.memo(() => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    // 暦月の干支一覧のjsonの呼び出し //////////////////////////////////////////////////////////////////////

    const eto_rekigetsu_json_url = "https://db.suureki.net/_db_etc/eto_all_rekigetsu.json";
    const [koyomi_data, setDataKoyomi] = useState<Koyomi[]>([]);
    useEffect(() => {
        fetch(eto_rekigetsu_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(koyomi_data => setDataKoyomi(koyomi_data))
            .catch(error => console.error('Error:', error));
    }, [eto_rekigetsu_json_url]);

    let eto10yearMap: { [dateY: string]: number } = {};
    let eto12yearMap: { [dateY: string]: number } = {};
    let kyuuseiMap: { [dateY: string]: number } = {};

    koyomi_data.forEach(koyomi => {
        eto10yearMap[koyomi.dateY] = Number(koyomi.eto10_year);
        eto12yearMap[koyomi.dateY] = Number(koyomi.eto12_year);
        kyuuseiMap[koyomi.dateY] = Number(koyomi.kyuusei_y);
    }, [todayY]);

    let tmpY = 0;
    let tmpYloop = todayY - 1901 + 1;
    let tmpAge = 0;

    const mainContents = [
        <div key='maincontentskey'>
            <h2 className='content_subtitle'>年齢早見表切替</h2>
            <div>
                <Stack spacing={2} direction="row" sx={{ marginTop: '2rem', marginBottom: '2rem' }}>
                    <Link component={RouterLink} underline="always" key="ageLinkA" color="primary" to={`/archives/db_koyomihenkan`} onClick={handleClick}>
                        <Button variant="outlined"><CheckBoxOutlineBlankIcon style={{ marginRight: '0.5rem' }} />満年齢の年齢</Button>
                    </Link>
                    <Link component={RouterLink} underline="always" key="ageLinkB" color="primary" to={`/archives/db_countingage`} onClick={handleClick}>
                        <Button variant="contained"><CheckBoxIcon style={{ marginRight: '0.5rem' }} />数え年の年齢</Button>
                    </Link>
                </Stack>
            </div>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>{todayY}年現在の{pageTitleS}</h2>
                <Alert severity="info" sx={{ marginTop: '1.5rem' }}>
                    <AlertTitle sx={{ fontSize: '0.9rem' }}>数え年の年齢は生まれた年を1歳とするため、生まれた月日は考慮しません。例外なく、皆、毎年1月1日に年を重ねます。</AlertTitle>
                </Alert>
                <div style={{ marginTop: '1rem' }}>

                    {Array.from({ length: tmpYloop }, (_, i) => {

                        tmpY = todayY - i;

                        /*
                            let dateJpA: string = `明治`;
                            let dateJpB: string = `大正`;
                            let dateJpC: string = `昭和`;
                            let dateJpD: string = `平成`;
                            let dateJpE: string = `令和`;
                            let dateJpExactA: number = 1868;
                            let dateJpExactB: number = 1912;
                            let dateJpExactC: number = 1926;
                            let dateJpExactD: number = 1989;
                            let dateJpExactE: number = 2019;
                        */

                        let dateJp = 0;
                        let dateJpTxt = "";
                        if (tmpY === dateJpExactE) {
                            dateJpTxt = dateJpE + "元年/" + dateJpD + "31年";
                        } else if (tmpY === dateJpExactD) {
                            dateJpTxt = dateJpD + "元年/" + dateJpC + "64年";
                        } else if (tmpY === dateJpExactC) {
                            dateJpTxt = dateJpC + "元年/" + dateJpB + "15年";
                        } else if (tmpY === dateJpExactB) {
                            dateJpTxt = dateJpB + "元年/" + dateJpA + "45年";
                        } else if (tmpY > 2018) {
                            dateJp = Number(tmpY) - dateJpExactE + 1;
                            dateJpTxt = dateJpE + dateJp + "年";
                        } else if (tmpY > 1988) {
                            dateJp = Number(tmpY) - dateJpExactD + 1;
                            dateJpTxt = dateJpD + dateJp + "年";
                        } else if (tmpY > 1925) {
                            dateJp = Number(tmpY) - dateJpExactC + 1;
                            dateJpTxt = dateJpC + dateJp + "年";
                        } else if (tmpY > 1911) {
                            dateJp = Number(tmpY) - dateJpExactB + 1;
                            dateJpTxt = dateJpB + dateJp + "年";
                        } else {
                            dateJp = Number(tmpY) - dateJpExactA + 1;
                            dateJpTxt = dateJpA + dateJp + "年";
                        }

                        let tmpMargin = '0.7rem';
                        if (tmpY % 10 === 1) { tmpMargin = '1.7rem'; } else { tmpMargin = '0.7rem'; }
                        let tmpkyuusei = kyuuseiMap[tmpY] - 1;
                        if (tmpkyuusei === -1) { tmpkyuusei = 8; }

                        tmpAge++;

                        return (
                            <div key={`inner-${i}`}>
                                <div style={{ marginBottom: tmpMargin }}>
                                    <Link
                                        component={RouterLink}
                                        underline="always"
                                        key={`pppage_adjp-${tmpY}`}
                                        color="primary"
                                        to={`/calendar/${tmpY}`}
                                        onClick={handleClick}
                                    >
                                        {tmpY}年生
                                    </Link>（
                                    {dateJpTxt}生）：
                                    {EtoName10[eto10yearMap[tmpY]]}
                                    {EtoName12[eto12yearMap[tmpY]]}：
                                    {tmpAge}歳
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Box>
        </div>
    ];

    const pageKey = "DBcountingage";
    const pagePath = pagePathS[pageKey];

    const metaDescription = pageDescription[pageKey];
    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        console.log(pagePath + ':', encryptedUid.substring(0, 3));

        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitle, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitle]);


    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },            // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };

    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>

                        {/* ***** メインコンテンツ ******************************************************************* */}

                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                            </h1>
                            <div>{mainContents}</div>
                            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                <AdSenseInarticle />
                            </div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
});

const App = () => (
    <AuthProvider>
        <DataBase />
    </AuthProvider>
);

export default App;