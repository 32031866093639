// import * as React from 'react';
import React, { useEffect } from 'react';
// import React, { useEffect, useState } from 'react';
//import { useParams } from 'react-router-dom';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton } from '@mui/material';
// import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { MobileDatePicker } from '@mui/x-date-pickers';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBannerMobile from '../components/AdSenseBannerMobile'; // 320x100、ラージモバイルバナー をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // 記事内広告 をインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';
// import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');  // 日本語ロケールを設定

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

/*
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        background: 'linear-gradient(to right, rgba(231, 244, 255, 0.99),rgba(231, 244, 255, 0.99), rgba(243, 250, 255, 0.99))',
        color: 'black',
        fontSize: theme.typography.pxToRem(16),
        border: '1px solid black',
    },
}));
*/

/* **************************************************************************************************** */
/* **************************************************************************************************** */
/* **************************************************************************************************** */
/* *****                               **************************************************************** */
/* ***** メインコンテンツの作成準備エリア **************************************************************** */
/* *****                               **************************************************************** */
/* **************************************************************************************************** */
/* **************************************************************************************************** */
/* **************************************************************************************************** */


function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}

const Goodday: React.FC = () => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    const navigate = useNavigate();

    // console.log('getdata=' + getdata);

    const today = new Date();
    // const date = '202408'
    let date = '';

    date = today.getFullYear() + (today.getMonth() + 1).toString().padStart(2, '0');



    let pageTitle: string | null;
    // let pageTitleS: string;
    let dateF: string;
    let dateY: string;
    let dateYM: string;
    // let dateYMD: string;
    let dateM: number;
    // let monthDayStart: number;
    // let monthDayEnd: number;
    // let monthDayStartWeek: number;
    // let monthDayStartWeekFastsunday: number;
    // let count: number = 0;


    // let dateJpNumberA: number;
    // let dateJpNumberB: number;
    // let dateJpNumberC: number;
    // let dateJpNumberD: number;
    // let dateJpNumberE: number;

    // let dateJpA: string = `明治`;
    // let dateJpB: string = `大正`;
    // let dateJpC: string = `昭和`;
    // let dateJpD: string = `平成`;
    // let dateJpE: string = `令和`;

    // let dateJpExactA: number = 18680125;
    // let dateJpExactB: number = 19120730;
    // let dateJpExactC: number = 19261225;
    // let dateJpExactD: number = 19890108;
    // let dateJpExactE: number = 20190501;

    let mainContentsA;
    let mainContentsB;
    let mainContentsC;
    let mainContentsD;


    /* *********************************************************************************************** */
    /* ***** 日歴・月暦・年歴         ***************************************************************** */
    /* ***** 各変数挿入               ****************************************************************** */
    /* *********************************************************************************************** */


    ////////////////////////////////////////////////////////////////////////
    //月暦の、各変数挿入
    ////////////////////////////////////////////////////////////////////////

    // pageTitle = `${date.substring(0, 4)}年${Number(date.substring(4, 6))}月の吉凶判断`;
    pageTitle = `二十八宿と十二直による吉凶判断`;
    // pageTitleS = `${date.substring(0, 4)}年${Number(date.substring(4, 6))}月`;
    dateF = `${date.substring(0, 4)}-${date.substring(4, 6)}`;
    dateYM = `${date.substring(0, 4)}${date.substring(4, 6)}`;
    // dateYMD = `${date.substring(0, 4)}${date.substring(4, 6)}01`;
    dateM = Number(date.substring(4, 6));
    dateY = String(date.substring(0, 4));


    // dateJpNumberA = Number(dateY) - 1867; // 和暦の算出：明治
    // dateJpNumberB = Number(dateY) - 1911; // 和暦の算出：大正
    // dateJpNumberC = Number(dateY) - 1925; // 和暦の算出：昭和
    // dateJpNumberD = Number(dateY) - 1988; // 和暦の算出：平成
    // dateJpNumberE = Number(dateY) - 2018; // 和暦の算出：令和


    // 和暦の算出：明治
    /*
    if (dateJpNumberA === 1) {
        dateJpA = dateJpA + `元年`;
    } else if (dateJpNumberA >= 1 && Number(dateYMD) >= dateJpExactA) {
        dateJpA = dateJpA + `${dateJpNumberA}年/`;
    } else {
        dateJpA = "";
    }
    */
    // 和暦の算出：大正
    /*
        if (dateJpNumberB === 1) {
            dateJpB = dateJpB + `元年`;
        } else if (dateJpNumberB >= 1) {
            dateJpB = dateJpB + `${dateJpNumberB}年/`;
        } else {
            dateJpB = "";
        }
    */
    // 和暦の算出：昭和
    /*
        if (dateJpNumberC === 1) {
            dateJpC = dateJpC + `元年`;
        } else if (dateJpNumberC >= 1) {
            dateJpC = dateJpC + `${dateJpNumberC}年/`;
        } else {
            dateJpC = "";
        }
    */
    // 和暦の算出：平成
    /*
        if (dateJpNumberD === 1) {
            dateJpD = dateJpD + `元年`;
        } else if (dateJpNumberD >= 1) {
            dateJpD = dateJpD + `${dateJpNumberD}年/`;
        } else {
            dateJpD = "";
        }
    */

    // 和暦の算出：令和
    // if (dateJpNumberE === 1) {
    //     dateJpE = dateJpE + `元年`;
    // } else if (dateJpNumberE >= 1) {
    //     dateJpE = dateJpE + `${dateJpNumberE}年`;
    // } else {
    //     dateJpE = "";
    // }



    /* *********************************************************************************************** */
    /* ***** ページ名の作成 *************************************************************************** */
    /* *********************************************************************************************** */

    const breadcrumbs = [
        <Link
            component={RouterLink}
            underline="hover"
            key="1"
            color="inherit"
            to="/"
            onClick={handleClick}>
            <IconButton
                aria-label="ホームページへページ移動"
                sx={{
                    margin: '0px 0px 1px 0px',
                    padding: '0px'
                }}>
                <HomeIcon />
            </IconButton>
        </Link>,
        <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
            {pageTitle}
        </Typography>,
    ];

    /* ********************************************************************************************** */
    /* ***** メインコンテンツの作成 ******************************************************************* */
    /* ***** メインコンテンツの作成：前処理 ************************************************************* */
    /* *********************************************************************************************** */




    /* **************************************************************************************************** */
    /* **************************************************************************************************** */
    /* **************************************************************************************************** */
    /* *****                            ******************************************************************* */
    /* ***** メインコンテンツの作成エリア ******************************************************************* */
    /* *****                            ******************************************************************* */
    /* **************************************************************************************************** */
    /* **************************************************************************************************** */
    /* **************************************************************************************************** */



    /* **************************************************************************************************** */
    /* ***** メインコンテンツの作成：月暦 ******************************************************************* */
    /* **************************************************************************************************** */



    /* **************************************************************************************************** */
    /* ***** メインコンテンツの作成：月暦：A ***************************************************************** */
    /* **************************************************************************************************** */

    mainContentsA = [
        <div key='maincontentskeya'>
            <Box className='content_subbox'>

            </Box>
        </div>
    ];

    /* **************************************************************************************************** */
    /* ***** メインコンテンツの作成：月暦：B ***************************************************************** */
    /* **************************************************************************************************** */

    mainContentsB = [
        <div key='maincontentskeyb'>
            <h2 className='content_subtitle'>吉凶項目</h2>
            <div>
                <ul>
                    <li>以下の項目の吉凶判断です。</li>
                    <li>
                        <Link component={RouterLink} underline="always" key="annotation-28" color="primary" to={`/annotation/28`} onClick={handleClick}>
                            二十八宿
                        </Link>
                    </li>
                    <li style={{ fontSize: 'small', color: 'green' }}>
                        <span>祭祀・</span>
                        <span>衣類裁断・</span>
                        <span>裁縫・</span>
                        <span>結婚・</span>
                        <span>葬式・</span>
                        <span>旅行・</span>
                        <span>遠出・</span>
                        <span>造作・</span>
                        <span>動土・</span>
                        <span>種蒔・</span>
                        <span>移転・</span>
                        <span>開店・</span>
                        <span>改装・</span>
                        <span>入学・</span>
                        <span>就職・</span>
                        <span>療養・</span>
                        <span>寄付・</span>
                        <span>相談・</span>
                        <span>午の刻(11時~13時)・</span>
                        <span>午の刻以外</span>
                    </li>
                    <li>
                        <Link component={RouterLink} underline="always" key="annotation-12" color="primary" to="/annotation/12" onClick={handleClick}>
                            十二直
                        </Link>
                    </li>
                    <li style={{ fontSize: 'small', color: 'green' }}>
                        <span>祭祀・</span>
                        <span>結婚・</span>
                        <span>葬式・</span>
                        <span>旅行・</span>
                        <span>買物・</span>
                        <span>外出・</span>
                        <span>動土・</span>
                        <span>種蒔・</span>
                        <span>新規事・</span>
                        <span>新築・</span>
                        <span>改装・</span>
                        <span>療養・</span>
                        <span>服薬・</span>
                        <span>訴訟・</span>
                        <span>争い毎・</span>
                        <span>漁猟・</span>
                        <span>高所へ移動・</span>
                        <span>舟で移動</span>
                    </li>
                </ul>
            </div>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <h2 className='content_subtitle'>表示する年月の選択</h2>
            <Box className='content_subbox'>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                    <DemoContainer components={['MobileDatePicker']}>
                        <Box display="flex" alignItems="center" className='search_space' key='searchKeyM'>
                            年月の選択：
                            <MobileDatePicker
                                views={['year', 'month']}
                                defaultValue={dayjs(dateF)}
                                onChange={(newValue) => {
                                    if (newValue) {
                                        const formattedDate = newValue.format('YYYYMM');
                                        navigate(`/goodday/${formattedDate}`);
                                    }
                                }}
                                format="YYYY/MM"
                                minDate={dayjs('1901-01-01')}
                                maxDate={dayjs('2100-12-31')}
                            />
                        </Box>
                    </DemoContainer>
                </LocalizationProvider>
                <br />もしくは<br /><br />
                <Link
                    component={RouterLink}
                    underline="hover"
                    key="2"
                    color="primary"
                    to={`/goodday/${dateYM}`}
                    onClick={handleClick}
                >
                    今月（{dateY}年{dateM}月）の吉凶判断
                </Link>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseInarticle />
            </div>
        </div>
    ];


    /* **************************************************************************************************** */
    /* ***** メインコンテンツの作成：月暦：D ***************************************************************** */
    /* **************************************************************************************************** */

    mainContentsD = [
        <div key='maincontentskeyd'>
        </div>
    ];


    /* **************************************************************************************************** */
    /* **************************************************************************************************** */
    /* **************************************************************************************************** */
    /* *****                            ******************************************************************* */
    /* ***** returnエリア               ******************************************************************* */
    /* *****                            ******************************************************************* */
    /* **************************************************************************************************** */
    /* **************************************************************************************************** */
    /* **************************************************************************************************** */



    const pageKey = "GoodDayIndex";
    const pagePath = pagePathS[pageKey];

    const metaDescription = pageDescription[pageKey];
    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    const pageTitleTmp = pageTitle; // title: pageTitle にすると、何故かエラーになるので、一旦変数に格納

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        console.log(pagePath + ':', encryptedUid.substring(0, 3));

        console.log('pageTitle:', pageTitle);
        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitleTmp, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitle]);


    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };

    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>

                        {/* ***** メインコンテンツ ******************************************************************* */}
                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                                <span></span>
                            </h1>
                            <div>{mainContentsA}</div>
                            <div>{mainContentsB}</div>
                            <div>{mainContentsC}</div>
                            <div>{mainContentsD}</div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
};

const App = () => (
    <AuthProvider>
        <Goodday />
    </AuthProvider>
);

export default App;