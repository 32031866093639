import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
// import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton, Alert, AlertTitle } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    WeekName,
    SekkiAngle,
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBannerMobile from '../components/AdSenseBannerMobile'; // 320x100、ラージモバイルバナー をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // 記事内広告 をインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

interface Sekki {
    date: string;           /* 年月日 */
    time: string;         /* 日時 */
    angle: string;    /* 祝日の該当数字 */
}

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}
const pageTitle = `二十四節気の日時`
const pageTitleS = `二十四節気の日時` // ページトップのナビゲーションリンク
const uppageTitle = `情報/DB`

// const today = new Date();
// const todayY = today.getFullYear();

const formatDateToYYYYMMDD = (date: Date): string => {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // JavaScriptの月は0から始まるので、1を足して調整
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}${month}${day}`;
};
const getHalfYearBeforeAndAfter = (): { todayYMD: string, todayYMDbefore: string } => {
    const today = new Date();
    const todayYMD = new Date(today);
    // 去年の1月1日
    const todayYMDbefore = new Date(today);
    todayYMDbefore.setFullYear(today.getFullYear() - 1);

    return {
        todayYMD: formatDateToYYYYMMDD(todayYMD),
        todayYMDbefore: formatDateToYYYYMMDD(todayYMDbefore),
    };
};
const { todayYMD, todayYMDbefore } = getHalfYearBeforeAndAfter();


let tmpYbefore: number = Number(todayYMDbefore.slice(0, 4));

let tmpYY: { [count: number]: number } = {};
for (let i = 0; i <= 3; i++) {
    tmpYY[i] = tmpYbefore + i;
}

let tmpYYtxt: { [count: number]: string } = {};
tmpYYtxt[0] = "去年";
tmpYYtxt[1] = "今年";
tmpYYtxt[2] = "来年";
tmpYYtxt[3] = "再来年";



const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton
            aria-label="ホームページへページ移動"
            sx={{
                margin: '0px 0px 1px 0px',
                padding: '0px'
            }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Link
        component={RouterLink}
        underline="hover"
        key="2"
        color="inherit"
        to="/archives"
        onClick={handleClick}
    >
        {uppageTitle}
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];



// const DataBase: React.FC = () => {
const DataBase: React.FC = React.memo(() => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    // 二十四節気の時間jsonの呼び出し //////////////////////////////////////////////////////////////////////

    const sekki_json_url = "https://db.suureki.net/_db_24sekki/24sekki_all.json";
    const [sekki_data, setDataSekki] = useState<Sekki[]>([]);
    useEffect(() => {
        fetch(sekki_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(sekki_data => setDataSekki(sekki_data))
            .catch(error => console.error('Error:', error));
    }, [sekki_json_url]);

    // JSONデータを日付をキーとするオブジェクトに変換します。
    let sekkiTimeMap: { [date: string]: string } = {};
    let sekkiAngleMap: { [date: string]: string } = {};
    sekki_data.forEach(sekki => {
        sekkiTimeMap[sekki.date] = sekki.time;
        sekkiAngleMap[sekki.date] = sekki.angle;
    });

    let todayAfterColor: string;
    todayAfterColor = 'gray';


    // let yearCount = 0;

    const renderMainContents = (yearCount: number) => {
        return (
            <div key={`maincontentskey-${yearCount}`}>
                <h2 className='content_subtitle'>{tmpYY[yearCount]}年の二十四節気<span style={{ fontSize: '0.9rem', color: 'white', backgroundColor: '#666', margin: '0rem 0rem 0rem 1rem', padding: '0rem 0.5rem 0rem 0.5rem' }}>{tmpYYtxt[yearCount]}</span></h2>
                {sekki_data.filter(sekki => Number(sekki.date.slice(0, 4)) === tmpYY[yearCount]).map(sekki => {
                    let dateObj = new Date(Number(sekki.date.slice(0, 4)), Number(sekki.date.slice(4, 6)) - 1, Number(sekki.date.slice(6, 8)));
                    let tmpW = dateObj.getDay().toString();
                    if (sekki.date >= todayYMD) { todayAfterColor = 'green'; }

                    return (
                        <Box key={sekki.date} sx={{ textAlign: 'left' }}>
                            <div style={{ borderBottom: '1px solid #ddd', letterSpacing: '0.5px', paddingTop: '0.3rem', paddingBottom: '0.3rem' }}>
                                <span style={{ width: '100%' }}>
                                    <span style={{ color: todayAfterColor }}>
                                        {sekki.date.slice(0, 4)}年{sekki.date.slice(4, 6)}月{sekki.date.slice(6, 8)}日
                                    </span>
                                    <span style={{ color: todayAfterColor, marginLeft: '0rem' }}>
                                        （{WeekName[tmpW]}）
                                    </span>
                                    <span style={{ color: todayAfterColor, marginLeft: '0rem' }}>
                                        {sekkiTimeMap[sekki.date].slice(0, 2)}時{sekkiTimeMap[sekki.date].slice(2, 4)}分
                                    </span>：
                                    <span style={{ color: todayAfterColor, marginLeft: '0rem' }}>
                                        {SekkiAngle[sekkiAngleMap[sekki.date]]}
                                    </span>
                                </span>
                            </div>
                        </Box>
                    )
                })}
            </div>
        );
    }

    const pageKey = "DB24sekki";
    const pagePath = pagePathS[pageKey];

    const metaDescription = pageDescription[pageKey];
    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        console.log(pagePath + ':', encryptedUid.substring(0, 3));

        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitle, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitle]);


    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };

    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>

                        {/* ***** メインコンテンツ ******************************************************************* */}

                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                            </h1>
                            <Alert severity="info" sx={{ marginTop: '1.5rem' }}>
                                <AlertTitle sx={{ fontSize: '0.9rem' }}>算出は、崇暦独自の計算方法によるものです。</AlertTitle>
                                <span style={{ marginTop: '0.5rem' }}>日時の文字色は、<span style={{ color: 'gray' }}>本日以前は灰色</span>、<span style={{ color: 'green' }}>本日以降は緑</span>で表示しております。</span><br />
                            </Alert>
                            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                <AdSenseBannerMobile />
                            </div>
                            <div>{renderMainContents(0)}</div>
                            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                <AdSenseBannerMobile />
                            </div>
                            <div>{renderMainContents(1)}</div>
                            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                <AdSenseBannerMobile />
                            </div>
                            <div>{renderMainContents(2)}</div>
                            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                <AdSenseBannerMobile />
                            </div>
                            <div>{renderMainContents(3)}</div>
                            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                <AdSenseInarticle />
                            </div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
});
// };

const App = () => (
    <AuthProvider>
        <DataBase />
    </AuthProvider>
);

export default App;