// import * as React from 'react';
import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
//import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton, Alert, AlertTitle } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBannerMobile from '../components/AdSenseBannerMobile'; // 320x100、ラージモバイルバナー をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // 記事内広告 をインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}
const pageTitle = `暦注とは？`
const pageTitleS = `暦注とは？` // ページトップのナビゲーションリンク

const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton
            aria-label="ホームページへページ移動"
            sx={{
                margin: '0px 0px 1px 0px',
                padding: '0px'
            }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];


const Annotation: React.FC = () => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    const mainContents = [
        <div key='maincontentskey'>
            <Alert severity="info" sx={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
                <AlertTitle sx={{ fontSize: '0.9rem' }}>暦注についての知識を深めるためには、以下のサイトもお勧めします。</AlertTitle>
                <div>
                    <span style={{ marginTop: '0.5rem' }}>
                        <a href="https://www.nao.ac.jp/faq/" target="_blank" rel="noopener noreferrer">国立天文台＞よくある質問</a><br />
                    </span>
                    <br />
                    <span style={{ marginTop: '0.5rem' }}>
                        <a href="https://ja.wikipedia.org/wiki/%E6%9A%A6%E6%B3%A8" target="_blank" rel="noopener noreferrer">Wikipedia＞暦注</a>
                    </span>
                </div>
            </Alert>

            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>暦注とは</h2>
                <div>
                    暦注（れきちゅう）とは、暦に記載される注釈や記号のことを指します。<br />
                    六曜、二十八宿、十二直や選日・雑節などの様々な暦注が存在し、日々の吉凶を判断したり、結婚式や旅行などの事柄の吉凶の判断、季節や農作業などの時期を表したりします。
                </div>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>新暦とは</h2>
                <div>
                    グレゴリオ暦とも呼ばれ、太陽の運行を基にした暦です。<br />
                    この暦は、ローマ教皇グレゴリウス13世によって1582年に導入されました。<br />
                    新暦の1年は365日で、4年に1度のうるう年を持ち、その際に1日を追加することで太陽年とのずれを調整します。<br />
                    現在、世界の多くの国々で公式に使用されているのはこの新暦（グレゴリオ暦）です。<br />
                    日本では、明治5年（1872年）11月9日に、太政官布告第337号の公布に伴い、使用されています。
                </div>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>旧暦とは</h2>
                <div>
                    太陰暦とも呼ばれ、月の運行を基にした暦です。<br />
                    具体的には、新月から次の新月までの期間を1ヶ月とし、その12ヶ月を1年とします。<br />
                    しかし、太陰暦の1年は約354日であり、太陽暦の1年（約365日）よりも短いため、3年に1度ほど閏月（うるうづき）を挿入して調整します。<br />
                    旧暦は、中国を始めとする多くの東アジアの国々で長い間使用されてきました。<br />
                    日本では、明治5年（1872年）11月9日に、太政官布告第337号により公布され、廃止されました。
                </div>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>ユリウス通日とは</h2>
                <div>
                    ユリウス通日（Julian Day Number, JDN）は、天文学や暦の研究で使用される日数の連続的なカウントです。<br />
                    ユリウス通日は、紀元前4713年1月1日の正午（グリニッジ標準時）を起点として、その日からの経過日数を整数で表します。<br />
                    本来なら小数点以下が存在するが、崇暦では省略している。
                </div>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>六曜とは</h2>
                <div>
                    六の曜があり、旧暦1日に特定の六曜を割り当て、順に巡らせ、日々の吉凶等を判断する。<br />
                    六曜星（ろくようせい）、六輝（ろっき）、宿曜（すくよう）とも言う。<br /><br />
                    吉凶の判断は、以下の通りです。<br />
                    <div>
                        先勝：<span style={{ color: 'blue' }}>午前中は吉</span>、<span style={{ color: 'red' }}>午後は凶</span>
                    </div>
                    <div>
                        友引：<span style={{ color: 'blue' }}>朝は吉</span>、<span style={{ color: 'red' }}>昼は凶</span>、<span style={{ color: 'blue' }}>夕は吉</span>、<span style={{ color: 'red' }}>葬式は慎むべき日</span>
                    </div>
                    <div>
                        先負：<span style={{ color: 'red' }}>午前中は凶</span>、<span style={{ color: 'blue' }}>午後は吉</span>
                    </div>
                    <div>
                        仏滅：<span style={{ color: 'red' }}>何事においても凶</span>
                    </div>
                    <div>
                        大安：<span style={{ color: 'blue' }}>何事においても吉</span>
                    </div>
                    <div>
                        赤口：<span style={{ color: 'red' }}>朝は凶</span>、<span style={{ color: 'blue' }}>昼は吉</span>、<span style={{ color: 'red' }}>夕は凶</span>
                    </div>
                </div>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>干支とは</h2>
                <div>
                    十干（じっかん）と十二支（じゅうにし）がある。<br />
                    十干は天干（てんかん）、十二支は地支（ちし）とも言い、十干と十二支を組み合わせたものを六十干支（ろくじっかんし）という。<br />
                    <br />
                    暦、時間、方位として使用する。六十干支は、甲子から癸亥までの60がある。
                </div>
                <Link
                    component={RouterLink}
                    underline="always"
                    key="the_twenty-eight_constellations"
                    color="primary"
                    to="/annotation/eto"
                    onClick={handleClick}
                >
                    もっと詳しく見る
                </Link>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>二十八宿・二十七宿とは</h2>
                <div>
                    二十八宿とは、天の赤道を東西南北と4分割、さらにそれぞれ7分割の合計28分割し、それぞれを宿とし、星座名をつけたものである。<br />
                    二十七宿とは、白道を27分割し、それぞれを宿としたもの。<br />
                    なお、二十八宿にあって二十七宿にない宿は、牛宿である。<br />
                    日々の吉凶等を判断する。
                </div>
                <Link
                    component={RouterLink}
                    underline="always"
                    key="the_twenty-eight_constellations"
                    color="primary"
                    to="/annotation/28"
                    onClick={handleClick}
                >
                    もっと詳しく見る
                </Link>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>十二直とは</h2>
                <div>
                    中段とも言う。<br />
                    北斗七星の下三星の方位と十二支（方位）を結びつけたもの。日々の吉凶等を判断する。
                </div>
                <Link
                    component={RouterLink}
                    underline="always"
                    key="twelve_words"
                    color="primary"
                    to="/annotation/12"
                    onClick={handleClick}
                >
                    もっと詳しく見る
                </Link>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>二十四節気・七十二候・節月とは</h2>
                <div>
                    二十四節気とは、一年を24分割し、それぞれに節気・季節を表す名称を割り当てたもの。
                </div>
                <div>
                    七十二候とは、其々の二十四節気をさらに3分割（初候、次候、末候）したもので、季節を表す方式のひとつ。<br />
                    読み仮名は、貞享暦（江戸時代）ではなく、略本暦（明治時代）に記載のものです。
                </div>
                <div>
                    節月とは、二十四節気を月に割り当てたもの。
                </div>
                <Link
                    component={RouterLink}
                    underline="always"
                    key="sekki_climates"
                    color="primary"
                    to="/annotation/24sekki"
                    onClick={handleClick}
                >
                    二十四節気を詳しく見る
                </Link><br />
                <Link
                    component={RouterLink}
                    underline="always"
                    key="72_climates"
                    color="primary"
                    to="/annotation/72"
                    onClick={handleClick}
                >
                    二十四節気・七十二候・節月を詳しく見る
                </Link><br />
                <Link
                    component={RouterLink}
                    underline="always"
                    key="24sekki"
                    color="primary"
                    to="/archives/db_24sekki"
                    onClick={handleClick}
                >
                    去年から再来年の二十四節気の日の一覧
                </Link>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>九星とは</h2>
                <div>
                    日九星は干支（甲子）と二十四節気、月九星は年の干支と二十四節気、年九星は遁日と二十四節気で決まる。<br />
                    遁日とは年九星の切替日のこと。<br />
                    陽遁日と陰遁日がある。
                </div>
            </Box>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>五行とは</h2>
                <div>
                    相互作用する5つの概念のこと。<br />
                    木火土金水の五行がある。
                </div>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>選日・雑節とは</h2>
                <div>
                    選日とは、雑節、年中行事等に含まれないものの総称です。
                </div>
                <div>
                    雑節とは、二十四節気等以外の、季節変わりを掴む特別な暦日です。
                </div>
                <Link
                    component={RouterLink}
                    underline="always"
                    key="Selected_days_and_miscellaneous_days"
                    color="primary"
                    to="/annotation/sz"
                    onClick={handleClick}
                >
                    もっと詳しく見る
                </Link>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseInarticle />
            </div>
        </div>
    ];

    /*
    <meta name="description" content="This is an example page" />
    <meta property="og:site_name" content="suureki.net">
    <meta property="og:title" content="柿本実さんの情報【suureki.net | すうれきねっと】">
    <meta property="og:description" content="suureki.netとは、1901年から2100年迄を網羅した万年暦・カレンダー・こよみサイトです。様々な暦の他、著名人の情報や生年月日による吉凶運勢・吉方位・バイオリズム・カバラ数秘術の情報を提供しています。">
    <meta property="og:type" content="website">
    <meta property="og:locale" content="ja_JP">
    <meta property="og:url" content="https://suureki.net/75882/register.php?id=17630317">
    <meta property="og:image" content="https://suureki.net/img/suureki_ogp_1200x630.png">
    <meta property="og:image:width" content="1200">
    <meta property="og:image:height" content="630">
    <meta name="twitter:card" content="summary_large_image">
    <meta name="twitter:url" content="https://suureki.net/">
    <meta name="twitter:image" content="https://suureki.net/img/suureki_ogp_1200x630.png">
    <meta name="twitter:title" content="日々、役立つ暦注を！">
    <meta name="twitter:description" content="万年暦・こよみサイト。二十八宿や十二直、二十四節気/七十二候などの様々な暦注を掲載。">
    <meta name="twitter:site" content="@suureki_cal">
    */

    const pageKey = "Annotationindex";
    const pagePath = pagePathS[pageKey];

    const metaDescription = pageDescription[pageKey];
    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        console.log(pagePath + ':', encryptedUid.substring(0, 3));

        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitle, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitle]);

    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };
    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>

                        {/* ***** メインコンテンツ ******************************************************************* */}

                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                            </h1>
                            <div>{mainContents}</div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
};


const App = () => (
    <AuthProvider>
        <Annotation />
    </AuthProvider>
);

export default App;