// src/pages/Calendar.tsx
// import * as React from 'react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton, styled, Button, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import { MonthLastDay } from '../SuurekiVarMonLastDay';
import {
    WeekName,
    MonName,
    EtoName12,
    EtoName10,
    MonNameRuby,
    Rokki,
    HoridayName,
    HoridayNameS,
    buddhaNameF,
    buddhaNameRuby,
    buddhaName,
    buddhaNameS,
    buddhaNamePlus,
    buddhaColor,
    buddhaDat,
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBannerMobile from '../components/AdSenseBannerMobile'; // 320x100、ラージモバイルバナー をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // 記事内広告 をインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';
// import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');  // 日本語ロケールを設定

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

interface Koyomi {
    date: string;           /* 日付 */
    eto10_month: number;    /* 月干支（暦：10支）： */
    eto12_month: number;    /* 月干支（暦：12支） */
    eto10_year: number;     /* 年干支（暦：10支） */
    eto12_year: number;     /* 年干支（暦：12支） */
    week: string;           /* 曜日 */
    jd: number;             /* ユリウス通日 */
    xxolddate: string;      /* 旧暦 */
    xxrokki: number;        /* 六曜 */
    eto10_day: number;      /* 日干支（10支） */
    eto12_day: number;      /* 日干支（12支） */
    xxgogyo: string;        /* 五行 */
    xxyadohachi: number;    /* 二十八宿 */
    xxyadonana: string;     /* 二十七宿 */
    xxsekki: number;        /* 二十四節気 */
    xxsetuzuki: string;     /* 節月 */
    eto10_month_s: number;  /* 月干支（節：10支） */
    eto12_month_s: number;  /* 月干支（節：12支） */
    eto10_year_s: number;   /* 年干支（節：10支） */
    eto12_year_s: number;   /* 年干支（節：12支） */
    xxzyunityoku: number;   /* 十二直 */
    xxkyuusei: number;      /* 日九星 */
    xxinyo: string;         /* 陰陽 */
    xxsenjitsu: string;     /* 選日 */
    xxzassetsu: string;     /* 雑節 */
    xxnanazyuni: string;    /* 七十二候 */
    voidtime: string;       /* ボイドタイム */
    yylongitudesun: string; /*  */
    yylongitudemoon: string;    /*  */
    yyagemoon: string;      /*  */
    kyuusei_y: number;      /* 年九星 */
    kyuusei_m: number;      /* 月九星 */
    sekki_date_s: string;   /*  */
    sekki_date_e: string;   /*  */
}

interface Buddhafair {
    date: string;           /* 年月日 */
    eto1012: string;        /* 御縁日の該当数字 */
    buddha_dat: string;     /* 御縁日の該当数字 */
    buddha_datJ: string;    /* 御縁日の該当数字 */
}

interface Horiday {
    date: string;           /* 年月日 */
    holiday_dat: string;    /* 祝日の該当数字 */
}

// interface Etcday {
//     date: string;           /* 年月日 */
//     etcday_dat: string;    /* 祝日の該当数字 */
// }


// interface monthdayend {
//     dateym: string;           /* 年月日 */
//     monlastday: string;         /* dateymの最後の日 */
// }

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        background: 'linear-gradient(to right, rgba(231, 244, 255, 0.99),rgba(231, 244, 255, 0.99), rgba(243, 250, 255, 0.99))',
        color: 'black',
        fontSize: theme.typography.pxToRem(16),
        border: '1px solid black',
    },
}));

/* **************************************************************************************************** */
/* **************************************************************************************************** */
/* **************************************************************************************************** */
/* *****                               **************************************************************** */
/* ***** メインコンテンツの作成準備エリア **************************************************************** */
/* *****                               **************************************************************** */
/* **************************************************************************************************** */
/* **************************************************************************************************** */
/* **************************************************************************************************** */


function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}

const BuddhaFair: React.FC = () => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    const { getdata } = useParams();
    // console.log('getdata=' + getdata);

    const today = new Date();
    // const date = '202408'
    let date = '';

    if (typeof getdata !== 'undefined') {

        const dateTmp: string = getdata.replace(/\D/g, ""); // 数字以外をなくす
        date = dateTmp;
        if (parseInt(date, 10) < 190101) {
            date = '190101'
        }
        if (parseInt(date, 10) > 210012) {
            date = '210012'
        }

    } else {
        date = today.getFullYear() + (today.getMonth() + 1).toString().padStart(2, '0');
    }



    let pageTitle: string | null;
    let pageTitleS: string;
    let dateF: string;
    let dateY: string;
    let dateYM: string;
    let dateYMD: string;
    let dateM: number;
    // let monthDayStart: number;
    let monthDayEnd: number;
    let monthDayStartWeek: number;
    // let monthDayStartWeekFastsunday: number;
    let count: number = 0;


    let dateJpNumberA: number;
    let dateJpNumberB: number;
    let dateJpNumberC: number;
    let dateJpNumberD: number;
    let dateJpNumberE: number;

    let dateJpA: string = `明治`;
    let dateJpB: string = `大正`;
    let dateJpC: string = `昭和`;
    let dateJpD: string = `平成`;
    let dateJpE: string = `令和`;

    let dateJpExactA: number = 18680125;
    // let dateJpExactB: number = 19120730;
    // let dateJpExactC: number = 19261225;
    // let dateJpExactD: number = 19890108;
    // let dateJpExactE: number = 20190501;

    let mainContentsA;
    let mainContentsB;
    let mainContentsC;
    let mainContentsD;


    /* *********************************************************************************************** */
    /* ***** 日歴・月暦・年歴         ***************************************************************** */
    /* ***** 各変数挿入               ****************************************************************** */
    /* *********************************************************************************************** */



    // let dateTmp = date;
    // let charColor = '';
    // let charBold = '';

    /*

    if (date.length === 4) {
        dateTmp = `${date}-01-01`;
    } else if (date.length === 6) {
        dateTmp = `${date.substring(0, 4)}-${date.substring(4, 6)}-01`;
    } else if (date.length === 8) {
        dateTmp = `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`;
    }
    */

    ////////////////////////////////////////////////////////////////////////
    //月暦の、各変数挿入
    ////////////////////////////////////////////////////////////////////////

    pageTitle = `${date.substring(0, 4)}年${Number(date.substring(4, 6))}月の仏様御縁日`;
    pageTitleS = `${date.substring(0, 4)}年${Number(date.substring(4, 6))}月`;
    dateF = `${date.substring(0, 4)}-${date.substring(4, 6)}`;
    dateYM = `${date.substring(0, 4)}${date.substring(4, 6)}`;
    dateYMD = `${date.substring(0, 4)}${date.substring(4, 6)}01`;
    dateM = Number(date.substring(4, 6));
    dateY = String(date.substring(0, 4));


    dateJpNumberA = Number(dateY) - 1867; // 和暦の算出：明治
    dateJpNumberB = Number(dateY) - 1911; // 和暦の算出：大正
    dateJpNumberC = Number(dateY) - 1925; // 和暦の算出：昭和
    dateJpNumberD = Number(dateY) - 1988; // 和暦の算出：平成
    dateJpNumberE = Number(dateY) - 2018; // 和暦の算出：令和


    // 和暦の算出：明治
    if (dateJpNumberA === 1) {
        dateJpA = dateJpA + `元年`;
    } else if (dateJpNumberA >= 1 && Number(dateYMD) >= dateJpExactA) {
        dateJpA = dateJpA + `${dateJpNumberA}年/`;
    } else {
        dateJpA = "";
    }
    // 和暦の算出：大正
    if (dateJpNumberB === 1) {
        dateJpB = dateJpB + `元年`;
    } else if (dateJpNumberB >= 1) {
        dateJpB = dateJpB + `${dateJpNumberB}年/`;
    } else {
        dateJpB = "";
    }
    // 和暦の算出：昭和
    if (dateJpNumberC === 1) {
        dateJpC = dateJpC + `元年`;
    } else if (dateJpNumberC >= 1) {
        dateJpC = dateJpC + `${dateJpNumberC}年/`;
    } else {
        dateJpC = "";
    }
    // 和暦の算出：平成
    if (dateJpNumberD === 1) {
        dateJpD = dateJpD + `元年`;
    } else if (dateJpNumberD >= 1) {
        dateJpD = dateJpD + `${dateJpNumberD}年/`;
    } else {
        dateJpD = "";
    }
    // 和暦の算出：令和
    if (dateJpNumberE === 1) {
        dateJpE = dateJpE + `元年`;
    } else if (dateJpNumberE >= 1) {
        dateJpE = dateJpE + `${dateJpNumberE}年`;
    } else {
        dateJpE = "";
    }



    /* *********************************************************************************************** */
    /* ***** ページ名の作成 *************************************************************************** */
    /* *********************************************************************************************** */

    const breadcrumbs = [
        <Link
            component={RouterLink}
            underline="hover"
            key="1"
            color="inherit"
            to="/"
            onClick={handleClick}>
            <IconButton
                aria-label="ホームページへページ移動"
                sx={{
                    margin: '0px 0px 1px 0px',
                    padding: '0px'
                }}>
                <HomeIcon />
            </IconButton>
        </Link>,
        <Link
            component={RouterLink}
            underline="hover"
            key="2"
            color="inherit"
            to="/buddhafair"
            onClick={handleClick}
        >
            仏様御縁日
        </Link>,
        <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
            {pageTitle}
        </Typography>,
    ];

    /* ********************************************************************************************** */
    /* ***** メインコンテンツの作成 ******************************************************************* */
    /* ***** メインコンテンツの作成：前処理 ************************************************************* */
    /* *********************************************************************************************** */

    // 月暦カレンダー表作成のための、1日と月末日の把握
    // monthDayStart = 1;
    monthDayEnd = Number(MonthLastDay[dateYM]);

    // 今日の暦jsonの呼び出し //////////////////////////////////////////////////////////////////////

    const calendar_json_url = `https://db.suureki.net/_db_calendar/calendar_${dateY}.json`;

    const [koyomi_data, setDataKoyomi] = useState<Koyomi[]>([]);
    useEffect(() => {
        fetch(calendar_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(koyomi_data => setDataKoyomi(koyomi_data))
            .catch(error => console.error('Error:', error));
    }, [calendar_json_url]);

    // JSONデータを日付をキーとするオブジェクトに変換します。
    let weekMap: { [date: string]: number } = {};
    let xxrokkiMap: { [date: string]: number } = {};
    let eto10dayMap: { [date: string]: number } = {};
    let eto12dayMap: { [date: string]: number } = {};
    koyomi_data.forEach(koyomi => {
        weekMap[koyomi.date] = Number(koyomi.week);
        xxrokkiMap[koyomi.date] = koyomi.xxrokki;
        eto10dayMap[koyomi.date] = koyomi.eto10_day;
        eto12dayMap[koyomi.date] = koyomi.eto12_day;
    });

    // 今日の御縁日jsonの呼び出し //////////////////////////////////////////////////////////////////////
    /*
    {
        "date": "20240101",
        "eto1012": "0-0",
        "buddha_dat": "19,23,24",
        "buddha_datJ": "甲子：妙見、聖天、大黒"
    },
    {
        "date": "20240103",
        "eto1012": "2-2",
        "buddha_dat": "4,28",
        "buddha_datJ": "丙寅：宝生、毘沙門"
    },
    */

    const buddhafair_json_url = `https://db.suureki.net/_db_buddhafair/buddhafair_${dateY}.json`;

    const [buddhafair_data, setDataBuddhafair] = useState<Buddhafair[]>([]);
    useEffect(() => {
        fetch(buddhafair_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(buddhafair_data => setDataBuddhafair(buddhafair_data))
            .catch(error => console.error('Error:', error));
    }, [buddhafair_json_url]);

    // JSONデータを日付をキーとするオブジェクトに変換します。
    let buddhafairMap: { [date: string]: string } = {};
    buddhafair_data.forEach(buddhafair => {
        buddhafairMap[buddhafair.date] = buddhafair.buddha_dat;
    });


    // 今年の祝日（祭日）jsonの呼び出し //////////////////////////////////////////////////////////////////////

    const horiday_json_url = "https://db.suureki.net/_db_horiday/horiday_all.json";
    // const horiday_json_url = "https://db.suureki.net/_db_horiday/horiday_" + `${dateY}` + ".json";

    const [horiday_data, setDataHoriday] = useState<Horiday[]>([]);
    useEffect(() => {
        fetch(horiday_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(horiday_data => setDataHoriday(horiday_data))
            .catch(error => console.error('Error:', error));
    }, [horiday_json_url]);

    // JSONデータを日付をキーとするオブジェクトに変換します。
    let holidayMap: { [date: string]: string } = {};
    horiday_data.forEach(horiday => {
        holidayMap[horiday.date] = horiday.holiday_dat;
    });


    /* **************************************************************************************************** */
    /* **************************************************************************************************** */
    /* **************************************************************************************************** */
    /* *****                            ******************************************************************* */
    /* ***** メインコンテンツの作成エリア *******************************************************************  }
    /* *****                            ******************************************************************* */
    /* **************************************************************************************************** */
    /* **************************************************************************************************** */
    /* **************************************************************************************************** */



    /* **************************************************************************************************** */
    /* ***** メインコンテンツの作成：月暦 ******************************************************************* */
    /* **************************************************************************************************** */

    let monthDayStartWeekFastsunday: number = 0;
    let prevFastsunday: number = 0;
    let countprevday = prevFastsunday;

    /* *********************************************************************************************** */
    /* ***** 日付変更リンクの作成     ******************************************************************* */
    /* *********************************************************************************************** */

    let PrevNextCalendarLink;

    PrevNextCalendarLink = [
        <div key='prevnextcalendarlinkkeyhr' className='content_prevnextcalendarlink' style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>
                <Link
                    component={RouterLink}
                    underline="hover"
                    key="1"
                    color="primary"
                    to={`/buddhafair/${dayjs(date).add(-1, 'month').format('YYYYMM')}`}
                    onClick={handleClick}
                    style={{ textDecoration: 'none' }}
                >
                    前の月
                </Link>
            </span>
            <span>{dateF}</span>
            <span>
                <Link
                    component={RouterLink}
                    underline="hover"
                    key="1"
                    color="primary"
                    to={`/buddhafair/${dayjs(date).add(1, 'month').format('YYYYMM')}`}
                    onClick={handleClick}
                    style={{ textDecoration: 'none' }}
                >
                    次の月
                </Link>
            </span>
        </div>
    ];

    /* **************************************************************************************************** */
    /* ***** メインコンテンツの作成：月暦：A ***************************************************************** */
    /* **************************************************************************************************** */

    mainContentsA = [
        <div key='maincontentskeya'>
            <Box className='content_subbox'>

                {koyomi_data.filter(koyomi => koyomi.date === dateYMD).map(koyomi => {
                    monthDayStartWeek = Number([koyomi.week]);
                    monthDayStartWeekFastsunday = 0 - monthDayStartWeek;
                    const prevYM = dayjs(dateYM).add(-1, 'month').format('YYYYMM')
                    const prevLastday = Number(dayjs(prevYM + '01').add(1, 'month').subtract(1, 'day').format('DD'));
                    prevFastsunday = prevLastday + monthDayStartWeekFastsunday;
                    return (
                        <Box sx={{ letterSpacing: '0.1em' }} key={koyomi.date}>
                            {/* 月暦：新暦 */}
                            <div className='calendar_info_box'>
                                <span className='calendar_info_row_right'>
                                    <span style={{ color: 'green' }}>{pageTitleS}</span><br />
                                    <span style={{ color: 'green' }}>{dateJpE}、</span>{/* 令和 */}
                                    <HtmlTooltip
                                        leaveTouchDelay={5000}
                                        enterTouchDelay={0}
                                        title={
                                            <React.Fragment>
                                                <span style={{ lineHeight: '1.3rem' }}>
                                                    {MonName[dateM]}（{MonNameRuby[dateM]}）
                                                    <hr />
                                                    {dateM}月の別名
                                                </span>
                                            </React.Fragment>
                                        }
                                        placement='top-start'
                                    >
                                        <span style={{ color: 'green', borderBottom: '1px dotted' }}>
                                            {MonName[dateM]}
                                        </span>
                                    </HtmlTooltip>
                                    <br />
                                    <span style={{ color: 'gray' }}>{dateJpA}</span>{/* 明治 */}
                                    <span style={{ color: 'gray' }}>{dateJpB}</span>{/* 大正 */}
                                    <span style={{ color: 'gray' }}>{dateJpC}</span>{/* 昭和 */}
                                    <span style={{ color: 'gray' }}>{dateJpD}</span>{/* 平成 */}<br />
                                </span>
                                <span style={{ color: 'green' }} className='calendar_info_row_left'>新暦：</span>
                            </div>
                        </Box>
                    )
                })}
            </Box>
        </div>
    ];

    /* **************************************************************************************************** */
    /* ***** メインコンテンツの作成：月暦：B ***************************************************************** */
    /* **************************************************************************************************** */

    count = monthDayStartWeekFastsunday;
    countprevday = prevFastsunday;

    mainContentsB = [
        <div key='maincontentskeyb'>
            <h2 className='content_subtitle'>カレンダー切替</h2>
            <div>
                <Stack spacing={2} direction="row" sx={{ marginTop: '2rem' }}>
                    <Link component={RouterLink} underline="always" key="calendarLinkA" color="primary" to={`/calendar/${date.substring(0, 4)}${date.substring(4, 6)}`} onClick={handleClick}>
                        <Button variant="outlined"><CheckBoxOutlineBlankIcon style={{ marginRight: '0.5rem' }} />暦注</Button>
                    </Link>
                    <Link component={RouterLink} underline="always" key="calendarLinkB" color="primary" to={`/goodday/${date.substring(0, 4)}${date.substring(4, 6)}`} onClick={handleClick}>
                        <Button variant="outlined"><CheckBoxOutlineBlankIcon style={{ marginRight: '0.5rem' }} />吉凶判断</Button>
                    </Link>
                    <Link component={RouterLink} underline="always" key="calendarLinkC" color="primary" to={`/buddhafair/${date.substring(0, 4)}${date.substring(4, 6)}`} onClick={handleClick}>
                        <Button variant="contained"><CheckBoxIcon style={{ marginRight: '0.5rem' }} />仏様御縁日</Button>
                    </Link>
                </Stack>
            </div>
            <h2 className='content_subtitle'>御縁日（ごえんにち）一覧</h2>
            <div>
                <ul style={{ fontSize: 'small' }}>
                    <li style={{ lineHeight: '1.3rem' }}>主な仏様を掲載し、寺院によって御縁日が異なる場合があります。</li>
                    <li style={{ lineHeight: '1.3rem' }}>御縁日一覧の括弧内の干支は、其々の守本尊を表します。</li>
                    <li style={{ lineHeight: '1.3rem' }}>大日如来・薬師如来・普賢菩薩は、寺院によって御縁日が異なります。</li>
                    <li style={{ lineHeight: '1.3rem', color: 'green' }}>其々の御縁日に定期的に寺院にお詣りをし、明示してある御真言を唱えると、仏様との御縁が深まるでしょう。</li>
                </ul>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="buddhafair-content"
                        id="buddhafair-header"
                        sx={{
                            background: 'linear-gradient(to left, #fff,#fff, #eee)', // 右から左にグラデーション
                        }}
                    >
                        御縁日一覧を表示
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            background: 'linear-gradient(to left, #fff,#fff, #eee)', // 右から左にグラデーション
                        }}
                    >
                        <ul>
                            <li style={{ fontSize: 'small' }}>
                                <span style={{ color: 'black' }}>如来</span><br />
                                <span style={{ color: buddhaColor[0] }}>{buddhaNameF[0]}、</span>
                                <span style={{ color: buddhaColor[1] }}>{buddhaNameF[1]}、</span>
                                <span style={{ color: buddhaColor[2] }}>{buddhaNameF[2]}、</span>
                                <span style={{ color: buddhaColor[3] }}>{buddhaNameF[3]}、</span>
                                <span style={{ color: buddhaColor[4] }}>{buddhaNameF[4]}、</span>
                                <span style={{ color: buddhaColor[5] }}>{buddhaNameF[5]}</span>
                                <br />
                                <span style={{ color: 'black' }}>菩薩</span><br />
                                <span style={{ color: buddhaColor[6] }}>{buddhaNameF[6]}、</span>
                                <span style={{ color: buddhaColor[7] }}>{buddhaNameF[7]}、</span>
                                <span style={{ color: buddhaColor[8] }}>{buddhaNameF[8]}、</span>
                                <span style={{ color: buddhaColor[9] }}>{buddhaNameF[9]}、</span>
                                <span style={{ color: buddhaColor[10] }}>{buddhaNameF[10]}、</span>
                                <span style={{ color: buddhaColor[11] }}>{buddhaNameF[11]}、</span>
                                <span style={{ color: buddhaColor[12] }}>{buddhaNameF[12]}、</span>
                                <span style={{ color: buddhaColor[13] }}>{buddhaNameF[13]}、</span>
                                <span style={{ color: buddhaColor[14] }}>{buddhaNameF[14]}、</span>
                                <span style={{ color: buddhaColor[15] }}>{buddhaNameF[15]}、</span>
                                <span style={{ color: buddhaColor[16] }}>{buddhaNameF[16]}、</span>
                                <span style={{ color: buddhaColor[17] }}>{buddhaNameF[17]}、</span>
                                <span style={{ color: buddhaColor[18] }}>{buddhaNameF[18]}、</span>
                                <span style={{ color: buddhaColor[19] }}>{buddhaNameF[19]}</span>
                                <br />
                                <span style={{ color: 'black' }}>明王</span><br />
                                <span style={{ color: buddhaColor[20] }}>{buddhaNameF[20]}、</span>
                                <span style={{ color: buddhaColor[21] }}>{buddhaNameF[21]}</span>
                                <br />
                                <span style={{ color: 'black' }}>天部</span><br />
                                <span style={{ color: buddhaColor[22] }}>{buddhaNameF[22]}、</span>
                                <span style={{ color: buddhaColor[23] }}>{buddhaNameF[23]}、</span>
                                <span style={{ color: buddhaColor[24] }}>{buddhaNameF[24]}、</span>
                                <span style={{ color: buddhaColor[25] }}>{buddhaNameF[25]}、</span>
                                <span style={{ color: buddhaColor[26] }}>{buddhaNameF[26]}、</span>
                                <span style={{ color: buddhaColor[27] }}>{buddhaNameF[27]}、</span>
                                <span style={{ color: buddhaColor[28] }}>{buddhaNameF[28]}</span>
                            </li>
                        </ul>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <h2 className='content_subtitle'>仏様の御縁日カレンダー</h2>
            <Box className='content_subbox'>

                {PrevNextCalendarLink}
                {/* 年月の表示 */}
                <Box sx={{
                    borderBottom: '5px solid white',
                    padding: '0px 0px 0px 0px',
                    backgroundColor: '#eee',
                    width: '100%',
                    display: 'grid',
                    gridAutoFlow: 'row',
                    gridTemplateColumns: 'repeat(1, 1fr)',
                    gap: 0,
                    minWidth: '330px',
                }}>
                    <Box sx={{ textAlign: 'center', color: '#000', margin: '10px' }}>{date.substring(0, 4)}年{Number(date.substring(4, 6))}月</Box>
                </Box>

                {/* 日月火水木金土の表示 */}
                <Box sx={{
                    borderBottom: '5px solid white',
                    padding: '0px 0px 0px 0px',
                    backgroundColor: '#eee',
                    width: '100%',
                    display: 'grid',
                    gridAutoFlow: 'row',
                    gridTemplateColumns: 'repeat(7, 1fr)',
                    gap: 0,
                    minWidth: '330px',
                }}>
                    <Box sx={{ textAlign: 'right', color: '#f00', margin: '10px' }}>{WeekName[0]}</Box>
                    <Box sx={{ textAlign: 'right', color: '#000', margin: '10px' }}>{WeekName[1]}</Box>
                    <Box sx={{ textAlign: 'right', color: '#000', margin: '10px' }}>{WeekName[2]}</Box>
                    <Box sx={{ textAlign: 'right', color: '#000', margin: '10px' }}>{WeekName[3]}</Box>
                    <Box sx={{ textAlign: 'right', color: '#000', margin: '10px' }}>{WeekName[4]}</Box>
                    <Box sx={{ textAlign: 'right', color: '#000', margin: '10px' }}>{WeekName[5]}</Box>
                    <Box sx={{ textAlign: 'right', color: '#00F', margin: '10px' }}>{WeekName[6]}</Box>
                </Box>

                {
                    Array.from({ length: 6 }, (_, i) => (
                        <Box key={`outer-${i}`} sx={{
                            borderBottom: '5px solid white',
                            padding: '0px 0px 0px 0px',
                            width: '100%',
                            display: 'grid',
                            gridAutoFlow: 'row',
                            gridTemplateColumns: 'repeat(7, 1fr)',
                            gap: 0,
                            minWidth: '330px',
                        }} className='calendar_bgcolor'>
                            {Array.from({ length: 7 }, (_, ii) => {
                                count++;
                                if (count >= 1 && count <= monthDayEnd) {
                                    let tmpDateYMD = dateYM + String(count).padStart(2, '0');
                                    let thisDayHoriday = holidayMap[tmpDateYMD];
                                    let weekcolor;

                                    if (thisDayHoriday || ii === 0) { weekcolor = `red`; } else if (ii === 6) { weekcolor = `blue`; } else { weekcolor = `black`; }

                                    let tmpDateYMDJ = tmpDateYMD.slice(0, 4) + "年" + parseInt(tmpDateYMD.slice(4, 6), 10) + "月" + parseInt(tmpDateYMD.slice(6, 8), 10) + "日（" + WeekName[ii] + "）";

                                    return (<Box key={`inner-${ii}`} sx={{ textAlign: 'right', height: 'auto', margin: '10px 10px 10px 0px' }}>
                                        {/* 日にち */}
                                        <div style={{ fontSize: '1.5rem', textAlign: 'right', color: weekcolor, whiteSpace: 'nowrap', marginBottom: '6px' }}>
                                            {count}
                                        </div>
                                        {/* 六曜 */}
                                        <div style={{ fontSize: '0.9rem', lineHeight: '0.9rem', color: '#666', whiteSpace: 'nowrap' }}>
                                            {Rokki[xxrokkiMap[tmpDateYMD]]}
                                        </div>
                                        {/* 干支 */}
                                        <div style={{ fontSize: '0.9rem', lineHeight: '0.9rem', color: '#666', whiteSpace: 'nowrap' }}>
                                            {EtoName10[eto10dayMap[tmpDateYMD]]}
                                            {EtoName12[eto12dayMap[tmpDateYMD]]}
                                        </div>
                                        {/* 祝日（祭日） */}
                                        <div style={{ marginBottom: '0.4rem' }}>
                                            <HtmlTooltip
                                                leaveTouchDelay={5000}
                                                enterTouchDelay={0}
                                                title={
                                                    <React.Fragment>
                                                        {tmpDateYMDJ}
                                                        <hr />
                                                        {HoridayName[thisDayHoriday]}
                                                    </React.Fragment>
                                                }
                                                placement='top-start'
                                            >
                                                <span style={{ fontSize: '0.9rem', lineHeight: '1rem', color: 'red', whiteSpace: 'nowrap', borderBottom: '1px dotted' }}>
                                                    {HoridayNameS[thisDayHoriday]}
                                                </span>
                                            </HtmlTooltip>
                                        </div>

                                        {/* 御縁日 */}
                                        {buddhafairMap[tmpDateYMD] && (
                                            <div>
                                                {buddhafairMap[tmpDateYMD].split(',').filter(Boolean).map((num, index) => (
                                                    <HtmlTooltip
                                                        key={index}
                                                        leaveTouchDelay={5000}
                                                        enterTouchDelay={0}
                                                        title={
                                                            <React.Fragment>
                                                                {tmpDateYMDJ}
                                                                <hr />
                                                                {buddhaNameF[Number(num.trim())]}<br />
                                                                {buddhaNameRuby[Number(num.trim())]}
                                                                {buddhaNamePlus[Number(num.trim())] && <hr />}
                                                                {buddhaNamePlus[Number(num.trim())]}
                                                            </React.Fragment>
                                                        }
                                                        placement='top-start'
                                                    >
                                                        <div style={{ fontSize: '0.9rem', lineHeight: '0.9rem', color: buddhaColor[Number(num.trim())], whiteSpace: 'nowrap', marginBottom: '0.6rem' }}>
                                                            {buddhaNameS[Number(num.trim())]}
                                                        </div>
                                                    </HtmlTooltip>
                                                ))}
                                            </div>
                                        )}

                                    </Box>);
                                } else if (count > monthDayEnd) {
                                    let countnextmonth = count - monthDayEnd;
                                    return (<Box key={`inner-${ii}`} sx={{ textAlign: 'right', color: 'gray', height: '90px', fontSize: '1.0rem', margin: '10px 10px 10px 0px' }}>{countnextmonth}</Box>);
                                } else {
                                    countprevday = countprevday + 1;
                                    return (<Box key={`inner-${ii}`} sx={{ textAlign: 'right', color: 'gray', height: '90px', fontSize: '1.0rem', margin: '10px 10px 10px 0px' }}>{countprevday}</Box>);
                                }
                            })}

                        </Box>

                    ))
                }
                {PrevNextCalendarLink}
            </Box>
        </div>
    ];

    /*
                                        <br /><span style={{ fontSize: '0.9rem', lineHeight: '0.9rem', color: 'blue', whiteSpace: 'nowrap' }}>{buddhaNameS[BuddhafairDetail[count.toString()]]}</span>

    */

    /* **************************************************************************************************** */
    /* ***** メインコンテンツの作成：月暦：D ***************************************************************** */
    /* **************************************************************************************************** */

    mainContentsD = [
        <div key='maincontentskeyd'>
        </div>
    ];




    /* **************************************************************************************************** */
    /* **************************************************************************************************** */
    /* **************************************************************************************************** */
    /* *****                            ******************************************************************* */
    /* ***** returnエリア               ******************************************************************* */
    /* *****                            ******************************************************************* */
    /* **************************************************************************************************** */
    /* **************************************************************************************************** */
    /* **************************************************************************************************** */


    let etcPageLink;

    etcPageLink = [
        <div key="etcPageLink">
            <div style={{ marginLeft: '1rem' }}>
                <Link
                    component={RouterLink}
                    underline="hover"
                    key="2"
                    color="primary"
                    to={`/calendar/${dateY}`}
                    onClick={handleClick}
                >
                    {dateY}年の年暦
                </Link>
            </div>
            <div style={{ marginLeft: '1rem' }}>
                <Link
                    component={RouterLink}
                    underline="hover"
                    key="2"
                    color="primary"
                    to={`/aspect/${dateY}`}
                    onClick={handleClick}
                >
                    {dateY}年盤の座相
                </Link>
            </div>
        </div>
    ];

    /*
    <meta name="description" content="This is an example page" />
    <meta property="og:site_name" content="suureki.net">
    <meta property="og:title" content="柿本実さんの情報【suureki.net | すうれきねっと】">
    <meta property="og:description" content="suureki.netとは、1901年から2100年迄を網羅した万年暦・カレンダー・こよみサイトです。様々な暦の他、著名人の情報や生年月日による吉凶運勢・吉方位・バイオリズム・カバラ数秘術の情報を提供しています。">
    <meta property="og:type" content="website">
    <meta property="og:locale" content="ja_JP">
    <meta property="og:url" content="https://suureki.net/75882/register.php?id=17630317">
    <meta property="og:image" content="https://suureki.net/img/suureki_ogp_1200x630.png">
    <meta property="og:image:width" content="1200">
    <meta property="og:image:height" content="630">
    <meta name="twitter:card" content="summary_large_image">
    <meta name="twitter:url" content="https://suureki.net/">
    <meta name="twitter:image" content="https://suureki.net/img/suureki_ogp_1200x630.png">
    <meta name="twitter:title" content="日々、役立つ暦注を！">
    <meta name="twitter:description" content="万年暦・こよみサイト。二十八宿や十二直、二十四節気/七十二候などの様々な暦注を掲載。">
    <meta name="twitter:site" content="@suureki_cal">
    */

    const pageKey = "BuddhaFair";
    const pagePath = pagePathS[pageKey] + dateYM;


    const metaDescription = dateY + '年' + dateM + '月' + pageDescription[pageKey];
    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    const pageTitleTmp = pageTitle; // title: pageTitle にすると、何故かエラーになるので、一旦変数に格納

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        console.log(pagePath + ':', encryptedUid.substring(0, 3));

        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitleTmp, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitle]);


    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };

    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>

                        {/* ***** メインコンテンツ ******************************************************************* */}
                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                                <span></span>
                            </h1>
                            <div>{mainContentsA}</div>
                            <div>{mainContentsB}</div>
                            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                <AdSenseBannerMobile />
                            </div>
                            <div>{mainContentsC}</div>
                            <div>{mainContentsD}</div>
                            <Box className='content_subbox_txt'>
                                <h2 className='content_subtitle'>他ページへのリンク</h2>
                                {etcPageLink}
                            </Box>
                            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                <AdSenseInarticle />
                            </div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
};

const App = () => (
    <AuthProvider>
        <BuddhaFair />
    </AuthProvider>
);

export default App;