// src/pages/Archives.tsx

// import * as React from 'react';
import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBannerMobile from '../components/AdSenseBannerMobile'; // 320x100、ラージモバイルバナー をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // 記事内広告 をインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}
const pageTitle = `情報/DB`
const pageTitleS = `情報/DB` // ページトップのナビゲーションリンク

const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton
            aria-label="ホームページへページ移動"
            sx={{
                margin: '0px 0px 1px 0px',
                padding: '0px'
            }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];


const Archives: React.FC = () => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    const mainContents = [
        <div key='maincontentskey'>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>和暦の日</h2>
                <div>明治時代の期間：</div>
                <ul>
                    <li className='inside-list-style'>明治元年（1868年）1月25日以降</li>
                    <li className='inside-list-style'>明治45年（1912年）7月29日迄</li>
                </ul>
                <div>大正時代の期間：</div>
                <ul>
                    <li className='inside-list-style'>大正元年（1912年）7月30日以降</li>
                    <li className='inside-list-style'>大正15年（1926年）12月24日迄</li>
                </ul>
                <div>昭和時代の期間：</div>
                <ul>
                    <li className='inside-list-style'>昭和元年（1926年）12月25日以降</li>
                    <li className='inside-list-style'>昭和64年（1989年）1月7日迄</li>
                </ul>
                <div>平成時代の期間：</div>
                <ul>
                    <li className='inside-list-style'>平成元年（1989年）1月8日以降</li>
                    <li className='inside-list-style'>平成31年（2019年）4月30日迄</li>
                </ul>
                <div>令和時代の期間：</div>
                <ul>
                    <li className='inside-list-style'>令和元年（2019年）5月1日以降</li>
                </ul>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>祝日/祭日一覧</h2>
                <div style={{ marginBottom: '1rem' }}>国民の祝日に関する法律（通称・祝日法）が施行された1948年からの祝日一覧です。</div>
                <Link
                    component={RouterLink}
                    underline="always"
                    key="db_horiday"
                    color="primary"
                    to="/archives/db_horiday"
                    onClick={handleClick}
                >
                    去年~来年迄
                </Link><br />
                <Link component={RouterLink} underline="always" key="db_horiday_1941" color="primary" to="/archives/db_horiday/1941" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1949~1950年迄</Link>
                <Link component={RouterLink} underline="always" key="db_horiday_1951" color="primary" to="/archives/db_horiday/1951" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1951~1960年迄</Link>
                <Link component={RouterLink} underline="always" key="db_horiday_1961" color="primary" to="/archives/db_horiday/1961" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1961~1970年迄</Link>
                <Link component={RouterLink} underline="always" key="db_horiday_1971" color="primary" to="/archives/db_horiday/1971" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1971~1980年迄</Link>
                <Link component={RouterLink} underline="always" key="db_horiday_1981" color="primary" to="/archives/db_horiday/1981" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1981~1990年迄</Link>
                <Link component={RouterLink} underline="always" key="db_horiday_1991" color="primary" to="/archives/db_horiday/1991" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1991~2000年迄</Link>
                <Link component={RouterLink} underline="always" key="db_horiday_2001" color="primary" to="/archives/db_horiday/2001" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>2001~2010年迄</Link>
                <Link component={RouterLink} underline="always" key="db_horiday_2011" color="primary" to="/archives/db_horiday/2011" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>2011~2020年迄</Link>
                <Link component={RouterLink} underline="always" key="db_horiday_2021" color="primary" to="/archives/db_horiday/2021" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>2021~2030年迄</Link>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>祝日ではない日と風習の一覧</h2>
                <div style={{ marginBottom: '1rem' }}>2017年以降の、祝日ではない日と風習の一覧です。</div>
                <Link
                    component={RouterLink}
                    underline="always"
                    key="db_etcday"
                    color="primary"
                    to="/archives/db_etcday"
                    onClick={handleClick}
                >
                    ページを見る
                </Link>
                <div style={{ marginTop: '1rem' }}>毎年の算出方法：</div>
                <ul>
                    <li className='inside-list-style'>七草の節句：1月7日</li>
                    <li className='inside-list-style'>バレンタインデー：2月14日</li>
                    <li className='inside-list-style'>ひなまつり、桃の節句：3月3日</li>
                    <li className='inside-list-style'>ホワイトデー：3月14日</li>
                    <li className='inside-list-style'>エイプリルフール：4月1日</li>
                    <li className='inside-list-style'>メーデー：5月1日</li>
                    <li className='inside-list-style'>端午の節句：5月5日</li>
                    <li className='inside-list-style'>七夕：7月7日</li>
                    <li className='inside-list-style'>母の日：5月第2日曜日</li>
                    <li className='inside-list-style'>父の日：6月第3日曜日</li>
                    <li className='inside-list-style'>菊の節句：9月9日</li>
                    <li className='inside-list-style'>ハロウィン：10月31日</li>
                    <li className='inside-list-style'>クリスマスイヴ：12月24日</li>
                    <li className='inside-list-style'>クリスマス：12月25日</li>
                </ul>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>年干支と年九星の一覧</h2>
                <div style={{ marginBottom: '1rem' }}>1901年から2100年迄の200年間の、年干支と年九星です。</div>
                <Link
                    component={RouterLink}
                    underline="always"
                    key="db_rekigetsu"
                    color="primary"
                    to="/archives/db_rekigetsu"
                    onClick={handleClick}
                >
                    ページを見る
                </Link>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>満月と新月の日</h2>
                <div style={{ marginBottom: '1rem' }}>1901年から2100年迄の200年間の、朔（新月）と望（満月）の日の一覧です。</div>
                <Link
                    component={RouterLink}
                    underline="always"
                    key="db_moon"
                    color="primary"
                    to="/archives/db_moon"
                    onClick={handleClick}
                >
                    半年前~半年後迄
                </Link><br />
                <Link component={RouterLink} underline="always" key="db_moon_1901" color="primary" to="/archives/db_moon/1901" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1901~1910年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_1911" color="primary" to="/archives/db_moon/1911" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1911~1920年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_1921" color="primary" to="/archives/db_moon/1921" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1921~1930年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_1931" color="primary" to="/archives/db_moon/1931" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1931~1940年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_1941" color="primary" to="/archives/db_moon/1941" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1941~1950年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_1951" color="primary" to="/archives/db_moon/1951" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1951~1960年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_1961" color="primary" to="/archives/db_moon/1961" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1961~1970年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_1971" color="primary" to="/archives/db_moon/1971" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1971~1980年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_1981" color="primary" to="/archives/db_moon/1981" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1981~1990年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_1991" color="primary" to="/archives/db_moon/1991" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1991~2000年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_2001" color="primary" to="/archives/db_moon/2001" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>2001~2010年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_2011" color="primary" to="/archives/db_moon/2011" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>2011~2020年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_2021" color="primary" to="/archives/db_moon/2021" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>2021~2030年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_2031" color="primary" to="/archives/db_moon/2031" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>2031~2040年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_2041" color="primary" to="/archives/db_moon/2041" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>2041~2050年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_2051" color="primary" to="/archives/db_moon/2051" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>2051~2060年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_2061" color="primary" to="/archives/db_moon/2061" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>2061~2070年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_2071" color="primary" to="/archives/db_moon/2071" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>2071~2080年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_2081" color="primary" to="/archives/db_moon/2081" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>2081~2090年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_2091" color="primary" to="/archives/db_moon/2091" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>2091~2100年迄</Link>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>満年齢早見表（満年齢の年齢早見表）</h2>
                <div style={{ marginBottom: '1rem' }}>今日現在の、満年齢の年齢と干支の早見表です。</div>
                <Link
                    component={RouterLink}
                    underline="always"
                    key="db_koyomihenkan"
                    color="primary"
                    to="/archives/db_koyomihenkan"
                    onClick={handleClick}
                >
                    ページを見る
                </Link>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>数え年年齢早見表（数え年の年齢早見表）</h2>
                <div style={{ marginBottom: '1rem' }}>今日現在の、数え年の年齢と早見表です。</div>
                <Link
                    component={RouterLink}
                    underline="always"
                    key="db_countingage"
                    color="primary"
                    to="/archives/db_countingage"
                    onClick={handleClick}
                >
                    ページを見る
                </Link>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>西暦・和暦変換表</h2>
                <div style={{ marginBottom: '1rem' }}>西暦と和暦、各年の干支と九星の早見表です。</div>
                <Link
                    component={RouterLink}
                    underline="always"
                    key="db_adjpcalendar"
                    color="primary"
                    to="/archives/db_adjpcalendar"
                    onClick={handleClick}
                >
                    ページを見る
                </Link>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>二十四節気の日</h2>
                <div style={{ marginBottom: '1rem' }}>去年から再来年の4年間の二十四節気の日の一覧です。</div>
                <Link
                    component={RouterLink}
                    underline="always"
                    key="db_24sekki"
                    color="primary"
                    to="/archives/db_24sekki"
                    onClick={handleClick}
                >
                    ページを見る
                </Link>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>12星座と13星座の星座表</h2>
                <div style={{ marginBottom: '1rem' }}>誕生日からの12星座と13星座の星座の一覧です。</div>
                <Link
                    component={RouterLink}
                    underline="always"
                    key="db_zodiacsigns"
                    color="primary"
                    to="/archives/db_zodiacsigns"
                    onClick={handleClick}
                >
                    ページを見る
                </Link>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>学校入学/卒業年表</h2>
                <div style={{ marginBottom: '1rem' }}>誕生日からの小学校/中学校/高等学校/大学年月の入学と卒業年月の一覧です。</div>
                <Link
                    component={RouterLink}
                    underline="always"
                    key="db_school_eg"
                    color="primary"
                    to="/archives/db_school_eg"
                    onClick={handleClick}
                >
                    ページを見る
                </Link>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseInarticle />
            </div>
        </div>
    ];

    /*
    <meta name="description" content="This is an example page" />
    <meta property="og:site_name" content="suureki.net">
    <meta property="og:title" content="柿本実さんの情報【suureki.net | すうれきねっと】">
    <meta property="og:description" content="suureki.netとは、1901年から2100年迄を網羅した万年暦・カレンダー・こよみサイトです。様々な暦の他、著名人の情報や生年月日による吉凶運勢・吉方位・バイオリズム・カバラ数秘術の情報を提供しています。">
    <meta property="og:type" content="website">
    <meta property="og:locale" content="ja_JP">
    <meta property="og:url" content="https://suureki.net/75882/register.php?id=17630317">
    <meta property="og:image" content="https://suureki.net/img/suureki_ogp_1200x630.png">
    <meta property="og:image:width" content="1200">
    <meta property="og:image:height" content="630">
    <meta name="twitter:card" content="summary_large_image">
    <meta name="twitter:url" content="https://suureki.net/">
    <meta name="twitter:image" content="https://suureki.net/img/suureki_ogp_1200x630.png">
    <meta name="twitter:title" content="日々、役立つ暦注を！">
    <meta name="twitter:description" content="万年暦・こよみサイト。二十八宿や十二直、二十四節気/七十二候などの様々な暦注を掲載。">
    <meta name="twitter:site" content="@suureki_cal">
    */

    const pageKey = "ArchivesIndex";
    const pagePath = pagePathS[pageKey];

    const metaDescription = pageDescription[pageKey];
    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        console.log(pagePath + ':', encryptedUid.substring(0, 3));

        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitle, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitle]);


    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };

    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>

                        {/* ***** メインコンテンツ ******************************************************************* */}

                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                            </h1>
                            <div>{mainContents}</div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
};


const App = () => (
    <AuthProvider>
        <Archives />
    </AuthProvider>
);

export default App;