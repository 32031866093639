// import * as React from 'react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton, styled, Button, Alert, AlertTitle } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    WeekName,
    YadohachiEvent,
    YadohachiEventName,
    YadohachiS,
    MonName,
    MonNameRuby,
    Rokki,
    ZyunityokuS,
    ZyunityokuEvent,
    ZyunityokuEventName,
    HoridayName,
    HoridayNameS,
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBannerMobile from '../components/AdSenseBannerMobile'; // 320x100、ラージモバイルバナー をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // 記事内広告 をインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';
import { MonthLastDay } from '../SuurekiVarMonLastDay';
// import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');  // 日本語ロケールを設定

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

interface Koyomi {
    date: string;           /* 日付 */
    eto10_month: number;    /* 月干支（暦：10支）： */
    eto12_month: number;    /* 月干支（暦：12支） */
    eto10_year: number;     /* 年干支（暦：10支） */
    eto12_year: number;     /* 年干支（暦：12支） */
    week: string;           /* 曜日 */
    jd: number;             /* ユリウス通日 */
    xxolddate: string;      /* 旧暦 */
    xxrokki: number;        /* 六曜 */
    eto10_day: number;      /* 日干支（10支） */
    eto12_day: number;      /* 日干支（12支） */
    xxgogyo: string;        /* 五行 */
    xxyadohachi: number;    /* 二十八宿 */
    xxyadonana: string;     /* 二十七宿 */
    xxsekki: number;        /* 二十四節気 */
    xxsetuzuki: string;     /* 節月 */
    eto10_month_s: number;  /* 月干支（節：10支） */
    eto12_month_s: number;  /* 月干支（節：12支） */
    eto10_year_s: number;   /* 年干支（節：10支） */
    eto12_year_s: number;   /* 年干支（節：12支） */
    xxzyunityoku: number;   /* 十二直 */
    xxkyuusei: number;      /* 日九星 */
    xxinyo: string;         /* 陰陽 */
    xxsenjitsu: string;     /* 選日 */
    xxzassetsu: string;     /* 雑節 */
    xxnanazyuni: string;    /* 七十二候 */
    voidtime: string;       /* ボイドタイム */
    yylongitudesun: string; /*  */
    yylongitudemoon: string;    /*  */
    yyagemoon: string;      /*  */
    kyuusei_y: number;      /* 年九星 */
    kyuusei_m: number;      /* 月九星 */
    sekki_date_s: string;   /*  */
    sekki_date_e: string;   /*  */
}


interface Horiday {
    date: string;           /* 年月日 */
    holiday_dat: string;    /* 祝日の該当数字 */
}

// interface Etcday {
//     date: string;           /* 年月日 */
//     etcday_dat: string;    /* 祝日の該当数字 */
// }


// interface monthdayend {
//     dateym: string;           /* 年月日 */
//     monlastday: string;         /* dateymの最後の日 */
// }

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        background: 'linear-gradient(to right, rgba(231, 244, 255, 0.99),rgba(231, 244, 255, 0.99), rgba(243, 250, 255, 0.99))',
        color: 'black',
        fontSize: theme.typography.pxToRem(16),
        border: '1px solid black',
    },
}));

/* **************************************************************************************************** */
/* **************************************************************************************************** */
/* **************************************************************************************************** */
/* *****                               **************************************************************** */
/* ***** メインコンテンツの作成準備エリア **************************************************************** */
/* *****                               **************************************************************** */
/* **************************************************************************************************** */
/* **************************************************************************************************** */
/* **************************************************************************************************** */


function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}

const Goodday: React.FC = () => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    const { getdata } = useParams();
    // console.log('getdata=' + getdata);

    const today = new Date();
    // const date = '202408'
    let date = '';

    if (typeof getdata !== 'undefined') {

        const dateTmp: string = getdata.replace(/\D/g, ""); // 数字以外をなくす
        date = dateTmp;
        if (parseInt(date, 10) < 190101) {
            date = '190101'
        }
        if (parseInt(date, 10) > 210012) {
            date = '210012'
        }

    } else {
        date = today.getFullYear() + (today.getMonth() + 1).toString().padStart(2, '0');
    }



    let pageTitle: string | null;
    let pageTitleS: string;
    let dateF: string;
    let dateY: string;
    let dateYM: string;
    let dateYMD: string;
    let dateM: number;
    // let monthDayStart: number;
    let monthDayEnd: number;
    let monthDayStartWeek: number;
    // let monthDayStartWeekFastsunday: number;
    let count: number = 0;


    let dateJpNumberA: number;
    let dateJpNumberB: number;
    let dateJpNumberC: number;
    let dateJpNumberD: number;
    let dateJpNumberE: number;

    let dateJpA: string = `明治`;
    let dateJpB: string = `大正`;
    let dateJpC: string = `昭和`;
    let dateJpD: string = `平成`;
    let dateJpE: string = `令和`;

    let dateJpExactA: number = 18680125;
    // let dateJpExactB: number = 19120730;
    // let dateJpExactC: number = 19261225;
    // let dateJpExactD: number = 19890108;
    // let dateJpExactE: number = 20190501;

    let mainContentsA;
    let mainContentsB;
    let mainContentsC;
    let mainContentsD;


    /* *********************************************************************************************** *}
    /* ***** 日歴・月暦・年歴         ***************************************************************** */
    /* ***** 各変数挿入               ****************************************************************** */
    /* *********************************************************************************************** */



    // let dateTmp = date;
    let charColor = '';
    let charBold = '';

    // if (date.length === 4) {
    //     dateTmp = `${date}-01-01`;
    // } else if (date.length === 6) {
    //     dateTmp = `${date.substring(0, 4)}-${date.substring(4, 6)}-01`;
    // } else if (date.length === 8) {
    //     dateTmp = `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`;
    // }

    ////////////////////////////////////////////////////////////////////////
    //月暦の、各変数挿入
    ////////////////////////////////////////////////////////////////////////

    pageTitle = `${date.substring(0, 4)}年${Number(date.substring(4, 6))}月の吉凶カレンダー`;
    pageTitleS = `${date.substring(0, 4)}年${Number(date.substring(4, 6))}月`;
    dateF = `${date.substring(0, 4)}-${date.substring(4, 6)}`;
    dateYM = `${date.substring(0, 4)}${date.substring(4, 6)}`;
    dateYMD = `${date.substring(0, 4)}${date.substring(4, 6)}01`;
    dateM = Number(date.substring(4, 6));
    dateY = String(date.substring(0, 4));


    dateJpNumberA = Number(dateY) - 1867; // 和暦の算出：明治
    dateJpNumberB = Number(dateY) - 1911; // 和暦の算出：大正
    dateJpNumberC = Number(dateY) - 1925; // 和暦の算出：昭和
    dateJpNumberD = Number(dateY) - 1988; // 和暦の算出：平成
    dateJpNumberE = Number(dateY) - 2018; // 和暦の算出：令和


    // 和暦の算出：明治
    if (dateJpNumberA === 1) {
        dateJpA = dateJpA + `元年`;
    } else if (dateJpNumberA >= 1 && Number(dateYMD) >= dateJpExactA) {
        dateJpA = dateJpA + `${dateJpNumberA}年/`;
    } else {
        dateJpA = "";
    }
    // 和暦の算出：大正
    if (dateJpNumberB === 1) {
        dateJpB = dateJpB + `元年`;
    } else if (dateJpNumberB >= 1) {
        dateJpB = dateJpB + `${dateJpNumberB}年/`;
    } else {
        dateJpB = "";
    }
    // 和暦の算出：昭和
    if (dateJpNumberC === 1) {
        dateJpC = dateJpC + `元年`;
    } else if (dateJpNumberC >= 1) {
        dateJpC = dateJpC + `${dateJpNumberC}年/`;
    } else {
        dateJpC = "";
    }
    // 和暦の算出：平成
    if (dateJpNumberD === 1) {
        dateJpD = dateJpD + `元年`;
    } else if (dateJpNumberD >= 1) {
        dateJpD = dateJpD + `${dateJpNumberD}年/`;
    } else {
        dateJpD = "";
    }
    // 和暦の算出：令和
    if (dateJpNumberE === 1) {
        dateJpE = dateJpE + `元年`;
    } else if (dateJpNumberE >= 1) {
        dateJpE = dateJpE + `${dateJpNumberE}年`;
    } else {
        dateJpE = "";
    }



    /* *********************************************************************************************** */
    /* ***** ページ名の作成 *************************************************************************** */
    /* *********************************************************************************************** */

    const breadcrumbs = [
        <Link
            component={RouterLink}
            underline="hover"
            key="1"
            color="inherit"
            to="/"
            onClick={handleClick}>
            <IconButton
                aria-label="ホームページへページ移動"
                sx={{
                    margin: '0px 0px 1px 0px',
                    padding: '0px'
                }}>
                <HomeIcon />
            </IconButton>
        </Link>,
        <Link
            component={RouterLink}
            underline="hover"
            key="2"
            color="inherit"
            to="/goodday"
            onClick={handleClick}
        >
            吉凶判断
        </Link>,
        <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
            {pageTitle}
        </Typography>,
    ];

    /* ********************************************************************************************** */
    /* ***** メインコンテンツの作成 ******************************************************************* */
    /* ***** メインコンテンツの作成：前処理 ************************************************************* */
    /* *********************************************************************************************** */

    // 月暦カレンダー表作成のための、1日と月末日の把握
    // monthDayStart = 1;
    monthDayEnd = Number(MonthLastDay[dateYM]);

    // 今日の暦jsonの呼び出し //////////////////////////////////////////////////////////////////////

    const calendar_json_url = `https://db.suureki.net/_db_calendar/calendar_${dateY}.json`;

    const [koyomi_data, setDataKoyomi] = useState<Koyomi[]>([]);
    useEffect(() => {
        fetch(calendar_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(koyomi_data => setDataKoyomi(koyomi_data))
            .catch(error => console.error('Error:', error));
    }, [calendar_json_url]);

    // JSONデータを日付をキーとするオブジェクトに変換します。
    let weekMap: { [date: string]: number } = {};
    let xxrokkiMap: { [date: string]: number } = {};
    let xxyadohachiMap: { [date: string]: number } = {};
    let xxzyunityokuMap: { [date: string]: number } = {};
    koyomi_data.forEach(koyomi => {
        weekMap[koyomi.date] = Number(koyomi.week);
        xxrokkiMap[koyomi.date] = koyomi.xxrokki;
        xxyadohachiMap[koyomi.date] = koyomi.xxyadohachi;
        xxzyunityokuMap[koyomi.date] = koyomi.xxzyunityoku;
    });


    // 今年の祝日（祭日）jsonの呼び出し //////////////////////////////////////////////////////////////////////

    const horiday_json_url = "https://db.suureki.net/_db_horiday/horiday_all.json";
    // const horiday_json_url = "https://db.suureki.net/_db_horiday/horiday_" + `${dateY}` + ".json";

    const [horiday_data, setDataHoriday] = useState<Horiday[]>([]);
    useEffect(() => {
        fetch(horiday_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(horiday_data => setDataHoriday(horiday_data))
            .catch(error => console.error('Error:', error));
    }, [horiday_json_url]);

    // JSONデータを日付をキーとするオブジェクトに変換します。
    let holidayMap: { [date: string]: string } = {};
    horiday_data.forEach(horiday => {
        holidayMap[horiday.date] = horiday.holiday_dat;
    });


    /* **************************************************************************************************** */
    /* **************************************************************************************************** */
    /* **************************************************************************************************** */
    /* *****                            ******************************************************************* */
    /* ***** メインコンテンツの作成エリア ******************************************************************* */
    /* *****                            ******************************************************************* */
    /* **************************************************************************************************** */
    /* **************************************************************************************************** */
    /* **************************************************************************************************** */



    /* **************************************************************************************************** */
    /* ***** メインコンテンツの作成：月暦 ******************************************************************* */
    /* **************************************************************************************************** */

    let monthDayStartWeekFastsunday: number = 0;
    let prevFastsunday: number = 0;
    let countprevday = prevFastsunday;

    /* *********************************************************************************************** */
    /* ***** 日付変更リンクの作成     ******************************************************************* */
    /* *********************************************************************************************** */

    let PrevNextCalendarLink;

    PrevNextCalendarLink = [
        <div key='prevnextcalendarlinkkeyhr' className='content_prevnextcalendarlink' style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>
                <Link
                    component={RouterLink}
                    underline="hover"
                    key="1"
                    color="primary"
                    to={`/goodday/${dayjs(date).add(-1, 'month').format('YYYYMM')}`}
                    onClick={handleClick}
                    style={{ textDecoration: 'none' }}
                >
                    前の月
                </Link>
            </span>
            <span>{dateF}</span>
            <span>
                <Link
                    component={RouterLink}
                    underline="hover"
                    key="1"
                    color="primary"
                    to={`/goodday/${dayjs(date).add(1, 'month').format('YYYYMM')}`}
                    onClick={handleClick}
                    style={{ textDecoration: 'none' }}
                >
                    次の月
                </Link>
            </span>
        </div>
    ];
    /* **************************************************************************************************** */
    /* ***** メインコンテンツの作成：月暦：A ***************************************************************** */
    /* **************************************************************************************************** */

    mainContentsA = [
        <div key='maincontentskeya'>
            <Box className='content_subbox'>

                {koyomi_data.filter(koyomi => koyomi.date === dateYMD).map(koyomi => {
                    monthDayStartWeek = Number([koyomi.week]);
                    monthDayStartWeekFastsunday = 0 - monthDayStartWeek;
                    const prevYM = dayjs(dateYM).add(-1, 'month').format('YYYYMM')
                    const prevLastday = Number(dayjs(prevYM + '01').add(1, 'month').subtract(1, 'day').format('DD'));
                    prevFastsunday = prevLastday + monthDayStartWeekFastsunday;
                    return (
                        <Box sx={{ letterSpacing: '0.1em' }} key={koyomi.date}>
                            {/* 月暦：新暦 */}
                            <div className='calendar_info_box'>
                                <span className='calendar_info_row_right'>
                                    <span style={{ color: 'green' }}>{pageTitleS}</span><br />
                                    <span style={{ color: 'green' }}>{dateJpE}、</span>{/* 令和 */}
                                    <HtmlTooltip
                                        leaveTouchDelay={5000}
                                        enterTouchDelay={0}
                                        title={
                                            <React.Fragment>
                                                <span style={{ lineHeight: '1.3rem' }}>
                                                    {MonName[dateM]}（{MonNameRuby[dateM]}）
                                                    <hr />
                                                    {dateM}月の別名
                                                </span>
                                            </React.Fragment>
                                        }
                                        placement='top-start'
                                    >
                                        <span style={{ color: 'green', borderBottom: '1px dotted' }}>
                                            {MonName[dateM]}
                                        </span>
                                    </HtmlTooltip>
                                    <br />
                                    <span style={{ color: 'gray' }}>{dateJpA}</span>{/* 明治 */}
                                    <span style={{ color: 'gray' }}>{dateJpB}</span>{/* 大正 */}
                                    <span style={{ color: 'gray' }}>{dateJpC}</span>{/* 昭和 */}
                                    <span style={{ color: 'gray' }}>{dateJpD}</span>{/* 平成 */}<br />
                                </span>
                                <span style={{ color: 'green' }} className='calendar_info_row_left'>新暦：</span>
                            </div>
                        </Box>
                    )
                })}
            </Box>
        </div>
    ];

    /* **************************************************************************************************** */
    /* ***** メインコンテンツの作成：月暦：B ***************************************************************** */
    /* **************************************************************************************************** */

    count = monthDayStartWeekFastsunday;
    countprevday = prevFastsunday;


    // すべてのボタンのキーを管理するための型定義
    type ButtonVariants = {
        [key: string]: 'contained' | 'outlined';
    };

    // GDBtn01 祭祀         
    // GDBtn02 衣類裁断     
    // GDBtn03 裁縫         
    // GDBtn04 造作         
    // GDBtn05 動土         
    // GDBtn06 種蒔         
    // GDBtn07 旅行         
    // GDBtn08 遠出         
    // GDBtn09 買物         
    // GDBtn10 外出         
    // GDBtn11 漁猟         
    // GDBtn12 結婚         
    // GDBtn13 葬式         
    // GDBtn14 入学         
    // GDBtn15 就職         
    // GDBtn16 新規事       
    // GDBtn17 移転         
    // GDBtn18 開店         
    // GDBtn19 新築         
    // GDBtn20 改装         
    // GDBtn21 訴訟        
    // GDBtn22 争い毎       
    // GDBtn23 寄付         
    // GDBtn24 相談         
    // GDBtn25 療養         
    // GDBtn26 服薬         
    // GDBtn27 高所へ移動   
    // GDBtn28 舟で移動     
    // GDBtn29 午の刻       
    // GDBtn30 午の刻以外   

    const [buttonVariants, setButtonVariants] = useState<ButtonVariants>({
        GDBtn01: 'contained',
        GDBtn02: 'outlined',
        GDBtn03: 'outlined',
        GDBtn04: 'outlined',
        GDBtn05: 'outlined',
        GDBtn06: 'outlined',
        GDBtn07: 'contained',
        GDBtn08: 'contained',
        GDBtn09: 'contained',
        GDBtn10: 'contained',
        GDBtn11: 'outlined',
        GDBtn12: 'outlined',
        GDBtn13: 'outlined',
        GDBtn14: 'outlined',
        GDBtn15: 'outlined',
        GDBtn16: 'outlined',
        GDBtn17: 'outlined',
        GDBtn18: 'outlined',
        GDBtn19: 'outlined',
        GDBtn20: 'outlined',
        GDBtn21: 'outlined',
        GDBtn22: 'outlined',
        GDBtn23: 'outlined',
        GDBtn24: 'outlined',
        GDBtn25: 'outlined',
        GDBtn26: 'outlined',
        GDBtn27: 'outlined',
        GDBtn28: 'outlined',
        GDBtn29: 'outlined',
        GDBtn30: 'outlined',
    });

    const toggleVariant = (btnKey: string) => {
        setButtonVariants((prevVariants) => ({
            ...prevVariants,
            [btnKey]: prevVariants[btnKey] === 'contained' ? 'outlined' : 'contained',
        }));
    };



    // YadohachiDetail の型を定義
    let YadohachiDetail: { [key: string]: string } = {};
    // let YadohachiDetailTmp;
    // let YDCount = count;

    for (let i = 1; i <= monthDayEnd; i++) {
        let YDtmpDateYMD = dateYM + String(i).padStart(2, '0');
        if (YadohachiEvent[xxyadohachiMap[YDtmpDateYMD]] !== undefined) {
            // console.log(YDtmpDateYMD + ":" + YadohachiEvent[xxyadohachiMap[YDtmpDateYMD]]);
            YadohachiEvent[xxyadohachiMap[YDtmpDateYMD]] && Array.from(YadohachiEvent[xxyadohachiMap[YDtmpDateYMD]]).forEach((char, index) => {
                if (char !== '3') {
                    // 20240820:年月日、index:何番目か、char:1(大凶)-5(大吉)
                    let YadohachiDetailTmp = parseInt(YDtmpDateYMD.slice(6, 8), 10) + '-' + String(index) + '-' + String(char);
                    YadohachiDetail[YadohachiDetailTmp] = 'exist';
                    // console.log(YadohachiDetailTmp + '=exist')
                }
            });
        }
    }

    let ZyunityokuDetail: { [key: string]: string } = {};
    // let ZyunityokuDetailTmp;
    // let ZDCount = count;

    for (let i = 1; i <= monthDayEnd; i++) {
        let ZDtmpDateYMD = dateYM + String(i).padStart(2, '0');
        if (ZyunityokuEvent[xxzyunityokuMap[ZDtmpDateYMD]] !== undefined) {
            // console.log(ZDtmpDateYMD + ":" + ZyunityokuEvent[xxzyunityokuMap[ZDtmpDateYMD]]);
            ZyunityokuEvent[xxzyunityokuMap[ZDtmpDateYMD]] && Array.from(ZyunityokuEvent[xxzyunityokuMap[ZDtmpDateYMD]]).forEach((char, index) => {
                if (char !== '3') {
                    // 20240820:年月日、index:何番目か、char:1(大凶)-5(大吉)
                    let ZyunityokuDetailTmp = parseInt(ZDtmpDateYMD.slice(6, 8), 10) + '-' + String(index) + '-' + String(char);
                    ZyunityokuDetail[ZyunityokuDetailTmp] = 'exist';
                    // console.log(ZyunityokuDetailTmp + '=exist')
                }
            });
        }
    }


    mainContentsB = [
        <div key='maincontentskeyb'>
            <h2 className='content_subtitle'>カレンダー切替</h2>
            <div>
                <Stack spacing={2} direction="row" sx={{ marginTop: '2rem' }}>
                    <Link component={RouterLink} underline="always" key="calendarLinkA" color="primary" to={`/calendar/${date.substring(0, 4)}${date.substring(4, 6)}`} onClick={handleClick}>
                        <Button variant="outlined"><CheckBoxOutlineBlankIcon style={{ marginRight: '0.5rem' }} />暦注</Button>
                    </Link>
                    <Link component={RouterLink} underline="always" key="calendarLinkB" color="primary" to={`/goodday/${date.substring(0, 4)}${date.substring(4, 6)}`} onClick={handleClick}>
                        <Button variant="contained"><CheckBoxIcon style={{ marginRight: '0.5rem' }} />吉凶判断</Button>
                    </Link>
                    <Link component={RouterLink} underline="always" key="calendarLinkC" color="primary" to={`/buddhafair/${date.substring(0, 4)}${date.substring(4, 6)}`} onClick={handleClick}>
                        <Button variant="outlined"><CheckBoxOutlineBlankIcon style={{ marginRight: '0.5rem' }} />仏様御縁</Button>
                    </Link>
                </Stack>
            </div>
            <h2 className='content_subtitle'>吉凶カレンダー</h2>
            <Alert severity="info" sx={{ marginTop: '1.5rem', marginBottom: '1rem' }}>
                <AlertTitle sx={{ fontSize: '0.8rem' }}>
                    <Link component={RouterLink} underline="always" key="annotation-28" color="primary" to={`/annotation/28`} onClick={handleClick}>
                        二十八宿
                    </Link>&nbsp;と&nbsp;
                    <Link component={RouterLink} underline="always" key="annotation-12" color="primary" to="/annotation/12" onClick={handleClick}>
                        十二直
                    </Link>&nbsp;
                    による吉凶判断です。
                </AlertTitle>
            </Alert>
            <Box className='content_subbox'>
                <Stack direction="row" flexWrap="wrap" sx={{ marginBottom: '1rem' }}>
                    <Button variant={buttonVariants.GDBtn01} onClick={() => toggleVariant('GDBtn01')} sx={{ margin: '5px 5px 5px 5px' }}>祭祀</Button>
                    <Button variant={buttonVariants.GDBtn02} onClick={() => toggleVariant('GDBtn02')} sx={{ margin: '5px 5px 5px 5px' }}>衣類裁断</Button>
                    <Button variant={buttonVariants.GDBtn03} onClick={() => toggleVariant('GDBtn03')} sx={{ margin: '5px 5px 5px 5px' }}>裁縫</Button>
                    <Button variant={buttonVariants.GDBtn04} onClick={() => toggleVariant('GDBtn04')} sx={{ margin: '5px 5px 5px 5px' }}>造作</Button>
                    <Button variant={buttonVariants.GDBtn05} onClick={() => toggleVariant('GDBtn05')} sx={{ margin: '5px 5px 5px 5px' }}>動土</Button>
                    <Button variant={buttonVariants.GDBtn06} onClick={() => toggleVariant('GDBtn06')} sx={{ margin: '5px 5px 5px 5px' }}>種蒔</Button>

                    <Button variant={buttonVariants.GDBtn07} onClick={() => toggleVariant('GDBtn07')} sx={{ margin: '5px 5px 5px 5px' }}>旅行</Button>
                    <Button variant={buttonVariants.GDBtn08} onClick={() => toggleVariant('GDBtn08')} sx={{ margin: '5px 5px 5px 5px' }}>遠出</Button>
                    <Button variant={buttonVariants.GDBtn09} onClick={() => toggleVariant('GDBtn09')} sx={{ margin: '5px 5px 5px 5px' }}>買物</Button>
                    <Button variant={buttonVariants.GDBtn10} onClick={() => toggleVariant('GDBtn10')} sx={{ margin: '5px 5px 5px 5px' }}>外出</Button>
                    <Button variant={buttonVariants.GDBtn11} onClick={() => toggleVariant('GDBtn11')} sx={{ margin: '5px 5px 5px 5px' }}>漁猟</Button>

                    <Button variant={buttonVariants.GDBtn12} onClick={() => toggleVariant('GDBtn12')} sx={{ margin: '5px 5px 5px 5px' }}>結婚</Button>
                    <Button variant={buttonVariants.GDBtn13} onClick={() => toggleVariant('GDBtn13')} sx={{ margin: '5px 5px 5px 5px' }}>葬式</Button>
                    <Button variant={buttonVariants.GDBtn14} onClick={() => toggleVariant('GDBtn14')} sx={{ margin: '5px 5px 5px 5px' }}>入学</Button>
                    <Button variant={buttonVariants.GDBtn15} onClick={() => toggleVariant('GDBtn15')} sx={{ margin: '5px 5px 5px 5px' }}>就職</Button>

                    <Button variant={buttonVariants.GDBtn16} onClick={() => toggleVariant('GDBtn16')} sx={{ margin: '5px 5px 5px 5px' }}>新規事</Button>
                    <Button variant={buttonVariants.GDBtn17} onClick={() => toggleVariant('GDBtn17')} sx={{ margin: '5px 5px 5px 5px' }}>移転</Button>
                    <Button variant={buttonVariants.GDBtn18} onClick={() => toggleVariant('GDBtn18')} sx={{ margin: '5px 5px 5px 5px' }}>開店</Button>
                    <Button variant={buttonVariants.GDBtn19} onClick={() => toggleVariant('GDBtn19')} sx={{ margin: '5px 5px 5px 5px' }}>新築</Button>
                    <Button variant={buttonVariants.GDBtn20} onClick={() => toggleVariant('GDBtn20')} sx={{ margin: '5px 5px 5px 5px' }}>改装</Button>

                    <Button variant={buttonVariants.GDBtn21} onClick={() => toggleVariant('GDBtn21')} sx={{ margin: '5px 5px 5px 5px' }}>訴訟</Button>
                    <Button variant={buttonVariants.GDBtn22} onClick={() => toggleVariant('GDBtn22')} sx={{ margin: '5px 5px 5px 5px' }}>争い毎</Button>
                    <Button variant={buttonVariants.GDBtn23} onClick={() => toggleVariant('GDBtn23')} sx={{ margin: '5px 5px 5px 5px' }}>寄付</Button>
                    <Button variant={buttonVariants.GDBtn24} onClick={() => toggleVariant('GDBtn24')} sx={{ margin: '5px 5px 5px 5px' }}>相談</Button>
                    <Button variant={buttonVariants.GDBtn25} onClick={() => toggleVariant('GDBtn25')} sx={{ margin: '5px 5px 5px 5px' }}>療養</Button>
                    <Button variant={buttonVariants.GDBtn26} onClick={() => toggleVariant('GDBtn26')} sx={{ margin: '5px 5px 5px 5px' }}>服薬</Button>

                    <Button variant={buttonVariants.GDBtn27} onClick={() => toggleVariant('GDBtn27')} sx={{ margin: '5px 5px 5px 5px' }}>高所へ移動</Button>
                    <Button variant={buttonVariants.GDBtn28} onClick={() => toggleVariant('GDBtn28')} sx={{ margin: '5px 5px 5px 5px' }}>舟で移動</Button>
                    <Button variant={buttonVariants.GDBtn29} onClick={() => toggleVariant('GDBtn29')} sx={{ margin: '5px 5px 5px 5px' }}>午の刻(11時~13時)</Button>
                    <Button variant={buttonVariants.GDBtn30} onClick={() => toggleVariant('GDBtn30')} sx={{ margin: '5px 5px 5px 5px' }}>午の刻(11時~13時)以外</Button>
                </Stack>

                <Box sx={{ margin: '0px 0px 15px 0px', fontSize: 'small', color: 'green', fontWeight: 'bold' }}>
                    上記にて表示する項目を解除・選択。
                </Box>
                <Box sx={{ margin: '0px 0px 15px 0px', fontSize: 'small' }}>
                    二十八宿の表示装飾：
                    <span style={{ color: 'blue', fontWeight: 'bold' }}>大吉</span>、
                    <span style={{ color: 'blue' }}>吉</span>、
                    <span style={{ color: 'red' }}>凶</span>、
                    <span style={{ color: 'red', fontWeight: 'bold' }}>大凶</span><br />
                    十二直の表示装飾：
                    <span style={{ color: '#0099FF', fontWeight: 'bold' }}>大吉</span>、
                    <span style={{ color: '#0099FF' }}>吉</span>、
                    <span style={{ color: '#FF00FF' }}>凶</span>
                </Box>
                <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                    <AdSenseBannerMobile />
                </div>
                {PrevNextCalendarLink}
                {/* 年月の表示 */}
                <Box sx={{
                    borderBottom: '5px solid white',
                    padding: '0px 0px 0px 0px',
                    backgroundColor: '#eee',
                    width: '100%',
                    display: 'grid',
                    gridAutoFlow: 'row',
                    gridTemplateColumns: 'repeat(1, 1fr)',
                    gap: 0,
                    minWidth: '330px',
                }}>
                    <Box sx={{ textAlign: 'center', color: '#000', margin: '10px' }}>{date.substring(0, 4)}年{Number(date.substring(4, 6))}月</Box>
                </Box>

                {/* 日月火水木金土の表示 */}
                <Box sx={{
                    borderBottom: '5px solid white',
                    padding: '0px 0px 0px 0px',
                    backgroundColor: '#eee',
                    width: '100%',
                    display: 'grid',
                    gridAutoFlow: 'row',
                    gridTemplateColumns: 'repeat(7, 1fr)',
                    gap: 0,
                    minWidth: '330px',
                }}>
                    <Box sx={{ textAlign: 'right', color: '#f00', margin: '10px' }}>{WeekName[0]}</Box>
                    <Box sx={{ textAlign: 'right', color: '#000', margin: '10px' }}>{WeekName[1]}</Box>
                    <Box sx={{ textAlign: 'right', color: '#000', margin: '10px' }}>{WeekName[2]}</Box>
                    <Box sx={{ textAlign: 'right', color: '#000', margin: '10px' }}>{WeekName[3]}</Box>
                    <Box sx={{ textAlign: 'right', color: '#000', margin: '10px' }}>{WeekName[4]}</Box>
                    <Box sx={{ textAlign: 'right', color: '#000', margin: '10px' }}>{WeekName[5]}</Box>
                    <Box sx={{ textAlign: 'right', color: '#00F', margin: '10px' }}>{WeekName[6]}</Box>
                </Box>

                {
                    Array.from({ length: 6 }, (_, i) => (
                        <Box key={`outer-${i}`} sx={{
                            borderBottom: '5px solid white',
                            padding: '0px 0px 0px 0px',
                            width: '100%',
                            display: 'grid',
                            gridAutoFlow: 'row',
                            gridTemplateColumns: 'repeat(7, 1fr)',
                            gap: 0,
                            minWidth: '330px',
                        }} className='calendar_bgcolor'>
                            {Array.from({ length: 7 }, (_, ii) => {
                                count++;
                                if (count >= 1 && count <= monthDayEnd) {
                                    let tmpDateYMD = dateYM + String(count).padStart(2, '0');
                                    let thisDayHoriday = holidayMap[tmpDateYMD];
                                    let weekcolor;
                                    let CountIndexCharTmp;

                                    if (thisDayHoriday || ii === 0) { weekcolor = `red`; } else if (ii === 6) { weekcolor = `blue`; } else { weekcolor = `black`; }

                                    let tmpDateYMDJ = tmpDateYMD.slice(0, 4) + "年" + parseInt(tmpDateYMD.slice(4, 6), 10) + "月" + parseInt(tmpDateYMD.slice(6, 8), 10) + "日（" + WeekName[ii] + "）";

                                    return (<Box key={`inner-${ii}`} sx={{ textAlign: 'right', height: 'auto', margin: '10px 10px 10px 0px' }}>
                                        {/* 日にち */}
                                        <span style={{ fontSize: '1.5rem', textAlign: 'right', color: weekcolor, whiteSpace: 'nowrap', marginBottom: '6px' }}>{count}</span>
                                        {/* 六曜 */}
                                        <br /><span style={{ fontSize: '0.9rem', lineHeight: '1rem', color: '#666', whiteSpace: 'nowrap' }}>{Rokki[xxrokkiMap[tmpDateYMD]]}</span>
                                        {/* 祝日（祭日） */}
                                        {thisDayHoriday && <br />}
                                        <HtmlTooltip
                                            leaveTouchDelay={5000}
                                            enterTouchDelay={0}
                                            title={
                                                <React.Fragment>
                                                    {tmpDateYMDJ}
                                                    <hr />
                                                    {HoridayName[thisDayHoriday]}
                                                </React.Fragment>
                                            }
                                            placement='top-start'
                                        >
                                            <span style={{ fontSize: '0.9rem', lineHeight: '1rem', color: 'red', whiteSpace: 'nowrap', borderBottom: '1px dotted', marginBottom: '5px' }}>{HoridayNameS[thisDayHoriday]}</span>
                                        </HtmlTooltip>

                                        {/* 28宿 */}
                                        <br /><span style={{ fontSize: '0.9rem', lineHeight: '1rem', color: 'green', whiteSpace: 'nowrap' }}>

                                            <span style={{ marginBottom: '5px' }}>{YadohachiS[xxyadohachiMap[tmpDateYMD]]}/{ZyunityokuS[xxzyunityokuMap[tmpDateYMD]]}</span><br />
                                            {/* YadohachiDetail[YadohachiDetailTmp] */}

                                            {
                                                YadohachiEvent[xxyadohachiMap[tmpDateYMD]] &&
                                                Array.from(YadohachiEvent[xxyadohachiMap[tmpDateYMD]]).map((char, index) => {

                                                    // count = 日
                                                    // index = 内容
                                                    // char = 吉凶
                                                    CountIndexCharTmp = count + '-' + index + '-' + char;

                                                    if (YadohachiDetail[CountIndexCharTmp] === 'exist') {

                                                        // console.log(CountIndexCharTmp + '= ' + YadohachiEventName[index]);

                                                        // GDBtn01 祭祀         
                                                        // GDBtn02 衣類裁断     
                                                        // GDBtn03 裁縫         
                                                        // GDBtn04 造作         
                                                        // GDBtn05 動土         
                                                        // GDBtn06 種蒔         
                                                        // GDBtn07 旅行         
                                                        // GDBtn08 遠出         
                                                        // GDBtn09 買物         
                                                        // GDBtn10 外出         
                                                        // GDBtn11 漁猟         
                                                        // GDBtn12 結婚         
                                                        // GDBtn13 葬式         
                                                        // GDBtn14 入学         
                                                        // GDBtn15 就職         
                                                        // GDBtn16 新規事       
                                                        // GDBtn17 移転         
                                                        // GDBtn18 開店         
                                                        // GDBtn19 新築         
                                                        // GDBtn20 改装         
                                                        // GDBtn21 訴訟        
                                                        // GDBtn22 争い毎       
                                                        // GDBtn23 寄付         
                                                        // GDBtn24 相談         
                                                        // GDBtn25 療養         
                                                        // GDBtn26 服薬         
                                                        // GDBtn27 高所へ移動   
                                                        // GDBtn28 舟で移動     
                                                        // GDBtn29 午の刻       
                                                        // GDBtn30 午の刻以外   

                                                        // 二十八宿
                                                        // GDBtn01 祭祀         0
                                                        // GDBtn02 衣類裁断     1
                                                        // GDBtn03 裁縫         2
                                                        // GDBtn12 結婚         3
                                                        // GDBtn13 葬式         4
                                                        // GDBtn07 旅行         5
                                                        // GDBtn08 遠出         6
                                                        // GDBtn04 造作         7
                                                        // GDBtn05 動土         8
                                                        // GDBtn06 種蒔         9
                                                        // GDBtn17 移転         10
                                                        // GDBtn18 開店         11
                                                        // GDBtn20 改装         12
                                                        // GDBtn14 入学         13
                                                        // GDBtn15 就職         14
                                                        // GDBtn25 療養         15
                                                        // GDBtn23 寄付         16
                                                        // GDBtn24 相談         17
                                                        // GDBtn29 午の刻       18
                                                        // GDBtn30 午の刻以外   19

                                                        if (char === '1') {
                                                            charColor = 'red';
                                                            charBold = 'bold';
                                                        } else if (char === '2') {
                                                            charColor = 'red';
                                                            charBold = 'normal';
                                                        } else if (char === '4') {
                                                            charColor = 'blue';
                                                            charBold = 'normal';
                                                        } else if (char === '5') {
                                                            charColor = 'blue';
                                                            charBold = 'bold';
                                                        } else {
                                                            charColor = 'black';
                                                            charBold = 'normal';
                                                        }

                                                        switch (index) {
                                                            case 0: // 祭祀
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn01 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{YadohachiEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 1: // 衣類裁断
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn02 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{YadohachiEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 2: // 裁縫
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn03 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{YadohachiEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 3: // 結婚
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn12 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{YadohachiEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 4: // 葬式
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn13 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{YadohachiEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 5: // 旅行
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn07 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{YadohachiEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 6: // 遠出
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn08 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{YadohachiEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 7: // 造作
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn04 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{YadohachiEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 8: // 動土
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn05 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{YadohachiEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 9: // 種蒔
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn06 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{YadohachiEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 10: // 移転
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn17 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{YadohachiEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 11: // 開店
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn18 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{YadohachiEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 12: // 改装
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn20 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{YadohachiEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 13: // 入学
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn14 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{YadohachiEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 14: // 就職
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn15 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{YadohachiEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 15: // 療養
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn25 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{YadohachiEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 16: // 寄付
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn23 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{YadohachiEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 17: // 相談
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn24 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{YadohachiEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 18: // 午の刻
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn29 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{YadohachiEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 19: // 午の刻以外
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn30 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{YadohachiEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            default:
                                                                return null;
                                                        }





                                                    }
                                                })
                                            }

                                        </span>

                                        {/* 12直 */}
                                        <br /><span style={{ fontSize: '0.9rem', lineHeight: '1rem', color: 'green', whiteSpace: 'nowrap' }}>

                                            {/* ZyunityokuDetail[ZyunityokuDetailTmp] */}


                                            {
                                                ZyunityokuEvent[xxzyunityokuMap[tmpDateYMD]] &&
                                                Array.from(ZyunityokuEvent[xxzyunityokuMap[tmpDateYMD]]).map((char, index) => {

                                                    // count = 日
                                                    // index = 内容
                                                    // char = 吉凶
                                                    CountIndexCharTmp = count + '-' + index + '-' + char;

                                                    if (ZyunityokuDetail[CountIndexCharTmp] === 'exist') {
                                                        // console.log(CountIndexCharTmp + '= ' + ZyunityokuEventName[index]);

                                                        // GDBtn01 祭祀         
                                                        // GDBtn02 衣類裁断     
                                                        // GDBtn03 裁縫         
                                                        // GDBtn04 造作         
                                                        // GDBtn05 動土         
                                                        // GDBtn06 種蒔         
                                                        // GDBtn07 旅行         
                                                        // GDBtn08 遠出         
                                                        // GDBtn09 買物         
                                                        // GDBtn10 外出         
                                                        // GDBtn11 漁猟         
                                                        // GDBtn12 結婚         
                                                        // GDBtn13 葬式         
                                                        // GDBtn14 入学         
                                                        // GDBtn15 就職         
                                                        // GDBtn16 新規事       
                                                        // GDBtn17 移転         
                                                        // GDBtn18 開店         
                                                        // GDBtn19 新築         
                                                        // GDBtn20 改装         
                                                        // GDBtn21 訴訟        
                                                        // GDBtn22 争い毎       
                                                        // GDBtn23 寄付         
                                                        // GDBtn24 相談         
                                                        // GDBtn25 療養         
                                                        // GDBtn26 服薬         
                                                        // GDBtn27 高所へ移動   
                                                        // GDBtn28 舟で移動     
                                                        // GDBtn29 午の刻       
                                                        // GDBtn30 午の刻以外   

                                                        // 十二直
                                                        // GDBtn01 祭祀         0
                                                        // GDBtn12 結婚         1
                                                        // GDBtn13 葬式         2
                                                        // GDBtn07 旅行         3
                                                        // GDBtn09 買物         4
                                                        // GDBtn10 外出         5
                                                        // GDBtn05 動土         6
                                                        // GDBtn06 種蒔         7
                                                        // GDBtn16 新規事       8
                                                        // GDBtn19 新築         9
                                                        // GDBtn20 改装         10
                                                        // GDBtn25 療養         11
                                                        // GDBtn26 服薬         12
                                                        // GDBtn21 訴訟         13
                                                        // GDBtn22 争い毎       14
                                                        // GDBtn11 漁猟         15
                                                        // GDBtn27 高所へ移動   16
                                                        // GDBtn28 舟で移動     17

                                                        if (char === '1') {
                                                            charColor = '#FF00FF';
                                                            charBold = 'bold';
                                                        } else if (char === '2') {
                                                            charColor = '#FF00FF';
                                                            charBold = 'normal';
                                                        } else if (char === '4') {
                                                            charColor = '#0099FF';
                                                            charBold = 'normal';
                                                        } else if (char === '5') {
                                                            charColor = '#0099FF';
                                                            charBold = 'bold';
                                                        } else {
                                                            charColor = 'black';
                                                            charBold = 'normal';
                                                        }

                                                        switch (index) {
                                                            case 0: // 祭祀
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn01 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{ZyunityokuEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 1: // 結婚
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn12 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{ZyunityokuEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 2: // 葬式
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn13 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{ZyunityokuEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 3: // 旅行
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn07 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{ZyunityokuEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 4: // 買物
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn09 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{ZyunityokuEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 5: // 外出
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn10 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{ZyunityokuEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 6: // 動土
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn05 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{ZyunityokuEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 7: // 種蒔
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn06 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{ZyunityokuEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 8: // 新規事
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn16 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{ZyunityokuEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 9: // 新築
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn19 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{ZyunityokuEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 10: // 改装
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn20 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{ZyunityokuEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 11: // 療養
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn25 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{ZyunityokuEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 12: // 服薬
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn26 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{ZyunityokuEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 13: // 訴訟
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn21 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{ZyunityokuEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 14: // 争い毎
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn22 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{ZyunityokuEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 15: // 漁猟
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn11 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{ZyunityokuEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 16: // 高所へ移動
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn27 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{ZyunityokuEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            case 17: // 舟で移動
                                                                return (<React.Fragment key={index}>{buttonVariants.GDBtn28 === 'contained' && (<><Box component="span" sx={{ color: charColor, fontWeight: charBold, fontSize: '0.9rem' }}>{ZyunityokuEventName[index]}</Box><br /></>)}</React.Fragment>);
                                                            default:
                                                                return null;
                                                        }
                                                    }
                                                })
                                            }





                                        </span>
                                        { /* buttonVariants.GDBtn01 === 'contained' && (<><Box component="span" sx={{ color: 'gray', fontSize: '0.9rem' }}>1祭祀</Box><br /></>) */}
                                        { /* buttonVariants.GDBtn02 === 'contained' && (<><Box component="span" sx={{ color: 'gray', fontSize: '0.8rem' }}>2衣類裁断</Box><br /></>) */}
                                        { /* buttonVariants.GDBtn03 === 'contained' && (<><Box component="span" sx={{ color: 'gray', fontSize: '0.9rem' }}>3裁縫</Box><br /></>) */}
                                        { /* buttonVariants.GDBtn04 === 'contained' && (<><Box component="span" sx={{ color: 'gray', fontSize: '0.9rem' }}>4造作</Box><br /></>) */}
                                        { /* buttonVariants.GDBtn05 === 'contained' && (<><Box component="span" sx={{ color: 'gray', fontSize: '0.9rem' }}>5動土</Box><br /></>) */}
                                        { /* buttonVariants.GDBtn06 === 'contained' && (<><Box component="span" sx={{ color: 'gray', fontSize: '0.9rem' }}>6種蒔</Box><br /></>) */}

                                        { /* buttonVariants.GDBtn07 === 'contained' && (<><Box component="span" sx={{ color: 'gray', fontSize: '0.9rem' }}>7旅行</Box><br /></>) */}
                                        { /* buttonVariants.GDBtn08 === 'contained' && (<><Box component="span" sx={{ color: 'gray', fontSize: '0.9rem' }}>8遠出</Box><br /></>) */}
                                        { /* buttonVariants.GDBtn09 === 'contained' && (<><Box component="span" sx={{ color: 'gray', fontSize: '0.9rem' }}>9買物</Box><br /></>) */}
                                        { /* buttonVariants.GDBtn10 === 'contained' && (<><Box component="span" sx={{ color: 'gray', fontSize: '0.9rem' }}>10外出</Box><br /></>) */}
                                        { /* buttonVariants.GDBtn11 === 'contained' && (<><Box component="span" sx={{ color: 'gray', fontSize: '0.9rem' }}>11漁猟</Box><br /></>) */}

                                        { /* buttonVariants.GDBtn12 === 'contained' && (<><Box component="span" sx={{ color: 'gray', fontSize: '0.9rem' }}>結婚</Box><br /></>) */}
                                        { /* buttonVariants.GDBtn13 === 'contained' && (<><Box component="span" sx={{ color: 'gray', fontSize: '0.9rem' }}>葬式</Box><br /></>) */}
                                        { /* buttonVariants.GDBtn14 === 'contained' && (<><Box component="span" sx={{ color: 'gray', fontSize: '0.9rem' }}>入学</Box><br /></>) */}
                                        { /* buttonVariants.GDBtn15 === 'contained' && (<><Box component="span" sx={{ color: 'gray', fontSize: '0.9rem' }}>就職</Box><br /></>) */}

                                        { /* buttonVariants.GDBtn16 === 'contained' && (<><Box component="span" sx={{ color: 'gray', fontSize: '0.9rem' }}>新規事</Box><br /></>) */}
                                        { /* buttonVariants.GDBtn17 === 'contained' && (<><Box component="span" sx={{ color: 'gray', fontSize: '0.9rem' }}>移転</Box><br /></>) */}
                                        { /* buttonVariants.GDBtn18 === 'contained' && (<><Box component="span" sx={{ color: 'gray', fontSize: '0.9rem' }}>開店</Box><br /></>) */}
                                        { /* buttonVariants.GDBtn19 === 'contained' && (<><Box component="span" sx={{ color: 'gray', fontSize: '0.9rem' }}>新築</Box><br /></>) */}
                                        { /* buttonVariants.GDBtn20 === 'contained' && (<><Box component="span" sx={{ color: 'gray', fontSize: '0.9rem' }}>改装</Box><br /></>) */}

                                        { /* buttonVariants.GDBtn21 === 'contained' && (<><Box component="span" sx={{ color: 'gray', fontSize: '0.9rem' }}>訴訟</Box><br /></>) */}
                                        { /* buttonVariants.GDBtn22 === 'contained' && (<><Box component="span" sx={{ color: 'gray', fontSize: '0.9rem' }}>争い毎</Box><br /></>) */}
                                        { /* buttonVariants.GDBtn23 === 'contained' && (<><Box component="span" sx={{ color: 'gray', fontSize: '0.9rem' }}>寄付</Box><br /></>) */}
                                        { /* buttonVariants.GDBtn24 === 'contained' && (<><Box component="span" sx={{ color: 'gray', fontSize: '0.9rem' }}>相談</Box><br /></>) */}
                                        { /* buttonVariants.GDBtn25 === 'contained' && (<><Box component="span" sx={{ color: 'gray', fontSize: '0.9rem' }}>療養</Box><br /></>) */}
                                        { /* buttonVariants.GDBtn26 === 'contained' && (<><Box component="span" sx={{ color: 'gray', fontSize: '0.9rem' }}>服薬</Box><br /></>) */}

                                        { /* buttonVariants.GDBtn27 === 'contained' && (<><Box component="span" sx={{ color: 'gray', fontSize: '0.9rem' }}>高所へ移動</Box><br /></>) */}
                                        { /* buttonVariants.GDBtn28 === 'contained' && (<><Box component="span" sx={{ color: 'gray', fontSize: '0.9rem' }}>舟で移動</Box><br /></>) */}
                                        { /* buttonVariants.GDBtn29 === 'contained' && (<><Box component="span" sx={{ color: 'gray', fontSize: '0.9rem' }}>午の刻</Box><br /></>) */}
                                        { /* buttonVariants.GDBtn30 === 'contained' && (<><Box component="span" sx={{ color: 'gray', fontSize: '0.9rem' }}>午の刻以外</Box><br /></>) */}


                                    </Box>);
                                } else if (count > monthDayEnd) {
                                    let countnextmonth = count - monthDayEnd;
                                    return (<Box key={`inner-${ii}`} sx={{ textAlign: 'right', color: 'gray', height: '90px', fontSize: '1.0rem', margin: '10px 10px 10px 0px' }}>{countnextmonth}</Box>);
                                } else {
                                    countprevday = countprevday + 1;
                                    return (<Box key={`inner-${ii}`} sx={{ textAlign: 'right', color: 'gray', height: '90px', fontSize: '1.0rem', margin: '10px 10px 10px 0px' }}>{countprevday}</Box>);
                                }
                            })}

                        </Box>

                    ))
                }
                {PrevNextCalendarLink}
            </Box>
        </div>
    ];

    /* **************************************************************************************************** */
    /* ***** メインコンテンツの作成：月暦：D ***************************************************************** */
    /* **************************************************************************************************** */

    mainContentsD = [
        <div key='maincontentskeyd'>
        </div>
    ];




    /* **************************************************************************************************** */
    /* **************************************************************************************************** */
    /* **************************************************************************************************** */
    /* *****                            ******************************************************************* */
    /* ***** returnエリア               ******************************************************************* */
    /* *****                            ******************************************************************* */
    /* **************************************************************************************************** */
    /* **************************************************************************************************** */
    /* **************************************************************************************************** */


    let etcPageLink;

    etcPageLink = [
        <div key="etcPageLink">
            <div style={{ marginLeft: '1rem' }}>
                <Link
                    component={RouterLink}
                    underline="hover"
                    key="2"
                    color="primary"
                    to={`/calendar/${dateY}`}
                    onClick={handleClick}
                >
                    {dateY}年の年暦
                </Link>
            </div>
            <div style={{ marginLeft: '1rem' }}>
                <Link
                    component={RouterLink}
                    underline="hover"
                    key="2"
                    color="primary"
                    to={`/aspect/${dateY}`}
                    onClick={handleClick}
                >
                    {dateY}年盤の座相
                </Link>
            </div>
        </div>
    ];

    const pageKey = "GoodDay";
    const pagePath = pagePathS[pageKey] + dateYM;

    const metaDescription = dateY + '年' + dateM + '月' + pageDescription['GoodDay'];
    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    const pageTitleTmp = pageTitle; // title: pageTitle にすると、何故かエラーになるので、一旦変数に格納

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        console.log(pagePath + ':', encryptedUid.substring(0, 3));

        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitleTmp, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitleTmp]);


    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };

    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>

                        {/* ***** メインコンテンツ ******************************************************************* */}
                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                                <span></span>
                            </h1>
                            <div>{mainContentsA}</div>
                            <div>{mainContentsB}</div>
                            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                <AdSenseBannerMobile />
                            </div>
                            <div>{mainContentsC}</div>
                            <div>{mainContentsD}</div>
                            <Box className='content_subbox_txt'>
                                <h2 className='content_subtitle'>他ページへのリンク</h2>
                                {etcPageLink}
                            </Box>
                            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                <AdSenseInarticle />
                            </div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
};

const App = () => (
    <AuthProvider>
        <Goodday />
    </AuthProvider>
);

export default App;