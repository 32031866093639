// src/pages/CalendarIndex.tsx
// import * as React from 'react';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton, TextField, InputAdornment } from '@mui/material';
import Grid from '@mui/material/Grid2';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import SwipeIcon from '@mui/icons-material/Swipe';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SearchIcon from '@mui/icons-material/Search';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    WeekName,
    MonName,
    Rokki,
    EtoName10,
    EtoName12,
    EtoName12RubyA,
    SekkiAngle,
    HoridayName,
    Yadohachi,
    YadohachiTotalG,
    YadohachiTotalB,
    Zyunityoku,
    ZyunityokuTotalG,
    ZyunityokuTotalB,
    siteNameS,
    siteType,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    articleBody,
    publisherType,
    publisherName,
    publisherLogoType,
    publisherLogoUrl,
    datePublished,
    buddhaName,
    buddhaNameD,
    buddhaColor,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';
import AdSenseBannerMobile from '../components/AdSenseBannerMobile'; // 320x100、ラージモバイルバナー をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // 記事内広告 をインポート
import addImg from '../img/add_sample_300x250.png'
import { MonthLastDay } from '../SuurekiVarMonLastDay';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');  // 日本語ロケールを設定

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        background: 'linear-gradient(to right, rgba(231, 244, 255, 0.99),rgba(231, 244, 255, 0.99), rgba(243, 250, 255, 0.99))',
        color: 'black',
        fontSize: theme.typography.pxToRem(16),
        border: '1px solid black',
    },
}));


interface Koyomi {
    date: string;           /* 日付 */
    eto10_month: number;    /* 月干支（暦：10支）： */
    eto12_month: number;    /* 月干支（暦：12支） */
    eto10_year: number;     /* 年干支（暦：10支） */
    eto12_year: number;     /* 年干支（暦：12支） */
    week: string;           /* 曜日 */
    jd: number;             /* ユリウス通日 */
    xxolddate: string;      /* 旧暦 */
    xxrokki: number;        /* 六曜 */
    eto10_day: number;      /* 日干支（10支） */
    eto12_day: number;      /* 日干支（12支） */
    xxgogyo: string;        /* 五行 */
    xxyadohachi: number;    /* 二十八宿 */
    xxyadonana: string;     /* 二十七宿 */
    xxsekki: number;        /* 二十四節気 */
    xxsetuzuki: string;     /* 節月 */
    eto10_month_s: number;  /* 月干支（節：10支） */
    eto12_month_s: number;  /* 月干支（節：12支） */
    eto10_year_s: number;   /* 年干支（節：10支） */
    eto12_year_s: number;   /* 年干支（節：12支） */
    xxzyunityoku: number;   /* 十二直 */
    xxkyuusei: number;      /* 日九星 */
    xxinyo: string;         /* 陰陽 */
    xxsenjitsu: string;     /* 選日 */
    xxzassetsu: string;     /* 雑節 */
    xxnanazyuni: string;    /* 七十二候 */
    voidtime: string;       /* ボイドタイム */
    yylongitudesun: string; /*  */
    yylongitudemoon: string;    /*  */
    yyagemoon: string;      /*  */
    kyuusei_y: number;      /* 年九星 */
    kyuusei_m: number;      /* 月九星 */
    sekki_date_s: string;   /*  */
    sekki_date_e: string;   /*  */
}

interface MoonStateFull {
    date: string;         /* 年月日 */
    time: string;         /* 日時 */
    moonecliptic: string; /* 黄経差 */
    mooneclipticrounding: string; /* 黄経差の四捨五入 */
    moonage: string;      /* 月齢 */
}

interface MoonState {
    date: string;         /* 年月日 */
    time: string;         /* 日時 */
    mooninfo: string; /* 新月・満月・下弦・上弦 */
    moonage: string;      /* 月齢 */
}

interface Buddhafair {
    date: string;           /* 年月日 */
    eto1012: string;        /* 御縁日の該当数字 */
    buddha_dat: string;     /* 御縁日の該当数字 */
    buddha_datJ: string;    /* 御縁日の該当数字 */
}

interface Horiday {
    date: string;           /* 年月日 */
    holiday_dat: string;    /* 祝日の該当数字 */
}

interface Etcday {
    date: string;           /* 年月日 */
    etcday_dat: string;    /* 祝日の該当数字 */
}

interface SekkiDay {
    date: string;           /* 年月日 */
    time: string;         /* 日時 */
    angle: string;    /* 祝日の該当数字 */
}


/* *********************************************************************************************** */
/* ***** ページ名の作成 *************************************************************************** */
/* *********************************************************************************************** */

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}
// const pageTitle = `カレンダーの崇暦`
const pageTitle = `カレンダーの崇暦 | 万年暦、様々な観点からの吉凶判断`
// const pageTitleS = `カレンダーの崇暦` // ページトップのナビゲーションリンク


const Home: React.FC = React.memo(() => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState("");

    const today = new Date();
    const todayW = today.getDay();

    // 来月、1ヶ月後
    const monthDate2 = new Date(today);
    monthDate2.setMonth(today.getMonth() + 1);
    // 2ヶ月後
    const monthDate3 = new Date(today);
    monthDate3.setMonth(today.getMonth() + 2);
    // 3ヶ月後
    const monthDate4 = new Date(today);
    monthDate4.setMonth(today.getMonth() + 3);
    // 4ヶ月後
    const monthDate5 = new Date(today);
    monthDate5.setMonth(today.getMonth() + 4);
    // 5ヶ月後
    const monthDate6 = new Date(today);
    monthDate6.setMonth(today.getMonth() + 5);

    // 先月、1ヶ月前
    const monthDatePrev = new Date(today);
    monthDatePrev.setMonth(today.getMonth() - 1);
    // 2ヶ月前
    const monthDatePrev3 = new Date(today);
    monthDatePrev3.setMonth(today.getMonth() - 2);
    // 3ヶ月前
    const monthDatePrev4 = new Date(today);
    monthDatePrev4.setMonth(today.getMonth() - 3);

    const dateY = today.getFullYear();
    const dateM = ("0" + (today.getMonth() + 1)).slice(-2);     // getMonthは0から11の値を返すため、+1する。また、1桁の月は0を先頭に追加。
    const dateD = ("0" + today.getDate()).slice(-2);              // 1桁の日は0を先頭に追加
    const dateYM = dateY + dateM;
    const monthDayEndA = Number(MonthLastDay[dateYM]);   // 月末日を取得
    const monthEndWeekdayA = new Date(dateY, Number(dateM) - 1, monthDayEndA).getDay();
    const dateYMD = dateY + dateM + dateD;

    // 来月、1ヶ月後
    const dateY2 = monthDate2.getFullYear();
    const dateM2 = (monthDate2.getMonth() + 1).toString().padStart(2, '0');
    const dateYM2 = dateY2 + dateM2;
    const monthDayEndB = Number(MonthLastDay[dateYM2]);   // 月末日を取得
    // 2ヶ月後
    const dateYM3 = monthDate3.getFullYear() + (monthDate3.getMonth() + 1).toString().padStart(2, '0');
    // 3ヶ月後
    // const dateYM4 = monthDate4.getFullYear() + (monthDate4.getMonth() + 1).toString().padStart(2, '0');
    // 4ヶ月後
    // const dateYM5 = monthDate5.getFullYear() + (monthDate5.getMonth() + 1).toString().padStart(2, '0');
    // 5ヶ月後
    // const dateYM6 = monthDate6.getFullYear() + (monthDate6.getMonth() + 1).toString().padStart(2, '0');

    const dateYprev = monthDatePrev.getFullYear();
    const dateMprev = (monthDatePrev.getMonth() + 1).toString().padStart(2, '0');
    const dateYMprev = dateYprev + dateMprev;
    const monthDayEndP = Number(MonthLastDay[dateYMprev]);   // 月末日を取得
    const monthEndWeekdayP = new Date(dateYprev, Number(dateMprev) - 1, monthDayEndP).getDay();

    // 2ヶ月後
    // const dateYMP3 = monthDatePrev3.getFullYear() + (monthDatePrev3.getMonth() + 1).toString().padStart(2, '0');
    // 3ヶ月後
    // const dateYMP4 = monthDatePrev4.getFullYear() + (monthDatePrev4.getMonth() + 1).toString().padStart(2, '0');


    // 1日後
    const dateTmp1 = new Date(today);
    dateTmp1.setDate(today.getDate() + 1);
    const dateYYYYMMDD1 = dateTmp1.getFullYear().toString() + (dateTmp1.getMonth() + 1).toString().padStart(2, '0') + dateTmp1.getDate().toString().padStart(2, '0');
    // 2日後
    const dateTmp2 = new Date(today);
    dateTmp2.setDate(today.getDate() + 2);
    const dateYYYYMMDD2 = dateTmp2.getFullYear().toString() + (dateTmp2.getMonth() + 1).toString().padStart(2, '0') + dateTmp2.getDate().toString().padStart(2, '0');
    // 3日後
    const dateTmp3 = new Date(today);
    dateTmp3.setDate(today.getDate() + 3);
    const dateYYYYMMDD3 = dateTmp3.getFullYear().toString() + (dateTmp3.getMonth() + 1).toString().padStart(2, '0') + dateTmp3.getDate().toString().padStart(2, '0');
    // 4日後
    const dateTmp4 = new Date(today);
    dateTmp4.setDate(today.getDate() + 4);
    const dateYYYYMMDD4 = dateTmp4.getFullYear().toString() + (dateTmp4.getMonth() + 1).toString().padStart(2, '0') + dateTmp4.getDate().toString().padStart(2, '0');
    // 5日後
    const dateTmp5 = new Date(today);
    dateTmp5.setDate(today.getDate() + 5);
    const dateYYYYMMDD5 = dateTmp5.getFullYear().toString() + (dateTmp5.getMonth() + 1).toString().padStart(2, '0') + dateTmp5.getDate().toString().padStart(2, '0');
    // 6日後
    const dateTmp6 = new Date(today);
    dateTmp6.setDate(today.getDate() + 6);
    const dateYYYYMMDD6 = dateTmp6.getFullYear().toString() + (dateTmp6.getMonth() + 1).toString().padStart(2, '0') + dateTmp6.getDate().toString().padStart(2, '0');
    // 7日後
    const dateTmp7 = new Date(today);
    dateTmp7.setDate(today.getDate() + 7);
    const dateYYYYMMDD7 = dateTmp7.getFullYear().toString() + (dateTmp7.getMonth() + 1).toString().padStart(2, '0') + dateTmp7.getDate().toString().padStart(2, '0');


    const dateJpNumberA = Number(dateY) - 1867; // 和暦の算出：明治
    const dateJpNumberB = Number(dateY) - 1911; // 和暦の算出：大正
    const dateJpNumberC = Number(dateY) - 1925; // 和暦の算出：昭和
    const dateJpNumberD = Number(dateY) - 1988; // 和暦の算出：平成
    const dateJpNumberE = Number(dateY) - 2018; // 和暦の算出：令和

    // JSONデータを日付をキーとする
    const calendar_json_url = `https://db.suureki.net/_db_calendar/calendar_${dateY}.json`;
    const calendar_json_url2 = `https://db.suureki.net/_db_calendar/calendar_${dateY + 1}.json`;

    const [koyomi_data, setDataKoyomi] = useState<Koyomi[]>([]);

    useEffect(() => {
        Promise.all([
            fetch(calendar_json_url).then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok.[暦/今年]');
                }
                return response.json();
            }),
            fetch(calendar_json_url2).then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok.[暦/来年]');
                }
                return response.json();
            })
        ])
            .then(([data1, data2]) => {
                // 両方のデータを合算してセット
                setDataKoyomi([...data1, ...data2]);
            })
            .catch(error => console.error('Error:', error));
    }, [calendar_json_url, calendar_json_url2]);

    // JSONデータを日付をキーとするオブジェクトに変換します。
    let weekMap: { [date: string]: number } = {};
    let xxrokkiMap: { [date: string]: number } = {};
    let xxsekkiMap: { [date: string]: number } = {};
    let eto10dayMap: { [date: string]: number } = {};
    let eto12dayMap: { [date: string]: number } = {};
    let eto10yearMap: { [date: string]: number } = {};
    let eto12yearMap: { [date: string]: number } = {};
    let xxyadohachiMap: { [date: string]: number } = {};
    let xxzyunityokuMap: { [date: string]: number } = {};
    let xxolddateMap: { [date: string]: string } = {};
    let xxolddateMapS: { [date: string]: string } = {};
    let xxolddateMapJ: { [date: string]: string } = {};
    let xxsenjitsuMap: { [date: string]: string } = {};
    let xxzassetsuMap: { [date: string]: string } = {};
    koyomi_data.forEach(koyomi => {
        weekMap[koyomi.date] = Number(koyomi.week);
        xxrokkiMap[koyomi.date] = koyomi.xxrokki;
        xxsekkiMap[koyomi.date] = koyomi.xxsekki;
        eto10dayMap[koyomi.date] = koyomi.eto10_day;
        eto12dayMap[koyomi.date] = koyomi.eto12_day;
        eto10yearMap[koyomi.date] = koyomi.eto10_year;
        eto12yearMap[koyomi.date] = koyomi.eto12_year;
        xxyadohachiMap[koyomi.date] = koyomi.xxyadohachi;
        xxzyunityokuMap[koyomi.date] = koyomi.xxzyunityoku;
        xxolddateMap[koyomi.date] = koyomi.xxolddate;
        const dateParts = koyomi.xxolddate.split('-').map(part => parseInt(part, 10));
        xxolddateMapS[koyomi.date] = dateParts[1] + "/" + dateParts[2];
        xxolddateMapJ[koyomi.date] = dateParts[1] + "月" + dateParts[2] + "日";
        xxsenjitsuMap[koyomi.date] = koyomi.xxsenjitsu;
        xxzassetsuMap[koyomi.date] = koyomi.xxzassetsu;
    });


    // 二十四節気の時間jsonの呼び出し //////////////////////////////////////////////////////////////////////
    /*
    { "date": "20730105", "time": "0321", "_comment": "小寒：しょうかん", "angle": "285" },
    { "date": "20730119", "time": "2040", "_comment": "大寒：だいかん", "angle": "300" },
    { "date": "20730203", "time": "1456", "_comment": "立春：りっしゅん", "angle": "315" },
    */
    const sekki_json_url = `https://db.suureki.net/_db_24sekki/24sekki_${dateY}.json`;
    const sekki_json_url2 = `https://db.suureki.net/_db_24sekki/24sekki_${dateY + 1}.json`;


    const [sekki_data, setDataSekki] = useState<SekkiDay[]>([]);

    useEffect(() => {
        Promise.all([
            fetch(sekki_json_url).then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok.[月A/今年]');
                }
                return response.json();
            }),
            fetch(sekki_json_url2).then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok.[月A/来年]');
                }
                return response.json();
            })
        ])
            .then(([data1, data2]) => {
                // 両方のデータを合算してセット
                setDataSekki([...data1, ...data2]);
            })
            .catch(error => console.error('Error:', error));
    }, [sekki_json_url, sekki_json_url2]);

    // JSONデータを日付をキーとするオブジェクトに変換します。
    let sekkiTimeMap: { [date: string]: string } = {};
    let sekkiAngleMap: { [date: string]: string } = {};
    sekki_data.forEach(sekki => {
        sekkiTimeMap[sekki.date] = sekki.time;
        sekkiAngleMap[sekki.date] = sekki.angle;
    });
    let sekkiCommentYM: { [date: string]: string } = {};
    sekkiCommentYM[dateYM] = "";
    sekkiCommentYM[dateYM2] = "";
    sekki_data.forEach(sekki => {
        let tmpDateYMsekki = sekki.date.substring(0, 6);
        sekkiCommentYM[tmpDateYMsekki] += Number(sekki.date.substring(4, 6)) + '/' + Number(sekki.date.substring(6, 8)) + '：' + SekkiAngle[sekki.angle] + '<br />';
    });

    // sekkiCommentYM[dateYM]の最後の文字を削除
    if (sekkiCommentYM[dateYM] !== undefined) {
        sekkiCommentYM[dateYM] = sekkiCommentYM[dateYM].slice(0, -6);
    }
    if (sekkiCommentYM[dateYM2] !== undefined) {
        sekkiCommentYM[dateYM2] = sekkiCommentYM[dateYM2].slice(0, -6);
    }

    let sekkiTooltipTxt: { [date: string]: string } = {};
    if (sekkiTimeMap[dateYMD] !== undefined) {
        sekkiTooltipTxt[dateYMD] = SekkiAngle[sekkiAngleMap[dateYMD]];
    }
    if (sekkiTimeMap[dateYYYYMMDD1] !== undefined) {
        sekkiTooltipTxt[dateYYYYMMDD1] = SekkiAngle[sekkiAngleMap[dateYYYYMMDD1]];
    }
    if (sekkiTimeMap[dateYYYYMMDD2] !== undefined) {
        sekkiTooltipTxt[dateYYYYMMDD2] = SekkiAngle[sekkiAngleMap[dateYYYYMMDD2]];
    }
    if (sekkiTimeMap[dateYYYYMMDD3] !== undefined) {
        sekkiTooltipTxt[dateYYYYMMDD3] = SekkiAngle[sekkiAngleMap[dateYYYYMMDD3]];
    }
    if (sekkiTimeMap[dateYYYYMMDD4] !== undefined) {
        sekkiTooltipTxt[dateYYYYMMDD4] = SekkiAngle[sekkiAngleMap[dateYYYYMMDD4]];
    }
    if (sekkiTimeMap[dateYYYYMMDD5] !== undefined) {
        sekkiTooltipTxt[dateYYYYMMDD5] = SekkiAngle[sekkiAngleMap[dateYYYYMMDD5]];
    }
    if (sekkiTimeMap[dateYYYYMMDD6] !== undefined) {
        sekkiTooltipTxt[dateYYYYMMDD6] = SekkiAngle[sekkiAngleMap[dateYYYYMMDD6]];
    }
    if (sekkiTimeMap[dateYYYYMMDD7] !== undefined) {
        sekkiTooltipTxt[dateYYYYMMDD7] = SekkiAngle[sekkiAngleMap[dateYYYYMMDD7]];
    }

    // 今日の御縁日jsonの呼び出し //////////////////////////////////////////////////////////////////////
    /*
    {
        "date": "20240101",
        "eto1012": "0-0",
        "buddha_dat": "19,23,24",
        "buddha_datJ": "甲子：妙見、聖天、大黒"
    },
    {
        "date": "20240103",
        "eto1012": "2-2",
        "buddha_dat": "4,28",
        "buddha_datJ": "丙寅：宝生、毘沙門"
    },
    */

    const buddhafair_json_url = `https://db.suureki.net/_db_buddhafair/buddhafair_${dateY}.json`;

    const [buddhafair_data, setDataBuddhafair] = useState<Buddhafair[]>([]);
    useEffect(() => {
        fetch(buddhafair_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(buddhafair_data => setDataBuddhafair(buddhafair_data))
            .catch(error => console.error('Error:', error));
    }, [buddhafair_json_url]);

    // JSONデータを日付をキーとするオブジェクトに変換します。
    let buddhafairMap: { [date: string]: string } = {};
    buddhafair_data.forEach(buddhafair => {
        buddhafairMap[buddhafair.date] = buddhafair.buddha_dat;
    });

    // 今年の祝日（祭日）jsonの呼び出し //////////////////////////////////////////////////////////////////////
    /*
    {
    "date": "19490101",
    "holiday_dat": "1"
    },
    {
    "date": "19490115",
    "holiday_dat": "2"
    },
    {
    "date": "19490321",
    "holiday_dat": "4"
    },
    */
    const horiday_json_url = "https://db.suureki.net/_db_horiday/horiday_all.json";

    const [horiday_data, setDataHoriday] = useState<Horiday[]>([]);
    useEffect(() => {
        fetch(horiday_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(horiday_data => setDataHoriday(horiday_data))
            .catch(error => console.error('Error:', error));
    }, [horiday_json_url]);

    // JSONデータを日付をキーとするオブジェクトに変換します。
    let holidayMap: { [date: string]: string } = {};
    horiday_data.forEach(horiday => {
        holidayMap[horiday.date] = horiday.holiday_dat;
    });

    let horidayCommentYM: { [date: string]: string } = {};
    horidayCommentYM[dateYM] = "";
    horidayCommentYM[dateYM2] = "";
    horiday_data.forEach(horiday => {
        let tmpDateYMhoriday = horiday.date.substring(0, 6);
        horidayCommentYM[tmpDateYMhoriday] += Number(horiday.date.substring(4, 6)) + '/' + Number(horiday.date.substring(6, 8)) + '：' + HoridayName[horiday.holiday_dat] + '<br />';
    });
    // horidayCommentYM[dateYM]の最後の文字を削除
    if (horidayCommentYM[dateYM] !== undefined) {
        horidayCommentYM[dateYM] = horidayCommentYM[dateYM].slice(0, -6);
    }
    if (horidayCommentYM[dateYM2] !== undefined) {
        horidayCommentYM[dateYM2] = horidayCommentYM[dateYM2].slice(0, -6);
    }


    // 祝日ではない日と風習 jsonの呼び出し //////////////////////////////////////////////////////////////////////
    /*
    { "date": "20170107", "etcday_dat": "七草の節句" },
    { "date": "20170214", "etcday_dat": "バレンタインデー" },
    { "date": "20170303", "etcday_dat": "ひなまつり、桃の節句" },
    { "date": "20170314", "etcday_dat": "ホワイトデー" },
    { "date": "20170401", "etcday_dat": "エイプリルフール" },
    { "date": "20170501", "etcday_dat": "メーデー" },
    { "date": "20170505", "etcday_dat": "端午の節句" },
    { "date": "20170514", "etcday_dat": "母の日" },
    { "date": "20170618", "etcday_dat": "父の日" },
    { "date": "20170707", "etcday_dat": "七夕" },
    { "date": "20170909", "etcday_dat": "菊の節句" },
    { "date": "20171031", "etcday_dat": "ハロウィン" },
    { "date": "20171224", "etcday_dat": "クリスマスイヴ" },
    { "date": "20171225", "etcday_dat": "クリスマス" },
    */
    const etcday_json_url = "https://db.suureki.net/_db_horiday/etcday_all.json";

    const [etcday_data, setDataEtcday] = useState<Etcday[]>([]);
    useEffect(() => {
        fetch(etcday_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(etcday_data => setDataEtcday(etcday_data))
            .catch(error => console.error('Error:', error));
    }, [etcday_json_url]);

    // JSONデータを日付をキーとするオブジェクトに変換します。
    let etcdayMap: { [date: string]: string } = {};
    etcday_data.forEach(etcday => {
        etcdayMap[etcday.date] = etcday.etcday_dat;
    });

    let etcdayCommentYM: { [date: string]: string } = {};
    etcdayCommentYM[dateYM] = "";
    etcdayCommentYM[dateYM2] = "";
    etcday_data.forEach(etcday => {
        etcdayMap[etcday.date] = etcday.etcday_dat;
        let tmpDateYMetcday = etcday.date.substring(0, 6);
        etcdayCommentYM[tmpDateYMetcday] += Number(etcday.date.substring(4, 6)) + '/' + Number(etcday.date.substring(6, 8)) + '：' + etcday.etcday_dat + '<br />';
    });
    // etcdayCommentYM[dateYM]の最後の文字を削除
    if (etcdayCommentYM[dateYM] !== undefined) {
        etcdayCommentYM[dateYM] = etcdayCommentYM[dateYM].slice(0, -6);
    }
    if (etcdayCommentYM[dateYM2] !== undefined) {
        etcdayCommentYM[dateYM2] = etcdayCommentYM[dateYM2].slice(0, -6);
    }

    // 今年の月状態jsonの呼び出し //////////////////////////////////////////////////////////////////////
    /*
        { "date": "20400101", "time": "0000", "moonecliptic": "191.91", "mooneclipticrounding": "192", "moonage": "15.93" },
        { "date": "20400102", "time": "0000", "moonecliptic": "202.84", "mooneclipticrounding": "203", "moonage": "16.93" },
        { "date": "20400103", "time": "0000", "moonecliptic": "213.87", "mooneclipticrounding": "214", "moonage": "17.93" },
        { "date": "20400104", "time": "0000", "moonecliptic": "225.04", "mooneclipticrounding": "225", "moonage": "18.93" },
        { "date": "20400105", "time": "0000", "moonecliptic": "236.4", "mooneclipticrounding": "236", "moonage": "19.93" },
    */

    const moon_json_url = `https://db.suureki.net/_db_moon/moonstatefull_${dateY}.json`;
    const moon_json_url2 = `https://db.suureki.net/_db_moon/moonstatefull_${dateY + 1}.json`;
    const [moon_data, setDataMoonStateFull] = useState<MoonStateFull[]>([]);

    useEffect(() => {
        Promise.all([
            fetch(moon_json_url).then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok.[月A/今年]');
                }
                return response.json();
            }),
            fetch(moon_json_url2).then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok.[月A/来年]');
                }
                return response.json();
            })
        ])
            .then(([data1, data2]) => {
                // 両方のデータを合算してセット
                setDataMoonStateFull([...data1, ...data2]);
            })
            .catch(error => console.error('Error:', error));
    }, [moon_json_url, moon_json_url2]);

    // JSONデータを日付をキーとするオブジェクトに変換します。
    let moonEclipticRounding: { [date: string]: string } = {};
    let MoonAgeMap: { [date: string]: string } = {};
    moon_data.forEach(moon => {
        moonEclipticRounding[moon.date] = moon.mooneclipticrounding;
        MoonAgeMap[moon.date] = moon.moonage;
    });


    // 今年の月（新月・満月・下弦・上弦）状態jsonの呼び出し //////////////////////////////////////////////////////////////////////
    /*
    { "date": "19140104", "time": "2208", "mooninfo": "halfxx-090", "moonage": "2.25" },
    { "date": "19140112", "time": "1408", "mooninfo": "fullxx-180", "moonage": "4.18" },
    { "date": "19140119", "time": "0929", "mooninfo": "waning-270", "moonage": "-3.45" },
    { "date": "19140126", "time": "1533", "mooninfo": "newxxx-000", "moonage": "-3.32" },
    */

    const moonstate_json_url = `https://db.suureki.net/_db_moon/moonstate_${dateY}.json`;
    const moonstate_json_url2 = `https://db.suureki.net/_db_moon/moonstate_${dateY + 1}.json`;
    const [moonstate_data, setDataMoonState] = useState<MoonState[]>([]);

    useEffect(() => {
        Promise.all([
            fetch(moonstate_json_url).then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok.[月B/今年]');
                }
                return response.json();
            }),
            fetch(moonstate_json_url2).then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok.[月B/来年]');
                }
                return response.json();
            })
        ])
            .then(([data1, data2]) => {
                // 両方のデータを合算してセット
                setDataMoonState([...data1, ...data2]);
            })
            .catch(error => console.error('Error:', error));
    }, [moonstate_json_url, moonstate_json_url2]);

    // JSONデータを日付をキーとするオブジェクトに変換します。
    let moonstatetime: { [date: string]: string } = {};
    let moonstateinfo: { [date: string]: string } = {};
    let moonstateage: { [date: string]: string } = {};
    moonstate_data.forEach(moon => {
        moonstatetime[moon.date] = moon.time;
        moonstateinfo[moon.date] = moon.mooninfo;
        moonstateage[moon.date] = moon.moonage;
    });

    let moonCommentYM: { [date: string]: string } = {};
    moonCommentYM[dateYM] = "";
    moonCommentYM[dateYM2] = "";
    moonstate_data.forEach(moon => {
        let tmpDateYMmoon = moon.date.substring(0, 6);
        if (moon.mooninfo === 'newxxx-000') {
            moonCommentYM[tmpDateYMmoon] += '<span>' + Number(moon.date.substring(4, 6)) + '/' + Number(moon.date.substring(6, 8)) + '：<img width=\'35px\' height=\'25px\' src=\'https://suureki.net/moon_img/moon_000.svg\' alt=\'新月画像\' />&nbsp;新月：' + Number(moonstatetime[moon.date].substring(0, 2)) + '時' + Number(moonstatetime[moon.date].substring(2, 4)) + '分</span><br />';
        }
        if (moon.mooninfo === 'fullxx-180') {
            moonCommentYM[tmpDateYMmoon] += '<span>' + Number(moon.date.substring(4, 6)) + '/' + Number(moon.date.substring(6, 8)) + '：<img width=\'35px\' height=\'25px\' src=\'https://suureki.net/moon_img/moon_180.svg\' alt=\'満月画像\' />&nbsp;満月：' + Number(moonstatetime[moon.date].substring(0, 2)) + '時' + Number(moonstatetime[moon.date].substring(2, 4)) + '分</span><br />';
        }
        // if (moon.mooninfo === 'halfxx-090') {
        //     moonCommentYM[tmpDateYMmoon] += '<span>' + Number(moon.date.substring(4, 6)) + '/' + Number(moon.date.substring(6, 8)) + '：<img width=\'20px\' src=\'https://suureki.net/moon_img/moon_090.svg\' alt=\'上弦画像\' />&nbsp;上弦：' + Number(moonstatetime[moon.date].substring(0, 2)) + '時' + Number(moonstatetime[moon.date].substring(2, 4)) + '分</span><br />';
        // }
        // if (moon.mooninfo === 'waning-270') {
        //     moonCommentYM[tmpDateYMmoon] += '<span>' + Number(moon.date.substring(4, 6)) + '/' + Number(moon.date.substring(6, 8)) + '：<img width=\'20px\' src=\'https://suureki.net/moon_img/moon_270.svg\' alt=\'下弦画像\' />&nbsp;下弦：' + Number(moonstatetime[moon.date].substring(0, 2)) + '時' + Number(moonstatetime[moon.date].substring(2, 4)) + '分</span><br />';
        // }
        //<img width='35px' src={https://suureki.net/moon_img/moon_000.svg} alt='' />
        //<img width='35px' src={moonTooltipImg[dateYMD]} alt='' />
    });
    // moonCommentYM[dateYM]の最後の文字を削除
    if (moonCommentYM[dateYM] !== undefined) {
        moonCommentYM[dateYM] = moonCommentYM[dateYM].slice(0, -6);
    }
    if (moonCommentYM[dateYM2] !== undefined) {
        moonCommentYM[dateYM2] = moonCommentYM[dateYM2].slice(0, -6);
    }


    let moonTooltipImg: { [date: string]: string } = {};
    let moonTooltipTxt: { [date: string]: string } = {};
    let moonTooltipAge: { [date: string]: string } = {};
    moonTooltipTxt[dateYMD] = "";
    moonTooltipTxt[dateYYYYMMDD1] = "";
    moonTooltipTxt[dateYYYYMMDD2] = "";
    moonTooltipTxt[dateYYYYMMDD3] = "";
    moonTooltipTxt[dateYYYYMMDD4] = "";
    moonTooltipTxt[dateYYYYMMDD5] = "";
    moonTooltipTxt[dateYYYYMMDD6] = "";
    moonTooltipTxt[dateYYYYMMDD7] = "";
    moonTooltipImg[dateYMD] = "";
    moonTooltipImg[dateYYYYMMDD1] = "";
    moonTooltipImg[dateYYYYMMDD2] = "";
    moonTooltipImg[dateYYYYMMDD3] = "";
    moonTooltipImg[dateYYYYMMDD4] = "";
    moonTooltipImg[dateYYYYMMDD5] = "";
    moonTooltipImg[dateYYYYMMDD6] = "";
    moonTooltipImg[dateYYYYMMDD7] = "";
    moonTooltipAge[dateYMD] = "";
    moonTooltipAge[dateYYYYMMDD1] = "";
    moonTooltipAge[dateYYYYMMDD2] = "";
    moonTooltipAge[dateYYYYMMDD3] = "";
    moonTooltipAge[dateYYYYMMDD4] = "";
    moonTooltipAge[dateYYYYMMDD5] = "";
    moonTooltipAge[dateYYYYMMDD6] = "";
    moonTooltipAge[dateYYYYMMDD7] = "";

    if (moonstateinfo[dateYMD] === 'newxxx-000') {
        moonTooltipImg[dateYMD] = "https://suureki.net/moon_img/moon_000.svg";
        moonTooltipTxt[dateYMD] = "新月：" + Number(moonstatetime[dateYMD].substring(0, 2)) + "時" + Number(moonstatetime[dateYMD].substring(2, 4)) + "分";
        moonTooltipAge[dateYMD] = "新月画像";
    } else if (moonstateinfo[dateYMD] === 'halfxx-090') {
        moonTooltipImg[dateYMD] = "https://suureki.net/moon_img/moon_090.svg";
        moonTooltipTxt[dateYMD] = "上弦：" + Number(moonstatetime[dateYMD].substring(0, 2)) + "時" + Number(moonstatetime[dateYMD].substring(2, 4)) + "分";
        moonTooltipAge[dateYMD] = "上弦画像";
    } else if (moonstateinfo[dateYMD] === 'fullxx-180') {
        moonTooltipImg[dateYMD] = "https://suureki.net/moon_img/moon_180.svg";
        moonTooltipTxt[dateYMD] = "満月：" + Number(moonstatetime[dateYMD].substring(0, 2)) + "時" + Number(moonstatetime[dateYMD].substring(2, 4)) + "分";
        moonTooltipAge[dateYMD] = "満月画像";
    } else if (moonstateinfo[dateYMD] === 'waning-270') {
        moonTooltipImg[dateYMD] = "https://suureki.net/moon_img/moon_270.svg";
        moonTooltipTxt[dateYMD] = "下弦：" + Number(moonstatetime[dateYMD].substring(0, 2)) + "時" + Number(moonstatetime[dateYMD].substring(2, 4)) + "分";
        moonTooltipAge[dateYMD] = "下弦画像";
    }
    if (moonstateinfo[dateYYYYMMDD1] === 'newxxx-000') {
        moonTooltipImg[dateYYYYMMDD1] = "https://suureki.net/moon_img/moon_000.svg";
        moonTooltipTxt[dateYYYYMMDD1] = "新月：" + Number(moonstatetime[dateYYYYMMDD1].substring(0, 2)) + "時" + Number(moonstatetime[dateYYYYMMDD1].substring(2, 4)) + "分";
        moonTooltipAge[dateYYYYMMDD1] = "新月画像";
    } else if (moonstateinfo[dateYYYYMMDD1] === 'halfxx-090') {
        moonTooltipImg[dateYYYYMMDD1] = "https://suureki.net/moon_img/moon_090.svg";
        moonTooltipTxt[dateYYYYMMDD1] = "上弦：" + Number(moonstatetime[dateYYYYMMDD1].substring(0, 2)) + "時" + Number(moonstatetime[dateYYYYMMDD1].substring(2, 4)) + "分";
        moonTooltipAge[dateYYYYMMDD1] = "上弦画像";
    } else if (moonstateinfo[dateYYYYMMDD1] === 'fullxx-180') {
        moonTooltipImg[dateYYYYMMDD1] = "https://suureki.net/moon_img/moon_180.svg";
        moonTooltipTxt[dateYYYYMMDD1] = "満月：" + Number(moonstatetime[dateYYYYMMDD1].substring(0, 2)) + "時" + Number(moonstatetime[dateYYYYMMDD1].substring(2, 4)) + "分";
        moonTooltipAge[dateYYYYMMDD1] = "満月画像";
    } else if (moonstateinfo[dateYYYYMMDD1] === 'waning-270') {
        moonTooltipImg[dateYYYYMMDD1] = "https://suureki.net/moon_img/moon_270.svg";
        moonTooltipTxt[dateYYYYMMDD1] = "下弦：" + Number(moonstatetime[dateYYYYMMDD1].substring(0, 2)) + "時" + Number(moonstatetime[dateYYYYMMDD1].substring(2, 4)) + "分";
        moonTooltipAge[dateYYYYMMDD1] = "下弦画像";
    }
    if (moonstateinfo[dateYYYYMMDD2] === 'newxxx-000') {
        moonTooltipImg[dateYYYYMMDD2] = "https://suureki.net/moon_img/moon_000.svg";
        moonTooltipTxt[dateYYYYMMDD2] = "新月：" + Number(moonstatetime[dateYYYYMMDD2].substring(0, 2)) + "時" + Number(moonstatetime[dateYYYYMMDD2].substring(2, 4)) + "分";
        moonTooltipAge[dateYYYYMMDD2] = "新月画像";
    } else if (moonstateinfo[dateYYYYMMDD2] === 'halfxx-090') {
        moonTooltipImg[dateYYYYMMDD2] = "https://suureki.net/moon_img/moon_090.svg";
        moonTooltipTxt[dateYYYYMMDD2] = "上弦：" + Number(moonstatetime[dateYYYYMMDD2].substring(0, 2)) + "時" + Number(moonstatetime[dateYYYYMMDD2].substring(2, 4)) + "分";
        moonTooltipAge[dateYYYYMMDD2] = "上弦画像";
    } else if (moonstateinfo[dateYYYYMMDD2] === 'fullxx-180') {
        moonTooltipImg[dateYYYYMMDD2] = "https://suureki.net/moon_img/moon_180.svg";
        moonTooltipTxt[dateYYYYMMDD2] = "満月：" + Number(moonstatetime[dateYYYYMMDD2].substring(0, 2)) + "時" + Number(moonstatetime[dateYYYYMMDD2].substring(2, 4)) + "分";
        moonTooltipAge[dateYYYYMMDD2] = "満月画像";
    } else if (moonstateinfo[dateYYYYMMDD2] === 'waning-270') {
        moonTooltipImg[dateYYYYMMDD2] = "https://suureki.net/moon_img/moon_270.svg";
        moonTooltipTxt[dateYYYYMMDD2] = "下弦：" + Number(moonstatetime[dateYYYYMMDD2].substring(0, 2)) + "時" + Number(moonstatetime[dateYYYYMMDD2].substring(2, 4)) + "分";
        moonTooltipAge[dateYYYYMMDD2] = "下弦画像";
    }
    if (moonstateinfo[dateYYYYMMDD3] === 'newxxx-000') {
        moonTooltipImg[dateYYYYMMDD3] = "https://suureki.net/moon_img/moon_000.svg";
        moonTooltipTxt[dateYYYYMMDD3] = "新月：" + Number(moonstatetime[dateYYYYMMDD3].substring(0, 2)) + "時" + Number(moonstatetime[dateYYYYMMDD3].substring(2, 4)) + "分";
        moonTooltipAge[dateYYYYMMDD3] = "新月画像";
    } else if (moonstateinfo[dateYYYYMMDD3] === 'halfxx-090') {
        moonTooltipImg[dateYYYYMMDD3] = "https://suureki.net/moon_img/moon_090.svg";
        moonTooltipTxt[dateYYYYMMDD3] = "上弦：" + Number(moonstatetime[dateYYYYMMDD3].substring(0, 2)) + "時" + Number(moonstatetime[dateYYYYMMDD3].substring(2, 4)) + "分";
        moonTooltipAge[dateYYYYMMDD3] = "上弦画像";
    } else if (moonstateinfo[dateYYYYMMDD3] === 'fullxx-180') {
        moonTooltipImg[dateYYYYMMDD3] = "https://suureki.net/moon_img/moon_180.svg";
        moonTooltipTxt[dateYYYYMMDD3] = "満月：" + Number(moonstatetime[dateYYYYMMDD3].substring(0, 2)) + "時" + Number(moonstatetime[dateYYYYMMDD3].substring(2, 4)) + "分";
        moonTooltipAge[dateYYYYMMDD3] = "満月画像";
    } else if (moonstateinfo[dateYYYYMMDD3] === 'waning-270') {
        moonTooltipImg[dateYYYYMMDD3] = "https://suureki.net/moon_img/moon_270.svg";
        moonTooltipTxt[dateYYYYMMDD3] = "下弦：" + Number(moonstatetime[dateYYYYMMDD3].substring(0, 2)) + "時" + Number(moonstatetime[dateYYYYMMDD3].substring(2, 4)) + "分";
        moonTooltipAge[dateYYYYMMDD3] = "下弦画像";
    }
    if (moonstateinfo[dateYYYYMMDD4] === 'newxxx-000') {
        moonTooltipImg[dateYYYYMMDD4] = "https://suureki.net/moon_img/moon_000.svg";
        moonTooltipTxt[dateYYYYMMDD4] = "新月：" + Number(moonstatetime[dateYYYYMMDD4].substring(0, 2)) + "時" + Number(moonstatetime[dateYYYYMMDD4].substring(2, 4)) + "分";
        moonTooltipAge[dateYYYYMMDD4] = "新月画像";
    } else if (moonstateinfo[dateYYYYMMDD4] === 'halfxx-090') {
        moonTooltipImg[dateYYYYMMDD4] = "https://suureki.net/moon_img/moon_090.svg";
        moonTooltipTxt[dateYYYYMMDD4] = "上弦：" + Number(moonstatetime[dateYYYYMMDD4].substring(0, 2)) + "時" + Number(moonstatetime[dateYYYYMMDD4].substring(2, 4)) + "分";
        moonTooltipAge[dateYYYYMMDD4] = "上弦画像";
    } else if (moonstateinfo[dateYYYYMMDD4] === 'fullxx-180') {
        moonTooltipImg[dateYYYYMMDD4] = "https://suureki.net/moon_img/moon_180.svg";
        moonTooltipTxt[dateYYYYMMDD4] = "満月：" + Number(moonstatetime[dateYYYYMMDD4].substring(0, 2)) + "時" + Number(moonstatetime[dateYYYYMMDD4].substring(2, 4)) + "分";
        moonTooltipAge[dateYYYYMMDD4] = "満月画像";
    } else if (moonstateinfo[dateYYYYMMDD4] === 'waning-270') {
        moonTooltipImg[dateYYYYMMDD4] = "https://suureki.net/moon_img/moon_270.svg";
        moonTooltipTxt[dateYYYYMMDD4] = "下弦：" + Number(moonstatetime[dateYYYYMMDD4].substring(0, 2)) + "時" + Number(moonstatetime[dateYYYYMMDD4].substring(2, 4)) + "分";
        moonTooltipAge[dateYYYYMMDD4] = "下弦画像";
    }
    if (moonstateinfo[dateYYYYMMDD5] === 'newxxx-000') {
        moonTooltipImg[dateYYYYMMDD5] = "https://suureki.net/moon_img/moon_000.svg";
        moonTooltipTxt[dateYYYYMMDD5] = "新月：" + Number(moonstatetime[dateYYYYMMDD5].substring(0, 2)) + "時" + Number(moonstatetime[dateYYYYMMDD5].substring(2, 4)) + "分";
        moonTooltipAge[dateYYYYMMDD5] = "新月画像";
    } else if (moonstateinfo[dateYYYYMMDD5] === 'halfxx-090') {
        moonTooltipImg[dateYYYYMMDD5] = "https://suureki.net/moon_img/moon_090.svg";
        moonTooltipTxt[dateYYYYMMDD5] = "上弦：" + Number(moonstatetime[dateYYYYMMDD5].substring(0, 2)) + "時" + Number(moonstatetime[dateYYYYMMDD5].substring(2, 4)) + "分";
        moonTooltipAge[dateYYYYMMDD5] = "上弦画像";
    } else if (moonstateinfo[dateYYYYMMDD5] === 'fullxx-180') {
        moonTooltipImg[dateYYYYMMDD5] = "https://suureki.net/moon_img/moon_180.svg";
        moonTooltipTxt[dateYYYYMMDD5] = "満月：" + Number(moonstatetime[dateYYYYMMDD5].substring(0, 2)) + "時" + Number(moonstatetime[dateYYYYMMDD5].substring(2, 4)) + "分";
        moonTooltipAge[dateYYYYMMDD5] = "満月画像";
    } else if (moonstateinfo[dateYYYYMMDD5] === 'waning-270') {
        moonTooltipImg[dateYYYYMMDD5] = "https://suureki.net/moon_img/moon_270.svg";
        moonTooltipTxt[dateYYYYMMDD5] = "下弦：" + Number(moonstatetime[dateYYYYMMDD5].substring(0, 2)) + "時" + Number(moonstatetime[dateYYYYMMDD5].substring(2, 4)) + "分";
        moonTooltipAge[dateYYYYMMDD5] = "下弦画像";
    }
    if (moonstateinfo[dateYYYYMMDD6] === 'newxxx-000') {
        moonTooltipImg[dateYYYYMMDD6] = "https://suureki.net/moon_img/moon_000.svg";
        moonTooltipTxt[dateYYYYMMDD6] = "新月：" + Number(moonstatetime[dateYYYYMMDD6].substring(0, 2)) + "時" + Number(moonstatetime[dateYYYYMMDD6].substring(2, 4)) + "分";
        moonTooltipAge[dateYYYYMMDD6] = "新月画像";
    } else if (moonstateinfo[dateYYYYMMDD6] === 'halfxx-090') {
        moonTooltipImg[dateYYYYMMDD6] = "https://suureki.net/moon_img/moon_090.svg";
        moonTooltipTxt[dateYYYYMMDD6] = "上弦：" + Number(moonstatetime[dateYYYYMMDD6].substring(0, 2)) + "時" + Number(moonstatetime[dateYYYYMMDD6].substring(2, 4)) + "分";
        moonTooltipAge[dateYYYYMMDD6] = "上弦画像";
    } else if (moonstateinfo[dateYYYYMMDD6] === 'fullxx-180') {
        moonTooltipImg[dateYYYYMMDD6] = "https://suureki.net/moon_img/moon_180.svg";
        moonTooltipTxt[dateYYYYMMDD6] = "満月：" + Number(moonstatetime[dateYYYYMMDD6].substring(0, 2)) + "時" + Number(moonstatetime[dateYYYYMMDD6].substring(2, 4)) + "分";
        moonTooltipAge[dateYYYYMMDD6] = "満月画像";
    } else if (moonstateinfo[dateYYYYMMDD6] === 'waning-270') {
        moonTooltipImg[dateYYYYMMDD6] = "https://suureki.net/moon_img/moon_270.svg";
        moonTooltipTxt[dateYYYYMMDD6] = "下弦：" + Number(moonstatetime[dateYYYYMMDD6].substring(0, 2)) + "時" + Number(moonstatetime[dateYYYYMMDD6].substring(2, 4)) + "分";
        moonTooltipAge[dateYYYYMMDD6] = "下弦画像";
    }
    if (moonstateinfo[dateYYYYMMDD7] === 'newxxx-000') {
        moonTooltipImg[dateYYYYMMDD7] = "https://suureki.net/moon_img/moon_000.svg";
        moonTooltipTxt[dateYYYYMMDD7] = "新月：" + Number(moonstatetime[dateYYYYMMDD7].substring(0, 2)) + "時" + Number(moonstatetime[dateYYYYMMDD7].substring(2, 4)) + "分";
        moonTooltipAge[dateYYYYMMDD7] = "新月画像";
    } else if (moonstateinfo[dateYYYYMMDD7] === 'halfxx-090') {
        moonTooltipImg[dateYYYYMMDD7] = "https://suureki.net/moon_img/moon_090.svg";
        moonTooltipTxt[dateYYYYMMDD7] = "上弦：" + Number(moonstatetime[dateYYYYMMDD7].substring(0, 2)) + "時" + Number(moonstatetime[dateYYYYMMDD7].substring(2, 4)) + "分";
        moonTooltipAge[dateYYYYMMDD7] = "上弦画像";
    } else if (moonstateinfo[dateYYYYMMDD7] === 'fullxx-180') {
        moonTooltipImg[dateYYYYMMDD7] = "https://suureki.net/moon_img/moon_180.svg";
        moonTooltipTxt[dateYYYYMMDD7] = "満月：" + Number(moonstatetime[dateYYYYMMDD7].substring(0, 2)) + "時" + Number(moonstatetime[dateYYYYMMDD7].substring(2, 4)) + "分";
        moonTooltipAge[dateYYYYMMDD7] = "満月画像";
    } else if (moonstateinfo[dateYYYYMMDD7] === 'waning-270') {
        moonTooltipImg[dateYYYYMMDD7] = "https://suureki.net/moon_img/moon_270.svg";
        moonTooltipTxt[dateYYYYMMDD7] = "下弦" + Number(moonstatetime[dateYYYYMMDD7].substring(0, 2)) + "時" + Number(moonstatetime[dateYYYYMMDD7].substring(2, 4)) + "分";
        moonTooltipAge[dateYYYYMMDD7] = "下弦画像";
    }
    // console.log(moonstateinfo[dateYYYYMMDD7])

    let todayWcolor: string;
    if (todayW === 0 || holidayMap[dateYMD] !== undefined) {
        todayWcolor = 'red';
    } else if (todayW === 6) {
        todayWcolor = 'blue';
    } else {
        todayWcolor = 'black';
    }
    let dateYYYYMMDDW1Color: string;
    if (weekMap[dateYYYYMMDD1] === 0 || holidayMap[dateYYYYMMDD1] !== undefined) {
        dateYYYYMMDDW1Color = 'red';
    } else if (weekMap[dateYYYYMMDD1] === 6) {
        dateYYYYMMDDW1Color = 'blue';
    } else {
        dateYYYYMMDDW1Color = 'black';
    }

    let dateYYYYMMDDW2Color: string;
    if (weekMap[dateYYYYMMDD2] === 0 || holidayMap[dateYYYYMMDD2] !== undefined) {
        dateYYYYMMDDW2Color = 'red';
    } else if (weekMap[dateYYYYMMDD2] === 6) {
        dateYYYYMMDDW2Color = 'blue';
    } else {
        dateYYYYMMDDW2Color = 'black';
    }

    let dateYYYYMMDDW3Color: string;
    if (weekMap[dateYYYYMMDD3] === 0 || holidayMap[dateYYYYMMDD3] !== undefined) {
        dateYYYYMMDDW3Color = 'red';
    } else if (weekMap[dateYYYYMMDD3] === 6) {
        dateYYYYMMDDW3Color = 'blue';
    } else {
        dateYYYYMMDDW3Color = 'black';
    }

    let dateYYYYMMDDW4Color: string;
    if (weekMap[dateYYYYMMDD4] === 0 || holidayMap[dateYYYYMMDD4] !== undefined) {
        dateYYYYMMDDW4Color = 'red';
    } else if (weekMap[dateYYYYMMDD4] === 6) {
        dateYYYYMMDDW4Color = 'blue';
    } else {
        dateYYYYMMDDW4Color = 'black';
    }

    let dateYYYYMMDDW5Color: string;
    if (weekMap[dateYYYYMMDD5] === 0 || holidayMap[dateYYYYMMDD5] !== undefined) {
        dateYYYYMMDDW5Color = 'red';
    } else if (weekMap[dateYYYYMMDD5] === 6) {
        dateYYYYMMDDW5Color = 'blue';
    } else {
        dateYYYYMMDDW5Color = 'black';
    }

    let dateYYYYMMDDW6Color: string;
    if (weekMap[dateYYYYMMDD6] === 0 || holidayMap[dateYYYYMMDD6] !== undefined) {
        dateYYYYMMDDW6Color = 'red';
    } else if (weekMap[dateYYYYMMDD6] === 6) {
        dateYYYYMMDDW6Color = 'blue';
    } else {
        dateYYYYMMDDW6Color = 'black';
    }

    let dateYYYYMMDDW7Color: string;
    if (weekMap[dateYYYYMMDD7] === 0 || holidayMap[dateYYYYMMDD7] !== undefined) {
        dateYYYYMMDDW7Color = 'red';
    } else if (weekMap[dateYYYYMMDD7] === 6) {
        dateYYYYMMDDW7Color = 'blue';
    } else {
        dateYYYYMMDDW7Color = 'black';
    }

    // monthDayFastW =  月の1日の曜日（0:日曜日、1:月曜日、2:火曜日、3:水曜日、4:木曜日、5:金曜日、6:土曜日）
    // monthDayFastW2 = 月の1日の曜日（0:日曜日、1:月曜日、2:火曜日、3:水曜日、4:木曜日、5:金曜日、6:土曜日）
    const monthDayFastW = weekMap[dateY + dateM + "01"];
    const monthDayFastW2 = weekMap[dateY2 + dateM2 + "01"];

    const breadcrumbs = [
        <Link
            component={RouterLink}
            underline="hover"
            key="1"
            color="inherit"
            to="/"
            onClick={handleClick}>
            <IconButton
                aria-label="ホームページへページ移動" // ユーザー補助機能名を追加
                sx={{
                    margin: '0px 0px 1px 0px',
                    padding: '0px'
                }}>
                <HomeIcon />
            </IconButton>
        </Link>,
        <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
            トップページ
        </Typography>,
    ];
    // const navigate = useNavigate();
    let mainContents;

    const monthDayStartWeekFastsundayA = 0 - monthDayFastW;
    const monthDayStartWeekFastsundayB = 0 - monthDayFastW2;
    //console.log(monthDayStartWeekFastsundayB);


    // const prevFastsunday = 28;
    //dateYMprev は、前月の月末日

    // const prevFastsundayA = monthDayEndP;
    // const prevFastsundayB = monthDayEndA;


    let countA = monthDayStartWeekFastsundayA;
    let countB = monthDayStartWeekFastsundayB;
    /*
    1:月 なら、日から1引く
    2:火 なら、日から2引く
    3:水 なら、日から3引く
    4:木 なら、日から4引く
    5:金 なら、日から5引く
    6:土 なら、日から6引く
    0:日 なら、日から0引く
    */
    let countprevdayA = monthDayEndP - monthEndWeekdayP;
    let countprevdayB = monthDayEndA - monthEndWeekdayA;
    let thisDayHoriday = '';

    // console.log('先月：' + dateYMprev + '-' + monthDayEndP + '（' + monthEndWeekdayP + WeekName[monthEndWeekdayP] + '）');
    // console.log('今月：' + dateYM + '-' + monthDayEndA + '（' + monthEndWeekdayA + WeekName[monthEndWeekdayA] + '）先月の最後の日曜：' + monthDayEndP + '-' + monthEndWeekdayP + '=' + countprevdayA);
    // console.log('来月：' + dateYM2 + '-' + monthDayEndB + '（' + monthEndWeekdayB + WeekName[monthEndWeekdayB] + '）先月の最後の日曜：' + monthDayEndA + '-' + monthEndWeekdayA + '=' + countprevdayB);


    countprevdayA--;
    countprevdayB--;

    mainContents = [
        <div key='maincontentskey'>
            <Box className='content_subbox_txt' style={{ margin: '2rem 0rem 2rem 0rem' }}>

                <TextField
                    id="celebrity_search"
                    label="崇暦内からキーワードで検索"
                    variant="outlined"
                    onChange={(e) => {
                        // 入力の値を状態に保持
                        setSearchValue(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        // Enter キーが押された場合に遷移
                        if (e.key === 'Enter' && searchValue) {
                            navigate(`/search/${searchValue}`);
                        }
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    edge="end"
                                    aria-label="search"
                                    onClick={() => {
                                        // SearchIconがクリックされたときに遷移
                                        if (searchValue) {
                                            navigate(`/search/${searchValue}`);
                                        }
                                    }}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#3277d5',  // 望む色に変更
                            },
                        },
                        backgroundColor: 'white',
                        width: '80vmin',
                        border: '0px solid #000',
                        borderRadius: 1
                    }}
                />
                <HtmlTooltip
                    leaveTouchDelay={5000}
                    enterTouchDelay={0}
                    title={
                        <React.Fragment>
                            <span style={{ lineHeight: '1.5rem' }}>
                                複数のキーワードでは検索出来ません。<br />
                                年月日の検索は、<br />
                                年月日は、YYYYMMDD（数字8桁）<br />
                                年月は、YYYYMM（数字6桁）<br />
                                年は、YYYY（数字6桁）で検索可。<br />
                            </span>
                        </React.Fragment>
                    }
                    placement='top-start'
                >
                    <ErrorOutlineIcon sx={{ fontSize: '2rem', color: '#3277d5', margin: '0px 0px 0px 10px' }} />
                </HtmlTooltip>
            </Box>



            <Box className='content_subbox_txt'>
                <div style={{ margin: '0rem 0rem 0rem 0rem', display: 'flex', alignItems: 'center' }}>
                    <ArrowLeftIcon color="disabled" />
                    <ArrowLeftIcon color="disabled" />
                    <SwipeIcon fontSize="small" color="disabled" />
                    <ArrowRightIcon color="disabled" />
                    <ArrowRightIcon color="disabled" />
                    <span style={{ color: '#111', fontSize: '0.8rem', marginLeft: '0.5rem' }}>Swipe on the table.</span>
                </div>
                <div className='table_enclosure'>
                    <table className='swipe_table' style={{ fontSize: '0.8rem' }}>
                        <thead key='swipe_table_thead'>
                            <tr>
                                {/* 概要：日にち、旧暦、二十四節気 */}
                                <th className='swipe_table_cell swipe_table_th_fixing' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', width: '4.5rem', minWidth: '4.5rem', maxWidth: '4.5rem', verticalAlign: 'middle', textAlign: 'right', lineHeight: '1.2rem', height: '50px', backgroundColor: '#e7e7e7' }}>
                                    <div>月日</div>
                                    <div></div>
                                </th>
                                {/* 概要：日にち、旧暦、二十四節気 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', width: '2.5rem', minWidth: '2.5rem', maxWidth: '2.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    <div>旧暦</div>
                                    <div>
                                        <span style={{ color: 'green', marginRight: '0.5rem' }}>節気</span>
                                    </div>
                                </td>
                                {/* 概要：六曜、干支 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', width: '2rem', minWidth: '2rem', maxWidth: '2rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    <div>六曜</div>
                                    <div>干支</div>
                                </td>
                                {/* 概要：月画像、満月新月 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', width: '12rem', minWidth: '12rem', maxWidth: '12rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    <div>0時の月の状態</div>
                                    <div>新月/上弦/満月/下弦</div>
                                </td>
                                {/* 概要：二十八宿、十二直 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', width: '2rem', minWidth: '2rem', maxWidth: '2rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    <div>12直</div>
                                    <div>28宿</div>
                                </td>
                                {/* 概要：二十八宿、十二直 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', width: '20rem', minWidth: '20rem', maxWidth: '20rem', verticalAlign: 'middle', lineHeight: '1.2rem', color: 'blue' }}>
                                    <div>十二直、吉事</div>
                                    <div>二十八宿、吉事</div>
                                </td>
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', width: '18rem', minWidth: '18rem', maxWidth: '18rem', verticalAlign: 'middle', lineHeight: '1.2rem', color: 'red' }}>
                                    <div>十二直、凶事</div>
                                    <div>二十八宿、凶事</div>
                                </td>
                                {/* 概要：御縁日 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', width: '22rem', minWidth: '22rem', maxWidth: '22rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    <div>御縁日（ごえんにち）の仏様</div>
                                    <div>※寺院により別日の場合あり</div>
                                </td>
                                {/* 概要：祝日/祭日 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0rem 0.5rem 0rem 0.5rem', width: '12rem', minWidth: '12rem', maxWidth: '12rem', verticalAlign: 'middle' }}>
                                    <div style={{ color: 'red' }}><span>祝日</span></div>
                                </td>
                            </tr>
                        </thead>
                        <tbody key='swipe_table'>
                            <tr key='day0'>
                                {/* 本日：日にち */}
                                <th className='swipe_table_cell swipe_table_th_fixing' style={{ backgroundColor: '#ffc', padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', textAlign: 'right', lineHeight: '1.2rem' }}>
                                    <div style={{ color: todayWcolor }}>{Number(dateM)}/{Number(dateD)}（{WeekName[weekMap[dateYMD]]}）</div>
                                    <div style={{ color: todayWcolor, marginRight: '0.5rem' }}>本日</div>
                                </th>
                                {/* 本日：旧暦、二十四節気 */}
                                <td className='swipe_table_cell' style={{ backgroundColor: '#ffc', padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    <div>{xxolddateMapS[dateYMD]}</div>
                                    <div>
                                        <span style={{ color: 'green', marginRight: '0.5rem', fontWeight: 'bold' }}>{sekkiTooltipTxt[dateYMD]}</span>
                                    </div>
                                </td>
                                {/* 本日：六曜、干支 */}
                                <td className='swipe_table_cell' style={{ backgroundColor: '#ffc', padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    <div>{Rokki[xxrokkiMap[dateYMD]]}</div>
                                    <div>{EtoName10[eto10dayMap[dateYMD]]}{EtoName12[eto12dayMap[dateYMD]]}</div>
                                </td>
                                {/* 本日：月画像、満月新月 */}
                                <td className='swipe_table_cell' style={{ backgroundColor: '#ffc', padding: '0rem 0.5rem 0rem 0.5rem', verticalAlign: 'bottom' }}>
                                    <div>
                                        <span><img width='35px' height='25px' src={`https://suureki.net/moon_img/moon_${moonEclipticRounding[dateYMD]}_half.svg`} alt={`月齢：${MoonAgeMap[dateYMD]}の月画像`} style={{ margin: '5px 0px -3px 0px' }} /></span>
                                    </div>
                                    <div>
                                        <span><img width='35px' height='auto' src={moonTooltipImg[dateYMD]} alt={moonTooltipAge[dateYMD]} style={{ margin: '5px 0px -3px 0px' }} /></span>
                                        <span style={{ marginLeft: '0.5rem', fontSize: '0.9rem', verticalAlign: 'text-bottom' }}>{moonTooltipTxt[dateYMD]}</span>
                                    </div>
                                </td>
                                {/* 本日：二十八宿、十二直 */}
                                <td className='swipe_table_cell' style={{ backgroundColor: '#ffc', padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    <div>{Zyunityoku[xxzyunityokuMap[dateYMD]]}</div>
                                    <div>{Yadohachi[xxyadohachiMap[dateYMD]]}</div>
                                </td>
                                {/* 本日：二十八宿、十二直 */}
                                <td className='swipe_table_cell' style={{ backgroundColor: '#ffc', padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem', color: 'blue' }}>
                                    <div>{ZyunityokuTotalG[xxzyunityokuMap[dateYMD]]}</div>
                                    <div>{YadohachiTotalG[xxyadohachiMap[dateYMD]]}</div>
                                </td>
                                <td className='swipe_table_cell' style={{ backgroundColor: '#ffc', padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem', color: 'red' }}>
                                    <div>{ZyunityokuTotalB[xxzyunityokuMap[dateYMD]]}</div>
                                    <div>{YadohachiTotalB[xxyadohachiMap[dateYMD]]}</div>
                                </td>
                                {/* 本日：御縁日 */}
                                <td className='swipe_table_cell' style={{ backgroundColor: '#ffc', padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    {buddhafairMap[dateYMD] && (
                                        <div>
                                            {buddhafairMap[dateYMD].split(',').filter(Boolean).map((num, index) => (
                                                <div key={`day0buddhafair-${num.trim()}`} style={{ whiteSpace: 'nowrap' }}>
                                                    {buddhaNameD[Number(num.trim())]}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </td>
                                {/* 本日：祝日/祭日 */}
                                <td className='swipe_table_cell' style={{ backgroundColor: '#ffc', padding: '0rem 0.5rem 0rem 0.5rem', verticalAlign: 'middle' }}>
                                    <div style={{ color: 'red' }}><span>{HoridayName[holidayMap[dateYMD]]}</span></div>
                                </td>
                            </tr>
                            <tr key='day1'>
                                {/* 明日：日にち */}
                                <th className='swipe_table_cell swipe_table_th_fixing' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', textAlign: 'right', lineHeight: '1.2rem', backgroundColor: '#e7e7e7' }}>
                                    <div style={{ color: dateYYYYMMDDW1Color }}>{Number(dateYYYYMMDD1.substring(4, 6))}/{Number(dateYYYYMMDD1.substring(6, 8))}（{WeekName[weekMap[dateYYYYMMDD1]]}）</div>
                                    <div style={{ color: dateYYYYMMDDW1Color, marginRight: '0.5rem' }}>明日</div>
                                </th>
                                {/* 明日：旧暦、二十四節気 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    <div>{xxolddateMapS[dateYYYYMMDD1]}</div>
                                    <div>
                                        <span style={{ color: 'green', marginRight: '0.5rem', fontWeight: 'bold' }}>{sekkiTooltipTxt[dateYYYYMMDD1]}</span>
                                    </div>
                                </td>
                                {/* 明日：六曜、干支 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    <div>{Rokki[xxrokkiMap[dateYYYYMMDD1]]}</div>
                                    <div>{EtoName10[eto10dayMap[dateYYYYMMDD1]]}{EtoName12[eto12dayMap[dateYYYYMMDD1]]}</div>
                                </td>
                                {/* 明日：月画像、満月新月 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'top', lineHeight: '1.2rem' }}>
                                    <div>
                                        <span><img width='35px' height='25px' src={`https://suureki.net/moon_img/moon_${moonEclipticRounding[dateYYYYMMDD1]}_half.svg`} alt={`月齢：${MoonAgeMap[dateYYYYMMDD1]}の月画像`} style={{ margin: '5px 0px -3px 0px' }} /></span>
                                    </div>
                                    <div>
                                        <span><img width='35px' height='auto' src={moonTooltipImg[dateYYYYMMDD1]} alt={moonTooltipAge[dateYYYYMMDD1]} style={{ margin: '5px 0px -3px 0px' }} /></span>
                                        <span style={{ marginLeft: '0.5rem', fontSize: '0.9rem', verticalAlign: 'text-bottom' }}>{moonTooltipTxt[dateYYYYMMDD1]}</span>
                                    </div>
                                </td>
                                {/* 明日：二十八宿、十二直 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    <div>{Zyunityoku[xxzyunityokuMap[dateYYYYMMDD1]]}</div>
                                    <div>{Yadohachi[xxyadohachiMap[dateYYYYMMDD1]]}</div>
                                </td>
                                {/* 明日：二十八宿、十二直 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem', color: 'blue' }}>
                                    <div>{ZyunityokuTotalG[xxzyunityokuMap[dateYYYYMMDD1]]}</div>
                                    <div>{YadohachiTotalG[xxyadohachiMap[dateYYYYMMDD1]]}</div>
                                </td>
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem', color: 'red' }}>
                                    <div>{ZyunityokuTotalB[xxzyunityokuMap[dateYYYYMMDD1]]}</div>
                                    <div>{YadohachiTotalB[xxyadohachiMap[dateYYYYMMDD1]]}</div>
                                </td>
                                {/* 明日：御縁日 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    {buddhafairMap[dateYYYYMMDD1] && (
                                        <div>
                                            {buddhafairMap[dateYYYYMMDD1].split(',').filter(Boolean).map((num, index) => (
                                                <div key={`day1buddhafair-${num.trim()}`} style={{ whiteSpace: 'nowrap' }}>
                                                    {buddhaNameD[Number(num.trim())]}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </td>
                                {/* 明日：祝日/祭日 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    <div style={{ marginTop: '-0.5rem', color: 'red' }}><span>{HoridayName[holidayMap[dateYYYYMMDD1]]}</span></div>
                                </td>
                            </tr>
                            <tr key='day2'>
                                {/* 2日後：日にち */}
                                <th className='swipe_table_cell swipe_table_th_fixing' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', textAlign: 'right', lineHeight: '1.2rem', backgroundColor: '#e7e7e7' }}>
                                    <div style={{ color: dateYYYYMMDDW2Color }}>{Number(dateYYYYMMDD2.substring(4, 6))}/{Number(dateYYYYMMDD2.substring(6, 8))}（{WeekName[weekMap[dateYYYYMMDD2]]}）</div>
                                    <div style={{ color: dateYYYYMMDDW2Color, marginRight: '0.5rem' }}>2日後</div>
                                </th>
                                {/* 2日後：旧暦、二十四節気 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    <div>{xxolddateMapS[dateYYYYMMDD2]}</div>
                                    <div>
                                        <span style={{ color: 'green', marginRight: '0.5rem', fontWeight: 'bold' }}>{sekkiTooltipTxt[dateYYYYMMDD2]}</span>
                                    </div>
                                </td>
                                {/* 2日後：六曜、干支 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    <div>{Rokki[xxrokkiMap[dateYYYYMMDD2]]}</div>
                                    <div>{EtoName10[eto10dayMap[dateYYYYMMDD2]]}{EtoName12[eto12dayMap[dateYYYYMMDD2]]}</div>
                                </td>
                                {/* 2日後：月画像、満月新月 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0rem 0.5rem 0rem 0.5rem', verticalAlign: 'bottom' }}>
                                    <div>
                                        <span><img width='35px' height='25px' src={`https://suureki.net/moon_img/moon_${moonEclipticRounding[dateYYYYMMDD2]}_half.svg`} alt={`月齢：${MoonAgeMap[dateYYYYMMDD2]}の月画像`} style={{ margin: '5px 0px -3px 0px' }} /></span>
                                    </div>
                                    <div>
                                        <span><img width='35px' height='auto' src={moonTooltipImg[dateYYYYMMDD2]} alt={moonTooltipAge[dateYYYYMMDD2]} style={{ margin: '5px 0px -3px 0px' }} /></span>
                                        <span style={{ marginLeft: '0.5rem', fontSize: '0.9rem', verticalAlign: 'text-bottom' }}>{moonTooltipTxt[dateYYYYMMDD2]}</span>
                                    </div>
                                </td>
                                {/* 2日後：二十八宿、十二直 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    <div>{Zyunityoku[xxzyunityokuMap[dateYYYYMMDD2]]}</div>
                                    <div>{Yadohachi[xxyadohachiMap[dateYYYYMMDD2]]}</div>
                                </td>
                                {/* 2日後：二十八宿、十二直 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem', color: 'blue' }}>
                                    <div>{ZyunityokuTotalG[xxzyunityokuMap[dateYYYYMMDD2]]}</div>
                                    <div>{YadohachiTotalG[xxyadohachiMap[dateYYYYMMDD2]]}</div>
                                </td>
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem', color: 'red' }}>
                                    <div>{ZyunityokuTotalB[xxzyunityokuMap[dateYYYYMMDD2]]}</div>
                                    <div>{YadohachiTotalB[xxyadohachiMap[dateYYYYMMDD2]]}</div>
                                </td>
                                {/* 2日後：御縁日 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    {buddhafairMap[dateYYYYMMDD2] && (
                                        <div>
                                            {buddhafairMap[dateYYYYMMDD2].split(',').filter(Boolean).map((num, index) => (
                                                <div key={`day2buddhafair-${num.trim()}`} style={{ whiteSpace: 'nowrap' }}>
                                                    {buddhaNameD[Number(num.trim())]}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </td>
                                {/* 2日後：祝日/祭日 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0rem 0.5rem 0rem 0.5rem', verticalAlign: 'middle' }}>
                                    <div style={{ marginTop: '-0.5rem', color: 'red' }}><span>{HoridayName[holidayMap[dateYYYYMMDD2]]}</span></div>
                                </td>
                            </tr>
                            <tr key='day3'>
                                {/* 3日後：日にち */}
                                <th className='swipe_table_cell swipe_table_th_fixing' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', textAlign: 'right', lineHeight: '1.2rem', backgroundColor: '#e7e7e7' }}>
                                    <div style={{ color: dateYYYYMMDDW3Color }}>{Number(dateYYYYMMDD3.substring(4, 6))}/{Number(dateYYYYMMDD3.substring(6, 8))}（{WeekName[weekMap[dateYYYYMMDD3]]}）</div>
                                    <div style={{ color: dateYYYYMMDDW3Color, marginRight: '0.5rem' }}>3日後</div>
                                </th>
                                {/* 3日後：旧暦、二十四節気 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    <div>{xxolddateMapS[dateYYYYMMDD3]}</div>
                                    <div>
                                        <span style={{ color: 'green', marginRight: '0.5rem', fontWeight: 'bold' }}>{sekkiTooltipTxt[dateYYYYMMDD3]}</span>
                                    </div>
                                </td>
                                {/* 3日後：六曜、干支 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    <div>{Rokki[xxrokkiMap[dateYYYYMMDD3]]}</div>
                                    <div>{EtoName10[eto10dayMap[dateYYYYMMDD3]]}{EtoName12[eto12dayMap[dateYYYYMMDD3]]}</div>
                                </td>
                                {/* 3日後：月画像、満月新月 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0rem 0.5rem 0rem 0.5rem', verticalAlign: 'bottom' }}>
                                    <div>
                                        <span><img width='35px' height='25px' src={`https://suureki.net/moon_img/moon_${moonEclipticRounding[dateYYYYMMDD3]}_half.svg`} alt={`月齢：${MoonAgeMap[dateYYYYMMDD3]}の月画像`} style={{ margin: '5px 0px -3px 0px' }} /></span>
                                    </div>
                                    <div>
                                        <span><img width='35px' height='auto' src={moonTooltipImg[dateYYYYMMDD3]} alt={moonTooltipAge[dateYYYYMMDD3]} style={{ margin: '5px 0px -3px 0px' }} /></span>
                                        <span style={{ marginLeft: '0.5rem', fontSize: '0.9rem', verticalAlign: 'text-bottom' }}>{moonTooltipTxt[dateYYYYMMDD3]}</span>
                                    </div>
                                </td>
                                {/* 3日後：二十八宿、十二直 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    <div>{Zyunityoku[xxzyunityokuMap[dateYYYYMMDD3]]}</div>
                                    <div>{Yadohachi[xxyadohachiMap[dateYYYYMMDD3]]}</div>
                                </td>
                                {/* 3日後：二十八宿、十二直 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem', color: 'blue' }}>
                                    <div>{ZyunityokuTotalG[xxzyunityokuMap[dateYYYYMMDD3]]}</div>
                                    <div>{YadohachiTotalG[xxyadohachiMap[dateYYYYMMDD3]]}</div>
                                </td>
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem', color: 'red' }}>
                                    <div>{ZyunityokuTotalB[xxzyunityokuMap[dateYYYYMMDD3]]}</div>
                                    <div>{YadohachiTotalB[xxyadohachiMap[dateYYYYMMDD3]]}</div>
                                </td>
                                {/* 3日後：御縁日 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    {buddhafairMap[dateYYYYMMDD3] && (
                                        <div>
                                            {buddhafairMap[dateYYYYMMDD3].split(',').filter(Boolean).map((num, index) => (
                                                <div key={`day3buddhafair-${num.trim()}`} style={{ whiteSpace: 'nowrap' }}>
                                                    {buddhaNameD[Number(num.trim())]}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </td>
                                {/* 3日後：祝日/祭日 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0rem 0.5rem 0rem 0.5rem', verticalAlign: 'middle' }}>
                                    <div style={{ marginTop: '-0.5rem', color: 'red' }}><span>{HoridayName[holidayMap[dateYYYYMMDD3]]}</span></div>
                                </td>
                            </tr>
                            <tr key='day4'>
                                {/* 4日後：日にち */}
                                <th className='swipe_table_cell swipe_table_th_fixing' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', textAlign: 'right', lineHeight: '1.2rem', backgroundColor: '#e7e7e7' }}>
                                    <div style={{ color: dateYYYYMMDDW4Color }}>{Number(dateYYYYMMDD4.substring(4, 6))}/{Number(dateYYYYMMDD4.substring(6, 8))}（{WeekName[weekMap[dateYYYYMMDD4]]}）</div>
                                    <div style={{ color: dateYYYYMMDDW4Color, marginRight: '0.5rem' }}>4日後</div>
                                </th>
                                {/* 4日後：旧暦、二十四節気 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    <div>{xxolddateMapS[dateYYYYMMDD4]}</div>
                                    <div>
                                        <span style={{ color: 'green', marginRight: '0.5rem', fontWeight: 'bold' }}>{sekkiTooltipTxt[dateYYYYMMDD4]}</span>
                                    </div>
                                </td>
                                {/* 4日後：六曜、干支 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    <div>{Rokki[xxrokkiMap[dateYYYYMMDD4]]}</div>
                                    <div>{EtoName10[eto10dayMap[dateYYYYMMDD4]]}{EtoName12[eto12dayMap[dateYYYYMMDD4]]}</div>
                                </td>
                                {/* 4日後：月画像、満月新月 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0rem 0.5rem 0rem 0.5rem', verticalAlign: 'bottom' }}>
                                    <div>
                                        <span><img width='35px' height='25px' src={`https://suureki.net/moon_img/moon_${moonEclipticRounding[dateYYYYMMDD4]}_half.svg`} alt={`月齢：${MoonAgeMap[dateYYYYMMDD4]}の月画像`} style={{ margin: '5px 0px -3px 0px' }} /></span>
                                    </div>
                                    <div>
                                        <span><img width='35px' height='auto' src={moonTooltipImg[dateYYYYMMDD4]} alt={moonTooltipAge[dateYYYYMMDD4]} style={{ margin: '5px 0px -3px 0px' }} /></span>
                                        <span style={{ marginLeft: '0.5rem', fontSize: '0.9rem', verticalAlign: 'text-bottom' }}>{moonTooltipTxt[dateYYYYMMDD4]}</span>
                                    </div>
                                </td>
                                {/* 4日後：二十八宿、十二直 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    <div>{Zyunityoku[xxzyunityokuMap[dateYYYYMMDD4]]}</div>
                                    <div>{Yadohachi[xxyadohachiMap[dateYYYYMMDD4]]}</div>
                                </td>
                                {/* 4日後：二十八宿、十二直 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem', color: 'blue' }}>
                                    <div>{ZyunityokuTotalG[xxzyunityokuMap[dateYYYYMMDD4]]}</div>
                                    <div>{YadohachiTotalG[xxyadohachiMap[dateYYYYMMDD4]]}</div>
                                </td>
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem', color: 'red' }}>
                                    <div>{ZyunityokuTotalB[xxzyunityokuMap[dateYYYYMMDD4]]}</div>
                                    <div>{YadohachiTotalB[xxyadohachiMap[dateYYYYMMDD4]]}</div>
                                </td>
                                {/* 4日後：御縁日 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    {buddhafairMap[dateYYYYMMDD4] && (
                                        <div>
                                            {buddhafairMap[dateYYYYMMDD4].split(',').filter(Boolean).map((num, index) => (
                                                <div key={`day4buddhafair-${num.trim()}`} style={{ whiteSpace: 'nowrap' }}>
                                                    {buddhaNameD[Number(num.trim())]}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </td>
                                {/* 4日後：祝日/祭日 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0rem 0.5rem 0rem 0.5rem', verticalAlign: 'middle' }}>
                                    <div style={{ marginTop: '-0.5rem', color: 'red' }}><span>{HoridayName[holidayMap[dateYYYYMMDD4]]}</span></div>
                                </td>
                            </tr>
                            <tr key='day5'>
                                {/* 5日後：日にち */}
                                <th className='swipe_table_cell swipe_table_th_fixing' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', textAlign: 'right', lineHeight: '1.2rem', backgroundColor: '#e7e7e7' }}>
                                    <div style={{ color: dateYYYYMMDDW5Color }}>{Number(dateYYYYMMDD5.substring(4, 6))}/{Number(dateYYYYMMDD5.substring(6, 8))}（{WeekName[weekMap[dateYYYYMMDD5]]}）</div>
                                    <div style={{ color: dateYYYYMMDDW5Color, marginRight: '0.5rem' }}>5日後</div>
                                </th>
                                {/* 5日後：旧暦、二十四節気 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    <div>{xxolddateMapS[dateYYYYMMDD5]}</div>
                                    <div>
                                        <span style={{ color: 'green', marginRight: '0.5rem', fontWeight: 'bold' }}>{sekkiTooltipTxt[dateYYYYMMDD5]}</span>
                                    </div>
                                </td>
                                {/* 5日後：六曜、干支 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    <div>{Rokki[xxrokkiMap[dateYYYYMMDD5]]}</div>
                                    <div>{EtoName10[eto10dayMap[dateYYYYMMDD5]]}{EtoName12[eto12dayMap[dateYYYYMMDD5]]}</div>
                                </td>
                                {/* 5日後：月画像、満月新月 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0rem 0.5rem 0rem 0.5rem', verticalAlign: 'bottom' }}>
                                    <div>
                                        <span><img width='35px' height='25px' src={`https://suureki.net/moon_img/moon_${moonEclipticRounding[dateYYYYMMDD5]}_half.svg`} alt={`月齢：${MoonAgeMap[dateYYYYMMDD5]}の月画像`} style={{ margin: '5px 0px -3px 0px' }} /></span>
                                    </div>
                                    <div>
                                        <span><img width='35px' height='auto' src={moonTooltipImg[dateYYYYMMDD5]} alt={moonTooltipAge[dateYYYYMMDD5]} style={{ margin: '5px 0px -3px 0px' }} /></span>
                                        <span style={{ marginLeft: '0.5rem', fontSize: '0.9rem', verticalAlign: 'text-bottom' }}>{moonTooltipTxt[dateYYYYMMDD5]}</span>
                                    </div>
                                </td>
                                {/* 5日後：二十八宿、十二直 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    <div>{Zyunityoku[xxzyunityokuMap[dateYYYYMMDD5]]}</div>
                                    <div>{Yadohachi[xxyadohachiMap[dateYYYYMMDD5]]}</div>
                                </td>
                                {/* 5日後：二十八宿、十二直 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem', color: 'blue' }}>
                                    <div>{ZyunityokuTotalG[xxzyunityokuMap[dateYYYYMMDD5]]}</div>
                                    <div>{YadohachiTotalG[xxyadohachiMap[dateYYYYMMDD5]]}</div>
                                </td>
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem', color: 'red' }}>
                                    <div>{ZyunityokuTotalB[xxzyunityokuMap[dateYYYYMMDD5]]}</div>
                                    <div>{YadohachiTotalB[xxyadohachiMap[dateYYYYMMDD5]]}</div>
                                </td>
                                {/* 5日後：御縁日 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    {buddhafairMap[dateYYYYMMDD5] && (
                                        <div>
                                            {buddhafairMap[dateYYYYMMDD5].split(',').filter(Boolean).map((num, index) => (
                                                <div key={`day5buddhafair-${num.trim()}`} style={{ whiteSpace: 'nowrap' }}>
                                                    {buddhaNameD[Number(num.trim())]}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </td>
                                {/* 5日後：祝日/祭日 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0rem 0.5rem 0rem 0.5rem', verticalAlign: 'middle' }}>
                                    <div style={{ marginTop: '-0.5rem', color: 'red' }}><span>{HoridayName[holidayMap[dateYYYYMMDD5]]}</span></div>
                                </td>
                            </tr>
                            <tr key='day6'>
                                {/* 6日後：日にち */}
                                <th className='swipe_table_cell swipe_table_th_fixing' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', textAlign: 'right', lineHeight: '1.2rem', backgroundColor: '#e7e7e7' }}>
                                    <div style={{ color: dateYYYYMMDDW6Color }}>{Number(dateYYYYMMDD6.substring(4, 6))}/{Number(dateYYYYMMDD6.substring(6, 8))}（{WeekName[weekMap[dateYYYYMMDD6]]}）</div>
                                    <div style={{ color: dateYYYYMMDDW6Color, marginRight: '0.5rem' }}>6日後</div>
                                </th>
                                {/* 6日後：旧暦、二十四節気 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    <div>{xxolddateMapS[dateYYYYMMDD6]}</div>
                                    <div>
                                        <span style={{ color: 'green', marginRight: '0.5rem', fontWeight: 'bold' }}>{sekkiTooltipTxt[dateYYYYMMDD6]}</span>
                                    </div>
                                </td>
                                {/* 6日後：六曜、干支 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    <div>{Rokki[xxrokkiMap[dateYYYYMMDD6]]}</div>
                                    <div>{EtoName10[eto10dayMap[dateYYYYMMDD6]]}{EtoName12[eto12dayMap[dateYYYYMMDD6]]}</div>
                                </td>
                                {/* 6日後：月画像、満月新月 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0rem 0.5rem 0rem 0.5rem', verticalAlign: 'bottom' }}>
                                    <div>
                                        <span><img width='35px' height='25px' src={`https://suureki.net/moon_img/moon_${moonEclipticRounding[dateYYYYMMDD6]}_half.svg`} alt={`月齢：${MoonAgeMap[dateYYYYMMDD6]}の月画像`} style={{ margin: '5px 0px -3px 0px' }} /></span>
                                    </div>
                                    <div>
                                        <span><img width='35px' height='auto' src={moonTooltipImg[dateYYYYMMDD6]} alt={moonTooltipAge[dateYYYYMMDD6]} style={{ margin: '5px 0px -3px 0px' }} /></span>
                                        <span style={{ marginLeft: '0.5rem', fontSize: '0.9rem', verticalAlign: 'text-bottom' }}>{moonTooltipTxt[dateYYYYMMDD6]}</span>
                                    </div>
                                </td>
                                {/* 6日後：二十八宿、十二直 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    <div>{Zyunityoku[xxzyunityokuMap[dateYYYYMMDD6]]}</div>
                                    <div>{Yadohachi[xxyadohachiMap[dateYYYYMMDD6]]}</div>
                                </td>
                                {/* 6日後：二十八宿、十二直 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem', color: 'blue' }}>
                                    <div>{ZyunityokuTotalG[xxzyunityokuMap[dateYYYYMMDD6]]}</div>
                                    <div>{YadohachiTotalG[xxyadohachiMap[dateYYYYMMDD6]]}</div>
                                </td>
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem', color: 'red' }}>
                                    <div>{ZyunityokuTotalB[xxzyunityokuMap[dateYYYYMMDD6]]}</div>
                                    <div>{YadohachiTotalB[xxyadohachiMap[dateYYYYMMDD6]]}</div>
                                </td>
                                {/* 6日後：御縁日 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    {buddhafairMap[dateYYYYMMDD6] && (
                                        <div>
                                            {buddhafairMap[dateYYYYMMDD6].split(',').filter(Boolean).map((num, index) => (
                                                <div key={`day6buddhafair-${num.trim()}`} style={{ whiteSpace: 'nowrap' }}>
                                                    {buddhaNameD[Number(num.trim())]}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </td>
                                {/* 6日後：祝日/祭日 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0rem 0.5rem 0rem 0.5rem', verticalAlign: 'middle' }}>
                                    <div style={{ marginTop: '-0.5rem', color: 'red' }}><span>{HoridayName[holidayMap[dateYYYYMMDD6]]}</span></div>
                                </td>
                            </tr>
                            <tr key='day7'>
                                {/* 7日後：日にち */}
                                <th className='swipe_table_cell swipe_table_th_fixing' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', textAlign: 'right', lineHeight: '1.2rem', backgroundColor: '#e7e7e7' }}>
                                    <div style={{ color: dateYYYYMMDDW7Color }}>{Number(dateYYYYMMDD7.substring(4, 6))}/{Number(dateYYYYMMDD7.substring(6, 8))}（{WeekName[weekMap[dateYYYYMMDD7]]}）</div>
                                    <div style={{ color: dateYYYYMMDDW7Color, marginRight: '0.5rem' }}>7日後</div>
                                </th>
                                {/* 7日後：旧暦、二十四節気 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    <div>{xxolddateMapS[dateYYYYMMDD7]}</div>
                                    <div>
                                        <span style={{ color: 'green', marginRight: '0.5rem', fontWeight: 'bold' }}>{sekkiTooltipTxt[dateYYYYMMDD7]}</span>
                                    </div>
                                </td>
                                {/* 7日後：六曜、干支 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    <div>{Rokki[xxrokkiMap[dateYYYYMMDD7]]}</div>
                                    <div>{EtoName10[eto10dayMap[dateYYYYMMDD7]]}{EtoName12[eto12dayMap[dateYYYYMMDD7]]}</div>
                                </td>
                                {/* 7日後：月画像、満月新月 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0rem 0.5rem 0rem 0.5rem', verticalAlign: 'bottom' }}>
                                    <div>
                                        <span><img width='35px' height='25px' src={`https://suureki.net/moon_img/moon_${moonEclipticRounding[dateYYYYMMDD7]}_half.svg`} alt={`月齢：${MoonAgeMap[dateYYYYMMDD7]}の月画像`} style={{ margin: '5px 0px -3px 0px' }} /></span>
                                    </div>
                                    <div>
                                        <span><img width='35px' height='auto' src={moonTooltipImg[dateYYYYMMDD7]} alt={moonTooltipAge[dateYYYYMMDD7]} style={{ margin: '5px 0px -3px 0px' }} /></span>
                                        <span style={{ marginLeft: '0.5rem' }}>{moonTooltipTxt[dateYYYYMMDD7]}</span>
                                    </div>
                                </td>
                                {/* 7日後：二十八宿、十二直 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    <div>{Zyunityoku[xxzyunityokuMap[dateYYYYMMDD7]]}</div>
                                    <div>{Yadohachi[xxyadohachiMap[dateYYYYMMDD7]]}</div>
                                </td>
                                {/* 7日後：二十八宿、十二直 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem', color: 'blue' }}>
                                    <div>{ZyunityokuTotalG[xxzyunityokuMap[dateYYYYMMDD7]]}</div>
                                    <div>{YadohachiTotalG[xxyadohachiMap[dateYYYYMMDD7]]}</div>
                                </td>
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem', color: 'red' }}>
                                    <div>{ZyunityokuTotalB[xxzyunityokuMap[dateYYYYMMDD7]]}</div>
                                    <div>{YadohachiTotalB[xxyadohachiMap[dateYYYYMMDD7]]}</div>
                                </td>
                                {/* 7日後：御縁日 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem', verticalAlign: 'middle', lineHeight: '1.2rem' }}>
                                    {buddhafairMap[dateYYYYMMDD7] && (
                                        <div>
                                            {buddhafairMap[dateYYYYMMDD7].split(',').filter(Boolean).map((num, index) => (
                                                <div key={`day7buddhafair-${num.trim()}`} style={{ whiteSpace: 'nowrap' }}>
                                                    {buddhaNameD[Number(num.trim())]}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </td>
                                {/* 7日後：祝日/祭日 */}
                                <td className='swipe_table_cell calendar_bgcolor' style={{ padding: '0rem 0.5rem 0rem 0.5rem', verticalAlign: 'middle' }}>
                                    <div style={{ marginTop: '-0.5rem', color: 'red' }}><span>{HoridayName[holidayMap[dateYYYYMMDD7]]}</span></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{ color: '#111', fontSize: '0.8rem', marginLeft: '0.5rem' }}>月画像は、各日0時の状態です。</div>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>今月来月カレンダー</h2>
                <Grid container>
                    <Grid key='CalendarA' sx={{ width: '330px', marginRight: '10px', marginBottom: '10px' }}>
                        <Box sx={{ borderBottom: '5px solid white', padding: '0px 0px 0px 0px', backgroundColor: '#eee', width: '330px', display: 'grid', gridAutoFlow: 'row', gridTemplateColumns: 'repeat(2, 1fr)', gap: 0, minWidth: '330px', }}>
                            <Box sx={{ textAlign: 'left', color: 'green', margin: '10px', fontWeight: 'normal', whiteSpace: 'nowrap', fontSize: '0.9rem' }}>
                                <span style={{ marginRight: '0.5rem' }}>
                                    今月、{dateY}年{Number(dateM)}月
                                </span>
                                <span style={{ marginRight: '1rem' }}>
                                    {MonName[Number(dateM)]}
                                </span>
                            </Box>
                            <Box sx={{ textAlign: 'right', margin: '10px', fontWeight: 'normal', fontSize: '0.9rem' }}>
                                <Link
                                    component={RouterLink}
                                    underline="always"
                                    key="calendar_link_dateYM"
                                    color="primary"
                                    to={`/calendar/${[dateYM]}`}
                                    onClick={handleClick}
                                >
                                    暦注
                                </Link>
                                <Link
                                    component={RouterLink}
                                    underline="always"
                                    key="goodday_link_dateYM"
                                    color="primary"
                                    to={`/goodday/${[dateYM]}`}
                                    onClick={handleClick}
                                    sx={{ marginLeft: '1rem' }}
                                >
                                    吉凶
                                </Link>
                            </Box>
                        </Box>
                        <Box sx={{
                            borderBottom: '5px solid white',
                            padding: '0px 0px 0px 0px',
                            backgroundColor: '#eee',
                            width: '330px',
                            display: 'grid',
                            gridAutoFlow: 'row',
                            gridTemplateColumns: 'repeat(7, 1fr)',
                            gap: 0,
                            minWidth: '330px',
                            fontSize: '0.9rem'
                        }}>
                            <Box sx={{ textAlign: 'right', color: '#f00', margin: '0px', paddingRight: '15px' }}>{WeekName[0]}</Box>
                            <Box sx={{ textAlign: 'right', color: '#000', margin: '0px', paddingRight: '15px' }}>{WeekName[1]}</Box>
                            <Box sx={{ textAlign: 'right', color: '#000', margin: '0px', paddingRight: '15px' }}>{WeekName[2]}</Box>
                            <Box sx={{ textAlign: 'right', color: '#000', margin: '0px', paddingRight: '15px' }}>{WeekName[3]}</Box>
                            <Box sx={{ textAlign: 'right', color: '#000', margin: '0px', paddingRight: '15px' }}>{WeekName[4]}</Box>
                            <Box sx={{ textAlign: 'right', color: '#000', margin: '0px', paddingRight: '15px' }}>{WeekName[5]}</Box>
                            <Box sx={{ textAlign: 'right', color: '#00F', margin: '0px', paddingRight: '15px' }}>{WeekName[6]}</Box>
                        </Box>
                        {
                            Array.from({ length: 6 }, (_, i) => (
                                <Box key={`outer-${i}`} sx={{
                                    borderBottom: '5px solid white',
                                    padding: '0px 0px 0px 0px',
                                    width: '330px',
                                    display: 'grid',
                                    gridAutoFlow: 'row',
                                    gridTemplateColumns: 'repeat(7, 1fr)',
                                    gap: 0,
                                    minWidth: '330px',
                                }} className='calendar_bgcolor'>
                                    {Array.from({ length: 7 }, (_, ii) => {
                                        countA++;
                                        if (countA >= 1 && countA <= monthDayEndA) {
                                            // カレンダー表示（1日~28日-31日）
                                            let tmpDateYMD = dateYM + String(countA).padStart(2, '0');
                                            let weekcolor = 'black';
                                            let todayBackColor = '#eee';
                                            if (dateYMD === tmpDateYMD) { todayBackColor = '#ffc' } else { todayBackColor = '#eee' }
                                            if (thisDayHoriday || ii === 0) { weekcolor = `red`; } else if (ii === 6) { weekcolor = `blue`; } else { weekcolor = `black`; }
                                            if (holidayMap[tmpDateYMD] !== undefined) { weekcolor = `red`; }
                                            return (<Box key={`inner-${ii}`} sx={{ textAlign: 'right', height: '40px', margin: '5px 10px 5px 0px', lineHeight: '1.2rem', backgroundColor: todayBackColor, paddingRight: '7px' }}>
                                                {/* 日にち */}
                                                <span style={{ fontSize: '1.1rem', textAlign: 'right', color: weekcolor, whiteSpace: 'nowrap', marginBottom: '0px' }}>{countA}</span><br />
                                                <span style={{ fontSize: '0.8rem', textAlign: 'right', color: '#111', whiteSpace: 'nowrap', marginBottom: '0px' }}>{Rokki[xxrokkiMap[tmpDateYMD]]}</span>
                                            </Box>);
                                        } else if (countA > monthDayEndA) {
                                            // 次月の日にち
                                            let countnextmonth = countA - monthDayEndA;
                                            return (<Box key={`inner-${ii}`} sx={{ textAlign: 'right', color: '#111', height: '40px', fontSize: '1.0rem', margin: '5px 10px 5px 0px', paddingRight: '7px' }}>{countnextmonth}</Box>);
                                        } else {
                                            // 前月の日にち
                                            countprevdayA = countprevdayA + 1;
                                            return (<Box key={`inner-${ii}`} sx={{ textAlign: 'right', color: '#111', height: '40px', fontSize: '1.0rem', margin: '5px 10px 5px 0px', paddingRight: '7px' }}>{countprevdayA}</Box>);
                                        }
                                    })}
                                </Box>
                            ))
                        }
                        <Box sx={{
                            borderBottom: '5px solid white',
                            padding: '0px 0px 0px 0px',
                            backgroundColor: '#eee',
                            width: '330px',
                            display: 'grid',
                            gridAutoFlow: 'row',
                            gridTemplateColumns: 'repeat(1, 1fr)',
                            gap: 0,
                            minWidth: '330px',
                            fontSize: '0.9rem'
                        }}>
                            <Box sx={{ textAlign: 'left', color: 'black', margin: '0px', lineHeight: '1.5rem', backgroundColor: 'white' }}>
                                <div style={{ color: 'red' }}>
                                    <div dangerouslySetInnerHTML={{ __html: horidayCommentYM[dateYM] }} />
                                </div>
                                <div style={{ color: 'green' }}>
                                    <div dangerouslySetInnerHTML={{ __html: sekkiCommentYM[dateYM] }} />
                                </div>
                                <div style={{ color: 'black' }}>
                                    <div dangerouslySetInnerHTML={{ __html: etcdayCommentYM[dateYM] }} />
                                </div>
                                <div style={{ color: 'black' }}>
                                    <div dangerouslySetInnerHTML={{ __html: moonCommentYM[dateYM] }} />
                                </div>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid key='CalendarB' sx={{ width: '330px', marginRight: '10px', marginBottom: '10px' }}>
                        <Box sx={{ borderBottom: '5px solid white', padding: '0px 0px 0px 0px', backgroundColor: '#eee', width: '330px', display: 'grid', gridAutoFlow: 'row', gridTemplateColumns: 'repeat(2, 1fr)', gap: 0, minWidth: '330px', }}>
                            <Box sx={{ textAlign: 'left', color: 'green', margin: '10px', fontWeight: 'normal', whiteSpace: 'nowrap', fontSize: '0.9rem' }}>
                                <span style={{ marginRight: '0.5rem' }}>
                                    来月、{dateY2}年{Number(dateM2)}月
                                </span>
                                <span style={{ marginRight: '0.5rem' }}>
                                    {MonName[Number(dateM2)]}
                                </span>
                            </Box>
                            <Box sx={{ textAlign: 'right', margin: '10px', fontWeight: 'normal', fontSize: '0.9rem' }}>
                                <Link
                                    component={RouterLink}
                                    underline="always"
                                    key="calendar_link_dateYM2"
                                    color="primary"
                                    to={`/calendar/${[dateYM2]}`}
                                    onClick={handleClick}
                                >
                                    暦注
                                </Link>
                                <Link
                                    component={RouterLink}
                                    underline="always"
                                    key="goodday_link_dateYM2"
                                    color="primary"
                                    to={`/goodday/${[dateYM2]}`}
                                    onClick={handleClick}
                                    sx={{ marginLeft: '1rem' }}
                                >
                                    吉凶
                                </Link>
                            </Box>
                        </Box>
                        <Box sx={{
                            borderBottom: '5px solid white',
                            padding: '0px 0px 0px 0px',
                            backgroundColor: '#eee',
                            width: '330px',
                            display: 'grid',
                            gridAutoFlow: 'row',
                            gridTemplateColumns: 'repeat(7, 1fr)',
                            gap: 0,
                            minWidth: '330px',
                            fontSize: '0.9rem'
                        }}>
                            <Box sx={{ textAlign: 'right', color: '#f00', margin: '0px', paddingRight: '15px' }}>{WeekName[0]}</Box>
                            <Box sx={{ textAlign: 'right', color: '#000', margin: '0px', paddingRight: '15px' }}>{WeekName[1]}</Box>
                            <Box sx={{ textAlign: 'right', color: '#000', margin: '0px', paddingRight: '15px' }}>{WeekName[2]}</Box>
                            <Box sx={{ textAlign: 'right', color: '#000', margin: '0px', paddingRight: '15px' }}>{WeekName[3]}</Box>
                            <Box sx={{ textAlign: 'right', color: '#000', margin: '0px', paddingRight: '15px' }}>{WeekName[4]}</Box>
                            <Box sx={{ textAlign: 'right', color: '#000', margin: '0px', paddingRight: '15px' }}>{WeekName[5]}</Box>
                            <Box sx={{ textAlign: 'right', color: '#00F', margin: '0px', paddingRight: '15px' }}>{WeekName[6]}</Box>
                        </Box>
                        {
                            Array.from({ length: 6 }, (_, i) => (
                                <Box key={`outer-${i}`} sx={{
                                    borderBottom: '5px solid white',
                                    padding: '0px 0px 0px 0px',
                                    width: '330px',
                                    display: 'grid',
                                    gridAutoFlow: 'row',
                                    gridTemplateColumns: 'repeat(7, 1fr)',
                                    gap: 0,
                                    minWidth: '330px',
                                }} className='calendar_bgcolor'>
                                    {Array.from({ length: 7 }, (_, ii) => {
                                        countB++;
                                        if (countB >= 1 && countB <= monthDayEndB) {
                                            // カレンダー表示（1日~28日-31日）
                                            let tmpDateYMD2 = dateYM2 + String(countB).padStart(2, '0');
                                            let weekcolor = 'black';
                                            if (thisDayHoriday || ii === 0) { weekcolor = `red`; } else if (ii === 6) { weekcolor = `blue`; } else { weekcolor = `black`; }
                                            if (holidayMap[tmpDateYMD2] !== undefined) { weekcolor = `red`; }
                                            return (<Box key={`inner-${ii}`} sx={{ textAlign: 'right', height: '40px', margin: '5px 10px 5px 0px', lineHeight: '1.2rem', paddingRight: '7px' }}>
                                                {/* 日にち */}
                                                <span style={{ fontSize: '1.1rem', textAlign: 'right', color: weekcolor, whiteSpace: 'nowrap', marginBottom: '0px' }}>{countB}</span><br />
                                                <span style={{ fontSize: '0.8rem', textAlign: 'right', color: '#111', whiteSpace: 'nowrap', marginBottom: '0px' }}>{Rokki[xxrokkiMap[tmpDateYMD2]]}</span>
                                            </Box>);
                                        } else if (countB > monthDayEndB) {
                                            // 次月の日にち
                                            let countnextmonth = countB - monthDayEndB;
                                            return (<Box key={`inner-${ii}`} sx={{ textAlign: 'right', color: '#111', height: '40px', fontSize: '1.0rem', margin: '5px 10px 5px 0px', paddingRight: '7px' }}>{countnextmonth}</Box>);
                                        } else {
                                            // 前月の日にち
                                            countprevdayB = countprevdayB + 1;
                                            return (<Box key={`inner-${ii}`} sx={{ textAlign: 'right', color: '#111', height: '40px', fontSize: '1.0rem', margin: '5px 10px 5px 0px', paddingRight: '7px' }}>{countprevdayB}</Box>);
                                        }
                                    })}
                                </Box>
                            ))
                        }
                        <Box sx={{
                            borderBottom: '5px solid white',
                            padding: '0px 0px 0px 0px',
                            backgroundColor: '#eee',
                            width: '330px',
                            display: 'grid',
                            gridAutoFlow: 'row',
                            gridTemplateColumns: 'repeat(1, 1fr)',
                            gap: 0,
                            minWidth: '330px',
                            fontSize: '0.9rem'
                        }}>
                            <Box sx={{ textAlign: 'left', color: 'black', margin: '0px', lineHeight: '1.5rem', backgroundColor: 'white' }}>
                                <div style={{ color: 'red' }}>
                                    <div dangerouslySetInnerHTML={{ __html: horidayCommentYM[dateYM2] }} />
                                </div>
                                <div style={{ color: 'green' }}>
                                    <div dangerouslySetInnerHTML={{ __html: sekkiCommentYM[dateYM2] }} />
                                </div>
                                <div style={{ color: 'black' }}>
                                    <div dangerouslySetInnerHTML={{ __html: etcdayCommentYM[dateYM2] }} />
                                </div>
                                <div style={{ color: 'black' }}>
                                    <div dangerouslySetInnerHTML={{ __html: moonCommentYM[dateYM2] }} />
                                </div>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box key='maincontentlink' style={{ marginTop: '0px' }}>
                <h2 className='content_subtitle'>リンク</h2>
                <div style={{ lineHeight: '2rem' }}>
                    <Link
                        component={RouterLink}
                        underline="always"
                        key="calendar_link_dateY-1"
                        color="primary"
                        to={`/calendar/${[dateY - 1]}`}
                        onClick={handleClick}
                        style={{ marginRight: '1rem' }}
                    >
                        <span>令和{dateJpNumberE - 1}年/{dateY - 1}年暦</span>
                    </Link>
                    <Link
                        component={RouterLink}
                        underline="always"
                        key="calendar_link_dateY"
                        color="primary"
                        to={`/calendar/${[dateY]}`}
                        onClick={handleClick}
                        style={{ marginRight: '1rem', backgroundColor: '#ffc' }}
                    >
                        <span>令和{dateJpNumberE}年/{dateY}年暦</span>
                    </Link>
                    <Link
                        component={RouterLink}
                        underline="always"
                        key="calendar_link_dateY1"
                        color="primary"
                        to={`/calendar/${[dateY + 1]}`}
                        onClick={handleClick}
                        style={{ marginRight: '1rem' }}
                    >
                        <span>令和{dateJpNumberE + 1}年/{dateY + 1}年暦</span>
                    </Link>
                    <Link
                        component={RouterLink}
                        underline="always"
                        key="calendar_link_dateY2"
                        color="primary"
                        to={`/calendar/${[dateY + 2]}`}
                        onClick={handleClick}
                        style={{ marginRight: '1rem' }}
                    >
                        <span>令和{dateJpNumberE + 2}年/{dateY + 2}年暦</span>
                    </Link>
                </div>
                <div style={{ marginTop: '1rem', lineHeight: '2rem' }}>
                    <Link
                        component={RouterLink}
                        underline="always"
                        key="aspect_link_dateY-1"
                        color="primary"
                        to={`/aspect/${[dateY - 1]}`}
                        onClick={handleClick}
                        style={{ marginRight: '1rem' }}
                    >
                        <span>令和{dateJpNumberE - 1}年/{dateY - 1}年座相</span>
                    </Link>
                    <Link
                        component={RouterLink}
                        underline="always"
                        key="aspect_link_dateY"
                        color="primary"
                        to={`/aspect/${[dateY]}`}
                        onClick={handleClick}
                        style={{ marginRight: '1rem', backgroundColor: '#ffc' }}
                    >
                        <span>令和{dateJpNumberE}年/{dateY}年座相</span>
                    </Link>
                    <Link
                        component={RouterLink}
                        underline="always"
                        key="aspect_link_dateY1"
                        color="primary"
                        to={`/aspect/${[dateY + 1]}`}
                        onClick={handleClick}
                        style={{ marginRight: '1rem' }}
                    >
                        <span>令和{dateJpNumberE + 1}年/{dateY + 1}年座相</span>
                    </Link>
                    <Link
                        component={RouterLink}
                        underline="always"
                        key="aspect_link_dateY2"
                        color="primary"
                        to={`/aspect/${[dateY + 2]}`}
                        onClick={handleClick}
                        style={{ marginRight: '1rem' }}
                    >
                        <span>令和{dateJpNumberE + 2}年/{dateY + 2}年座相</span>
                    </Link>
                </div>
                <div style={{ marginTop: '1rem', lineHeight: '2rem' }}>
                    <Link
                        component={RouterLink}
                        underline="always"
                        key="calendar_link_dateYMPrev"
                        color="primary"
                        to={`/calendar/${[dateYMprev]}`}
                        onClick={handleClick}
                        style={{ marginRight: '1rem' }}
                    >
                        <span>{dateYMprev.substring(0, 4)}年{Number(dateYMprev.substring(4, 6))}月暦</span>
                    </Link>
                    <Link
                        component={RouterLink}
                        underline="always"
                        key="calendar_link_dateYM"
                        color="primary"
                        to={`/calendar/${[dateYM]}`}
                        onClick={handleClick}
                        style={{ marginRight: '1rem', backgroundColor: '#ffc' }}
                    >
                        <span>{dateY}年{dateM}月暦</span>
                    </Link>
                    <Link
                        component={RouterLink}
                        underline="always"
                        key="calendar_link_dateYM2"
                        color="primary"
                        to={`/calendar/${[dateYM2]}`}
                        onClick={handleClick}
                        style={{ marginRight: '1rem' }}
                    >
                        <span>{dateY2}年{dateM2}月暦</span>
                    </Link>
                    <Link
                        component={RouterLink}
                        underline="always"
                        key="calendar_link_dateYM3"
                        color="primary"
                        to={`/calendar/${[dateYM3]}`}
                        onClick={handleClick}
                        style={{ marginRight: '1rem' }}
                    >
                        <span>{dateYM3.substring(0, 4)}年{Number(dateYM3.substring(4, 6))}月暦</span>
                    </Link>
                </div>
                <div style={{ marginTop: '1rem', lineHeight: '2rem' }}>
                    <Link
                        component={RouterLink}
                        underline="always"
                        key="calendar_link_horiday"
                        color="primary"
                        to={`/archives/db_horiday`}
                        onClick={handleClick}
                        style={{ marginRight: '1rem' }}
                    >
                        <span>去年と今年と来年の祝日一覧</span>
                    </Link>

                </div>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseInarticle />
            </div>
        </div>
    ];

    /*
    <meta name="description" content="This is an example page" />
    <meta property="og:site_name" content="suureki.net">
    <meta property="og:title" content="柿本実さんの情報【suureki.net | すうれきねっと】">
    <meta property="og:description" content="万年暦・カレンダーサイト。日本の伝統的な暦法を現代に伝えることを目指したサイトです。二十八宿や十二直での吉凶判断、二十四節気/七十二候等の様々な暦注を掲載・検索。生年月日による吉凶判断も。">
    <meta property="og:type" content="website">
    <meta property="og:locale" content="ja_JP">
    <meta property="og:url" content="https://suureki.net/75882/register.php?id=17630317">
    <meta property="og:image" content="https://suureki.net/img/suureki_ogp_1200x630.png">
    <meta property="og:image:width" content="1200">
    <meta property="og:image:height" content="630">
    <meta name="twitter:card" content="summary_large_image">
    <meta name="twitter:url" content="https://suureki.net/">
    <meta name="twitter:image" content="https://suureki.net/img/suureki_ogp_1200x630.png">
    <meta name="twitter:title" content="日々、役立つ暦注を！">
    <meta name="twitter:description" content="万年暦・こよみサイト。二十八宿や十二直、二十四節気/七十二候などの様々な暦注を掲載。">
    <meta name="twitter:site" content="@suureki_cal">
    */

    const pageKey = "Home";
    const metaDescription = pageDescription[pageKey];
    const metaTitle = pageTitle;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    //　トップページの場合
    // https://suureki.net/
    const schemaOrg = {
        "@context": "https://schema.org",
        "@type": siteType,                                  // トップページはWebSite 、トップページ以外はWebPage
        "@id": siteUrlHead,                                 // トップページURL、https://suureki.net/
        "url": siteUrlHead,                                 // トップページURL、https://suureki.net/
        "name": siteNameS,                                  // サイト名、カレンダーの崇暦
        "datePublished": datePublished,                     // 公開日
        "dateModified": dateY + '-' + dateM + '-' + dateD,  // 更新日
        "description": pageDescription[pageKey],            // 説明文
        "articleBody": articleBody[pageKey],                // 詳細な説明文
        "publisher": {
            "@type": publisherType,                         // 組織、Organization
            "name": publisherName,                          // 組織名、suureki.net
            "logo": {
                "@type": publisherLogoType,                 // ImageObject
                "url": publisherLogoUrl                     // https://suureki.net/img/suureki_ogp_1200x630.png // 96px x 96px
            }
        }
    };

    // その他のページの場合
    // （記事の場合、headlineとarticleBody項目を追加）
    // https://suureki.net/about
    // const schemaOrg2 = {
    //     "@context": "https://schema.org",
    //     "@graph": [
    //         {
    //             "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
    //             "@id": "https://suureki.net/about",             // ページURL
    //             "url": "https://suureki.net/about",             // ページURL
    //             "isPartOf": { "@id": siteUrlHead + '/' },       // トップページURL、https://suureki.net/
    //             "name": "ページタイトルをここに記述",             // ページタイトル
    //             "datePublished": datePublished,                 // 公開日
    //             "dateModified": "2023-11-22",                   // 更新日
    //             "description": pageDescription['About'],        // 説明文
    //             "image": publisherLogoUrl,                      // 画像
    //         }
    //     ]
    // };

    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{pageTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>

                        {/* ***** メインコンテンツ ******************************************************************* */}

                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ lineHeight: '1.6rem' }}>
                                    <div>
                                        <span style={{ color: '#111' }}>明治{dateJpNumberA}年/</span>
                                        <span style={{ color: '#111' }}>大正{dateJpNumberB}年/</span>
                                        <span style={{ color: '#111' }}>昭和{dateJpNumberC}年/</span>
                                        <span style={{ color: '#111' }}>平成{dateJpNumberD}年</span>
                                    </div>
                                    <div>
                                        <span style={{ color: 'green', fontWeight: 'normal', fontSize: '1.2rem' }}>令和{dateJpNumberE}年・</span>
                                        <span style={{ color: 'green', fontWeight: 'normal', fontSize: '1.2rem' }}>{dateY}年{Number(dateM)}月{Number(dateD)}日（{WeekName[todayW]}）</span>
                                    </div>
                                    <div>
                                        <span>{EtoName12[eto12yearMap[dateYMD]]}年（{EtoName12RubyA[eto12yearMap[dateYMD]]}年）、</span>
                                        <span style={{ color: '#111' }}>来年は {EtoName12[eto12yearMap[`${dateY + 1}0101`]]}年（{EtoName12RubyA[eto12yearMap[`${dateY + 1}0101`]]}年）</span>
                                    </div>
                                    <div style={{ color: 'red' }}>{HoridayName[holidayMap[dateYMD]]}</div>
                                </div>
                            </h1>
                            <div>{mainContents}</div>
                        </div>
                        <div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
});

const App = () => (
    <AuthProvider>
        <Home />
    </AuthProvider>
);

export default App;