// src/pages/Archives.tsx
import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Breadcrumbs, Typography, Stack, IconButton, Box, TextField, styled, InputAdornment } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBannerMobile from '../components/AdSenseBannerMobile'; // 320x100、ラージモバイルバナー をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // 記事内広告 をインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SearchIcon from '@mui/icons-material/Search';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');  // 日本語ロケールを設定

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        background: 'linear-gradient(to right, rgba(231, 244, 255, 0.99),rgba(231, 244, 255, 0.99), rgba(243, 250, 255, 0.99))',
        color: 'black',
        fontSize: theme.typography.pxToRem(16),
        border: '1px solid black',
    },
}));


function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}
const pageTitle = `サイトマップ`
const pageTitleS = `サイトマップ` // ページトップのナビゲーションリンク

const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton
            aria-label="ホームページへページ移動"
            sx={{
                margin: '0px 0px 1px 0px',
                padding: '0px'
            }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];

const today = new Date();
const todayY = today.getFullYear();
const todayM = ("0" + (today.getMonth() + 1)).slice(-2); // JavaScriptの月は0から始まるので、1を足して調整
const todayD = ("0" + today.getDate()).slice(-2);


const Sitemap: React.FC = React.memo(() => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState("");

    let dateY = today.getFullYear();
    let dateM = (today.getMonth() + 1).toString().padStart(2, '0');
    let dateD = (today.getDate()).toString().padStart(2, '0');
    let dateF = dateY + "-" + dateM + "-" + dateD;


    const mainContents = [
        <div key='maincontentskey'>
            <h2 className='content_subtitle'>サイトマップ</h2>
            <ul>
                <li className='inside-list-style'>
                    <Link component={RouterLink} underline="always" key="home" color="primary" to="/" onClick={handleClick}>
                        Home
                    </Link>
                </li>
                <ul>
                    <li className='inside-list-style' style={{ backgroundColor: '#f0f0f0', padding: '0.2rem 0.3rem 0.2rem 1rem', margin: '0.4rem 0rem 0.4rem -1rem' }}>
                        サイト内検索
                    </li>
                    <ul>
                        <Box className='content_subbox_txt' style={{ margin: '2rem 0rem 2rem 0rem' }}>
                            <TextField
                                id="celebrity_search"
                                label="崇暦内からキーワードで検索"
                                variant="outlined"
                                onChange={(e) => {
                                    // 入力の値を状態に保持
                                    setSearchValue(e.target.value);
                                }}
                                onKeyDown={(e) => {
                                    // Enter キーが押された場合に遷移
                                    if (e.key === 'Enter' && searchValue) {
                                        navigate(`/search/${searchValue}`);
                                    }
                                }}

                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton
                                                edge="end"
                                                aria-label="search"
                                                onClick={() => {
                                                    // SearchIconがクリックされたときに遷移
                                                    if (searchValue) {
                                                        navigate(`/search/${searchValue}`);
                                                    }
                                                }}
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}

                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#3277d5',  // 望む色に変更
                                        },
                                    },
                                    backgroundColor: 'white',
                                    width: '80vmin',
                                    border: '0px solid #000',
                                    borderRadius: 1
                                }}
                            />
                            <HtmlTooltip
                                leaveTouchDelay={5000}
                                enterTouchDelay={0}
                                title={
                                    <React.Fragment>
                                        <span style={{ lineHeight: '1.5rem' }}>
                                            複数のキーワードでは検索出来ません。<br />
                                            年月日の検索は、<br />
                                            年月日は、YYYYMMDD（数字8桁）<br />
                                            年月は、YYYYMM（数字6桁）<br />
                                            年は、YYYY（数字6桁）で検索可。<br />
                                        </span>
                                    </React.Fragment>
                                }
                                placement='top-start'
                            >
                                <ErrorOutlineIcon sx={{ fontSize: '2rem', color: '#3277d5', margin: '0px 0px 0px 10px' }} />
                            </HtmlTooltip>
                        </Box>

                    </ul>
                </ul>
            </ul>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <ul>
                <ul>
                    <li className='inside-list-style' style={{ backgroundColor: '#f0f0f0', padding: '0.2rem 0.3rem 0.2rem 1rem', margin: '0.4rem 0rem 0.4rem -1rem' }}>
                        <Link component={RouterLink} underline="always" key="calendar" color="primary" to="/calendar" onClick={handleClick}>
                            カレンダー/日暦・月暦・年暦の検索
                        </Link>
                    </li>
                    <ul>
                        <li className='inside-list-style-sub'>
                            <Link component={RouterLink} underline="always" key="calendar_day" color="primary" to={`/calendar/${todayY}${todayM}${todayD}`} onClick={handleClick}>
                                今日の日暦
                            </Link>
                        </li>
                        <li className='inside-list-style-sub'>
                            <Link component={RouterLink} underline="always" key="calendar_month" color="primary" to={`/calendar/${todayY}${todayM}`} onClick={handleClick}>
                                今月の月暦
                            </Link>
                        </li>
                        <li className='inside-list-style-sub'>
                            <Link component={RouterLink} underline="always" key="calendar_year" color="primary" to={`/calendar/${todayY}`} onClick={handleClick}>
                                今年の年暦
                            </Link>
                        </li>
                        <li className='inside-list-style-sub'>
                            <Link component={RouterLink} underline="always" key="calendar_search" color="primary" to={`/calendar_search`} onClick={handleClick}>
                                暦の条件による検索
                            </Link>
                        </li>
                        <li className='inside-list-style-sub'>
                            他の日暦（1901年~2100年）
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                                <DemoContainer components={['MobileDatePicker']}>
                                    <Box display="flex" alignItems="center" key='searchKeyD' style={{ marginBottom: '0.5rem' }}>
                                        <MobileDatePicker
                                            views={['year', 'month', 'day']}
                                            defaultValue={dayjs(dateF)}
                                            onChange={(newValue) => {
                                                if (newValue && newValue.isValid()) {
                                                    const formattedDate = newValue.format('YYYYMMDD');
                                                    navigate(`/calendar/${formattedDate}`);
                                                }
                                            }}
                                            format="YYYY/MM/DD"
                                            minDate={dayjs('1901-01-01')}
                                            maxDate={dayjs('2100-12-31')}
                                            localeText={{ toolbarTitle: '日暦の年月日' }}
                                            slotProps={{
                                                toolbar: {
                                                    toolbarPlaceholder: '__',
                                                    toolbarFormat: 'YYYY年MM月DD日',
                                                    hidden: false,
                                                }
                                            }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: '#3277d5',  // 望む色に変更
                                                    },
                                                },
                                            }}
                                        />
                                    </Box>
                                </DemoContainer>
                            </LocalizationProvider>
                        </li>
                        <li className='inside-list-style-sub'>
                            他の月暦（1901年~2100年）
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                                <DemoContainer components={['MobileDatePicker']}>
                                    <Box display="flex" alignItems="center" key='searchKeyM' style={{ marginBottom: '0.5rem' }}>
                                        <MobileDatePicker
                                            views={['year', 'month']}
                                            defaultValue={dayjs(dateF)}
                                            onChange={(newValue) => {
                                                if (newValue) {
                                                    const formattedDate = newValue.format('YYYYMM');
                                                    navigate(`/calendar/${formattedDate}`);
                                                }
                                            }}
                                            format="YYYY/MM"
                                            minDate={dayjs('1901-01-01')}
                                            maxDate={dayjs('2100-12-31')}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: '#3277d5',  // 望む色に変更
                                                    },
                                                },
                                            }}
                                        />
                                    </Box>
                                </DemoContainer>
                            </LocalizationProvider>
                        </li>
                        <li className='inside-list-style-sub'>
                            他の年暦（1901年~2100年）
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                                <DemoContainer components={['MobileDatePicker']}>
                                    <Box display="flex" alignItems="center" key='searchKeyY' style={{ marginBottom: '0.5rem' }}>
                                        <MobileDatePicker
                                            views={['year']}
                                            defaultValue={dayjs(dateF)}
                                            onChange={(newValue) => {
                                                if (newValue) {
                                                    const formattedDate = newValue.format('YYYY');
                                                    navigate(`/calendar/${formattedDate}`);
                                                }
                                            }}
                                            format="YYYY"
                                            minDate={dayjs('1901-01-01')}
                                            maxDate={dayjs('2100-12-31')}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: '#3277d5',  // 望む色に変更
                                                    },
                                                },
                                            }}
                                        />
                                    </Box>
                                </DemoContainer>
                            </LocalizationProvider>
                        </li>
                    </ul>
                </ul>
            </ul>
            <ul>
                <ul>
                    <li className='inside-list-style' style={{ backgroundColor: '#f0f0f0', padding: '0.2rem 0.3rem 0.2rem 1rem', margin: '0.4rem 0rem 0.4rem -1rem' }}>
                        <Link
                            component={RouterLink} underline="always" key="aspect" color="primary" to="/aspect" onClick={handleClick}>
                            座相
                        </Link>
                    </li>
                    <ul>
                        <li className='inside-list-style-sub'>
                            <Link component={RouterLink} underline="always" key="aspect_year" color="primary" to={`/aspect/${todayY}`} onClick={handleClick}>
                                今年の座相
                            </Link>
                        </li>
                        <li className='inside-list-style-sub'>
                            他の年の座相（1901年~2100年）
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                                <DemoContainer components={['MobileDatePicker']}>
                                    <Box display="flex" alignItems="center" key='aspectKeyY' style={{ marginBottom: '0.5rem' }}>
                                        <MobileDatePicker
                                            views={['year']}
                                            defaultValue={dayjs(dateF)}
                                            onChange={(newValue) => {
                                                if (newValue) {
                                                    const formattedDate = newValue.format('YYYY');
                                                    navigate(`/aspect/${formattedDate}`);
                                                }
                                            }}
                                            format="YYYY"
                                            minDate={dayjs('1901-01-01')}
                                            maxDate={dayjs('2100-12-31')}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: '#3277d5',  // 望む色に変更
                                                    },
                                                },
                                            }}
                                        />
                                    </Box>
                                </DemoContainer>
                            </LocalizationProvider>
                        </li>
                    </ul>
                </ul>
            </ul>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <ul>
                <ul>
                    <li className='inside-list-style' style={{ backgroundColor: '#f0f0f0', padding: '0.2rem 0.3rem 0.2rem 1rem', margin: '0.4rem 0rem 0.4rem -1rem' }}>
                        <Link
                            component={RouterLink} underline="always" key="goodday" color="primary" to="/goodday" onClick={handleClick}>
                            二十八宿と十二直による吉凶判断・カレンダー
                        </Link>
                    </li>
                    <ul>
                        <li className='inside-list-style-sub'>
                            <Link component={RouterLink} underline="always" key="goodday" color="primary" to={`/goodday/${todayY}${todayM}`} onClick={handleClick}>
                                今月の吉凶判断・カレンダー
                            </Link>
                        </li>
                        <li className='inside-list-style-sub'>
                            他の吉凶判断・カレンダー（1901年~2100年）
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                                <DemoContainer components={['MobileDatePicker']}>
                                    <Box display="flex" alignItems="center" key='searchKeyM' style={{ marginBottom: '0.5rem' }}>
                                        <MobileDatePicker
                                            views={['year', 'month']}
                                            defaultValue={dayjs(dateF)}
                                            onChange={(newValue) => {
                                                if (newValue) {
                                                    const formattedDate = newValue.format('YYYYMM');
                                                    navigate(`/goodday/${formattedDate}`);
                                                }
                                            }}
                                            format="YYYY/MM"
                                            minDate={dayjs('1901-01-01')}
                                            maxDate={dayjs('2100-12-31')}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: '#3277d5',  // 望む色に変更
                                                    },
                                                },
                                            }}
                                        />
                                    </Box>
                                </DemoContainer>
                            </LocalizationProvider>
                        </li>
                    </ul>
                </ul>
            </ul>
            <ul>
                <ul>
                    <li className='inside-list-style' style={{ backgroundColor: '#f0f0f0', padding: '0.2rem 0.3rem 0.2rem 1rem', margin: '0.4rem 0rem 0.4rem -1rem' }}>
                        <Link
                            component={RouterLink} underline="always" key="buddhafair" color="primary" to="/buddhafair" onClick={handleClick}>
                            仏様（寺院の神様）御縁日カレンダー
                        </Link>
                    </li>
                    <ul>
                        <li className='inside-list-style-sub'>
                            <Link component={RouterLink} underline="always" key="buddhafair" color="primary" to={`/buddhafair/${todayY}${todayM}`} onClick={handleClick}>
                                今月の御縁日カレンダー
                            </Link>
                        </li>
                        <li className='inside-list-style-sub'>
                            他の御縁日カレンダー（1901年~2100年）
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                                <DemoContainer components={['MobileDatePicker']}>
                                    <Box display="flex" alignItems="center" key='searchKeyM' style={{ marginBottom: '0.5rem' }}>
                                        <MobileDatePicker
                                            views={['year', 'month']}
                                            defaultValue={dayjs(dateF)}
                                            onChange={(newValue) => {
                                                if (newValue) {
                                                    const formattedDate = newValue.format('YYYYMM');
                                                    navigate(`/buddhafair/${formattedDate}`);
                                                }
                                            }}
                                            format="YYYY/MM"
                                            minDate={dayjs('1901-01-01')}
                                            maxDate={dayjs('2100-12-31')}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: '#3277d5',  // 望む色に変更
                                                    },
                                                },
                                            }}
                                        />
                                    </Box>
                                </DemoContainer>
                            </LocalizationProvider>
                        </li>
                    </ul>
                </ul>
            </ul>
            <ul>
                <ul>
                    <li className='inside-list-style' style={{ backgroundColor: '#f0f0f0', padding: '0.2rem 0.3rem 0.2rem 1rem', margin: '0.4rem 0rem 0.4rem -1rem' }}>
                        <Link
                            component={RouterLink} underline="always" key="birthday" color="primary" to="/birth" onClick={handleClick}>
                            誕生日による、様々な情報・吉凶
                        </Link>
                    </li>
                    <ul>
                        <li className='inside-list-style-sub'>
                            誕生日の選択（1901年~2090年）
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                                <DemoContainer components={['MobileDatePicker']}>
                                    <Box display="flex" alignItems="center" key='birthKeyYMD' style={{ marginBottom: '0.5rem' }}>
                                        <MobileDatePicker
                                            views={['year', 'month', 'day']}
                                            defaultValue={dayjs(dateF)}
                                            onChange={(newValue) => {
                                                if (newValue && newValue.isValid()) {
                                                    const formattedDate = newValue.format('YYYYMMDD');
                                                    navigate(`/birth/${formattedDate}`);
                                                }
                                            }}
                                            format="YYYY/MM/DD"
                                            minDate={dayjs('1901-01-01')}
                                            maxDate={dayjs('2090-12-31')}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: '#3277d5',  // 望む色に変更
                                                    },
                                                },
                                            }}
                                        />
                                    </Box>
                                </DemoContainer>
                            </LocalizationProvider>
                        </li>
                    </ul>
                </ul>
            </ul>
            <ul>
                <ul>
                    <li className='inside-list-style' style={{ backgroundColor: '#f0f0f0', padding: '0.2rem 0.3rem 0.2rem 1rem', margin: '0.4rem 0rem 0.4rem -1rem' }}>
                        <Link component={RouterLink} underline="always" key="archives" color="primary" to="/celebrity" onClick={handleClick}>
                            著名人の情報
                        </Link>
                    </li>
                    <ul>
                        <li className='inside-list-style-sub'>
                            著名人の検索
                            <div style={{ marginBottom: '1rem' }}>
                                <TextField
                                    id="celebrity_search"
                                    label=""
                                    variant="outlined"
                                    onChange={(e) => {
                                        // 入力の値を状態に保持
                                        setSearchValue(e.target.value);
                                    }}
                                    onKeyDown={(e) => {
                                        // Enter キーが押された場合に遷移
                                        if (e.key === 'Enter' && searchValue) {
                                            navigate(`/celebrity_search/${searchValue}`);
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton
                                                    edge="end"
                                                    aria-label="search"
                                                    onClick={() => {
                                                        // SearchIconがクリックされたときに遷移
                                                        if (searchValue) {
                                                            navigate(`/celebrity_search/${searchValue}`);
                                                        }
                                                    }}
                                                >
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#3277d5',  // 望む色に変更
                                            },
                                        },
                                        backgroundColor: 'white',
                                        width: '80vmin',
                                        border: '0px solid #000',
                                        borderRadius: 1
                                    }}
                                />
                            </div>

                        </li>
                    </ul>

                </ul>
            </ul>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <ul>
                <ul>
                    <li className='inside-list-style' style={{ backgroundColor: '#f0f0f0', padding: '0.2rem 0.3rem 0.2rem 1rem', margin: '0.4rem 0rem 0.4rem -1rem' }}>
                        <Link component={RouterLink} underline="always" key="archives" color="primary" to="/archives" onClick={handleClick}>
                            情報/データベース
                        </Link>
                    </li>
                    <ul>
                        <li className='inside-list-style-sub'>
                            祝日/祭日一覧
                        </li>
                        <ul>
                            <li className='inside-list-style-sub-sub'>
                                <Link component={RouterLink} underline="always" key="db_horiday" color="primary" to="/archives/db_horiday" onClick={handleClick}>
                                    去年~来年迄
                                </Link>
                            </li>
                            <li className='inside-list-style-sub-sub'>
                                <Link component={RouterLink} underline="always" key="db_horiday_1941-1950" color="primary" to="/archives/db_horiday/1941" onClick={handleClick}>
                                    1949~1950年迄
                                </Link>
                            </li>
                            <li className='inside-list-style-sub-sub'>
                                <Link component={RouterLink} underline="always" key="db_horiday_1951-1960" color="primary" to="/archives/db_horiday/1951" onClick={handleClick}>
                                    1951~1960年迄
                                </Link>
                            </li>
                            <li className='inside-list-style-sub-sub'>
                                <Link component={RouterLink} underline="always" key="db_horiday_1961-1970" color="primary" to="/archives/db_horiday/1961" onClick={handleClick}>
                                    1961~1970年迄
                                </Link>
                            </li>
                            <li className='inside-list-style-sub-sub'>
                                <Link component={RouterLink} underline="always" key="db_horiday_1971-1980" color="primary" to="/archives/db_horiday/1971" onClick={handleClick}>
                                    1971~1980年迄
                                </Link>
                            </li>
                            <li className='inside-list-style-sub-sub'>
                                <Link component={RouterLink} underline="always" key="db_horiday_1981-1990" color="primary" to="/archives/db_horiday/1981" onClick={handleClick}>
                                    1981~1990年迄
                                </Link>
                            </li>
                            <li className='inside-list-style-sub-sub'>
                                <Link component={RouterLink} underline="always" key="db_horiday_1991-2000" color="primary" to="/archives/db_horiday/1991" onClick={handleClick}>
                                    1991~2000年迄
                                </Link>
                            </li>
                            <li className='inside-list-style-sub-sub'>
                                <Link component={RouterLink} underline="always" key="db_horiday_2001-2010" color="primary" to="/archives/db_horiday/2001" onClick={handleClick}>
                                    2001~2010年迄
                                </Link>
                            </li>
                            <li className='inside-list-style-sub-sub'>
                                <Link component={RouterLink} underline="always" key="db_horiday_2011-2020" color="primary" to="/archives/db_horiday/2011" onClick={handleClick}>
                                    2011~2020年迄
                                </Link>
                            </li>
                            <li className='inside-list-style-sub-sub'>
                                <Link component={RouterLink} underline="always" key="db_horiday_2021-2030" color="primary" to="/archives/db_horiday/2021" onClick={handleClick}>
                                    2021~2030年迄
                                </Link>
                            </li>
                        </ul>
                        <li className='inside-list-style-sub'>
                            <Link component={RouterLink} underline="always" key="db_etcday" color="primary" to="/archives/db_etcday" onClick={handleClick}>
                                祝日ではない日と風習の一覧
                            </Link>
                        </li>
                        <li className='inside-list-style-sub'>
                            <Link component={RouterLink} underline="always" key="db_rekigetsu" color="primary" to="/archives/db_rekigetsu" onClick={handleClick}>
                                年干支と年九星の一覧
                            </Link>
                        </li>
                        <li className='inside-list-style-sub'>
                            満月と新月の日
                        </li>
                        <ul>
                            <li className='inside-list-style-sub-sub'>
                                <Link component={RouterLink} underline="always" key="db_moon" color="primary" to="/archives/db_moon" onClick={handleClick}>
                                    半年前~半年後迄（含･時間）
                                </Link>
                            </li>
                            <li className='inside-list-style-sub-sub'>
                                <Link component={RouterLink} underline="always" key="db_moon_1901" color="primary" to="/archives/db_moon/1901" onClick={handleClick}>
                                    1901~1910年迄
                                </Link>
                            </li>
                            <li className='inside-list-style-sub-sub'>
                                <Link component={RouterLink} underline="always" key="db_moon_1911" color="primary" to="/archives/db_moon/1911" onClick={handleClick}>
                                    1911~1920年迄
                                </Link>
                            </li>
                            <li className='inside-list-style-sub-sub'>
                                <Link component={RouterLink} underline="always" key="db_moon_1921" color="primary" to="/archives/db_moon/1921" onClick={handleClick}>
                                    1921~1930年迄
                                </Link>
                            </li>
                            <li className='inside-list-style-sub-sub'>
                                <Link component={RouterLink} underline="always" key="db_moon_1931" color="primary" to="/archives/db_moon/1931" onClick={handleClick}>
                                    1931~1940年迄
                                </Link>
                            </li>
                            <li className='inside-list-style-sub-sub'>
                                <Link component={RouterLink} underline="always" key="db_moon_1941" color="primary" to="/archives/db_moon/1941" onClick={handleClick}>
                                    1941~1950年迄
                                </Link>
                            </li>
                            <li className='inside-list-style-sub-sub'>
                                <Link component={RouterLink} underline="always" key="db_moon_1951" color="primary" to="/archives/db_moon/1951" onClick={handleClick}>
                                    1951~1960年迄
                                </Link>
                            </li>
                            <li className='inside-list-style-sub-sub'>
                                <Link component={RouterLink} underline="always" key="db_moon_1961" color="primary" to="/archives/db_moon/1961" onClick={handleClick}>
                                    1961~1970年迄
                                </Link>
                            </li>
                            <li className='inside-list-style-sub-sub'>
                                <Link component={RouterLink} underline="always" key="db_moon_1971" color="primary" to="/archives/db_moon/1971" onClick={handleClick}>
                                    1971~1980年迄
                                </Link>
                            </li>
                            <li className='inside-list-style-sub-sub'>
                                <Link component={RouterLink} underline="always" key="db_moon_1981" color="primary" to="/archives/db_moon/1981" onClick={handleClick}>
                                    1981~1990年迄
                                </Link>
                            </li>
                            <li className='inside-list-style-sub-sub'>
                                <Link component={RouterLink} underline="always" key="db_moon_1991" color="primary" to="/archives/db_moon/1991" onClick={handleClick}>
                                    1991~2000年迄
                                </Link>
                            </li>
                            <li className='inside-list-style-sub-sub'>
                                <Link component={RouterLink} underline="always" key="db_moon_2001" color="primary" to="/archives/db_moon/2001" onClick={handleClick}>
                                    2001~2010年迄
                                </Link>
                            </li>
                            <li className='inside-list-style-sub-sub'>
                                <Link component={RouterLink} underline="always" key="db_moon_2011" color="primary" to="/archives/db_moon/2011" onClick={handleClick}>
                                    2011~2020年迄
                                </Link>
                            </li>
                            <li className='inside-list-style-sub-sub'>
                                <Link component={RouterLink} underline="always" key="db_moon_2021" color="primary" to="/archives/db_moon/2021" onClick={handleClick}>
                                    2021~2030年迄
                                </Link>
                            </li>
                            <li className='inside-list-style-sub-sub'>
                                <Link component={RouterLink} underline="always" key="db_moon_2031" color="primary" to="/archives/db_moon/2031" onClick={handleClick}>
                                    2031~2040年迄
                                </Link>
                            </li>
                            <li className='inside-list-style-sub-sub'>
                                <Link component={RouterLink} underline="always" key="db_moon_2041" color="primary" to="/archives/db_moon/2041" onClick={handleClick}>
                                    2041~2050年迄
                                </Link>
                            </li>
                            <li className='inside-list-style-sub-sub'>
                                <Link component={RouterLink} underline="always" key="db_moon_2051" color="primary" to="/archives/db_moon/2051" onClick={handleClick}>
                                    2051~2060年迄
                                </Link>
                            </li>
                            <li className='inside-list-style-sub-sub'>
                                <Link component={RouterLink} underline="always" key="db_moon_2061" color="primary" to="/archives/db_moon/2061" onClick={handleClick}>
                                    2061~2070年迄
                                </Link>
                            </li>
                            <li className='inside-list-style-sub-sub'>
                                <Link component={RouterLink} underline="always" key="db_moon_2071" color="primary" to="/archives/db_moon/2071" onClick={handleClick}>
                                    2071~2080年迄
                                </Link>
                            </li>
                            <li className='inside-list-style-sub-sub'>
                                <Link component={RouterLink} underline="always" key="db_moon_2081" color="primary" to="/archives/db_moon/2081" onClick={handleClick}>
                                    2081~2090年迄
                                </Link>
                            </li>
                            <li className='inside-list-style-sub-sub'>
                                <Link component={RouterLink} underline="always" key="db_moon_2091" color="primary" to="/archives/db_moon/2091" onClick={handleClick}>
                                    2091~2100年迄
                                </Link>
                            </li>
                        </ul>
                        <li className='inside-list-style-sub'>
                            <Link component={RouterLink} underline="always" key="Quick_age_chartA" color="primary" to="/archives/db_koyomihenkan" onClick={handleClick}>
                                満年齢の年齢早見表
                            </Link>
                        </li>
                        <li className='inside-list-style-sub'>
                            <Link component={RouterLink} underline="always" key="Quick_age_chartB" color="primary" to="/archives/db_countingage" onClick={handleClick}>
                                数え年の年齢早見表
                            </Link>
                        </li>
                        <li className='inside-list-style-sub'>
                            <Link component={RouterLink} underline="always" key="Western_calendar_conversion_table" color="primary" to="/archives/db_adjpcalendar" onClick={handleClick}>
                                西暦和暦変換表
                            </Link>
                        </li>
                        <li className='inside-list-style-sub'>
                            <Link component={RouterLink} underline="always" key="Twenty-four_Sections" color="primary" to="/archives/db_24sekki" onClick={handleClick}>
                                二十四節気の日時
                            </Link>
                        </li>
                        <li className='inside-list-style-sub'>
                            <Link component={RouterLink} underline="always" key="horoscope" color="primary" to="/archives/db_zodiacsigns" onClick={handleClick}>
                                12星座と13星座の星座表
                            </Link>
                        </li>
                        <li className='inside-list-style-sub'>
                            <Link component={RouterLink} underline="always" key="School-Entrance_Graduation-Chronology" color="primary" to="/archives/db_school_eg" onClick={handleClick}>
                                学校入学/卒業年表
                            </Link>
                        </li>
                    </ul>
                </ul>
            </ul>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <ul>
                <ul>
                    <li className='inside-list-style' style={{ backgroundColor: '#f0f0f0', padding: '0.2rem 0.3rem 0.2rem 1rem', margin: '0.4rem 0rem 0.4rem -1rem' }}>
                        <Link component={RouterLink} underline="always" key="annotation" color="primary" to="/annotation" onClick={handleClick}>
                            暦注とは？
                        </Link>
                    </li>
                    <ul>
                        <li className='inside-list-style-sub'>
                            <Link component={RouterLink} underline="always" key="annotation-eto" color="primary" to="/annotation/eto" onClick={handleClick}>
                                干支とは
                            </Link>
                        </li>
                        <li className='inside-list-style-sub'>
                            <Link component={RouterLink} underline="always" key="annotation-28" color="primary" to="/annotation/28" onClick={handleClick}>
                                二十八宿・二十七宿とは / 吉凶表
                            </Link>
                        </li>
                        <li className='inside-list-style-sub'>
                            <Link component={RouterLink} underline="always" key="annotation-12" color="primary" to="/annotation/12" onClick={handleClick}>
                                十二直とは / 吉凶表
                            </Link>
                        </li>
                        <li className='inside-list-style-sub'>
                            <Link component={RouterLink} underline="always" key="annotation-24sekki" color="primary" to="/annotation/24sekki" onClick={handleClick}>
                                二十四節気とは
                            </Link>
                        </li>
                        <li className='inside-list-style-sub'>
                            <Link component={RouterLink} underline="always" key="annotation-72" color="primary" to="/annotation/72" onClick={handleClick}>
                                二十四節気・七十二候・節月とは
                            </Link>
                        </li>
                        <li className='inside-list-style-sub'>
                            <Link component={RouterLink} underline="always" key="annotation-sz" color="primary" to="/annotation/sz" onClick={handleClick}>
                                選日・雑節とは
                            </Link>
                        </li>
                    </ul>
                </ul>
            </ul>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <ul>
                <ul>
                    <li className='inside-list-style' style={{ backgroundColor: '#f0f0f0', padding: '0.2rem 0.3rem 0.2rem 1rem', margin: '0.4rem 0rem 0.4rem -1rem' }}>
                        <Link component={RouterLink} underline="always" key="about" color="primary" to="/about" onClick={handleClick}>
                            崇暦について
                        </Link>
                    </li>
                    <li className='inside-list-style' style={{ backgroundColor: '#f0f0f0', padding: '0.2rem 0.3rem 0.2rem 1rem', margin: '0.4rem 0rem 0.4rem -1rem' }}>
                        <Link component={RouterLink} underline="always" key="update" color="primary" to="/update" onClick={handleClick}>
                            更新履歴
                        </Link>
                    </li>
                    <li className='inside-list-style' style={{ backgroundColor: '#f0f0f0', padding: '0.2rem 0.3rem 0.2rem 1rem', margin: '0.4rem 0rem 0.4rem -1rem' }}>
                        <Link component={RouterLink} underline="always" key="privacy" color="primary" to="/privacy" onClick={handleClick}>
                            プライバシーポリシー・免責事項
                        </Link>
                    </li>
                    <li className='inside-list-style' style={{ backgroundColor: '#f0f0f0', padding: '0.2rem 0.3rem 0.2rem 1rem', margin: '0.4rem 0rem 0.4rem -1rem' }}>
                        <Link component={RouterLink} underline="always" key="mailmagazine" color="primary" to="/mailmagazine" onClick={handleClick}>
                            暦注の配信
                        </Link>
                    </li>
                    <li className='inside-list-style' style={{ backgroundColor: '#f0f0f0', padding: '0.2rem 0.3rem 0.2rem 1rem', margin: '0.4rem 0rem 0.4rem -1rem' }}>
                        <Link component={RouterLink} underline="always" key="navi" color="primary" to="/navi" onClick={handleClick}>
                            知りたいことナビ
                        </Link>
                    </li>
                </ul>
            </ul>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseInarticle />
            </div>
        </div>
    ];

    const pageKey = "Sitemap";
    const pagePath = pagePathS[pageKey];

    const metaDescription = pageDescription[pageKey];
    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        console.log(pagePath + '::', encryptedUid.substring(0, 3));

        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitle, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitle]);


    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };

    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>

                        {/* ***** メインコンテンツ ******************************************************************* */}

                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                            </h1>
                            <div>{mainContents}</div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
});

const App = () => (
    <AuthProvider>
        <Sitemap />
    </AuthProvider>
);

export default App;